import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import qs from 'qs';
import algoliasearch from 'algoliasearch';
import {
  Configure,
  connectRefinementList,
  connectHierarchicalMenu,
  SearchBox,
  ClearRefinements,
  CurrentRefinements,
  SortBy,
  NumericMenu,
} from 'react-instantsearch-dom';
import { DSRefinementList } from '#components/Search/CustomRefinements';
import constants from '#lib/constants';
import HitAsset from '#components/Search/Hit';
import { Asset, Project } from '#types/index';
import { getInfiniteHitsComponent } from '#components/Search/infiniteHits';
import { CustomRangeInput } from '#components/Search/CustomRangeInput';
import { CustomHierarchicalMenu } from '#components/Search/HierarchicalMenu';
import {
  DropspotRangeSlider,
  DropspotRangeSlider2,
} from '#components/Search/CustomRangeSlider';
import { useFirebaseCurrentUser } from '#lib/hooks/useFirebaseCurrentUser';
import cn from 'classnames';
import { CollectionTraitsSearch } from '#components/TraderDashboard/Project/CollectionTraitsSearch';
import {
  Filter,
  getFiltersFromUrl,
} from '#components/TraderDashboard/TraderHomePage';
import { FirestoreQuery, FirestoreQueryItem } from '#lib/firestore';
import { Dialog, Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { FunnelIcon, XMarkIcon } from '@heroicons/react/24/outline';

const InfiniteHits = getInfiniteHitsComponent<Asset>();

const searchClient = algoliasearch(
  '91RRC8EAH8',
  'f1f7d0552a938afdece75849a9b79abc'
);

type AssetSearchProps = {
  collectionId?: string;
  showRarity?: boolean;
  inGroupOfferMode?: boolean;
  showSideFilter?: boolean;
  showProjectInfo?: boolean;
  heightClass?: string;
  useB64ForImage?: boolean;
  floor?: number;
  imageDisplayPreference?: string;
  overrideBaseMediaType?: boolean;
  preventOnchainRender?: boolean;
  showFilters?: boolean;
  containerClasses?: string;
  hitsPerPage?: number;
  traitFilters?: string;
  project?: Project;
  listedCount?: number;
  doSelectAsset?: (asset: Asset) => void;
};

const assetIndex = constants.ALGOLIA_ASSET_INDEX;
const assetSearchIndex = constants.ALGOLIA_ASSET_INDEX;
const assetSearchIndexOldestFirst = constants.ALGOLIA_ASSET_OLDEST_FIRST_INDEX;
const assetRecentFirst = 'ASSET_RECENTLY_UPDATED_FIRST';
const updateAfter = 700;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SearchState = any;
const createURL = (state: SearchState) => `?${qs.stringify(state)}`;

const pathToSearchState = (path: string) =>
  path.includes('?') ? qs.parse(path.substring(path.indexOf('?') + 1)) : {};

export const searchStateToURL = (searchState: SearchState) =>
  searchState ? `${window.location.pathname}?${qs.stringify(searchState)}` : '';

const CustomDSRefinementList = connectRefinementList(DSRefinementList);
const DropspotRangeInput = connectRefinementList(CustomRangeInput);
const DropspotTraitsFilter = connectHierarchicalMenu(CustomHierarchicalMenu);

export const InfiniteAssetSearchV3: FC<AssetSearchProps> = ({
  collectionId,
  showRarity = false,
  showSideFilter = true,
  inGroupOfferMode = false,
  showFilters = false,
  showProjectInfo = false,
  useB64ForImage = false,
  floor,
  heightClass = 'h-[26rem] sm:h-[25rem]',
  imageDisplayPreference = 'IMAGE',
  overrideBaseMediaType,
  preventOnchainRender,
  project,
  containerClasses = 'grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-8 px-4 sm:px-0',
  hitsPerPage = 20,
  doSelectAsset,
  listedCount = 0,
}) => {
  const [searchState, setSearchState] = React.useState(
    pathToSearchState(typeof window === 'object' ? window.location.href : '')
  );
  const router = useRouter();

  const filterDiv = useRef<HTMLDivElement>(null);
  const debouncedSetState = React.useRef<NodeJS.Timeout>();

  const [traitFiltersLocal, setTraitFiltersLocal] = useState<
    string | undefined
  >();

  React.useEffect(() => {
    if (router) {
      router.beforePopState(({ url }) => {
        setSearchState(pathToSearchState(url));
        return true;
      });
    }
  }, [router]);

  useEffect(() => {
    //clear traits
    setTraitFiltersLocal(undefined);
    
  }, [collectionId]);

  const [filters, setFilters] = useState<Filter[]>([]);
  const [traitFilter, setTraitFilter] = useState<Filter>();

  useEffect(() => {
    const traitFilter = filters.find((f) => f.type === 'trait');
    console.log('traitFilter>>>>>', traitFilter);
    setTraitFilter(traitFilter);
    setFilters(getFiltersFromUrl(router));

    console.log('current filters: ', getFiltersFromUrl(router));
  }, [router]);
  const DEFAULT_QUERY = {
    desc: true,
    orderBy: 'creationDate',
    whereClauses: [
      { fieldPath: 'projectId', opStr: '==', value: project?.id || '' },
    ] as FirestoreQueryItem[],
  };
  const [open, setOpen] = useState(false);
  const { currentUser } = useFirebaseCurrentUser();
  const [currentFirestoreQuery, setCurrentFirestoreQuery] =
    useState<FirestoreQuery>(DEFAULT_QUERY);
  return (
    <div className="px-2 w-full h-full">
      {showFilters && (
        <>
          <>
            <div className="sticky top-0 flex gap-0 flex-wrap items-center justify-between z-10 pb-6 pt-6 sm:-mx-12 sm:px-12">
              <div className="flex flex-row items-center justify-start gap-4 flex-wrap">
                <SearchBox />
                {/* <MobileView>{renderMobileFilter()}</MobileView> */}

                <Popover>
                  <Popover.Button>
                    <div className="flex flex-row gap-2 bg-white px-5 py-3 outline-0 focus:outline-none items-center rounded-full border border-gray-300 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-200 hover:-translate-y-1 duration-300 hover:shadow-lg">
                      <p className="font-semibold">Status</p>

                      <ChevronDownIcon className="w-5 h-5" />
                    </div>
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                    unmount={false}
                  >
                    <Popover.Panel
                      unmount={false}
                      className="absolute z-10 bg-white shadow-2xl rounded-xl dark:bg-gray-900 mt-1 border dark:border-gray-700 dark:text-gray-100"
                    >
                      <div className="grid grid-cols-1 w-48 p-4">
                        <CustomDSRefinementList
                          attribute="marketStatus"
                          searchable={false}
                          transformItems={(items) =>
                            items.map((item: { label: string }) => ({
                              ...item,
                              label:
                                item.label == 'Listed'
                                  ? 'Buy Now'
                                  : item.label == 'UNLISTED'
                                  ? 'Not Listed'
                                  : item.label,
                            }))
                          }
                        />
                      </div>
                    </Popover.Panel>
                  </Transition>
                </Popover>

                <>
                  <Popover>
                    <Popover.Button>
                      <div className="flex flex-row gap-2 bg-white px-5 py-3 outline:none focus:outline-none items-center rounded-full border border-gray-300 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-200 hover:-translate-y-1 duration-200 hover:shadow-lg">
                        <p className="font-semibold">Price</p>
                        <ChevronDownIcon className="w-5 h-5" />
                      </div>
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                      unmount={false}
                    >
                      <Popover.Panel
                        unmount={false}
                        className="absolute w-96 z-10 bg-white dark:bg-gray-900 shadow-2xl mt-1 rounded-xl border dark:border-gray-700"
                      >
                        <div className="w-full flex flex-col px-8 my-5">
                          <p className="text-gray-900 dark:text-gray-300 text-lg mt-2 mb-3 font-semibold">
                            Price Range (₳)
                          </p>
                          <span className="mt-2 mb-6">
                            <NumericMenu
                              attribute="price"
                              items={[
                                { end: 100000000, label: ' < ₳100' },
                                {
                                  start: 100000000,
                                  end: 500000000,
                                  label: ' ₳100 - ₳500',
                                },
                                {
                                  start: 500000000,
                                  end: 1000000000,
                                  label: ' ₳500 - $1,000',
                                },
                                {
                                  start: 1000000000,
                                  end: 5000000000,
                                  label: ' ₳1,000 - ₳5,000',
                                },
                                { start: 5000000000, label: '>$5000' },
                              ]}
                            />
                          </span>

                          <DropspotRangeInput
                            attribute={'price'}
                            precision={1}
                            min={0}
                          />
                        </div>
                        <div className="w-full h-10 px-12 pb-8 flex items-center justify-start mt-8 mb-4">
                          <DropspotRangeSlider
                            min={0}
                            max={100000000000}
                            attribute="price"
                          />
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                  {!!showRarity && (
                    <Popover>
                      <Popover.Button>
                        <div className="flex flex-row gap-2 bg-white px-5 py-3 outline:none focus:outline-none items-center rounded-full border boder-gray-300 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-200 hover:-translate-y-1 duration-200 hover:shadow-lg">
                          <p className="font-semibold">Rarity</p>
                          <ChevronDownIcon className="w-5 h-5" />
                        </div>
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                        unmount={false}
                      >
                        <Popover.Panel
                          unmount={false}
                          className="absolute z-10 bg-white dark:bg-gray-800 dark:text-gray-100 border dark:border-gray-700 mt-1 shadow-2xl rounded-xl"
                        >
                          <div className="grid grid-cols-1 w-48 p-4">
                            <CustomDSRefinementList
                              attribute="rarityStatus"
                              searchable={false}
                            />
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </Popover>
                  )}
                  {!!showRarity && (
                    <Popover>
                      <Popover.Button>
                        <div className="flex flex-row gap-2 bg-white px-5 py-3 outline:none focus:outline-none items-center rounded-full border boder-gray-300 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-200 hover:-translate-y-1 duration-200 hover:shadow-lg">
                          <p className="font-semibold">Traits</p>
                          <ChevronDownIcon className="w-5 h-5" />
                        </div>
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                        unmount={false}
                      >
                        <Popover.Panel
                          unmount={false}
                          className="absolute z-10 bg-white dark:bg-gray-800 dark:text-gray-100 border dark:border-gray-700 mt-1 shadow-2xl rounded-xl"
                        >
                          <div className="grid grid-cols-1 p-4 w-[40rem]">
                            {/* <HierarchicalMenu
                        attributes={['algoliaTraitsL1', 'algoliaTraitsL2']}
                      /> */}
                            <DropspotTraitsFilter
                              limit={1000}
                              showMore={false}
                              attributes={[
                                'algoliaTraitsL1',
                                'algoliaTraitsL2',
                              ]}
                            />
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </Popover>
                  )}
                </>
              </div>
              <div className="flex-row items-center hidden sm:flex">
                <SortBy
                  className="cursor-pointer"
                  defaultRefinement={assetSearchIndex}
                  items={[
                    {
                      value: assetSearchIndex,
                      label: 'Price: lowest first',
                    },
                    {
                      value: assetSearchIndexOldestFirst,
                      label: 'Price: highest first',
                    },
                    {
                      value: assetRecentFirst,
                      label: 'Recently updated',
                    },
                  ]}
                />
              </div>
            </div>
            <div
              ref={filterDiv}
              className="hidden sm:flex flex-row items-center gap-2 pb-2 text-gray-800 dark:text-gray-400 font-light tracking-wide transition-all translate duration-200 h-0 opacity-0"
            >
              <p className="text-sm text-gray-700 dark:text-gray-500 font-light">
                Filters:
              </p>

              <CurrentRefinements
                defaultRefinement={'LISTED'}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                transformItems={(items: any) => {
                  return (
                    items
                      .filter(
                        (item: { attribute: string }) =>
                          item.attribute == 'marketStatus'
                      )

                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      .map((item: any) => {
                        if (item.attribute == 'marketStatus') {
                          const m = item.items.map(
                            (item: { label: string }) => ({
                              ...item,
                              label:
                                item.label == 'Listed'
                                  ? 'Buy Now'
                                  : item.label == 'UNLISTED'
                                  ? 'Not Listed'
                                  : item.label,
                            })
                          );
                          return {
                            ...item,
                            items: m,
                          };
                        } else {
                          return item;
                        }
                      })
                  );
                }}
              />
              <CurrentRefinements
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                transformItems={(items: any) => {
                  return items.filter(
                    (item: { attribute: string }) =>
                      item.attribute == 'rarityStatus'
                  );
                }}
              />
              <ClearRefinements
                translations={{
                  reset: 'clear all',
                }}
              />
            </div>
          </>
        </>
      )}

      {showSideFilter && (
        <div
          className="fixed left-2 bottom-28 md:hidden z-[20] px-3 py-3 w-max rounded-full gap-1 border-gray-300 bg-gray-100 dark:bg-gray-900/90 dark:border-gray-700/50 dark:text-gray-400 dark:hover:text-white border shadow-sm flex items-center justify-center cursor-pointer hover:shadow-xl "
          onClick={() => setOpen(true)}
        >
          <FunnelIcon className="w-6 h-6" />
        </div>
      )}
      {showSideFilter && (
        <div className={`${open ? 'block' : 'hidden'}`}>
          <Transition appear show={true} as={Fragment}>
            <Dialog
              as="div"
              className={`fixed inset-0 z-40 overflow-y-auto ${
                open ? 'block' : 'hidden'
              }`}
              onClose={() => setOpen(false)}
            >
              <Dialog.Overlay className="fixed inset-0 backdrop-filter backdrop-blur-xl backdrop-brightness-75" />
              <div className="min-h-screen px-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                  className="inline-block h-screen align-middle"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <div className="inline-block relative w-full max-w-xl h-max p-12 my-12 overflow-scroll text-center align-middle transition-all transform bg-white dark:bg-gray-800 border-gray-300 dark:border-gray-700/50 border shadow-xl rounded-2xl">
                    <div
                      className="absolute top-3 right-3 dark:text-gray-100 text-gray-800 rounded-full cursor-pointer"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <XMarkIcon className="w-6 h-6" />
                    </div>
                    <div className="flex flex-row w-full bg-blur-2xl z-50 h-max">
                      <div className="w-80 pt-2  overflow-y-scroll mx-auto scrollbar-hide">
                        <div className=" mb-2 flex justify-between w-full">
                          <SearchBox className="w-96" />
                        </div>
                        <SortBy
                          className="cursor-pointer"
                          defaultRefinement={assetSearchIndex}
                          items={[
                            {
                              value: assetSearchIndex,
                              label: 'Price: lowest first',
                            },
                            {
                              value: assetSearchIndexOldestFirst,
                              label: 'Price: highest first',
                            },
                            {
                              value: assetRecentFirst,
                              label: 'Recently updated',
                            },
                          ]}
                        />
                        <div className="w-full  py-6">
                          <div className="text-gray-700  dark:text-gray-500  font-bold mb-2">
                            Market Status
                          </div>
                          <CustomDSRefinementList
                            attribute="marketStatus"
                            defaultRefinement={
                              project?.shouldDefaultBuyNow || listedCount > 10
                                ? ['LISTED']
                                : undefined
                            }
                            searchable={false}
                            transformItems={(items) =>
                              items.map((item: { label: string }) => ({
                                ...item,
                                label:
                                  item.label == 'LISTED'
                                    ? 'Buy Now'
                                    : item.label == 'UNLISTED'
                                    ? 'Not for Sale'
                                    : item.label,
                              }))
                            }
                          />
                        </div>
                        <div className="w-full ">
                          <div className="text-gray-700  dark:text-gray-500  font-bold mb-2">
                            Contract
                          </div>
                          <CustomDSRefinementList
                            attribute="listedAt"
                            searchable={false}
                            transformItems={(items) =>
                              items.map((item: { label: string }) => ({
                                ...item,
                                label:
                                  item.label == 'JPGStoreV2'
                                    ? 'JPG Store'
                                    : item.label,
                              }))
                            }
                          />
                        </div>
                        <div className=" py-6 mt-2 my-2">
                          <div className="text-gray-700  dark:text-gray-500  font-bold mb-2">
                            Price
                          </div>
                          <div className="my-2">
                            <DropspotRangeSlider2 attribute="price" />
                          </div>
                        </div>
                        {!!project && project.source != 'Dropspot' && (
                          <div className="flex flex-col ">
                            <div className="px-0  min-h-[12rem]">
                              <div className="text-gray-700  dark:text-gray-500 font-bold mb-2">
                                Rarity
                              </div>
                              <CustomDSRefinementList
                                attribute="rarityStatus"
                                searchable={false}
                              />
                            </div>
                            <div className="px-0 mt-6 min-h-[12rem] h-full mb-24">
                              <div className="text-gray-700  dark:text-gray-500 font-bold mb-2">
                                Traits
                              </div>
                              <CollectionTraitsSearch
                                project={project}
                                setSearchQuery={(q) =>
                                  setCurrentFirestoreQuery(q)
                                }
                                setAlgoliaFilters={(f) => {
                                  console.log('>>>>filters:', f);
                                  setTraitFiltersLocal(f);
                                }}
                                firestoreQuery={currentFirestoreQuery}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition>
        </div>
      )}

      {showSideFilter && (
        <div className="hidden md:flex flex-row w-full red-400 h-16 sticky top-[5rem] bg-blur-2xl z-50">
          <div className="absolute right-0">
            <SortBy
              className="cursor-pointer"
              defaultRefinement={assetSearchIndex}
              items={[
                {
                  value: assetSearchIndex,
                  label: 'Price: lowest first',
                },
                {
                  value: assetSearchIndexOldestFirst,
                  label: 'Price: highest first',
                },
                {
                  value: assetRecentFirst,
                  label: 'Recently updated',
                },
              ]}
            />
          </div>
          <div
            className="w-80 pt-2  overflow-y-scroll scrollbar-hide"
            style={{
              height: `calc(100vh  - 8rem)`,
            }}
          >
            <div className="pr-8 flex justify-between w-full">
              <SearchBox className="w-96" />
            </div>
            <div className="w-full pr-6 py-6">
              <div className="text-gray-700  dark:text-gray-500  font-bold mb-2">
                Market Status
              </div>
              <CustomDSRefinementList
                attribute="marketStatus"
                defaultRefinement={
                  project?.shouldDefaultBuyNow || listedCount > 10
                    ? ['LISTED']
                    : undefined
                }
                searchable={false}
                transformItems={(items) =>
                  items.map((item: { label: string }) => ({
                    ...item,
                    label:
                      item.label == 'LISTED'
                        ? 'Buy Now'
                        : item.label == 'UNLISTED'
                        ? 'Not for Sale'
                        : item.label,
                  }))
                }
              />
            </div>
            <div className="w-full pr-6">
              <div className="text-gray-700  dark:text-gray-500  font-bold mb-2">
                Contract
              </div>
              <CustomDSRefinementList
                attribute="listedAt"
                searchable={false}
                transformItems={(items) =>
                  items.map((item: { label: string }) => ({
                    ...item,
                    label:
                      item.label == 'JPGStoreV2' ? 'JPG Store' : item.label,
                  }))
                }
              />
            </div>
            <div className="pr-6 py-6 mt-2 my-2">
              <div className="text-gray-700  dark:text-gray-500  font-bold mb-2">
                Price
              </div>
              <div className="my-2">
                <DropspotRangeSlider2 attribute="price" />
              </div>
              {/* 
              <DropspotRangeInput attribute={'price'} precision={1} /> */}
            </div>
            {!!project && project.source != 'Dropspot' && (
              <div className="flex flex-col pr-8">
                <div className="px-0  min-h-[12rem]">
                  <div className="text-gray-700  dark:text-gray-500 font-bold mb-2">
                    Rarity
                  </div>
                  <CustomDSRefinementList
                    attribute="rarityStatus"
                    searchable={false}
                  />
                </div>
                <div className="px-0 mt-6 min-h-[12rem] h-full mb-24">
                  <div className="text-gray-700  dark:text-gray-500 font-bold mb-2">
                    Traits
                  </div>
                  <CollectionTraitsSearch
                    project={project}
                    setSearchQuery={(q) => setCurrentFirestoreQuery(q)}
                    setAlgoliaFilters={(f) => {
                      console.log('>>>>filters:', f);
                      setTraitFiltersLocal(f);
                    }}
                    firestoreQuery={currentFirestoreQuery}
                  />
                </div>
              </div>
            )}
          </div>

          <div></div>
        </div>
      )}

      <div
        className={cn('flex flex-row w-full ', { 'md:pl-80': showSideFilter })}
      >
        <div className="flex flex-row w-full">
          {!!collectionId && traitFiltersLocal && (
            <Configure
              hitsPerPage={hitsPerPage}
              filters={`projectId:${collectionId} ${traitFiltersLocal}`}
            />
          )}
          {!!collectionId && !traitFiltersLocal && (
            <Configure
              hitsPerPage={hitsPerPage}
              filters={`projectId:${collectionId}`}
            />
          )}
          {!collectionId && (
            <Configure hitsPerPage={hitsPerPage} filters={`status:Minted`} />
          )}

          <InfiniteHits
            containerClassNames={containerClasses}
            render={(hit: Asset) => (
              <div key={hit.id} className="ais-InfiniteHits-item ">
                <HitAsset
                  asset={hit}
                  isLoggedIn={!!currentUser}
                  showProjectInfo={showProjectInfo}
                  inGroupOfferMode={inGroupOfferMode}
                  showRarity={hit.showRarity}
                  heightClass={heightClass}
                  useB64ForImage={useB64ForImage}
                  project={project}
                  floor={floor}
                  imageDisplayPreference={imageDisplayPreference}
                  overrideBaseMediaType={overrideBaseMediaType}
                  preventOnchainRender={preventOnchainRender}
                  viewingUserId={currentUser?.uid}
                  doSelectAsset={(a) => {
                    doSelectAsset && doSelectAsset(a);
                  }}
                />
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};
