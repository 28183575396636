import { useDarkSide } from '#lib/hooks/useDarkSide';
import { Project } from '#types/index';
import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import { Tooltip } from '@nextui-org/react';
import React, { useState } from 'react';
import cn from 'classnames';

type VerifiedCollectionProps = {
  project: Project;
  overideToWhite?: boolean;
};

export const VerifiedCollectionPopover = ({
  project,
  overideToWhite,
}: VerifiedCollectionProps) => {
  const { colorTheme, setTheme } = useDarkSide();
  const [darkSide, setDarkSide] = useState<boolean>(
    colorTheme === 'light' ? true : false
  );

  const renderCreatorView = () => {
    return (
      <>
        {project.verifiedByDS
          ? 'Verified by Dropspot.'
          : 'Collection not verified.'}
      </>
    );
  };

  return (
    <>
      <div className="flex flex-row gap-2 items-center">
        <Tooltip
          content={renderCreatorView()}
          placement={'bottom'}
          color={darkSide == true ? 'invert' : 'default'}
        >
          <div className="flex flex-row gap-1 items-center">
            {' '}
            <div
              className={cn(
                ' w-7 h-7 rounded-full flex items-center justify-center',
                { 'bg-blue-600': !!project.verifiedByDS },
                { 'bg-gray-600': !project.verifiedByDS }
              )}
            >
              <CheckBadgeIcon className="w-6 h-6 text-white" />
            </div>
            <span
              className={cn(
                {
                  'text-white font-medium ml-1': !!overideToWhite,
                },
                {
                  'text-blue-600 dark:text-white font-bold ml-1':
                    !overideToWhite,
                },
                { 'font-mono': project.verifiedByDS }
              )}
            >
              {project.verifiedByDS ? project.tokenPrefix : 'Not verified'}
            </span>
          </div>
        </Tooltip>
      </div>
    </>
  );
};
