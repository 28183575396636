import getAbsoluteURL from '#lib/getAbsoluteUrl';
import asset from 'pages/create/asset';
import { useCallback, useEffect, useState } from 'react';
import { useFirebaseCurrentUser } from './useFirebaseCurrentUser';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import { getAssetHistoryListener, getAssetStatsListener } from '#lib/firestore';
import { AssetHistory, AssetStats } from '#types/index';

export const useAssetStats = (
  policy?: string,
  tokenName?: string,
  assetId?: string
) => {
  const [assetHistory, setAssetHistory] = useState<
    AssetHistory[] | undefined
  >();

  const [assetStats, setAssetStats] = useState<AssetStats | undefined>();
  const [statsLoading, setStatsLoading] = useState(true);
  const { currentUser } = useFirebaseCurrentUser();

  const getTTAssetStats = useCallback(
    async (policy: string, tokenName: string, assetId: string) => {
      console.log('Call THe Asset Stats Here');

      const attr = JSON.stringify({
        assetId: assetId as string,
        tokenName: tokenName as string,
        policy: policy as string,
      });

      firebase.functions().httpsCallable('callPubSubTopic')({
        topic: 'asset-status-refresh',
        attributes: attr,
      });

      getAssetStatsListener(assetId, tokenName, policy, (data) => {
        console.log('have some data');
        setAssetStats(data);

        setStatsLoading(false);
      });

      getAssetHistoryListener(assetId, tokenName, policy, (data) => {
        console.log('have some data');
        setAssetHistory(data);

        setStatsLoading(false);
      });

      // const url = getAbsoluteURL(
      //   `/api/taptools/updateAssetStats?policy=${policy}&tokenName=${tokenName}`
      // );

      // const result = await fetch(url, {
      //   method: 'GET',
      //   headers: {
      //     Authorization: `bearer ${await currentUser?.getIdToken(true)}`,
      //   },
      // });

      // if (result.ok) {
      //   try {
      //     const data = (await result.json()) as AssetHistory[];
      //     setAssetHistory(data);
      //   } catch (error) {
      //     console.log("Could'nt do it. ");
      //   }
      // }
    },
    []
  );

  useEffect(() => {
    if (!assetId || !policy || !tokenName) {
      setStatsLoading(false);
      return;
    }
    getTTAssetStats(policy, tokenName, assetId);
  }, [assetId, getTTAssetStats, policy, tokenName]);
  return {
    assetHistory,
    statsLoading,
    assetStats,
  };
};
