/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { FC } from 'react';
import { Hit, RefinementListProvided } from 'react-instantsearch-core';
import cn from 'classnames';
import { Checkbox } from '#components/Inputs/Checkbox/Checkbox';
import { RocketLaunchIcon } from '@heroicons/react/24/outline';
import DaisyButton from '#components/Daisy/DaisyButton';
import { NextImage } from '#components/Assets/NextImage';

interface RefinementsType extends Partial<RefinementListProvided> {
  items: Array<
    Hit<{
      count: number;
      isRefined: boolean;
      label: string;
      value: string[];
      image: string;
    }>
  >;
}

interface ToggleRefinementProps {
  currentRefinement: any;
  refine: any;
  canRefine: boolean;
}

export const DSToggleRefinement: FC<ToggleRefinementProps> = ({
  refine,
  currentRefinement,
}) => {
  return (
    <a
      href="#"
      className={cn(
        'flex flex-row gap-2 items-center font-bold border py-3 px-4 bg-white  rounded-full dark:text-gray-100 dark:bg-gray-900 hover:-translate-y-1 transition duration-300 hover:shadow-lg',
        {
          'border-green-500': currentRefinement,
        },
        {
          'border-gray-300 dark:border-gray-600': !currentRefinement,
        }
      )}
      onClick={(event) => {
        event.preventDefault();
        refine(!currentRefinement);
      }}
    >
      <RocketLaunchIcon
        className={cn(
          'w-5 h-5',
          {
            'text-green-500': currentRefinement,
          },
          {
            'dark:text-gray-300 text-gray-600': !currentRefinement,
          }
        )}
      />
      <span
        className={cn(
          {
            'text-green-500': currentRefinement,
          },
          {
            'dark:text-gray-300 text-gray-700': !currentRefinement,
          }
        )}
      >
        {' '}
        Launchpad
      </span>
    </a>
  );
};

export const MyRefinementList: FC<RefinementsType> = ({
  items,
  refine,
  searchForItems,
  createURL,
}) => {
  return (
    <div className="flex flex-col gap-3 mx-2 my-2">
      <div>
        <input
          type="search"
          onChange={(event) =>
            searchForItems && searchForItems(event.currentTarget.value)
          }
        />
      </div>
      {items.map((item) => (
        <div key={item.label} className="flex-1 ">
          <a
            href={createURL && createURL(item.value)}
            style={{ fontWeight: item.isRefined ? 'bold' : '' }}
            onClick={(event) => {
              event.preventDefault();
              refine && refine(item.value);
            }}
          >
            <div className="flex justify-between">
              <div className="w-full flex flex-row gap-2 items-center">
                <img
                  src={item.image}
                  className="w-10 h-10 object-cover"
                  alt={item.label}
                />
                <div>{item.label}</div>
              </div>
              <div>({item.count})</div>
            </div>
          </a>
        </div>
      ))}
    </div>
  );
};

export const DSProjectFacetList: FC<RefinementsType> = ({
  items,
  refine,
  searchForItems,
  createURL,
}) => {
  return (
    <div className="flex flex-col gap-2 mt-3">
      {items.map((item) => (
        <div key={item.label} className="flex-1 py-1">
          <a
            href={createURL && createURL(item.value)}
            onClick={(event) => {
              event.preventDefault();
              refine && refine(item.value);
            }}
          >
            <div className=" flex flex-row gap-2 justify-between">
              <div className="flex flex-row  items-center font-normal">
                <span
                  className={cn(
                    'hover:text-black hover:dark:text-white',
                    { 'text-gray-900 dark:text-gray-100': item.isRefined },
                    { 'text-gray-600 dark:text-gray-400': !item.isRefined }
                  )}
                >
                  <span className="flex items-center gap-3">
                    <div className="relative w-8 h-8 flex-initial overflow-hidden rounded-full">
                      <NextImage
                        src={JSON.parse(item.label).pt}
                        width={100}
                        height={100}
                        priority={false}
                        className="object-cover w-full h-full"
                      />
                    </div>
                    <div className="flex-1 pr-2">
                      {JSON.parse(item.label).title}
                    </div>
                  </span>
                </span>
              </div>
              <div className="text-sm text-gray-600 dark:text-gray-400">
                {/* ({item.count}) */}
                <input
                  type="checkbox"
                  checked={item.isRefined}
                  onChange={() => console.log('changed')}
                  className="checkbox checkbox-sm checkbox-secondary"
                />
              </div>
            </div>
          </a>
        </div>
      ))}
    </div>
  );
};

export const DSRefinementList: FC<RefinementsType> = ({
  items,
  refine,
  searchForItems,
  createURL,
}) => {
  return (
    <div className="flex flex-col gap-2 mt-2">
      {items.map((item) => (
        <div key={item.label} className="flex-1 relative w-full py-0.5 ">
          <a
            href={createURL && createURL(item.value)}
            className="z-10 flex flex-row justify-between "
            onClick={(event) => {
              event.preventDefault();
              refine && refine(item.value);
            }}
          >
            <div className="w-full z-10 ">
              <div className=" flex flex-row gap-2 justify-between ">
                <div className="flex flex-row items-center font-normal">
                  {/* <span
                  className={cn(
                    { 'bg-green-500': item.isRefined },
                    { 'bg-gray-700': !item.isRefined },
                    'w-1.5 h-1.5  rounded-full mr-3 font-light '
                  )}
                /> */}
                  <span
                    className={cn(
                      'hover:text-black hover:dark:text-white',
                      { 'text-gray-900 dark:text-gray-100': item.isRefined },
                      { 'text-gray-600 dark:text-gray-400': !item.isRefined }
                    )}
                  >
                    {item.label}
                  </span>
                </div>
              </div>
            </div>
          </a>
          <input
            type="checkbox"
            checked={item.isRefined}
            onChange={() => console.log('changed')}
            onClick={(event) => {
              event.preventDefault();
            }}
            className="checkbox checkbox-sm checkbox-secondary absolute right-0 top-1 z-0"
          />
        </div>
      ))}
    </div>
  );
};

export const DSRarirtyRefinementList: FC<RefinementsType> = ({
  items,
  refine,
  searchForItems,
  createURL,
}) => {
  return (
    <div className="flex flex-col gap-2">
      {items.map((item) => (
        <div key={item.label} className="flex-1">
          <a
            href={createURL && createURL(item.value)}
            style={{
              fontWeight: item.isRefined
                ? 'text-gray-600 dark:text-gray-400'
                : 'text-gray-900 dark:text-white',
            }}
            onClick={(event) => {
              event.preventDefault();
              refine && refine(item.value);
            }}
          >
            <div className=" flex flex-row gap-2 justify-between">
              <div className="flex flex-row items-center gap-2">
                <Checkbox
                  checked={item.isRefined}
                  size="xs"
                  onChange={() => {
                    console.log('do nothing');
                  }}
                />

                <span
                  className={cn(
                    { 'text-gray-900 dark:text-gray-100': item.isRefined },
                    { 'text-gray-600 dark:text-gray-400': !item.isRefined }
                  )}
                >
                  {item.label}
                </span>
              </div>
              <div className="text-sm text-gray-600 dark:text-gray-400">
                ({item.count})
              </div>
            </div>
          </a>
        </div>
      ))}
    </div>
  );
};

export const DaisyInlineRefinements: FC<RefinementsType> = ({
  items,
  refine,
  searchForItems,
  createURL,
}) => {
  return (
    <div className="flex flex-row gap-2 flex-wrap duration-200 transition-all">
      {items.map((item) => (
        <button
          className={`btn btn-xs lg:btn-md ${
            item.isRefined ? 'btn-secondary' : 'btn-ghost'
          }`}
          key={item.label}
          onClick={(event) => {
            event.preventDefault();
            refine && refine(item.value);
          }}
        >
          {item.label}
        </button>

        // <div
        //   onClick={(event) => {
        //     event.preventDefault();
        //     refine && refine(item.value);
        //   }}
        //   key={item.label}
        //   className={`cursor-pointer dark:border-gray-600 flex-1 px-6 py-2.5 text-gray-900 dark:text-white rounded-full flex items-center justify-center border transition duration-150 hover:shadow-md hover:bg-white dark:hover:bg-gray-900 hover:-translate-y-0.5 ${
        //     item.isRefined
        //       ? 'border-red-500 bg-white dark:bg-gray-900'
        //       : 'border-gray-300 bg-gray-50 dark:bg-gray-800'
        //   }`}
        // >
        //   <div className=" flex flex-row gap-2 justify-between font-light">
        //     <div className="flex flex-row">{item.label}</div>
        //   </div>
        // </div>
      ))}
    </div>
  );
};

export const InlineRefinements: FC<RefinementsType> = ({
  items,
  refine,
  searchForItems,
  createURL,
}) => {
  return (
    <div className="flex flex-row gap-2 duration-200 transition-all">
      {items.map((item) => (
        <div
          onClick={(event) => {
            event.preventDefault();
            refine && refine(item.value);
          }}
          key={item.label}
          className={`cursor-pointer dark:border-gray-600 flex-1 px-6 py-2.5 text-gray-900 dark:text-white rounded-full flex items-center justify-center border transition duration-150 hover:shadow-md hover:bg-white dark:hover:bg-gray-900 hover:-translate-y-0.5 ${
            item.isRefined
              ? 'border-red-500 bg-white dark:bg-gray-900'
              : 'border-gray-300 bg-gray-50 dark:bg-gray-800'
          }`}
        >
          <div className=" flex flex-row gap-2 justify-between font-light">
            <div className="flex flex-row">{item.label}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export const CustomCurrentRefinements = ({
  items,
  attributeLabel,
}: {
  items: any[];
  attributeLabel: string;
}) => (
  <div>
    {!items || (items.length == 0 && <p>{attributeLabel}</p>)}

    <ul>
      {items.map((item) => (
        <li key={item.label}>
          {item.items ? (
            <React.Fragment>
              <ul>
                {item.items.map((nested: any) => (
                  <li key={nested.label}>
                    <a
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        //  refine(nested.value);
                      }}
                    >
                      {nested.label}
                    </a>
                  </li>
                ))}
              </ul>
            </React.Fragment>
          ) : (
            <a
              href="#"
              onClick={(event) => {
                event.preventDefault();
                //   refine(item.value);
              }}
            >
              {item.label}
            </a>
          )}
        </li>
      ))}
    </ul>
  </div>
);
