import constants from '../constants';
import getAbsoluteURL from '../getAbsoluteUrl';
import { BUILD_ACTION } from '../plutus/DropspotMarketContract';
import { APIError, DropspotMarketError } from '../plutus/DropspotMarketError';
import { CardanoWalletExtended } from '../wallet/WalletContext';

export async function signAndSubmit(
  wallet: CardanoWalletExtended,
  txCbor: string,
  listener: (action: BUILD_ACTION) => void
): Promise<string> {
  const signTx = async () => {
    try {
      return await wallet.signTx(txCbor, true);
    } catch (err) {
      console.error(err);
      // throw new Error('Transaction Signing Failed');
      throw new DropspotMarketError({
        code: (err as APIError).code,
        info: (err as APIError).info,
        type: 'SIGN',
      });
    }
  };
  const signatures = await signTx();
  if (!signatures) {
    throw new Error('Transaction Signing Failed');
  }
  listener('Submitting');
  const url = getAbsoluteURL('/api/jpg/tx-submit');

  const txSubmitRequest = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      body: txCbor,
      signatures,
      network: constants.TESTNET ? 'preprod' : 'mainnet',
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!txSubmitRequest) {
    throw new Error('Unable to Submit Transaction');
  }

  const txId = await txSubmitRequest.text();
  console.log('TxId', txId);
  if (txId === 'Error' || txId === '"Error"') {
    throw new Error('Tx Submit Failed');
  }

  listener('Submitted');
  return (txId);
}
