import { FC, } from 'react';
import { Royalty } from '#types/index';

export interface SaleEstimateProps {
  price: number;
  commissionPercent: number;
  royalties?: Royalty[];
}

export const SaleEstimate: FC<SaleEstimateProps> = ({
  price,
  commissionPercent,
  royalties,
}) => {
  const commissionAmount = price * commissionPercent;

  const royaltyTotalPercent = royalties
    ? royalties.reduce((acc, cur) => acc + cur.percent, 0)
    : 0;
  const royaltyAmount =
    royaltyTotalPercent === 0 ? 0 : Math.max(1, price * royaltyTotalPercent);
  const earnings = price - commissionAmount - royaltyAmount;

  return (
    <SaleEstimateTotals
      price={price}
      commissionAmount={commissionAmount}
      commissionPercent={commissionPercent}
      royaltyAmount={royaltyAmount}
      royaltyTotalPercent={royaltyTotalPercent}
      earnings={earnings}
    />
  );
};

export interface SaleEstimateTotalsProps {
  price: number;
  commissionAmount: number;
  commissionPercent?: number;
  royaltyTotalPercent?: number;
  royaltyAmount?: number;
  commissionSavings?: number;
  earnings: number;
}

export const SaleEstimateTotals = ({
  price,
  commissionAmount,
  commissionPercent,
  commissionSavings,
  royaltyAmount,
  royaltyTotalPercent,
  earnings,
}: SaleEstimateTotalsProps) => {
  return (
    <div className="sm:mb-4">
      <div className="text-sm text-gray-600 dark:text-gray-100 font-light">
        Sales Estimate
      </div>
      <div className="flex flex-row justify-between mt-1 text-gray-900">
        <p className="text-gray-600 dark:text-gray-400 font-normal">
          Platform fee{' '}
          {commissionPercent !== undefined && <>({commissionPercent * 100}%)</>}
        </p>
        <p className=" text-gray-800  dark:text-gray-100">
          ₳ {price > 2 && commissionAmount.toFixed(0)}
        </p>
      </div>

      {commissionSavings !== undefined && commissionSavings > 0 ? (
        <div className="flex flex-row justify-between mt-1 text-gray-900">
          <p className="text-gray-600 dark:text-gray-400 font-normal">
            SuperCube savings
          </p>
          <p className=" text-gray-800  dark:text-gray-100">
            ₳ {commissionSavings.toFixed(0)}
          </p>
        </div>
      ) : null}

      <div className="flex flex-row justify-between mt-1 text-gray-900">
        <p className="text-gray-600 dark:text-gray-400 font-normal">
          Royalty{' ('}
          {royaltyTotalPercent &&
            royaltyTotalPercent > 0 &&
            royaltyTotalPercent * 100}
          {'%)'}
        </p>
        <p className="text-gray-800 dark:text-gray-100 font-normal">
          {royaltyAmount ? <>₳{price > 2 && royaltyAmount.toFixed(0)} </> : '₳ 0'}
        </p>
      </div>

      <div className="flex flex-row justify-between mt-1 text-gray-900  dark:text-gray-200 font-bold">
        <p className="text-gray-600 dark:text-gray-100">Earnings</p>
        <p>₳{price > 2 && earnings.toFixed(0)}</p>
      </div>
    </div>
  );
};
