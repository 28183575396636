import { getProjectTraits } from '#lib/firestore';
import { ProjectTrait, TraitDictionary, TraitDictionaryV2 } from '#types/index';
import { useEffect, useState } from 'react';

export const useProjectTraits = (projectId: string) => {
  const [traits, setTraits] = useState<TraitDictionary | undefined>(undefined);
  const [traitsV2, setTraitsV2] = useState<TraitDictionaryV2 | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getProjectTraits(projectId)
      .then((data) => {
        setTraits(data.traitDictionary);
        setTraitsV2(data.traitDictionaryV2);
      })
      .catch(() => console.log('error'))
      .finally(() => setLoading(false));
  }, [projectId]);
  return {
    traits,
    traitsV2,
    loading,
  };
};
