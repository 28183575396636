import { trpcClient } from '../../trpc';
import { trpc } from '../../../pages/_app';
import { SuperCubeUtxoData } from '../../../server/routes/cubes';
import { superCube } from '@dropspot-io/contract-api';
import { Cardano } from '../../../types/cardano';
import { getAddress, getNetworkParams, submitTx } from '../DropspotMarketContract';
import {
  Address,
  Assets,
  Datum,
  ListData,
  MintingPolicyHash,
  TxId,
  TxOutput,
  UTxO,
  Value,
  bytesToHex,
  hexToBytes,
  config,
} from '@hyperionbt/helios';
import constants from '#lib/constants';

const ds_wallet = constants.SUPER_CUBE_WALLET;
const supercubePolicy = constants.SUPER_CUBE_POLICY;

// Get SuperCubes Available (Array or UTxOs, tag them by Level)

export function useSuperCube(policy: string) {
  return trpc.useQuery(['sc-for-policy', { policy }], {});
  //  const { data, isLoading } = trpc.useQuery(['sc-for-policy', policy], {});

  // return { data, isLoading };
}

export function useSuperCubes() {
  const { data, isLoading } = trpc.useQuery(['sc-list']);

  return { data, isLoading };
}

export async function getSuperCubeUtxo() {
  const utxos = await trpcClient.query('super-cube-utxos');

  return utxos;
}
const superCubeLevels = ['DS_LEVEL_1', 'DS_LEVEL_2', 'DS_LEVEL_3'] as const;
type SuperCubeLevel = typeof superCubeLevels[number];

export type TransactSuperCubeProps = {
  wallet: Cardano;
  startTime: number;
  endTime: number;
  level: SuperCubeLevel; //TODO: Need to select a Cube based on the Level
  policy: string;
  currentCube?: SuperCubeUtxoData;
  onChange?: (status: string) => void;
};

export async function transactSuperCube({
  wallet,
  startTime,
  endTime,
  policy,
  currentCube,
  onChange,
  level,
}: TransactSuperCubeProps) {
  // Validate that the Start Date is before the End Date
  if (startTime >= endTime) {
    throw new Error('Start Date must be before End Date');
  }

  // Ensure that Current Cube is either expired or is for the same Policy
  if (currentCube) {
    if (currentCube.policy !== policy) {
      throw new Error('Current Cube is not for this Policy');
    }

    if (currentCube.end > Date.now()) {
      throw new Error('Current Cube is not expired');
    }
  }

  //set the config:
  config.IS_TESTNET = constants.TESTNET;

  const walletAddress = await getAddress(wallet);

  const networkParams = await getNetworkParams();
  const contract = await trpcClient.query('sc-trading-contract');

  // So if we have an existing Cube then lets try extending it
  if (currentCube) {
    return superCube.extend({
      startDate: startTime,
      expiry: endTime,
      ownerAddress: Address.fromHex(walletAddress).toBech32(),
      policy: policy,
      wallet: wallet,
      cubeUTxO: sqlResultToUTxO(currentCube),
      networkParams,
      scriptCbor: contract.cbor,
      dropspotAddress: ds_wallet,
      reductionPolicy: supercubePolicy,
      listener: onChange,
      submitTx: (tx) => submitTx(tx, []),
    });
  }

  // Get an Expired SuperCube to purchase!?

  const utxo = await trpcClient.query('sc-avail-utxo', { level });
  console.log('buySuperCube> UTXO', JSON.stringify(utxo));
  if (!utxo) {
    throw new Error('No SuperCubes Available to buy');
  }

  console.log('buySuperCube', {
    startDate: startTime,
    expiry: endTime,
    ownerAddress: Address.fromHex(walletAddress).toBech32(),
    policy: policy,
    wallet: wallet,
    cubeUTxO: bytesToHex(sqlResultToUTxO(utxo).toCbor()),
    networkParams,
    scriptCbor: contract.cbor,
    level: level,
  });

  return superCube.buy({
    startDate: startTime,
    expiry: endTime,
    ownerAddress: Address.fromHex(walletAddress).toBech32(),
    policy: policy,
    wallet: wallet,
    cubeUTxO: sqlResultToUTxO(utxo),
    networkParams,
    scriptCbor: contract.cbor,
    dropspotAddress: ds_wallet,
    reductionPolicy: supercubePolicy,
    listener: onChange,
    submitTx: (tx) => submitTx(tx, []),
  });
}

function sqlResultToUTxO(u: SuperCubeUtxoData) {
  const assets = new Assets();

  u.utxo.assets.forEach((asset) => {
    assets.addComponent(
      MintingPolicyHash.fromHex(asset.policy),
      hexToBytes(asset.oc_name),
      BigInt(asset.quantity)
    );
  });

  return new UTxO(
    TxId.fromHex(u.utxo.txHash),
    BigInt(u.utxo.txIndex),
    new TxOutput(
      Address.fromBech32(u.utxo.address),
      new Value(BigInt(u.utxo.lovelace), assets),
      Datum.inline(ListData.fromCbor(hexToBytes(u.utxo.datum)))
    )
  );
}

// Get a SuperCube for Policy (txHash, txIndex, UTxO)

// Manage SuperCube - Abstraction of buySuperCube and extendSuperCube
// i.e., if the Policy is not set then buySuperCube, if the Policy is set then extendSuperCube
