import { Asset } from '#types/index';
import React from 'react';
import { Tooltip } from '@nextui-org/react';
import { NextImage } from '#components/Assets/NextImage';
import dynamic from 'next/dynamic';
const ReactPlayerWrapper = dynamic(() => import('../../DSVideoPlayer'), {
  ssr: false,
});

type AssetFilesProps = { asset: Asset };

export const AssetFilesRegion = ({ asset }: AssetFilesProps) => {
  return (
    <>
      <div className="w-full  p-2  shadow-inner flex flex-row gap-4  items-center  rounded-xl">
        <div className="w-24 h-24 bg-gray-800 rounded-lg relative overflow-hidden">
          <NextImage
            src={asset.thumbnailUrl || asset.assetUrl}
            priority={false}
            b64={asset.thumbnailB64}
            layout="fill"
            quality={100}
            avoidCloudflare={true}
          />
        </div>
        {asset.filesImported &&
          asset.filesImported.map((file) => {
            return (
              <React.Fragment key={file.dsFilePath}>
                <div className="w-24 h-24 bg-gray-800 rounded-lg relative overflow-hidden">
                  {file.dsFile && file.mediaType.startsWith('image') && (
                    <NextImage
                      src={file.dsFile}
                      priority={false}
                      quality={100}
                      height={150}
                      width={150}
                      avoidCloudflare={false}
                    />
                  )}
                  {file.dsFile && file.mediaType.startsWith('video') && (
                    <ReactPlayerWrapper
                      src={file.dsFile}
                      shouldPlay={true}
                      showControls={true}
                      muted={true}
                    />
                  )}
                </div>
              </React.Fragment>
            );
          })}
      </div>
    </>
  );
};
