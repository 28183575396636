import React, { Component } from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
import { InfiniteHitsProvided } from 'react-instantsearch-core';
import { InlineLoading } from '#components/Loading/InlineLoading';
import cn from 'classnames';

interface InfiniteHitsType<THit> extends InfiniteHitsProvided {
  render: (hit: THit) => React.ReactElement;
  containerClassNames?: string;
}

class InfiniteHits<T> extends Component<InfiniteHitsType<T>> {
  static propTypes = {
    hits: PropTypes.arrayOf(PropTypes.object).isRequired,
    hasMore: PropTypes.bool.isRequired,
    refineNext: PropTypes.func.isRequired,
  };

  private observer!: IntersectionObserver;
  private sentinel: Element | null = null;

  onSentinelIntersection: IntersectionObserverCallback = (
    entries: IntersectionObserverEntry[]
  ) => {
    const { hasMore, refineNext } = this.props;
    entries.forEach((entry) => {
      if (entry.isIntersecting && hasMore) {
        refineNext();
      }
    });
  };

  componentDidMount() {
    this.observer = new IntersectionObserver(this.onSentinelIntersection);
    if (this.sentinel) this.observer.observe(this.sentinel);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  render() {
    const { hits, hasMore } = this.props;

    return (
      <div className="ais-InfiniteHits w-full">
        <div
          className={cn(
            'ais-InfiniteHits-list',
            this.props.containerClassNames
          )}
        >
          {hits.map((hit) => this.props.render(hit))}
        </div>
        <ul className="mt-16">
          <li
            className="ais-InfiniteHits-sentinel flex justify-center"
            ref={(c) => (this.sentinel = c)}
          >
            {hasMore && (
              <>
                <InlineLoading />
              </>
            )}
          </li>
        </ul>
      </div>
    );
  }
}

export function getInfiniteHitsComponent<T>() {
  return connectInfiniteHits<InfiniteHitsType<T>, T>(InfiniteHits);
}
