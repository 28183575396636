import { useSuperCube } from '../../lib/plutus/SuperCube';

import { CubeIcon } from '@heroicons/react/24/outline';
import { CSSProperties } from '@nextui-org/react/types/theme';
import classNames from 'classnames';

type SuperCubeProps = {
  policy: string;
  listedOnSuperCubeContract?: boolean;
  width?: number;
  height?: number;
  className?: string;
};

export function SuperCube({
  policy,
  listedOnSuperCubeContract,
  width = 20,
  height = 20,
  className,
}: SuperCubeProps) {
  // console.log('In SuperCube', policy, listedOnSuperCubeContract);
  const { data, isLoading } = useSuperCube(policy);

  if (!data?.cubeLevel || isLoading) {
    return null;
  }

  let color = !listedOnSuperCubeContract ? '#cfcfcf' : '#BFFF00';

  const message = listedOnSuperCubeContract
    ? 'Cube Available for Trade'
    : 'Cube Unavailable for Trade';
  let css: CSSProperties | undefined = undefined;

  if (listedOnSuperCubeContract) {
    switch (data.cubeLevel) {
      case 'DS_LEVEL_1': {
        color = '#00BAFF';
        css = {
          //    background: `radial-gradient(${color}CC, ${color}33)`,
          // boxShadow: `0px 0px ${width}px ${color}`,
          //   mixBlendMode: 'normal',
        };
        break;
      }
      case 'DS_LEVEL_2': {
        color = '#FF0076';
        css = {
          //    background: `radial-gradient(${color}CC, ${color}33)`,
          //  boxShadow: `0px 0px ${width}px ${color}`,
          //  mixBlendMode: 'normal',
        };
        break;
      }
      case 'DS_LEVEL_3': {
        color = '#FF3E00';
        css = {
          //    background: `radial-gradient(${color}CC, ${color}33)`,
          //    boxShadow: `0px 0px ${width}px ${color}`,
          // mixBlendMode: 'normal',
        };
        break;
      }
    }
  }

  return (
    <div className="flex flex-row items-center">
      <CubeIcon
        aria-label={message}
        color={color}
        width={width}
        height={height}
        style={css}
        className={classNames('', className)}
      />
    </div>
  );
}
