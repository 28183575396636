import { Tooltip } from '@nextui-org/react';
import React, { useState } from 'react';
import { copyTextToClipboard } from '#components/ShareButton/ShareButton';
import {
  ArrowTopRightOnSquareIcon,
  CheckIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/24/outline';
import Link from 'next/link';
import { cardanoExplorerURL } from '#lib/plutus/utils';
import { useDarkSide } from '#lib/hooks/useDarkSide';

export function MiddleEllipsisV2({
  text,
  label,
  classNames,
  hideTooltip,
}: {
  text: string;
  label: string;
  classNames?: string;
  hideTooltip?: boolean;
}) {
  const [isCopied, setIsCopied] = React.useState(false);
  const { colorTheme, setTheme } = useDarkSide();
  const [darkSide, setDarkSide] = useState<boolean>(
    colorTheme === 'light' ? true : false
  );

  const handleCopyClick = (string: string) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(string || `No ${label}`)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderCreatorView = (string: string) => {
    return (
      <div className={`flex flex-col gap-0 text-base  font-medium w-52`}>
        <div
          className="flex flex-row justify-between items-center cursor-pointer hover:bg-gray-300 dark:hover:bg-gray-800 px-3 py-2 rounded-lg my-1"
          onClick={() => handleCopyClick(string)}
        >
          {!!isCopied && (
            <>
              <p>Copied</p>
              <CheckIcon className="w-5 h-5 text-green-600" />
            </>
          )}
          {!isCopied && (
            <>
              <p>Copy {label}</p>
              <DocumentDuplicateIcon className="w-5 h-5" />
            </>
          )}
        </div>
        <div className="flex flex-row justify-between items-center cursor-pointer hover:bg-gray-300 dark:hover:bg-gray-800 px-3 py-2 rounded-lg my-1">
          <Link href={`${cardanoExplorerURL()}/address/${string}`}>
            <a
              target={'_blank'}
              className="flex flex-row justify-between w-full"
            >
              Open Cardanoscan <ArrowTopRightOnSquareIcon className="h-5 w-5" />
            </a>
          </Link>
        </div>
      </div>
    );
  };
  if (hideTooltip)
    return (
      <span className={` ${classNames}`}>
        <div
          className="w-48 flex items-center justify-center whitespace-nowrap text-center"
          onClick={() => handleCopyClick(text)}
        >
          <span className="dark:text-gray-300 dark:hover:text-white text-gray-700 hover:text-black font-mono font-light">
            {text.length < 16
              ? text
              : text.slice(0, 8) + '...' + text.slice(-8)}
          </span>
        </div>
      </span>
    );

  return (
    <span className={` ${classNames}`}>
      <Tooltip
        content={renderCreatorView(text)}
        placement={'bottom'}
        enterDelay={0.5}
        color={darkSide == true ? 'invert' : 'default'}
      >
        <div
          className="w-48 items-center justify-center whitespace-nowrap text-center"
          onClick={() => handleCopyClick(text)}
        >
          <span className="dark:text-gray-300 dark:hover:text-white text-gray-700 hover:text-black font-mono font-light">
            {text.length < 16
              ? text
              : text.slice(0, 8) + '...' + text.slice(-8)}
          </span>
        </div>
      </Tooltip>
    </span>
  );
}
