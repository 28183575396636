import { Disclosure, Transition } from '@headlessui/react';
import {
  ArrowPathIcon,
  BoltIcon,
} from '@heroicons/react/24/solid';
import classNames from 'classnames';
import {
  useAllOffers,
  useRefresh,
} from './OffersStore';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { TxDisplay } from '#components/Offers/TxDisplay';
import { selectMyOffers, selectReceievedOffers, useOfferUIStore } from './OfferUIStore';
import { Tooltip } from '@nextui-org/react';
import { AcceptOfferMoodal } from '#components/Offers/AcceptOfferModal';
import { BestOffer, Project } from '#types/index';

dayjs.extend(relativeTime);
dayjs.extend(utc);

export const Offers = ({ project, floor, tokenName }: OffersRenderProps) => {
  const offers = useAllOffers();

  console.log('>>>> Offers', offers);


  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="border rounded-t-lg dark:border-gray-700/80 p-4 flex border-gray-300 flex-col my-1">
            <div className="dark:text-gray-400 text-base flex gap-2 items-center font-bold tracking-wider justify-between w-full">
              <span className="flex flex-row items-center gap-2 ">
                <BoltIcon
                  className={classNames('w-5 duration-200 text-info', {
                    'text-info': open,
                  })}
                />
                <span>Offers</span>
              </span>

              <span className="badge badge-info">{offers.length} </span>
            </div>
          </Disclosure.Button>
          <Transition
            show={open}
            className="overflow-hidden"
            enter="transition transition-[max-height] duration-500 ease-in"
            enterFrom="transform max-h-0"
            enterTo="transform max-h-screen"
            leave="transition transition-[max-height] duration-300 ease-out"
            leaveFrom="transform max-h-screen"
            leaveTo="transform max-h-0"
          >
            <Disclosure.Panel className="text-gray-500 bg-base-100 flex flex-col rounded-xl px-6 dark:border-gray-700/80 p-3">
              <OffersRender
                project={project}
                floor={floor}
                tokenName={tokenName}
              />
            </Disclosure.Panel>
          </Transition>
          <span className="flex w-full justify-center">
            <div
              className="btn btn-xs btn-ghost mt-4 text-white flex gap-2 opacity-50"
              onClick={() => useRefresh}
            >
              <ArrowPathIcon className="w-5" />
              Refresh
            </div>
          </span>
        </>
      )}
    </Disclosure>
  );
};

type OffersRenderProps = {
  project: Project;
  tokenName: string;
  floor: number;
};

const OffersRender = ({ project, tokenName, floor }: OffersRenderProps) => {
  const offers = useAllOffers();
  const flatenedOffers: BestOffer[] = offers
    .flatMap((offer) =>
      offer.details
        //   .filter((detail) => detail.type === 'Collection')
        .filter((d) =>
          d.type == 'Collection' ? true : d.tokenName == tokenName
        )
        .map<BestOffer>((detail) => {

          if (detail.type === 'Collection') {
            return ({
              ...offer,
              detail: detail,
              type: 'Collection',
              owning_stake_address: offer.stakeAddress,
              key: `${offer.tx_hash}.${offer.index}.${detail.type}`,
              collection_policy: detail.policy,
            })
          }

          return ({
            ...offer,
            detail: detail,
            type: 'Asset',
            owning_stake_address: offer.stakeAddress,
            key: `${offer.tx_hash}.${offer.index}.${detail.type}`,
            collection_policy: detail.policy,
            token_name: detail.tokenName
          })
        }
        )
    )
    .sort((a, b) => b.detail.offer - a.detail.offer);

  console.log('flatenedOffers', flatenedOffers);

  // we need to change this here a little.
  // need to flatten the offers and then only return those that match the asset or those
  // that are collection offers
  // then we need to sort, and restrict.

  if (!offers || offers.length === 0) {
    return <p>There are no offers on this item yet.</p>;
  }

  return (
    <div className="w-full flex flex-col gap-1">
      {/* {offers.map((offer) => {
        return (
          <Offer
            key={`${offer.tx_hash}.${offer.tx_index}`}
            offer={offer}
            project={project}
            floor={floor}
          />
        );
      })} */}

      {flatenedOffers.map((o) => {
        return (
          <OfferV2
            key={o.tx_hash}
            offer={o}
            project={project}
            floor={floor}
            time={o.time}
            tx_hash={o.tx_hash}
          />
        );
      })}
    </div>
  );
};

function OfferV2({
  offer,
  tx_hash,
  time,
  project,
  floor,
}: {
  offer: FlattenedOffer;
  tx_hash: string;
  time: number;
  project: Project;
  floor: number;
}) {
  const myOffers = useOfferUIStore(selectMyOffers);
  const receivedOffers = useOfferUIStore(selectReceievedOffers);
  const isReceivedOffer = receivedOffers.find((o) => o.tx_hash == offer.tx_hash);

  return (
    <div className="flex items-start gap-2 pt-2 flex-col">
      <div className="w-full flex flex-col items-start gap-4">
        <div className="flex flex-row items-center gap-4 w-full">
          <div className="flex flex-1 flex-row gap-1 items-center">
            <span className="flex items-center gap-2">
              <p className="dark:text-white text-gray-900 font-bold text-lg flex-1">
                {' '}
                ₳ {((offer.detail.offer || 0) / 1_000_000).toLocaleString()}
              </p>

              {myOffers?.find((o) => o.tx_hash == tx_hash) && (
                <div className="bg-secondary w-2 h-2 rounded-full"></div>
              )}
            </span>
          </div>
          <div className="flex-1">{offer.type}</div>
          <span className="flex-1">
            {' '}
            <div className="flex flex-col font-normal">
              <Tooltip
                content={
                  <>
                    {' '}
                    {dayjs
                      .utc(time * 1_000)
                      .local()
                      .format('DD MMM, YYYY | HH:mm')}{' '}
                  </>
                }
              >
                {' '}
                <span className=" text-sm dark:text-gray-400 text-gray-600 font-normal">
                  {dayjs.utc(time * 1_000).fromNow()}
                </span>
              </Tooltip>
            </div>
          </span>
          <TxDisplay txHash={tx_hash} />
          <div className="flex-1 flex items-end justify-end">
            {isReceivedOffer ? (
              <AcceptOfferMoodal
                offer={offer}
                open={false}
                project={project}
                floor={floor}
                offerAccepted={() => console.log('Offer has been accepted.')}
              />
            ) : (
              <div className="btn btn-sm btn-disabled">Accept Offer</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

type FlattenedOffer = BestOffer; /* &
  tx_hash: string;
  tx_index: string;
  has_collection_offer: boolean;
  has_asset_offer: boolean;
  value: unknown;
  time: number;
  type: 'Collection' | 'Asset';
  policy: string;
  quantity: number;
  offer: number;
  project?: Project | undefined;
  tokenName?: string | undefined;
};*/

