import { usePolicyStatsListenerListener } from '#lib/hooks/usePolicyStats';
import {
  Asset,
  HolderDistribution,
  LiveTransaction,
  NativePolicyStats,
  Project,
  SaleHistory,
  TTHolderDistribution,
} from '#types/index';
import { useRouter } from 'next/router';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import constants from '#lib/constants';
import { DSInfiniteGridHandle } from '#components/DataHandler/DSInfiniteGrid';
import { AssetItem, useAssetItems } from '#lib/hooks/useAsset';
import { FirestoreQuery, FirestoreQueryItem } from '#lib/firestore';
import { ProjectSocials } from '../ProjectSocials';
import { DSRefinementList } from '#components/Search/CustomRefinements';
import { CustomRangeInput } from '#components/Search/CustomRangeInput';
import { InstantSearch, connectRefinementList } from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch';
import { InfiniteAssetSearchV3 } from '#components/Search/InfiniteAssetSearchV3';
import DaisyButton from '#components/Daisy/DaisyButton';
import { NextImage } from '#components/Assets/NextImage';
import { PolicyTooltipV2 } from '#components/Tooltip/PolicyTooltipV2';
import { AboutCreatorToolTip } from '#components/Creator/AboutTheCreator/AboutCreatorTooltip';
import {
  OfferContextWrapper,
  OfferResponseWithMyOffer,
  useAllOffers,
} from '#components/Assets/AssetPage/MarketActionOffers/OffersStore';
import {
  OfferUIStore,
  useOfferUIStore,
} from '#components/Assets/AssetPage/MarketActionOffers/OfferUIStore';
import { VerifiedCollectionPopover } from '#components/Tooltip/VerifiedCollectionPopover';
import { RoyaltiesTooltip } from '#components/Tooltip/RoyaltiesTooltip';
import { useCardanoWallet } from '#lib/wallet/WalletContext';
import { useFirebaseCurrentUser } from '#lib/hooks/useFirebaseCurrentUser';
import { SuperCube } from '../../SuperCubes/SuperCube';
import { Tab } from '@headlessui/react';
import { SingleOfferModal } from '#components/Offers/SingleOfferModal';
import { OfferDetails } from '#server/routes/asset/offers';
import { GroupOfferPanel } from '#components/Offers/OfferGroupPanel';
import { ProjectOffers } from '#components/Offers/ProjectOffers';
import { AcceptOfferMoodal } from '#components/Offers/AcceptOfferModal';
import {
  ReceivedOffer,
  useMyReceivedOffers,
} from '#components/Wallet/ReceivedOffers';

type ProjectPage = {
  project: Project;
  ssrStats?: NativePolicyStats;
  ssrAssetItems: AssetItem[];
  isInTraderMode?: boolean;
  ssrSalesHistory?: LiveTransaction[];
  ssrHolderDistro?: TTHolderDistribution;
  ssrListedAssets?: Asset[];
};

function assetColumnCalc(width: number): number {
  if (width < 400) {
    return 1;
  }

  if (width < 600) {
    return 2;
  }

  if (width < 1200) {
    return 4;
  }

  if (width < 1600) {
    return 5;
  }

  return 7;
}

export const ProjectPageMainCreator = ({
  project,
  ssrStats,
  ssrAssetItems,
  isInTraderMode,
  ssrSalesHistory,
  ssrListedAssets,
  ssrHolderDistro,
}: ProjectPage) => {
  const DEFAULT_QUERY = {
    desc: true,
    orderBy: 'creationDate',
    whereClauses: [
      { fieldPath: 'projectId', opStr: '==', value: project.id },
    ] as FirestoreQueryItem[],
  };

  const router = useRouter();

  const CustomDSRefinementList = connectRefinementList(DSRefinementList);
  const assetInfiniteGridRef = React.useRef<DSInfiniteGridHandle>(null);
  const [currentFirestoreQuery, setCurrentFirestoreQuery] =
    useState<FirestoreQuery>(DEFAULT_QUERY);
  const assetItems = useAssetItems({
    query: currentFirestoreQuery,
    ssrAssetItems: ssrAssetItems,
  });

  useEffect(() => {
    setGridSmoothLoading(true);
    setTimeout(() => setGridSmoothLoading(false), 1000);
    if (assetInfiniteGridRef.current) {
      assetInfiniteGridRef.current.refreshCache();
      console.log('SEARCH QUERY CHANGED - Cache refresh called. ');
    }
  }, [currentFirestoreQuery]);

  const DropspotRangeInput = connectRefinementList(CustomRangeInput);
  const [gridSmoothLoading, setGridSmoothLoading] = useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);
  // let initialTabs: Tab[] = [
  //   {
  //     id: 'I',
  //     name: 'Items',
  //     current: true,
  //   },

  //   {
  //     id: 'A',
  //     name: 'Activity',
  //     current: false,
  //   },
  // ];

  // if (project.source == 'Market') {
  //   initialTabs = [
  //     {
  //       id: 'I',
  //       name: 'Items',
  //       current: true,
  //     },
  //     {
  //       id: 'S',
  //       name: 'Summary',
  //       current: false,
  //     },
  //     {
  //       id: 'A',
  //       name: 'Activity',
  //       current: false,
  //     },
  //   ];
  // }

  // if (project.showRarity) {
  //   initialTabs.splice(2, 0, {
  //     id: 'ANALYTICS',
  //     name: 'Rarity',
  //     current: false,
  //   });
  // }
  // const [tabs, setTabs] = useState<Tab[]>(initialTabs);
  // const [currentTab, setCurrentTab] = useState<string>(tabs[0].id);
  //   const [searchState, setSearchState] = useState<SearchState>({});
  const [sideBarShown, setSideBarShown] = useState(true);
  const [movementBarShown, setMovementBarShown] = useState(true);
  const [traitFilters, setTraitFilters] = useState<string | undefined>();
  const [mode, setMode] = useState(
    project.source == 'Dropspot' ? 'ITEMS' : 'SUMMARY'
  );
  const [groupOfferMode, setGroupOfferMode] = useState(false);
  const [offerAssets, setOfferAssets] = useState<Asset[] | undefined>();
  const [pinned, setPinned] = useState(false);
  const { policyStatistics } = usePolicyStatsListenerListener({
    policy: project.policy || '',
    ssrStats,
  });
  // const { ref, inView } = useInView({
  //   /* Optional options */
  //   threshold: 0.25,
  //   initialInView: true,
  // });

  // useEffect(() => {
  //   console.log('intersection in view');
  //   if (!inView) {
  //     console.log('>>>>>>>>>>>>OUT OF THE VIEW LETS PIN >>>>>>>>>>>>>>');
  //     setPinned(true);
  //   } else {
  //     setPinned(false);
  //   }
  // }, [inView]);

  const collectionIndex = constants.ALGOLIA_ASSET_INDEX;
  const searchClient = algoliasearch(
    '91RRC8EAH8',
    'f1f7d0552a938afdece75849a9b79abc'
  );

  return (
    <>
      <div className="w-full h-full">
      <span className="absolute bottom-0 left-0 right-0 mx-auto flex items-end z-11 mb-10 justify-center w-max">
        <div className="flex gap-2 dark:bg-black/20 bg-white/20 p-4 rounded-xl shadow-lg">
            <DaisyButton
              label={'Liquid Offer'}
              colorName="btn-accent"
              classNames="text-gray-800 dark:text-white"
              size="btn-sm"
              onClick={() => {
                console.log('enter offer mode');
                setGroupOfferMode(!groupOfferMode);
              }}
            />
            <SingleOfferModal
              project={project}
              open={false}
              floor={(policyStatistics?.floor_price || 0) / 1_000_000}
              offerType="COLLECTION"
              offerAdded={function(): void {
                throw new Error('Function not implemented.');
              }}
            />
          </div>
        </span>
        <OfferContextWrapper policy={project.policy || ''}>
          <div className="">
            <ProjectHeaderCreator
              project={project}
              setMode={(mode) => setMode(mode)}
              mode={mode}
              toggleGroupOfferMode={() => setGroupOfferMode(!groupOfferMode)}
            />
          </div>

          <InstantSearch
            indexName={collectionIndex}
            searchClient={searchClient}
            onSearchStateChange={(state) => {
              console.log('search state changed');
              console.log('STATE: ', JSON.stringify(state));
            }}
          >
            <div className="mx-auto max-w-[105rem]">
              <Tab.Group>
                <Tab.List
                  as="div"
                  className={'flex items-end justify-end w-full gap-4'}
                >
                  <Tab as="button" className={'btn btn-sm'}>
                    Items
                  </Tab>
                  <Tab as="button" className={'btn btn-sm'}>
                    Offers
                  </Tab>
                </Tab.List>
                <Tab.Panels>
                  <Tab.Panel as="div" className={'w-full h-full pb-96'}>
                    <InfiniteAssetSearchV3
                      collectionId={project.id}
                      showSideFilter={false}
                      showFilters={true}
                      showRarity={project.showRarity}
                      inGroupOfferMode={groupOfferMode}
                      heightClass="h-[24rem]"
                      hitsPerPage={36}
                      traitFilters={traitFilters}
                      project={project}
                      overrideBaseMediaType={project.overrideBaseMediaType}
                      useB64ForImage={project.imageDisplayPreference == 'B64'}
                      imageDisplayPreference={project.imageDisplayPreference}
                      preventOnchainRender={project.preventOnchainRender}
                      containerClasses="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-4 4xl:grid-cols-5 5xl:grid-cols-5 6xl:grid-cols-5  gap-8 px-4 sm:px-0"
                      doSelectAsset={(asset) => {
                        console.log('added offer object');
                      }}
                    />
                  </Tab.Panel>
                  <Tab.Panel>
                    <ProjectOffers project={project} />
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </InstantSearch>
        </OfferContextWrapper>
      </div>

      <GroupOfferPanel
        isActive={groupOfferMode}
        assets={offerAssets}
        doClose={() => setGroupOfferMode(false)}
      />
    </>
  );
};

type ProjectHeaderProps = {
  project: Project;
  mode: string;
  setMode: (mode: string) => void;
  toggleGroupOfferMode: () => void;
  toggleSideBar?: () => void;
};

const ProjectHeaderCreator = ({
  project,
  mode,
  setMode,
  toggleGroupOfferMode,
}: ProjectHeaderProps) => {
  const router = useRouter();

  const offers = useMyReceivedOffers();
  const distinctRows = useMemo(() => {
    return offers.filter((offer, index, self) => {
      if (router.query.all) return true;
      // Check if the Collection or Asset exists in a position prior to this one
      const exists = self.findIndex((o) => {
        if (o.detail.type !== offer.detail.type) return false;

        switch (o.detail.type) {
          case 'Asset':
            return (
              project.policy === o.detail.policy &&
              o.detail.tokenName === offer.detail.tokenName
            );

          case 'Collection':
            return project.policy === o.detail.policy;
        }
      });

      return exists === index;
    });
  }, [offers, router.query.all, project.policy]);


  return (
    <>
      <div className="relative w-full h-[24rem] ">
        {!!project.featureImage && (
          <NextImage
            src={project.bannerImage}
            b64={project.bannerImageB64}
            width={2400}
            height={200}
            quality={100}
            priority={false}
            className={'absolute top-0 left-0 w-full h-full object-cover'}
          />
        )}
        <div className="absolute top-0  flex-col w-full h-full bg-gradient-to-b from-black/10 via-black/40 to-base-200 dark:from-black/30 dark:via-black/40 dark:to-base-200 flex items-center  justify-end pb-8">
          <div className="relative w-full max-w-[105rem] ">
            <div className="absolute -bottom-2  mt-12 rounded-lg shadow-md overflow-hidden p-3 backdrop-filter backdrop-x backdrop-brightness-200">
              <div className="relative h-36 w-36 bg-white rounded-lg overflow-hidden opactiy-100 backdrop-filter-none">
                {!!project.featureImage && (
                  <NextImage
                    src={project.featureImage}
                    b64={project.featureImageB64}
                    width={300}
                    height={400}
                    quality={100}
                    priority={false}
                    className={'w-full h-full rounded-lg object-cover'}
                  />
                )}
              </div>
            </div>
            <div className="absolute bottom-0 left-48 flex flex-col gap-2">
              <div>
                {(project.startDate || 0) > new Date().getTime() && (
                  <div className="my-1 py-1">
                    <Timer2
                      deadline={new Date(project.startDate || 0).toString()}
                    />
                  </div>
                )}
              </div>
              <PolicyTooltipV2 policy={project.policy} />
            </div>
            <div className="absolute bottom-0 right-2">
              <AboutCreatorToolTip creator={project.creator} placement="left" />
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-[105rem]">
        <div className="flex items-center justify-between">
          <div className="flex items-center text-7xl font-extrabold mt-1 text-shadow shadow-white/50">
            {project.name}

            <SuperCube
              policy={project.policy || ''}
              listedOnSuperCubeContract
              className="ml-8"
            />
          </div>
          <div className="flex flex-col items-end">
            <span className="flex flex-row items-center gap-3">
              {distinctRows?.length > 0 ? (
                <AcceptOfferMoodal
                  project={undefined}
                  offer={{
                    ...distinctRows[0],
                    details: [distinctRows[0].detail],
                  }}
                  open={false}
                  offerAccepted={function(): void {
                    throw new Error('Function not implemented.');
                  }}
                />
              ) : (
                <div className="btn btn-sm btn-disabled">Accept Offer</div>
              )}
            </span>
            <ProjectSocials project={project} />
          </div>
        </div>
        <div className="flex flex-row items-center">
          <div>
            by{' '}
            <span className="dark:text-white font-medium text-xl">
              {project.creator?.displayName}
            </span>
          </div>
          <div className="flex flex-row mr-4 items-end my-4 gap-4 ml-4">
            <VerifiedCollectionPopover
              project={project}
              overideToWhite={true}
            />
            <RoyaltiesTooltip project={project} overideToWhite={true} />
          </div>
        </div>

        <div className="w-full justify-between flex items-center">
          <div className="text-lg font-normal mt-3">
            {project.projectDescription}
          </div>
        </div>
      </div>
    </>
  );
};

export const Timer2 = ({ deadline = new Date().toString() }) => {
  const parsedDeadline = useMemo(() => Date.parse(deadline), [deadline]);
  const [time, setTime] = useState(parsedDeadline - Date.now());
  const SECOND = 1000;
  const MINUTE = SECOND * 60;
  const HOUR = MINUTE * 60;
  const DAY = HOUR * 24;
  useEffect(() => {
    const interval = setInterval(
      () => setTime(parsedDeadline - Date.now()),
      1000
    );

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col">
      <p>Dropping Soon</p>
      <div className="timer flex flex-row gap-2">
        {Object.entries({
          Days: time / DAY,
          Hours: (time / HOUR) % 24,
          Minutes: (time / MINUTE) % 60,
          Seconds: (time / SECOND) % 60,
        }).map(([label, value]) => (
          <div key={label} className="">
            <div className="box">
              <span className="countdown font-mono text-4xl">
                <span
                  className="value"
                  style={
                    {
                      '--value': `${Math.floor(value)}`.padStart(2, '0'),
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    } as any
                  }
                >
                  {`${Math.floor(value)}`.padStart(2, '0')}
                </span>
              </span>
              <span className="text text-sm">{label}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const MemoProjectPageMainCreator = React.memo(ProjectPageMainCreator);

const selectReceievedOffers = (state: OfferUIStore) => state.acceptableOffers;
const selectMyOffers = (state: OfferUIStore) => state.myOffers;
