import { Creator } from '#types/index';
import { Tooltip } from '@nextui-org/react';
import { Divider } from 'components/Divider/Divider';
import Link from 'next/link';
import React from 'react';
import { AboutTheCreatorProps } from './AboutTheCreator';
export interface CreatorViewProps {
  creator: Creator;
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'topStart'
    | 'topEnd'
    | 'leftStart'
    | 'leftEnd'
    | 'bottomStart'
    | 'bottomEnd'
    | 'rightStart'
    | 'rightEnd'
    | undefined;
}
export const AboutCreatorToolTip = ({
  creator,
  placement = 'bottom',
}: CreatorViewProps) => {
  const renderCreatorView = () => {
    return (
      <div
        className={
          'text-sm bg-opactiy-20 p-8 rounded-md z-10 flex flex-col gap-6 w-96 text-gray-900'
        }
      >
        <div className="flex flex-row justify-between">
          <img
            src={creator.profilePhoto}
            alt="creator portrait"
            className="w-24 h-24 rounded-full object-cover"
          />
          <Link href={`/profile/${creator.displayName}`}>
            <a className="border border-gray-400 h-10 rounded-full flex items-center px-3 hover:text-white hover:bg-black hover:shadow-md">
              View Profile
            </a>
          </Link>
        </div>

        <div className="font-bold text-3xl">{creator.displayName}</div>
        <div className="font-light text-base text-gray-700">{creator.bio}</div>
        <div className="flex flex-row w-full gap-8">
          <div className="flex flex-col  gap-2">
            <p className="text-4xl font-bold">{creator.collectionCount}</p>
            <p className="text-gray-800">Collections</p>
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-4xl font-bold">{creator.mintedCount}</p>
            <p className="text-gray-800">Minted</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Tooltip content={renderCreatorView()} placement={placement}>
        <div className="flex flex-col shadow-md rounded-full dark:bg-gray-900/50 bg-white/90 hover:-translate-y-1 duration-200 transition border dark:border-gray-700 justify-center">
          <div
            className={`text-gray-900 dark:text-white gap-2 group pl-1 rounded-full inline-flex items-center text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-0 focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <img
              src={creator?.profilePhoto}
              alt="user photo"
              className="w-9 h-9 ml-0 object-cover rounded-full pixelated shadow-md"
            />
            <span className="text-sm mr-4">{creator?.displayName}</span>
          </div>
        </div>
      </Tooltip>
    </>
  );
};
