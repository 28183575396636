import {
  FirestoreQuery,
  FirestoreQueryItem,
  getAsset,
  getAssetFromPolicyAndTokenName,
  getAssetsForNextBatch,
  getAssetsWithDynamicMapping,
} from '#lib/firestore';
import { Asset } from '#types/index';
import React from 'react';
import { useEffect, useState } from 'react';

export type AssetItem = Asset & {
  groupKey?: string;
  key: number;
};

const LIMIT = 24;

const DEFAULT_QUERY_X = {
  desc: true,
  orderBy: 'creationDate',
  whereClauses: [
    { fieldPath: 'status', opStr: '==', value: 'Minted' },
  ] as FirestoreQueryItem[],
};

type UseAssetItemsProps = {
  query?: FirestoreQuery;
  text?: string;
  ssrAssetItems?: AssetItem[];
};

export const useAssetItems = ({ query, ssrAssetItems }: UseAssetItemsProps) => {
  const [loading, setLoading] = React.useState(true);
  const [hasMoreData, setHasMoreData] = React.useState(true);
  const [items, setItems] = React.useState<AssetItem[]>(ssrAssetItems || []);
  const lastKey = React.useRef<number>();

  useEffect(() => {
    setHasMoreData(true);
    lastKey.current = undefined;
  }, [query]);

  const loadBatch = React.useCallback(
    async (key?: number) => {
      console.log('ILT: In loadBatch', key, lastKey.current);
      setLoading(true);
      if (!!lastKey.current && lastKey.current === key) {
        console.log('ILT: In loadBatch - Debounce');
        setLoading(false);
        return Promise.resolve();
      }

      if (!key && lastKey.current) {
        console.log('ILT: In loadBatch - Already Got Data');
        setLoading(false);
        return Promise.resolve();
      }

      lastKey.current = key;
      console.log('ILT: WHERE: ', JSON.stringify(query?.whereClauses));
      const theQuery = !!query ? query : DEFAULT_QUERY_X;

      if (!key) {
        try {
          try {
            const data = await getAssetsWithDynamicMapping(theQuery, LIMIT);
            const x: AssetItem[] = data.assets.map((ass) => {
              const a = ass as AssetItem;
              a.key = ass.creationDate;
              a.groupKey = data.lastKey;
              return a;
            });
            if (x.length < LIMIT) {
              setHasMoreData(false);
            }
            setItems(x);
          } catch (message_1) {
            setLoading(false);
            return console.error(message_1);
          }
        } finally {
          return setLoading(false);
        }
      }

      try {
        try {
          const data_1 = await getAssetsForNextBatch(key, LIMIT, theQuery);
          const x_1: AssetItem[] = data_1.assets.map((ass_1) => {
            const a_1 = ass_1 as AssetItem;
            a_1.key = ass_1.creationDate;
            a_1.groupKey = data_1.lastKey;
            setLoading(false);
            return a_1;
          });
          if (x_1.length < LIMIT) {
            setHasMoreData(false);
          }
          setItems((i) => [...i, ...x_1]);

          // console.log('ITEMS:', JSON.stringify(x_1));
        } catch (message_1) {
          setLoading(false);
          return console.error(message_1);
        }
      } finally {
        return setLoading(false);
      }
    },
    [query]
  );

  React.useEffect(() => {
    loadBatch();
  }, [loadBatch]);

  return { loading, items, loadBatch, hasMoreData };
};

export const usePaginatedAssets = (
  query: FirestoreQuery,
  limit: number,
  lastKey: number,
  ssrAssets?: Asset[]
) => {
  const [assets, setAssets] = useState<Asset[] | undefined>(ssrAssets);
  const [loading, setLoading] = useState(true);
  const [theLastKey, setTheLastKey] = useState('');

  //const query: FirestoreQuery = { orderBy: 'creationDate', desc: true };

  useEffect(() => {
    if (!lastKey) {
      getAssetsWithDynamicMapping(query, limit).then((rp) => {
        setAssets(rp.assets);
        setTheLastKey(rp.lastKey);
        setLoading(false);
      });
    } else {
      getAssetsForNextBatch(lastKey, limit, query).then((rp) => {
        setAssets(assets?.concat(rp.assets));
        setTheLastKey(rp.lastKey);
        setLoading(false);
      });
    }
  }, [limit, lastKey, query, assets]);
  return {
    assets,
    loading,
    theLastKey,
  };
};

export const useAssetFromPolicyAndTokenName = (
  policy: string,
  tokenName: string
) => {
  const [asset, setAsset] = useState<Asset | undefined>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getAssetFromPolicyAndTokenName(policy, tokenName).then((data) => {
      if (data) {
        setAsset(data);
      }
      setLoading(false);
    });
  }, [policy, tokenName]);
  return {
    asset,
    loading,
  };
};

export const useAssetFromId = (id: string) => {
  const [asset, setAsset] = useState<Asset | undefined>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getAsset(id).then((data) => {
      if (data) {
        setAsset(data);
      }
      setLoading(false);
    });
  }, [id]);
  return {
    asset,
    loading,
  };
};
