import { useDegenStore } from '#lib/hooks/useGlobalMode';
import { useState } from 'react';

import { useFirebaseCurrentUser } from '#lib/hooks/useFirebaseCurrentUser';
import Footer from '#components/Footer/Footer';
import { NavigationBar } from '#components/TraderDashboard/Header/NavigationBar';

type Props = {
  preview?: boolean;
  hideSubNav?: boolean;
  selectedSubMenuIndex?: number;
  translucent?: boolean;
  fullWidth?: boolean;
  showFooter?: boolean;
  children: React.ReactNode;
  miniFooter?: boolean;
};

const Layout = ({
  hideSubNav,
  selectedSubMenuIndex = -1,
  children,
  showFooter = true,
  miniFooter = false,
  translucent = false,
}: Props) => {
  const mode = useDegenStore((state) => state.degenMode);
  const selectTheProject = useDegenStore(
    (state) => state.setSelectedCollection
  );
  const { claims, currentUser } = useFirebaseCurrentUser();
  const [isWalletOpen, setIsWalletOpen] = useState(false);
  // const [isLiveActionOpen, setIsLiveActionOpen] = useState(false);
  // const { id, photoURL, displayName } = useAuthUser();

  return (
    <div className="w-full">
      <NavigationBar />
      <div className="layout">
        <div>{children}</div>
        {showFooter && (
          <div className=" mt-12 mx-auto  max-w-[120rem] container">
            <Footer />
          </div>
        )}
      </div>
    </div>
  );
};

export default Layout;
