import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Bars3Icon } from '@heroicons/react/24/solid';
import ThemeSwitcher from '#components/NavigationBar/ThemeSwitcher';
import {
  RocketLaunchIcon,
  BuildingStorefrontIcon,
} from '@heroicons/react/24/solid';
import { Search } from '#components/Search/Search';
import { DiscordIcon } from '#components/svg/discord';
import { TwitterIcon } from '#components/svg/twitterIcon';

export default function MobileSlideMenu({
  claims,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  claims?: { [key: string]: any };
}) {
  const [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }
  const about = [
    { name: 'Mission & Vision', href: 'https://docs.dropspot.io/' },
    { name: 'Blog', href: 'https://blog.dropspot.io/' },
    { name: 'Help Center', href: 'https://dropspot.gitbook.io/guides/' },
  ];
  const socials = [
    {
      name: 'Discord',
      href: 'https://discord.gg/wrXJY7TCvV',
      icon: function icon() {
        return (
          <div className="text-gray-800  dark:text-white w-6 h-6">
            <DiscordIcon />
          </div>
        );
      },
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/dropspot_io',
      icon: function icon() {
        return (
          <div className="text-gray-800  dark:text-white w-6 h-6">
            <svg fill="currentColor" viewBox="0 0 24 24">
              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
            </svg>
          </div>
        );
      },
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/dropspot.io/',
      icon: function icon() {
        return (
          <div className="text-gray-800  dark:text-white w-6 h-6">
            <svg fill="currentColor" viewBox="0 0 24 24">
              <path
                fillRule="evenodd"
                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <button
        onClick={openModal}
        className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
      >
        <span className="sr-only">Open menu</span>
        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
      </button>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setIsOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-200 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-200 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-gray-200 dark:bg-gray-800 dark:bg-opacity-70 bg-opacity-75 backdrop-blur-xl  pt-4 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                            <ThemeSwitcher />
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-gray-100/50 dark:text-white dark:bg-gray-900/50 text-black hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => setIsOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                        <div className="bg-clear w-full h-1 border-b border-gray-300 dark:border-gray-700 mt-3"></div>
                      </div>
                      <div className="relative mt- flex-1 px-4 sm:px-6">
                        <div className="flex flex-col pt-3">
                          <Search />
                          <p className="dark:text-white items-center flex text-gray-800 uppercase font-semibold pt-2 text-xl">
                            <span className=" w-max h-10 flex items-center -center  text-gray-800  dark:text-white  tracking-wider text-sm font-semibold  ">
                              <BuildingStorefrontIcon className=" pr-2 h-6 text-gray-900 dark:text-white" />
                            </span>{' '}
                            Marketplace
                          </p>
                          <a
                            href={'/marketplace'}
                            className="pl-8 py-1 dark:text-gray-200 text-gray-600 capitalize font-semibold hover:scale-[1.025] hover:-translate-y-[1px] duration-200 transition cursor-pointer text-lg"
                          >
                            Collections
                          </a>
                          <a
                            href={'/marketplace/explore'}
                            className="pl-8 py-1 dark:text-gray-200 text-gray-600 capitalize font-semibold hover:scale-[1.025] hover:-translate-y-[1px] duration-200 transition cursor-pointer text-lg"
                          >
                            Explore
                          </a>
                          <a
                            href={'/trending'}
                            className="pl-8 py-1 dark:text-gray-200 text-gray-600 capitalize font-semibold hover:scale-[1.025] hover:-translate-y-[1px] duration-200 transition cursor-pointer text-lg "
                          >
                            Trending
                          </a>
                        </div>
                        <a
                          href={claims?.creator ? '/create' : '/create/onboard'}
                          className="flex items-center  hover:scale-[1.025] hover:-translate-y-1 duration-200 transition cursor-pointer dark:text-white text-gray-800 capitalize pt-1 text-xl"
                        >
                          <span className=" w-max h-10 flex items-center -center  text-gray-800  dark:text-white  tracking-wider text-sm font-semibold  ">
                            <RocketLaunchIcon className=" pr-2 h-6 w-8 text-gray-900 dark:text-white" />
                          </span>{' '}
                          Creator Launchpad
                        </a>
                        <a
                          href={'/verify'}
                          className="flex items-center  hover:scale-[1.025] hover:-translate-y-1 duration-200 transition cursor-pointer dark:text-white text-gray-800   text-xl"
                        >
                          <span className=" w-max h-10 flex items-center -center  text-gray-800  dark:text-white  tracking-wider text-sm font-semibold  ">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className=" pr-2 h-6 text-gray-900 dark:text-white"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                              />
                            </svg>
                          </span>{' '}
                          Verify Your Collection
                        </a>
                        <div className="flex flex-col ">
                          <p className="dark:text-white items-center flex text-gray-800 uppercase font-semibold pt-1 text-xl">
                            <span className=" w-max h-10 flex items-center -center  text-gray-800  dark:text-white  tracking-wider text-sm font-semibold  ">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="pr-2 w-8 h-8 text-gray-900 dark:text-white"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                />
                              </svg>
                            </span>{' '}
                            About
                          </p>{' '}
                          {about.map((item) => (
                            <a
                              href={item.href}
                              target={'_blank'}
                              className=" pl-8 py-1 dark:text-gray-200 text-gray-600 capitalize hover:scale-[1.025] hover:-translate-y-[1px] duration-200 transition cursor-pointer text-lg"
                              rel="noreferrer"
                              key={item.name}
                            >
                              {item.name}
                            </a>
                          ))}
                        </div>
                        {socials.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className="flex items-center  hover:scale-[1.025] hover:-translate-y-1 duration-200 transition cursor-pointer dark:text-white text-gray-800 capitalize pt-1 text-xl"
                          >
                            <span className="pr-2 w-8 h-10 flex items-center -center  text-gray-800  dark:text-white  tracking-wider text-sm font-semibold  ">
                              {item.icon()}
                            </span>{' '}
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
