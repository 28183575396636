import React from 'react';
import type { Hit } from 'react-instantsearch-core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Project } from '#types/index';
import { ImageSwap } from '#components/Assets/DSImage';
import { ProjectCardPublic } from '#components/Projects/ProjectCardPublic';

const HitProject = ({ project }: { project: Project }) => {
  const initDayJS = dayjs.extend(relativeTime);
  return (
    <ProjectCardPublic
      project={project}
      playAsVideo={project.playAsVideo}
      heightClass="h-[38rem]"
    />
  );
};

export default HitProject;
