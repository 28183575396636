import { useMemo, useState } from 'react';
import {
  selectGetOfferableAssets,
  selectLoadingMyOffers,
  selectMyOffers,
  selectReceievedOffers,
  useOfferUIStore,
} from '../Assets/AssetPage/MarketActionOffers/OfferUIStore';
import { InlineLoading } from '../Loading/InlineLoading';
import { SwatchIcon } from '@heroicons/react/24/solid';
import { useRouter } from 'next/router';
import React from 'react';
import { AssetImage } from '#components/Assets/AssetPage/AssetImage';
import { MiddleEllipsis } from '../MiddleEllipsis/MiddleEllipsis';
import {
  ArrowTopRightOnSquareIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/outline';
import Link from 'next/link';
import dayJs from 'dayjs';
import { NextImage } from '#components/Assets/NextImage';
import { cardanoExplorerURL } from '#lib/plutus/utils';
import { AcceptOfferMoodal } from '#components/Offers/AcceptOfferModal';
import DaisyButton from '../Daisy/DaisyButton';
import { BestOffer } from '../../types';

export type ReceivedOffer = BestOffer; //OfferRecord;

export function useMyReceivedOffers() {
  const getOfferableAssets = useOfferUIStore(selectGetOfferableAssets);
  const myOffers = useOfferUIStore(selectMyOffers);
  const receivedOffers = useOfferUIStore(selectReceievedOffers);

  const distinctRows: BestOffer[] = useMemo(() => {
    console.log('>>> Received Offers', receivedOffers);
    const validOffers =
      receivedOffers?.filter(
        (offer) =>
          offer !== null &&
          offer !== undefined &&
          !myOffers?.some((myOffer) => myOffer.tx_hash === offer.tx_hash)
      ) ?? [];

    // Explode validOffers so that we have one per OfferDetail

    return validOffers
      .flatMap((offer) => {
        const currentOffer = offer;

        const x: BestOffer[] = offer.details.map((detail) => {

          if (detail.type === 'Collection') {
            return {
              ...currentOffer,
              detail: detail,
              type: 'Collection',
              collection_policy: detail.policy,
              owning_stake_address: currentOffer.stakeAddress,
              key: `${currentOffer.tx_hash}.${detail.policy}.Collection`,
            };
          }

          return {
            ...currentOffer,
            detail: detail,
            type: 'Asset',
            collection_policy: detail.policy,
            owning_stake_address: currentOffer.stakeAddress,
            token_name: detail.tokenName,
            key: `${currentOffer.tx_hash}.${detail.policy}.${detail.tokenName}.Asset`
          };
        });

        return x;
      })
//      .filter((offer) => {
//        const assetsCouldOffer = getOfferableAssets({
//          ...offer,
//          details: [offer.detail],
//        });
//
//        return assetsCouldOffer.length > 0;
//      })
      .filter((offer, index, self) => {
        // Ensure that this Offer is Unique
        return (
          index ===
          self.findIndex((o) => {
            if (o.type !== offer.type) return false;

            switch (o.type) {
              case 'Asset':
                return (
                  o.detail.policy === offer.detail.policy &&
                  o.detail.tokenName === offer.detail.tokenName &&
                  o.tx_hash === offer.tx_hash &&
                  o.index === offer.index
                );

              case 'Collection':
                return (
                  o.detail.policy === offer.detail.policy &&
                  o.tx_hash === offer.tx_hash &&
                  o.index === offer.index
                );
            }
          })
        );
      })
      .sort((a, b) => {
        if (a.detail.offer > b.detail.offer) {
          return -1;
        } else if (a.detail.offer === b.detail.offer) {
          return 0;
        }
        return 1;
      });
  }, [myOffers, receivedOffers, getOfferableAssets]);

  console.log('>>> Received Offers (distinct)', distinctRows);
  return distinctRows;
}

export function ReceivedOffers({ offers }: { offers: BestOffer[] }) {
  const loadingMyOffers = useOfferUIStore(selectLoadingMyOffers);
  const router = useRouter();
  const myOffers = useOfferUIStore(selectMyOffers);
  const [open, setOpen] = useState<null | number>(null);

  const toggleOpen = (index: number) => {
    if (open === index) {
      setOpen(null); // Close the row if it's already open
    } else {
      setOpen(index); // Open the row with the specified index
    }
  };

  const removeQueryParam = (param: string) => {
    const { pathname, query } = router;
    //@ts-expect-error - Will fix when brain works again
    const params = new URLSearchParams(query);
    params.delete(param);
    router.replace({ pathname, query: params.toString() }, undefined, {
      shallow: true,
    });
  };

  const distinctRows = useMemo(() => {
    return offers.filter((offer, index, self) => {
      if (router.query.all) return true;
      // Check if the Collection or Asset exists in a position prior to this one
      const exists = self.findIndex((o) => {
        if (o.type !== offer.type) return false;

        switch (o.type) {
          case 'Asset':
            return (
              offer.type === o.type &&
              o.collection_policy === offer.collection_policy &&
              o.token_name === offer.token_name
            );

          case 'Collection':
            return o.collection_policy === offer.collection_policy;
        }
      });
      console.log(
        'offer',
        offer.type,
        offer.collection_policy,
        offer.type === 'Asset' ? offer.token_name : 'NA',
        exists,
        index
      );
      return exists === index;
    });
  }, [offers, router.query.all]);

  if (loadingMyOffers) {
    return (
      <div className="w-full min-h-[33rem] flex flex-col justify-center items-center bg-white/25 dark:bg-gray-900/40 rounded-lg gap-2">
        <SwatchIcon
          className="w-24 opacity-30 object-fit shadow-xl"
          aria-label="No Offers"
        />
        <h2 className="text-2xl font-bold mt-4 dark:text-white">
          Loading offers
        </h2>
        <p className="text-base font-light text-gray-500 dark:text-gray-300 max-w-md text-center mb-8">
          Loading received offers.
        </p>
        <InlineLoading ballSize={8} />
      </div>
    );
  }

  if (
    distinctRows?.filter(
      (offer) =>
        offer !== null &&
        offer !== undefined &&
        !myOffers?.some((myOffer) => myOffer.tx_hash === offer.tx_hash)
    ).length === 0
  ) {
    return (
      <div className="w-full min-h-[33rem] flex flex-col justify-center items-center bg-white/25 dark:bg-gray-900/40 rounded-lg gap-2">
        <SwatchIcon
          className="w-24 opacity-30 object-fit shadow-xl"
          aria-label="No Offers"
        />
        <h2 className="text-2xl font-bold mt-4 dark:text-white">
          No Offers Received
        </h2>
        <p className="text-base font-light text-gray-500 dark:text-gray-300 max-w-md text-center mb-8">
          Offers matched to your wallet assets live here.
        </p>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col gap-2 justify-center items-center">
      <div className="w-full flex flex-col lg:hidden">
        {distinctRows?.map((offer, i) => {
          return (
            <div
              key={i}
              className="w-full flex flex-col justify-center items-center border-t border-b dark:border-gray-700 border-gray-300"
            >
              <div className="flex w-full justify-between py-2">
                {offer.detail.type === 'Asset'
                  ? offer.detail.asset && (
                    <React.Fragment key={offer.detail.asset.id}>
                      <div className="flex items-center w-full text-sm sm:text-base gap-3">
                        <span className="flex flex-row gap-2 items-center">
                          <div className="w-12 h-12 flex-none">
                            <AssetImage
                              asset={offer.detail.asset}
                              thumb={true}
                            />
                          </div>
                          <div className="font-bold flex flex-col items-start">
                            <span className="font-normal text-sm">
                              {offer.detail.asset.algoliaProjectFacet &&
                                JSON.parse(
                                  offer.detail.asset.algoliaProjectFacet
                                ).title}
                            </span>
                            <span>
                              {offer.detail.asset.title} (
                              {offer.detail.asset.tokenName})
                            </span>
                            <span className="text-accent">Asset Offer</span>
                          </div>
                        </span>
                      </div>
                    </React.Fragment>
                  )
                  : offer.detail.project && (
                    <React.Fragment
                      key={`${offer.tx_hash}${offer.detail.project.id}`}
                    >
                      <div className="flex items-center w-full text-sm sm:text-base gap-3">
                        <span className="flex flex-row gap-2 items-center">
                          <div className="relative w-12 h-12 flex-none">
                            <NextImage
                              src={offer.detail.project.heroImage}
                              priority={false}
                              width={100}
                              layout="fill"
                              className="object-cover rounded-xl"
                            />
                          </div>
                          <div className="font-bold flex flex-col items-start">
                            <span className="font-normal">
                              {offer.detail.project.name}
                            </span>
                            <span className="text-secondary">
                              Collection Offer
                            </span>
                          </div>
                        </span>
                      </div>
                    </React.Fragment>
                  )}
                <div className="flex gap-2 ">
                  <div className="flex flex-col justify-center items-end ">
                    <React.Fragment key={offer.detail.policy}>
                      <div className="font-bold text-lg flex items-center mt-1 w-max">
                        ₳{' '}
                        {(offer.detail.offer / 1_000_000)
                          .toFixed(0)
                          .toLocaleString()}
                      </div>
                    </React.Fragment>
                    <span className="text-xs">
                      {dayJs(offer.time * 1000).format('DD/MM/YYYY')}
                    </span>
                  </div>
                  <AcceptOfferMoodal
                    offer={{ ...offer, details: [offer.detail] }}
                    open={false}
                    offerAccepted={() => console.log('accepted.')}
                    compactMode={true}
                  />
                  <ChevronDownIcon
                    className={`w-6 cursor-pointer text-gray-400 dark:text-gray-600 ${open === i && 'transform rotate-180'}`}
                    onClick={() => toggleOpen(i)}
                  />
                </div>
              </div>
              {open === i && (
                <div className="flex justify-between w-full pb-2">
                  {offer.tx_hash && (
                    <Link
                      href={`${cardanoExplorerURL()}/transaction/${offer.tx_hash
                        }`}
                      target={'_blank'}
                      className='w-full'
                    >
                      <a className=" flex w-full items-center justify-between px-1 gap-2">
                        <div className="w-48">
                          <MiddleEllipsis
                            text={offer.tx_hash}
                            classNames="text-xs"
                          />
                        </div>
                        <div>
                          <ArrowTopRightOnSquareIcon className="w-4" />
                        </div>
                      </a>
                    </Link>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className='w-full hidden lg:flex'>
        <table className="table min-w-full divide-y divide-gray-300 dark:divide-gray-700 dark:text-gray-200 ">
          <thead className="bg-gray-50 dark:bg-gray-900 dark:text-gray-300 w-full">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 text-left font-semibold text-gray-900 dark:text-gray-200 sm:pl-6"
              >
                Items
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left  font-semibold text-gray-900 dark:text-gray-200"
              >
                Offer
              </th>

              {/* <th
              scope="col"
              className="px-3 py-3.5 text-left  font-semibold text-gray-900 dark:text-gray-200"
            >
              Total
            </th> */}
              <th
                scope="col"
                className="px-3 py-3.5 text-left  font-semibold text-gray-900 dark:text-gray-200"
              >
                Date
              </th>
              <th
                scope="col"
                className="w-96 px-3 py-3.5 text-left  font-semibold text-gray-900 dark:text-gray-200"
              >
                Transaction
              </th>
              <th
                scope="col"
                className="w-48 px-3 py-3.5 text-left  font-semibold text-gray-900 dark:text-gray-200 "
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody className='w-full'>
            {distinctRows?.map((offer) => (
              <tr key={offer.key}>
                <td className="whitespace-nowrap pl-3 pr-1 py-3 text-gray-800 dark:text-gray-200">
                  <div className="flex flex-col w-full gap-2">
                    {offer.detail.type === 'Asset'
                      ? offer.detail.asset && (
                        <React.Fragment key={offer.detail.asset.id}>
                          <div className="flex items-center w-full  gap-3">
                            <span className="flex flex-row gap-2 items-center">
                              <div className="w-12 h-12 flex-none">
                                <AssetImage
                                  asset={offer.detail.asset}
                                  thumb={true}
                                />
                              </div>
                              <div className="font-bold flex flex-col items-start">
                                <span className="font-normal text-sm">
                                  {offer.detail.asset.algoliaProjectFacet &&
                                    JSON.parse(
                                      offer.detail.asset.algoliaProjectFacet
                                    ).title}
                                </span>
                                <span>
                                  {offer.detail.asset.title} (
                                  {offer.detail.asset.tokenName})
                                </span>
                                <span className="text-accent">Asset Offer</span>
                              </div>
                            </span>
                          </div>
                        </React.Fragment>
                      )
                      : offer.detail.project && (
                        <React.Fragment key={offer.key}                        >
                          <div className="flex items-center w-full  gap-3">
                            <span className="flex flex-row gap-2 items-center">
                              <div className="relative w-12 h-12 flex-none">
                                <NextImage
                                  src={offer.detail.project.heroImage}
                                  priority={false}
                                  width={100}
                                  layout="fill"
                                  className="object-cover rounded-xl"
                                />
                              </div>
                              <div className="font-bold flex flex-col items-start">
                                <span className="font-normal">
                                  {offer.detail.project.name}
                                </span>
                                <span className="text-secondary">
                                  Collection Offer
                                </span>
                              </div>
                            </span>
                          </div>
                        </React.Fragment>
                      )}
                  </div>
                </td>
                <td className="whitespace-nowrap pl-1 pr-1 py-3 text-gray-800 dark:text-gray-200">
                  <React.Fragment key={offer.detail.policy}>
                    <div className="font-bold text-lg h-12 flex items-center mt-1 flex-1">
                      ₳{' '}
                      {(offer.detail.offer / 1_000_000)
                        .toFixed(0)
                        .toLocaleString()}
                    </div>
                  </React.Fragment>
                </td>
                {/* <td className="whitespace-nowrap pl-1 pr-1 py-3 text-gray-800 dark:text-gray-200">
              Address
            </td> */}
                <td className="whitespace-nowrap pl-1 pr-1 py-3 text-gray-800 dark:text-gray-200">
                  {dayJs(offer.time * 1000).format('DD/MM/YYYY')}
                </td>
                <td className="whitespace-nowrap pl-1 pr-1 py-3 text-gray-800 dark:text-gray-200 font-normal">
                  {offer.tx_hash && (
                    <Link
                      href={`${cardanoExplorerURL()}/transaction/${offer.tx_hash
                        }`}
                      target={'_blank'}
                    >
                      <a className=" flex items-center gap-2">
                        <div className="w-48">
                          <MiddleEllipsis
                            text={offer.tx_hash}
                            classNames="text-xs"
                          />
                        </div>
                        <div>
                          <ArrowTopRightOnSquareIcon className="w-4" />
                        </div>
                      </a>
                    </Link>
                  )}
                </td>

                <td className="whitespace-nowrap pl-1 pr-1 py-3 text-gray-800 dark:text-gray-200">
                  <AcceptOfferMoodal
                    offer={{ ...offer, details: [offer.detail] }}
                    open={false}
                    offerAccepted={() => console.log('accepted.')}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="fixed bottom-0 left-0 right-0 flex justify-center z-20">
        <div className="bg-white/20 dark:bg-black/20 mb-10 p-4 rounded-xl shadow-lg">
          <DaisyButton
            size="btn-sm"
            label={router.query.all ? 'Show Top Offers' : 'Show All Offers'}
            colorName="btn-accent"
            onClick={() => {
              if (router.query.all) {
                removeQueryParam('all');
              } else {
                router.replace({ query: { ...router.query, all: 1 } });
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

