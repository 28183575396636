import { Fragment, useEffect, useState } from 'react';
import { FC } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Divider } from 'components/Divider/Divider';
import DaisyButton from '#components/Daisy/DaisyButton';
import cn from 'classnames';
import { XCircleIcon } from '@heroicons/react/24/outline';

export interface OfferModalProps {
  title: string;
  message: string;
  severity: string;
  open: boolean;
  onClose?: () => void;
}

export const OfferErrorModal: FC<OfferModalProps> = ({
  title,
  message,
  severity,
  open,
  onClose,
}) => {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => setIsOpen(open), [open]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          open={isOpen}
          className="fixed inset-0 z-40 overflow-y-auto transition-height duration-150 transform"
          onClose={() => {
            onClose && onClose();
            setIsOpen(false);
          }}
        >
          <div className="min-h-screen px-4 text-center transition-height duration-150 transform">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 backdrop-filter backdrop-blur-xl backdrop-brightness-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            ></span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block dark:bg-base-300 w-full max-w-lg p-9 my-12 overflow-hidden text-left align-middle  bg-white shadow-xl rounded-2xl transition-height duration-150 transform">
                <div className="flex flex-row justify-between">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-bold leading-6 text-gray-800 dark:text-white flex items-center gap-3"
                  >
                    {title}
                  </Dialog.Title>
                </div>
                <div className="mt-4">
                  <Divider />
                </div>

                <div
                  className={cn(
                    'duration-200 transition-all flex flex-col justify-between font-normal text-center items-center dark:text-gray-300 text-gray-700'
                  )}
                >
                  <span className="flex flex-col items-center">
                    <XCircleIcon className="w-16 my-6 text-red-400" />

                    <p className="">{message}</p>
                    <p>
                      Please contact Dropspot support if you require futher
                      assistance.
                    </p>
                  </span>
                  <div className="w-full mt-8">
                    <DaisyButton
                      label="OK"
                      colorName="btn-neutral"
                      size="btn-md"
                      classNames="flex-1 w-full"
                      onClick={() => {
                        onClose && onClose();
                        setIsOpen(false);
                      }}
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
