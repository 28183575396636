/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useProvenanceItemsFromCache } from '#lib/hooks/useNativeAssetTransactions';
import { Asset, Project, ProvenanceItem } from '#types/index';
import {
  ArrowTopRightOnSquareIcon,
  ChartPieIcon,
  ChevronLeftIcon,
  HeartIcon,
  PhotoIcon,
  RectangleStackIcon,
  ShieldCheckIcon,
} from '@heroicons/react/24/solid';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useProjectAssets } from '#lib/hooks/useProjectAssets';
import Link from 'next/link';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import Head from 'next/head';
import { cardanoExplorerURL } from '#lib/plutus/utils';
import { Divider } from '#components/Divider/Divider';
import classNames from 'classnames';
import { Tooltip } from '@nextui-org/react';
import { ShareButtonSmall } from '#components/ShareButton/ShareButtonSmall';
import { useRouter } from 'next/router';
import { createLikeForAsset, getAssetListener } from '#lib/firestore';
import { useIsAssetLiked } from '#lib/utils/LikesContext';
import { SuccessNotification } from '#components/Notifications/Notification';
import { useNotifier } from 'react-headless-notifier';
import { AssetFlipCardPublic } from '../AssetFlipCard/AssetFlipCardPublic';
import { AboutCreatorToolTip } from '#components/Creator/AboutTheCreator/AboutCreatorTooltip';
import { AssetImage } from './AssetImage';
import DaisyButton, { ButtonProps } from '#components/Daisy/DaisyButton';
import { Disclosure, Transition } from '@headlessui/react';
import cn from 'classnames';

import { InferQueryOutput } from '#lib/trpc';
import { useCoinGecko } from '#lib/hooks/useCoinGecko';
import { useIpCountry } from '#lib/hooks/useIpCountry';
import {
  countryToCurrency,
  getExchangeRate,
  renderCurrency,
} from './ChainInteraction/utils';
import { InlineLoading } from '#components/Loading/InlineLoading';
import { getWalletPKH } from '#lib/plutus/DropspotMarketContract';
import {
  CardanoWalletExtended,
  useCardanoWallet,
} from '#lib/wallet/WalletContext';
import { Address, StakeAddress } from '@hyperionbt/helios';
import { AssetInsights } from './AssetPageComps/AssetInsights';
import { AssetAttributesRegion } from './AssetPageComps/AssetAttributes';
import { AssetFilesRegion } from './AssetPageComps/AssetFilesRegion';
import { MarketActionModalV2 } from './MarketActionModalV2/MarketActionModalV2';
import { ProcessingTransactionModalComponentV2 } from './MarketActionModalV2/ProcessingTransactionModalComponentV2';
import { CreateOfferV2 } from './MarketActionModalV2/NewListingModalV2';
import {
  AssetPageMarketContextWrapper,
  WrapperRef,
  useMarketActionModalStatus,
  useMarketLoading,
  useMarketPerformListing,
  useMarketPerformTransaction,
  useMarketReload,
  useMarketSetWallet,
  useMarketTxInProgress,
  useMarketUtxos,
} from './MarketActionModalV2/MarketStore';
import { PolicyTooltipV2 } from '#components/Tooltip/PolicyTooltipV2';
import DaisyBadge from '#components/Daisy/DaisyBadge';
import { AssetHoldersRegion } from './AssetPageComps/AssetHoldersRegion';
import { Offers } from './MarketActionOffers/Offers';
import { ArrowPathIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { Timer2 } from '#components/Projects/Onboard/ProjectPageMainCreator';
import { SuperCube } from '../../SuperCubes/SuperCube';
import {
  OfferContextWrapper,
  useSetWallet,
} from './MarketActionOffers/OffersStore';
import { SingleOfferModal } from '#components/Offers/SingleOfferModal';
import {
  selectSetWallet,
  useOfferUIStore,
} from './MarketActionOffers/OfferUIStore';
import { usePolicyStatsListenerListener } from '#lib/hooks/usePolicyStats';
import { MiddleEllipsisV2 } from '#components/MiddleEllipsis/MiddleEllipsisVersion2';
type AssetUtxoType = InferQueryOutput<'asset-utxo-2'>;

export interface AssetPageProps {
  asset: Asset;
  project: Project;
  isLoggedIn: boolean;
  assetUrl?: string;
}

dayjs.extend(relativeTime);
dayjs.extend(utc);

export const AssetPageMarketV2 = ({
  asset,
  project,
  isLoggedIn,
  assetUrl,
}: AssetPageProps) => {
  const assetPageMarketRef = useRef<WrapperRef>(null);

  useEffect(() => {
    // Effect Hook to wait for the transaction to be confirmed
    // Useful if the Asset changes state externally (e.g. another user buys it)
    if (!asset.txHash) return;
    assetPageMarketRef.current?.waitForTransaction(asset.txHash);
  }, [asset.txHash]);

  const { wallet } = useCardanoWallet();

  const setWallet = useOfferUIStore(selectSetWallet);

  useEffect(() => {
    if (!wallet) return;
    setWallet(wallet);
  }, [wallet, setWallet]);

  return (
    <AssetPageMarketContextWrapper
      wrapperRef={assetPageMarketRef}
      asset={asset}
      project={project}
    >
            <OfferContextWrapper
        policy={asset.policy || ''}
        tokenName={
          asset.tokenNameEncoded
            ? asset.tokenNameEncoded
            : Buffer.from(asset.tokenName || '', 'utf-8').toString('hex')
        }
      >
        <AssetPageMarketV2_
          asset={asset}
          assetUrl={assetUrl}
          project={project}
          isLoggedIn={isLoggedIn}
        />
      </OfferContextWrapper>
    </AssetPageMarketContextWrapper>
  );
};

const AssetPageMarketV2_ = ({
  asset,
  project,
  isLoggedIn,
  assetUrl,
}: AssetPageProps) => {
  const actionModalStatus = useMarketActionModalStatus();
  const setWallet = useMarketSetWallet();
  const setOfferWallet = useSetWallet();
  const reload = useMarketReload();
  const txInProgress = useMarketTxInProgress();
  const { wallet, loadingWalletAddress, walletAddress } = useCardanoWallet();
  const [walletPKH, setWalletPKH] = useState<string>();
  const [stakeAddress, setStakeAddress] = useState<string | undefined>();

  async function getStake(wallet: CardanoWalletExtended) {
    wallet
      .getRewardAddresses()
      .then((addr) => {
        setStakeAddress(StakeAddress.fromHex(addr[0]).toBech32());
      })
      .catch(console.error);
  }
  // Get the Wallet PKH
  useEffect(() => {
    if (!wallet) return;
    setWallet(wallet);
    setOfferWallet(wallet);

    getWalletPKH(wallet)
      .then((pkh) => setWalletPKH(pkh.hex))
      .catch(console.error);

    getStake(wallet)
      .then((stake) => console.log('fasav', stake))
      .catch(console.error);
  }, [wallet, setWallet, setOfferWallet]);

  useEffect(() => {
    if (asset.txHash !== undefined) return;
    //refresh the utxos:
    reload();
  }, [asset.txHash, reload]);

  const { assets } = useProjectAssets({
    projectId: project.id,
    limit: 10,
    // listedOnly: project.source == 'Market',
  });

  const router = useRouter();

  const {
    loading: provenanceLoading,
    provenanceItems,
    refreshProvenanceItems,
  } = useProvenanceItemsFromCache({
    assetId: asset.id,
    policy: asset.policy,
    tokenName: asset.tokenName,
  });

  const isAssetLiked = useIsAssetLiked(asset.id);
  const handleLikeAsset = async () => {
    const action = await createLikeForAsset(asset);
    if (action === 'R') {
      showNotificationRemmoveSuccess();
    } else {
      showNotificationAddSuccess();
    }
  };
  const { notify } = useNotifier();
  const { policyStatistics } = usePolicyStatsListenerListener({
    policy: project.policy || '',
  });

  const assetProvenanceRef = React.useRef<AssetProvenanceRef>(null);

  const showNotificationAddSuccess = () => {
    notify(<SuccessNotification message="NFT added to your likes 👌" />, {
      max: 1,
      position: 'bottom',
      duration: 2000,
    });
  };

  const showNotificationRemmoveSuccess = () => {
    notify(<SuccessNotification message="NFT removed from your likes 👌" />, {
      max: 1,
      position: 'bottom',
      duration: 2000,
    });
  };
  return (
    <div className="w-full">
      <MarketActionModalV2>
        {(() => {
          console.log('>>>> actionModalStatus', actionModalStatus);
          switch (actionModalStatus) {
            case 'OFF':
              return null;
            case 'WAITING':
              return <ProcessingTransactionModalComponentV2 />;
            case 'BUILDING':
              return <CreateOfferV2 />;
          }
        })()}
      </MarketActionModalV2>
      <Head>
        <title>{project.name}</title>
        <meta property="og:title" content={project.name} key="title" />
        <meta property="og:image" content={project.heroImage}></meta>
        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta property="og:type" content={project.heroImage}></meta>
      </Head>

      <div className="w-full flex flex-col sm:flex-row pt-24 sm:py-24 sm:px-10 text-gray-900 dark:text-gray-100 dark:bg-base-200">
        <div className="flex-1 flex-col sm:mt-8 w-full sm:w-6/12 flex items-center justify-start mr-12">
          <div className="w-full  h-[24rem] sm:h-full sm:max-h-[calc(100vh-12rem)]  rounded-xl flex justify-center items-start  overflow-hidden group mx-0 sm:mx-0">
            <AssetImage asset={asset} assetUrl={assetUrl} />
          </div>
        </div>
        <div className=" w-full sm:w-6/12 pl-2 pr-2 flex flex-col mr-4 mt-6">
          <div className="flex flex-col justify-between items-start px-2 sm:px-0">
            <span className="flex flex-row justify-between w-full gap-1 items-center">
              <span className="flex flex-col sm:flex-row items-end gap-4">
                <h1 className="text-2xl sm:text-6xl font-bold -mt-3 pt-2">
                  {asset.title == 'UNSET' ? asset.tokenName : asset.title}
                </h1>
                {!!asset.quantity && asset.quantity > 1 && (
                  <span className="pb-2">
                    <DaisyBadge
                      label={`${asset.quantity} editions`}
                      size="badge-sm"
                      colorName="btn-info"
                    />
                  </span>
                )}
              </span>

              <div className="flex flex-row gap-2 justify-center items-center">
                <div className="flex items-center justify-center dark:bg-gray-900 rounded-full">
                  <Tooltip
                    content={!!isAssetLiked ? 'Unlike' : 'Add to liked list'}
                  >
                    <div className="w-12 h-12 rounded-full  bg-gray-100 border dark:bg-gray-900 dark:border-gray-600 dark:text-gray-500 dark:hover:text-white shadow-sm flex items-center justify-center cursor-pointer hover:shadow-xl duration-200 transition hover:-translate-y-1">
                      <HeartIcon
                        className={classNames(
                          !!isAssetLiked
                            ? 'text-red-500'
                            : 'text-gray-600 opacity-80 dark:text-gray-500',
                          'w-5 h-5 hover:scale-105 hover:text-red-500 transform duration-200 cursor-pointer'
                        )}
                        onClick={(e) => {
                          handleLikeAsset();
                          e.preventDefault();
                        }}
                      />
                    </div>
                  </Tooltip>
                </div>

                <div className="flex items-center justify-center dark:bg-gray-900 rounded-full">
                  {/* <ShareButtonSmall url={getAbsoluteURL(router.asPath)} /> */}
                  <ShareButtonSmall
                    url={`http://dropspot.io${router.asPath}`}
                  />
                </div>
                <DaisyButton
                  classNames="btn-circle"
                  icon={<ArrowTopRightOnSquareIcon className="w-6" />}
                  label={''}
                  onClick={function (): void {
                    throw new Error('Function not implemented.');
                  }}
                />
              </div>
            </span>

            <div className=" dark:text-gray-400 tracking-wide mb-4 py-1 px-1  text-lg font-normal">
              {asset.description}
            </div>
          </div>

          <div className="flex flex-col mt-2 mb-2 w-full lg:flex-row gap-8 px-2 sm:px-0">
            <div className="flex flex-row gap-4 flex-wrap mb-4">
              {project.name !== 'Not Verified' ? (
                <Tooltip
                  content={'Back to collection'}
                  placement="bottom"
                  isDisabled={project.name === 'Not Verified'}
                >
                  <Link href={`/marketplace/${project.tokenPrefix}`}>
                    <a className="flex flex-shrink items-center gap-2 font-semibold">
                      <div
                        className={`py-1.5  ${
                          project.name !== 'Not Verified'
                            ? 'hover:-translate-y-1 cursor-pointer'
                            : 'cursor-default'
                        }  transition duration-150 bg-white dark:bg-gray-900/50 dark:text-white shadow-md rounded-full flex flex-row items-center pl-2 pr-5 border border-gray-300 dark:border-gray-700 gap-1`}
                      >
                        <img
                          src={project.heroImage}
                          alt="Event Hero"
                          className="object-cover w-9 h-9 rounded-full flex-shrink -ml-1"
                        ></img>

                        <div className="font-bold pl-1">{project.name}</div>
                      </div>
                    </a>
                  </Link>
                </Tooltip>
              ) : (
                <PolicyTooltipV2
                  policy={asset.policy || 'No Asset'}
                  hideTooltip={false}
                  label="Policy"
                />
              )}

              {project.source !== 'Market' &&
                !!project.creator &&
                !!project.creator.profilePhoto &&
                !project.creator?.profilePhoto.startsWith('undefined') && (
                  <AboutCreatorToolTip creator={project.creator} />
                )}

              <div className="flex flex-row flex-wrap gap-4 ">
                {project.source == 'Market' && (
                  <PolicyTooltipV2
                    policy={asset.id || 'No Asset'}
                    hideTooltip={false}
                    label="asset"
                  />
                )}
              </div>
            </div>
          </div>
          {(project.startDate || 0) > new Date().getTime() && (
            <div className="my-2">
              <Timer2 deadline={new Date(project.startDate || 0).toString()} />
            </div>
          )}
          <span className="dark:text-gray-400 font-normal mb-6">
            {asset.longDescription ||
              asset.description ||
              asset.otherAttributes
                ?.filter((a) => a.key.toUpperCase() === 'DESCRIPTION')
                .reduce((prev, current) => `${prev} ${current.value}`, '')}
          </span>
          <span className="">
            <MarketRegionV2
              loading={loadingWalletAddress}
              walletPKH={walletPKH}
              walletAddress={walletAddress}
              stakeAddress={stakeAddress}
              txInProgress={txInProgress}
              asset={asset}
              project={project}
              forceDisable={(project.startDate || 0) > new Date().getTime()}
            />
          </span>

          <Offers
            project={project}
            tokenName={
              asset.tokenNameEncoded
                ? asset.tokenNameEncoded
                : Buffer.from(asset.tokenName || 'XX').toString('hex')
            }
            floor={policyStatistics?.floor_price || 0}
          />
          <div className="flex flex-col w-full gap-2">
            <Disclosure defaultOpen={!!asset.additionalProperties}>
              {({ open }) => (
                <>
                  <Disclosure.Button className="border rounded-t-lg dark:border-gray-700/80 p-4 border-gray-300  flex flex-col my-1">
                    <div className="dark:text-gray-400 w-full text-base flex gap-2 items-center font-bold tracking-wider">
                      <ShieldCheckIcon
                        className={cn('w-5 duration-200 text-info', {
                          'text-info': open,
                        })}
                      />{' '}
                      <div className="flex flex-row w-full  justify-between">
                        <span>Attributes & Traits</span>
                        <span className="badge badge-info">
                          {asset.additionalPropertiesCalc?.length}
                        </span>
                      </div>
                    </div>
                  </Disclosure.Button>
                  <Transition
                    show={open}
                    className="overflow-hidden"
                    enter="transition transition-[max-height] duration-500 ease-in"
                    enterFrom="transform max-h-0"
                    enterTo="transform max-h-screen"
                    leave="transition transition-[max-height] duration-300 ease-out"
                    leaveFrom="transform max-h-screen"
                    leaveTo="transform max-h-0"
                  >
                    <Disclosure.Panel className="text-gray-500 border dark:border-gray-700 border-gray-300 rounded-lg  p-4">
                      <AssetAttributesRegion asset={asset} />
                    </Disclosure.Panel>
                  </Transition>
                </>
              )}
            </Disclosure>
            {project.source != 'Dropspot' && <AssetInsights asset={asset} />}
            {asset.quantity && asset?.quantity > 1 && (
              <AssetHoldersRegion asset={asset} />
            )}

            <Disclosure defaultOpen>
              {({ open }) => (
                <>
                  <Disclosure.Button className="border rounded-t-lg dark:border-gray-700/80 p-4 flex flex-col border-gray-300  my-1">
                    <div className="dark:text-gray-400 text-base flex gap-2 items-center justify-between w-full font-bold tracking-wider">
                      <span className="flex flex-row items-center gap-2">
                        <PhotoIcon
                          className={cn('w-5 duration-200 text-info', {
                            'text-info': open,
                          })}
                        />
                        {'  '}
                        Artifacts{' '}
                      </span>
                      {/* <span className="font-light">1 file</span> */}
                    </div>
                  </Disclosure.Button>
                  <Transition
                    show={open}
                    className="overflow-hidden"
                    enter="transition transition-[max-height] duration-500 ease-in"
                    enterFrom="transform max-h-0"
                    enterTo="transform max-h-screen"
                    leave="transition transition-[max-height] duration-300 ease-out"
                    leaveFrom="transform max-h-screen"
                    leaveTo="transform max-h-0"
                  >
                    <Disclosure.Panel className="text-gray-500 border dark:border-gray-700/80 p-3">
                      <AssetFilesRegion asset={asset} />
                    </Disclosure.Panel>
                  </Transition>
                </>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="border rounded-t-lg dark:border-gray-700/80 p-4 flex flex-col border-gray-300 my-1">
                    <div className="dark:text-gray-400 text-base flex gap-2 items-center font-bold tracking-wider">
                      <RectangleStackIcon
                        className={'w-5 duration-200 text-info'}
                      />{' '}
                      History
                    </div>
                  </Disclosure.Button>
                  <Transition
                    show={open}
                    className="overflow-hidden"
                    enter="transition transition-[max-height] duration-500 ease-in"
                    enterFrom="transform max-h-0"
                    enterTo="transform max-h-screen"
                    leave="transition transition-[max-height] duration-300 ease-out"
                    leaveFrom="transform max-h-screen"
                    leaveTo="transform max-h-0"
                  >
                    <Disclosure.Panel className="text-gray-500 border dark:border-gray-700/80 p-3">
                      <AssetProvenance
                        ref={assetProvenanceRef}
                        project={project}
                        loadingFromCache={provenanceLoading}
                        provenanceItems={provenanceItems}
                        refreshProvenanceItems={refreshProvenanceItems}
                      />
                    </Disclosure.Panel>
                  </Transition>
                </>
              )}
            </Disclosure>
          </div>
          <div className="flex flex-col mt-2 mb-2 w-full lg:flex-row gap-8 px-2 sm:px-0">
            <div className="flex flex-row gap-4 flex-wrap mb-4">
              {/* <Tooltip content={'Open collection'} placement="bottom">
                <Link href={`/collections/${project.tokenPrefix}`}>
                  <a className="flex flex-shrink items-center gap-2 font-semibold">
                    <div className=" py-1 hover:-translate-y-1 cursor-pointer transition duration-150 bg-white dark:bg-gray-900 dark:text-white shadow-md rounded-full flex flex-row items-center pl-2 pr-5 border border-gray-300 dark:border-gray-600 gap-1">
                      <img
                        src={project.heroImage}
                        alt="Event Hero"
                        className="object-cover w-19 h-10 rounded-full flex-shrink -ml-1"
                      ></img>

                      <div className="font-bold pl-1 flex flex-col">
                        <span className="text-xs font-light">Verified</span>
                        {project.name}
                      </div>
                    </div>
                  </a>
                </Link>
              </Tooltip> */}
              {project.source == 'Dropspot' && (
                <AboutCreatorToolTip creator={project.creator} />
              )}
              {/* <div className="flex flex-row flex-wrap gap-4 ">
                {project.source == 'Market' && (
                  <PolicyTooltip
                    policy={asset.id || 'No Asset'}
                    hideTooltip={false}
                    label="asset"
                  />
                )}
              </div> */}
            </div>
          </div>
          {/* <MarketRegionMain
            asset={asset}
            project={project}
            reload={async () => {
              try {
                await clearTxHash(asset.id);
                assetProvenanceRef.current?.reload();
              } catch (message) {
                return console.error(message);
              }
            }}
          />
          <span className="px-2">
            <TabBar
              className=""
              tabs={tabs}
              tabSelected={(tabId) => {
                const tab = tabs.find((t) => t.id === tabId);

                if (!tab) {
                  console.error('No Tab? Something went wrong');
                  return;
                }

                setCurrentTab(tab.id);
                setTabs((currTabs) =>
                  currTabs.map((tab) => {
                    tab.current = tab.id === tabId;
                    return tab;
                  })
                );
              }}
            />
            <span className={classNames({ hidden: currentTab !== 'ABOUT' })}>
              {renderAssetOverview()}
            </span>
            <span
              className={classNames({ hidden: currentTab !== 'ATTRIBUTES' })}
            >
              {asset.assetOrigin == 'Market'
                ? renderAttributesCollectible()
                : renderAttributesArt()}
            </span>
            <span
              className={classNames({ hidden: currentTab !== 'PROVENANCE' })}
            >
              <AssetProvenance
                ref={assetProvenanceRef}
                asset={asset}
                project={project}
              />
            </span>

            <span
              className={classNames({ hidden: currentTab !== 'ANALYTICS' })}
            >
              {renderPriceHistoryChart()}
            </span>
          </span> */}
        </div>
      </div>
      <div className="dark:bg-base-200 pt-12 pb-24">
        <div className=" container mx-auto">
          <div className="text-3xl sm:font-6xl font-bold sentencecase pb-2 text-gray-900 dark:text-gray-100 text-center sm:text-left">
            More from this collection
          </div>
          <Divider />
          <div className="grid mt-10 w-full px-2 sm:px-0 gap-2 sm:gap-10 grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-5 4xl-grid-cols-5  5xl:grid-cols-6 6xl:grid-cols-7">
            {assets?.map((asset) => (
              <React.Fragment key={asset.id}>
                <AssetFlipCardPublic
                  asset={asset}
                  key={asset.id}
                  heightClass="h-[18rem] sm:h-96 "
                  showLikes={isLoggedIn}
                  link={`/nft/${asset.id}`}
                  autoPlay={false}
                  useB64ForImage={project.imageDisplayPreference == 'B64'}
                />
              </React.Fragment>
            ))}
          </div>
          {/* <div className="flex justify-center mt-16">
            <Link href={`/marketplace/${project.tokenPrefix}`}>
              <a className="bg-white dark:bg-gray-700 border dark:border-gray-600 dark:text-white border-gray-200 hover:shadow-lg  dark:text:gray-200 text-lg rounded-full text-gray-900 flex justify-center items-center px-9 py-3 hover:scale-105 duration-200 hover:-translate-y-1">
                Explore the Collection
              </a>
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
};

type AssetProvenanceRef = {
  reload: () => void;
};
type AssetProvenanceProps = {
  project: Project;
  loadingFromCache: boolean;
  refreshProvenanceItems: () => void;
  provenanceItems?: ProvenanceItem[];
};

const AssetProvenance = React.forwardRef<
  AssetProvenanceRef,
  AssetProvenanceProps
>(
  (
    { loadingFromCache, project, refreshProvenanceItems, provenanceItems },
    ref
  ) => {
    // const {
    //   assetTransactions,
    //   loading,
    //   reload: updateProvenance,
    // } = useNativeAssetTransactions({
    //   asset: asset,
    // });
    React.useImperativeHandle(ref, () => ({
      reload: () => {
        refreshProvenanceItems();
      },
    }));

    function actionToDisplay(action: string) {
      switch (action) {
        case 'LIST':
          return 'Listed for sale';
        case 'PURCHASE':
          return 'Purchased';
        case 'CANCEL':
          return 'Delisted';
        case 'RELIST':
          return 'Relist';
      }
      return action;
    }

    const [rowsToDisplay, setRowsToDisplay] = useState(5);
    return (
      <div className="flex flex-col w-full lg:flex-row gap-8">
        <div className="flex-1">
          <div className="flex flex-1 flex-col justify-between mt-2 ">
            <div className="flex flex-col gap-2 w-full">
              {loadingFromCache && (
                <>
                  <div className="animate-pulse flex flex-row space-x-4">
                    <div className="rounded-full bg-gray-300 h-8 w-8"></div>
                    <div className="bg-gray-300 h-8 flex-1 rounded-lg"></div>
                    <div className="bg-gray-300 h-8 flex-1 rounded-lg"></div>
                    <div className="bg-gray-300 h-8 w-24 rounded-lg"></div>
                  </div>
                  <div className="animate-pulse flex flex-row space-x-4">
                    <div className="rounded-full bg-gray-300 h-8 w-8"></div>
                    <div className="bg-gray-300 h-8 flex-1 rounded-lg"></div>
                    <div className="bg-gray-300 h-8 flex-1 rounded-lg"></div>
                    <div className="bg-gray-300 h-8 w-24 rounded-lg"></div>
                  </div>
                </>
              )}

              {provenanceItems
                ?.slice(0, Math.min(rowsToDisplay, provenanceItems.length))
                .map((txs, idx) => {
                  if (txs.action !== 'MINT') {
                    return (
                      <React.Fragment
                        key={`${txs.tx_hash}.${txs.action}${idx}`}
                      >
                        <Link
                          href={`${cardanoExplorerURL()}/transaction/${
                            txs.tx_hash
                          }`}
                        >
                          <a target={'_blank'}>
                            <div className="group flex flex-row bg-white dark:bg-gray-700/40 justify-start w-full gap-4 items-center shadow-sm py-3 px-4 rounded-lg border border-gray-200 dark:border-gray-700 transform duration-100 dark:text-white">
                              <img
                                src={
                                  txs.toUser?.photo ||
                                  `https://www.gravatar.com/avatar/?d=mp&s=500`
                                }
                                className="rounded-full w-11 h-11 flex-initial pixelated shadow-md ring-2 ring-white object-cover"
                                alt="action"
                              />
                              <div className="flex flex-col flex-1">
                                <div className="flex-1 flex gap-2 flex-row text-gray-900 capitalize dark:text-gray-100 items-center">
                                  <div className="font-bold normal-case">
                                    {actionToDisplay(txs.action)} by
                                  </div>
                                  {txs.toUser?.displayName &&
                                    txs.toUser?.displayName}
                                  {!txs.toUser?.displayName && (
                                    <div
                                      style={{}}
                                      className="max-w-[8rem] text-gray-900 dark:text-gray-300 dark:group-hover:text-white"
                                    >
                                      <MiddleEllipsisV2
                                        text={txs.toUser?.address || ''}
                                        label="Address"
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="flex-1 text-gray-600 dark:text-gray-500 text-sm normal-case mt-1">
                                  {dayjs
                                    .utc(txs.date)
                                    .local()
                                    .format('DD MMM, YYYY - HH:mm')}{' '}
                                  <span className=" text-sm">
                                    ({dayjs.utc(txs.date).fromNow()})
                                  </span>
                                </div>
                              </div>
                              {(txs.action == 'LIST' ||
                                txs.action == 'RELIST') &&
                                txs.listed_price > 0 && (
                                  <div className="text-lg font-semibold">
                                    ₳ {txs.listed_price / 1_000_000}
                                  </div>
                                )}
                              {txs.action == 'PURCHASE' &&
                                txs.price_paid > 0 && (
                                  <div className="text-lg font-semibold">
                                    ₳ {(txs.price_paid / 1_000_000).toFixed(1)}
                                  </div>
                                )}

                              <div className="flex-initial w-5 text-gray-400 flex flex-row">
                                <ArrowTopRightOnSquareIcon className="w-5 h-5 group-hover:text-black dark:group-hover:text-white transform duration-100" />
                              </div>
                            </div>
                          </a>
                        </Link>
                      </React.Fragment>
                    );
                  }
                })}

              {provenanceItems
                ?.filter((txs) => txs.action === 'MINT')
                .map((txs, idx) => {
                  return (
                    <React.Fragment key={`${txs.tx_hash}.${txs.action}${idx}`}>
                      <Link
                        href={`${cardanoExplorerURL()}/transaction/${
                          txs.tx_hash
                        }`}
                      >
                        <a target={'_blank'}>
                          <div className="group flex flex-row bg-white dark:bg-gray-700/40 justify-start w-full gap-4 items-center shadow-sm py-3 px-4 rounded-lg border border-gray-200 dark:border-gray-700 transform duration-200 dark:text-white">
                            <img
                              src={
                                project.creator.profilePhoto ||
                                `https://www.gravatar.com/avatar/?d=mp&s=500`
                              }
                              className="rounded-full w-11 h-11 flex-initial pixelated shadow-md ring-2 ring-white object-cover"
                              alt="action"
                            />
                            <div className="flex-1 text-gray-900 dark:text-white  flex flex-col  ">
                              <div className="flex items-center gap-2">
                                <div className="font-bold">Minted by</div>
                                <span className="text-gray-900 dark:text-gray-300 dark:group-hover:text-white">
                                  {project.creator.displayName}{' '}
                                </span>
                              </div>
                              <div className="flex-1 text-gray-600 dark:text-gray-500 text-sm normal-case">
                                {dayjs
                                  .utc(txs.date)
                                  .local()
                                  .format('DD MMM, YYYY - HH:mm')}{' '}
                                ({dayjs.utc(txs.date).fromNow()})
                              </div>
                            </div>
                            <div className="flex-initial w-5 dark:text-gray-400">
                              <ArrowTopRightOnSquareIcon className="w-5 h-5 group-hover:text-black dark:group-hover:text-white transform duration-100" />
                            </div>
                          </div>
                        </a>
                      </Link>
                    </React.Fragment>
                  );
                })}
            </div>
            <div className="flex flex-row justify-center pt-8 ">
              {rowsToDisplay == 5 && (
                <span
                  className="font-light cursor-pointer text-sm dark:text-gray-400 text-gray-600"
                  onClick={() => setRowsToDisplay(provenanceItems?.length || 5)}
                >
                  Show more
                </span>
              )}
              {rowsToDisplay > 5 && (
                <span
                  className="font-light cursor-pointer"
                  onClick={() => {
                    setRowsToDisplay(5);
                    window.scrollTo(0, 0);
                  }}
                >
                  Hide
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

AssetProvenance.displayName = 'AssetProvenance';
export function flattenNoSpaces(item: string | string[]) {
  if (Array.isArray(item)) {
    return item.join('');
  }
  return item;
}

type MarketRegionV2Props = {
  loading: boolean;
  walletPKH?: string;
  walletAddress?: string;
  stakeAddress?: string;
  txInProgress?: boolean;
  asset: Asset;
  project?: Project;
  forceDisable?: boolean;
};

export const MarketRegionV2 = React.memo(MarketRegionV2_);

export function MarketRegionV2_({
  loading, // Identifies if the Wallet has loaded
  walletPKH,
  walletAddress,
  stakeAddress,
  txInProgress,
  asset,
  project,
  forceDisable,
}: MarketRegionV2Props) {
  const { coinGeckoPrice } = useCoinGecko();
  const { defaultCountry } = useIpCountry();
  const utxos = useMarketUtxos();
  const marketLoading = useMarketLoading();
  const doTransaction = useMarketPerformTransaction();
  const doListing = useMarketPerformListing();
  const [lAsset, setLAsset] = useState<Asset>();
  const [selectedUtxoIndex, setSelectedUtxoIndex] = useState<number>(0);
  const [openMakeOffer, setOpenMakeOffer] = useState(false);
  const goForward = () => {
    let next = selectedUtxoIndex + 1;
    if (next == utxos.length) {
      next = 0;
    }
    setSelectedUtxoIndex(next);
  };
  const goBack = () => {
    let prev = selectedUtxoIndex - 1;
    if (prev < 0) {
      prev = utxos.length - 1;
    }
    setSelectedUtxoIndex(prev);
  };

  const selectedUtxo = utxos
    .sort((a, b) => parseFloat(b.price || '0') - parseFloat(a.price || '0'))
    .map((utxo, index) => {
      if (index == selectedUtxoIndex) {
        return utxo;
      }
    })[0];

  useEffect(() => {
    return getAssetListener(asset.id, (a) => setLAsset(a));
  }, [asset.id, selectedUtxo?.price, selectedUtxo?.type]);

  const doOffer = () => {
    console.log('doin offer');
    setOpenMakeOffer(true);
  };

  if (loading || utxos.length === 0) {
    return (
      <div className="justify-between border rounded-lg dark:border-gray-700/80 p-6 border-gray-300 dark:bg-base-200 flex flex-col mb-4 h-[11rem]">
        <span className="animate-pulse">
          <div className="flex justify-between items-center">
            <div className="dark:text-gray-400 text-base uppercase font-bold">
              MARKET STATUS
            </div>

            <div className="text-sm font-light dark:text-info text-info flex items-center gap-1">
              <ArrowPathIcon className="h-4 w-4 text-info animate-spin " />{' '}
              Loading
            </div>
          </div>
        </span>

        <div className="w-full flex justify-between flex-1">
          <div className="flex flex-col">
            <div className="font-bold mt-4 text-5xl blur">₳ 1000</div>
            <div className="font-bold text-sm blur text-gray-500">
              $500 <span className="text-yellow-500">0 5%</span>
            </div>
          </div>
          <div className="items-end flex">
            <DaisyButton
              classNames="btn-neutral"
              label="Buy Now"
              isDisabled={true}
              onClick={() => console.log('do nothing')}
            />
          </div>
        </div>
      </div>
    );
  }

  function titleMessage(userIsOwner: boolean, utxo: AssetUtxoType[number]) {
    switch (utxo.type) {
      case 'TXN':
        return userIsOwner ? 'Manage Listing' : 'Buy Now';
      case 'URL':
        return 'Buy Now';
      case 'WALLET':
        return userIsOwner ? 'In Your Wallet' : 'Not listed';
    }
  }

  //royalty items is an arr of obj and we need to combine percent value for each
  const royaltyTotalPercent = project?.royaltyItems
    ? project?.royaltyItems?.reduce((acc: number, cur: { percent: number }) => acc + cur.percent, 0)
    : 0;

    return (
    <React.Fragment>
        <SingleOfferModal
          project={project || undefined}
          asset={asset}
          hideLaunchButton={true}
          open={openMakeOffer}
          offerType={'ASSET'}
          offerAdded={() => console.log('here')}
          onClose={() => setOpenMakeOffer(false)}
        />
      {utxos
        // .filter((utxo) => !!utxo.price)
        .sort((a, b) => parseFloat(b.price || '0') - parseFloat(a.price || '0'))
        // .slice(selectedUtxoIndex, selectedUtxoIndex + 1)
        .map((utxo, index) => {
          if (index == selectedUtxoIndex) {
            if (utxo.type === 'ERR') {
              return (
                <div
                  key={`${utxo.type}-${index}`}
                  className="border rounded-lg dark:border-gray-700/80 p-6 border-gray-300  flex flex-col mb-4"
                >
                  <div className="flex justify-between items-center">
                    <div className="dark:text-gray-400 text-sm font-semibold">
                      Issue with this Asset Listing
                    </div>
                  </div>

                  <div className="flex flex-row gap-2 items-end justify-between">
                    {utxo.message}
                  </div>
                </div>
              );
            }

            const ada = lovelaceToAda(utxo.price);
            const userIsOwner: boolean =
              (utxo.type === 'WALLET' &&
                ((utxo.ownerStakeAddress !== null &&
                  walletPKH !== undefined &&
                  utxo.ownerStakeAddress === walletPKH) ||
                  utxo.ownerStakeAddress === stakeAddress ||
                  (walletAddress !== undefined &&
                    utxo.ownerStakeAddress === walletAddress))) ||
              (utxo.type === 'TXN' &&
                !!utxo.asset_owner &&
                walletPKH !== undefined &&
                Address.fromHex(utxo.asset_owner.join('')).pubKeyHash.hex ===
                  walletPKH) ||
              (utxo.type === 'JPG_TXN' &&
                !!utxo.asset_owner &&
                walletPKH !== undefined &&
                utxo.asset_owner === walletPKH);

            // const paymentAddress = Address.fromHex(
            //   (!!utxo.asset_owner && utxo.asset_owner.join('')) || ''
            // );
            // const ownerAddress = paymentAddress.toBech32();
            // const stakingHash = paymentAddress.stakingHash;

            // const stakeAddress = StakeAddress.fromStakeKeyHash(
            //   constants.TESTNET,
            //   stakingHash
            // );

            return (
              <Fragment key={`${utxo.type}-${index}`}>
                <div className="border rounded-lg dark:border-gray-600/50 shadow-xl p-6 border-gray-300 dark:bg-base-300 flex flex-col mb-4">
                  <div className="flex justify-between items-center">
                    <div className="dark:text-gray-400 text-base uppercase font-bold">
                      {titleMessage(userIsOwner, utxo)}
                    </div>
                    {txInProgress && (
                      <div className="text-sm font-light dark:text-info text-info flex items-center gap-1">
                        <ArrowPathIcon className="h-4 w-4 text-info animate-spin " />{' '}
                        Confirming
                      </div>
                    )}
                  </div>

                  <div className="flex flex-row flex-wrap gap-2 sm:items-end justify-between">
                    <div>
                      <span className="flex flex-row items-end gap-4 mt-2">
                        <div className="font-bold w-max text-3xl sm:text-5xl">
                          ₳ {renderPrice(utxo, lAsset || asset, userIsOwner)}
                        </div>
                        {/* {asset.quantity && asset.quantity > 1 && (
                      <span className="pb-3 text-sm">
                        1 / {utxos.filter((u) => !!u.price).length} listings
                      </span>
                    )} */}
                      </span>
                      <div className="font-normal text-gray-400  mt-2 text-base pb-1">
                        <span className="flex items-center gap-2 sm:text-base text-sm">
                          <span className="w-max text-sm">
                            {!!defaultCountry && !!coinGeckoPrice ? (
                              <>
                                {ada === '-'
                                  ? '-'
                                  : renderCurrency(
                                      countryToCurrency(defaultCountry),
                                      (getExchangeRate(
                                        defaultCountry,
                                        coinGeckoPrice
                                      ) || 0) * ada
                                    )}
                              </>
                            ) : (
                              <InlineLoading ballSize={7} />
                            )}{' '}
                            |{' '}
                          </span>
                          <span className="flex items-center gap-1 text-yellow-500 sm:mr-5">
                            {' '}
                            <ChartPieIcon className="w-4 h-4" /> {royaltyTotalPercent * 100}%
                          </span>
                          <SuperCube
                            policy={asset.policy || ''}
                            listedOnSuperCubeContract={
                              utxo.supercube_enabled || utxo.type === 'WALLET'
                            }
                          />
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-row w-full max-w-max flex-wrap-reverse justify-center sm:justify-end items-center gap-2 mt-2 lg:mt-8">
                      {!forceDisable && (
                        <TransactionComponent
                          utxo={utxo}
                          txInProgress={txInProgress}
                          userIsOwner={userIsOwner}
                          onClick={(action) => {
                            switch (action) {
                              case 'buy':
                              case 'cancel':
                                return doTransaction(action, utxo);
                              case 'list':
                                return doListing({ type: 'list' });
                              case 'relist':
                                return doListing({ type: 'relist', utxo });
                              case 'makeOffer':
                                return doOffer();
                              case 'delistToDs':
                                return doListing({ type: 'delistToDs', utxo });
                            }
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {asset.quantity && asset.quantity > 1 && utxos.length > 1 && (
                  <div className="flex flex-row gap-2 items-center">
                    <div>
                      <DaisyButton
                        onClick={() => goBack()}
                        label=""
                        size="btn-sm"
                        icon={<ChevronLeftIcon className="w-4" />}
                      />
                    </div>
                    <div>
                      {selectedUtxoIndex + 1} of {asset.quantity}
                    </div>
                    <div>
                      {' '}
                      <DaisyButton
                        onClick={() => goForward()}
                        label=""
                        size="btn-sm"
                        icon={<ChevronRightIcon className="w-4" />}
                      />
                    </div>
                  </div>
                )}
              </Fragment>
            );
          }
        })}
    </React.Fragment>
  );
}

function renderPrice(
  utxo: AssetUtxoType[number],
  asset: Asset,
  userIsOwner: boolean
) {
  if (
    (asset.marketStatus === 'UNLISTED' &&
      utxo.type !== 'JPG_TXN' &&
      utxo.type !== 'TXN') ||
    (utxo.type === 'WALLET' && !userIsOwner)
  ) {
    return '-';
  }
  if (utxo.price) {
    return Number.parseInt(utxo.price) / 1000000;
  }

  if (asset.price && utxo.type !== 'WALLET') {
    return asset.price / 1000000;
  }

  return '-';
}

export type TransactionComponentProps = {
  utxo: AssetUtxoType[number];
  userIsOwner: boolean;
  btnClasses?: ButtonProps['size'];
  txInProgress?: boolean;
  onClick: (
    action: 'buy' | 'cancel' | 'list' | 'relist' | 'makeOffer' | 'delistToDs'
  ) => void;
};
export function TransactionComponent({
  utxo,
  userIsOwner,
  btnClasses,
  onClick,
  txInProgress,
}: TransactionComponentProps) {
  const loading = useMarketLoading();
  const disableButtons = txInProgress || loading;

  switch (utxo.type) {
    case 'URL': {
      return (
        <>
          <DaisyButton
            label={'Make Offer'}
            isDisabled={disableButtons}
            size={btnClasses}
            onClick={function (): void {
              onClick('makeOffer');
            }}
          />
          <DaisyButton
            label={utxo.contract === 'SpaceBudz' ? 'SpaceBudz.io' : 'JPG Store'}
            colorName="btn-secondary"
            size={btnClasses}
            isDisabled={disableButtons}
            onClick={function (): void {
              window.open(utxo.url, '_blank');
            }}
          />
        </>
      );
    }
    case 'WALLET': {
      if (userIsOwner) {
        return (
          <DaisyButton
            label={'Create Listing'}
            colorName="btn-secondary"
            size={btnClasses}
            isDisabled={disableButtons}
            onClick={function (): void {
              onClick('list');
            }}
          />
        );
      }

      return (
        <DaisyButton
          label={'Make Offer'}
          isDisabled={disableButtons}
          size={btnClasses}
          onClick={function (): void {
            onClick('makeOffer');
          }}
        />
      );
    }
    case 'TXN': {
      if (userIsOwner) {
        return (
          <>
            <DaisyButton
              label={'Reprice'}
              isDisabled={disableButtons}
              size={btnClasses}
              colorName="btn-secondary"
              onClick={function (): void {
                onClick('relist');
              }}
            />
            <DaisyButton
              label={'Delist'}
              isDisabled={disableButtons}
              size={btnClasses}
              colorName="btn-neutral"
              onClick={function (): void {
                onClick('cancel');
              }}
            />
          </>
        );
      }

      return (
        <>
          <DaisyButton
            label={'Make Offer'}
            size={btnClasses}
            isDisabled={disableButtons}
            onClick={function (): void {
              onClick('makeOffer');
            }}
          />
          <DaisyButton
            label={'Buy Now'}
            isDisabled={disableButtons}
            colorName="btn-secondary"
            size={btnClasses}
            onClick={() => {
              onClick('buy');
            }}
          />
        </>
      );
    }
    case 'JPG_TXN': {
      if (userIsOwner) {
        return (
          <>
            <DaisyButton
              label={'Relist to Dropspot'}
              isDisabled={disableButtons}
              size={btnClasses}
              colorName="btn-secondary"
              onClick={function (): void {
                onClick('delistToDs');
              }}
            />
          </>
        );
      }

      return (
        <>
          <DaisyButton
            label={'Make Offer'}
            size={btnClasses}
            isDisabled={disableButtons}
            onClick={function (): void {
              onClick('makeOffer');
            }}
          />
          <DaisyButton
            label={'Buy Now'}
            isDisabled={disableButtons}
            colorName="btn-secondary"
            size={btnClasses}
            onClick={() => {
              onClick('buy');
            }}
          />
        </>
      );
    }
  }

  return null;
}

// Rounding inspired by https://stackoverflow.com/a/48764436/5018798
function lovelaceToAda(price: string | undefined, toDecimalPlaces = 0) {
  if (!price) {
    return '-';
  }
  const numberPrice = parseInt(price);
  if (Number.isNaN(numberPrice)) return '-';
  const p = Math.pow(10, toDecimalPlaces);

  return (Math.round(numberPrice / 1_000_000) * p) / p;
}
