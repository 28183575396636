import { copyTextToClipboard } from '#components/ShareButton/ShareButton';
import { useDarkSide } from '#lib/hooks/useDarkSide';
import { cardanoExplorerURL } from '#lib/plutus/utils';
import cn from 'classnames';
import {
  CheckIcon,
  ArrowTopRightOnSquareIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/24/outline';
import { Tooltip } from '@nextui-org/react';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import MiddleEllipsis from 'react-middle-ellipsis';

type PolicyTooltipProps = {
  policy?: string;
  hideTooltip?: boolean;
  label?: string;
};

export const PolicyTooltip = ({
  policy,
  hideTooltip,
  label = 'Policy',
}: PolicyTooltipProps) => {
  const [isCopied, setIsCopied] = useState(false);
  const { colorTheme, setTheme } = useDarkSide();
  const [isHidden, setIsHidden] = useState(true);
  const [darkSide, setDarkSide] = useState<boolean>(
    colorTheme === 'light' ? true : false
  );
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(policy || `No ${label}`)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setIsHidden(false);
    }, 100);
  }, [policy]);

  const renderCreatorView = () => {
    return (
      <div className="flex flex-col gap-0 text-base w-52 font-medium ">
        <div
          className="flex flex-row justify-between items-center cursor-pointer hover:bg-gray-300 px-3 py-2 rounded-lg my-1"
          onClick={() => handleCopyClick()}
        >
          {!!isCopied && (
            <>
              <p>Copied</p>
              <CheckIcon className="w-5 h-5 text-green-600" />
            </>
          )}
          {!isCopied && (
            <>
              <p>Copy {label}</p>
              <DocumentDuplicateIcon className="w-5 h-5" />
            </>
          )}
        </div>
        <div className="flex flex-row justify-between items-center cursor-pointer hover:bg-gray-300 px-3 py-2 rounded-lg my-1">
          <Link
            href={`${cardanoExplorerURL()}/${
              label == 'asset' ? 'token' : 'tokenPolicy'
            }/${policy}`}
          >
            <a
              target={'_blank'}
              className="flex flex-row justify-between w-full"
            >
              Open Cardanoscan <ArrowTopRightOnSquareIcon className="h-5 w-5" />
            </a>
          </Link>
        </div>
      </div>
    );
  };

  if (!!hideTooltip) {
    return (
      <>
        {' '}
        <div className="flex flex-row flex-wrap gap-4">
          <div className="h-10 bg-white dark:bg-gray-900/80 text-white w-[16rem] border dark:border-gray-700/80 rounded-full shadow-lg flex flex-row">
            <div className="bg-gray-200 dark:bg-gray-800 dark:text-gray-300 flex flex-shrink rounded-full px-0.5 text-gray-800 text-sm font-normal pr-8 shadow-md items-center">
              <img
                src={'/img/cardano-logo-black.webp'}
                alt="Event Hero"
                className="object-cover w-5 h-5 rounded-full flex-shrink m-2"
              ></img>
              {label}
            </div>

            <Link
              href={`${cardanoExplorerURL()}/${
                label == 'asset' ? 'token' : 'tokenPolicy'
              }/${policy}`}
            >
              <a
                className="flex flex-shrink text-gray-800 dark:text-white whitespace-nowrap items-center ml-2 gap-2 pr-2 w-40 hover:text-black font-semibold text-sm"
                target={'_blank'}
              >
                <MiddleEllipsis>
                  <span
                    className={cn('font-mono text-sm duration-200 transition', {
                      'opacity-0': isHidden,
                    })}
                  >
                    {policy ?? `* No ${label} *`}
                  </span>
                </MiddleEllipsis>
              </a>
            </Link>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <Tooltip
        content={renderCreatorView()}
        placement={'bottom'}
        color={darkSide == true ? 'invert' : 'default'}
      >
        <div className="flex flex-row flex-wrap gap-4">
          <div className="h-10 bg-white dark:bg-gray-900/80 text-white w-[16rem] border dark:border-gray-600 rounded-full shadow-lg flex flex-row">
            <div className="bg-gray-200 dark:bg-gray-800 dark:text-gray-300 flex flex-shrink rounded-full px-0.5 text-gray-800 text-sm font-normal pr-8 shadow-md items-center">
              <img
                src={'/img/cardano-logo-black.webp'}
                alt="Event Hero"
                className="object-cover w-5 h-5 rounded-full flex-shrink m-2"
              ></img>
              {label}
            </div>

            <Link
              href={`${cardanoExplorerURL()}/${
                label == 'asset' ? 'token' : 'tokenPolicy'
              }/${policy}`}
            >
              <a
                className="flex flex-shrink text-gray-800 dark:text-white items-center ml-2 gap-2 pr-2 w-40 whitespace-nowrap hover:text-black font-semibold text-sm"
                target={'_blank'}
              >
                <MiddleEllipsis>
                  <span
                    className={cn('font-mono duration-200 transition', {
                      'opacity-0': isHidden,
                    })}
                  >
                    {policy ?? `* No Policy *`}
                  </span>
                </MiddleEllipsis>
              </a>
            </Link>
          </div>
        </div>
      </Tooltip>
    </>
  );
};

// export const TokenTooltip = ({ policy, hideTooltip }: PolicyTooltipProps) => {
//   const [isCopied, setIsCopied] = useState(false);
//   const { colorTheme, setTheme } = useDarkSide();
//   const [isHidden, setIsHidden] = useState(true);
//   const [darkSide, setDarkSide] = useState<boolean>(
//     colorTheme === 'light' ? true : false
//   );
//   const handleCopyClick = () => {
//     // Asynchronously call copyTextToClipboard
//     copyTextToClipboard(policy || 'No Policy')
//       .then(() => {
//         // If successful, update the isCopied state value
//         setIsCopied(true);
//         setTimeout(() => {
//           setIsCopied(false);
//         }, 1500);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };

//   useEffect(() => {
//     setTimeout(() => {
//       setIsHidden(false);
//     }, 100);
//   }, [policy]);

//   const renderCreatorView = () => {
//     return (
//       <div className="flex flex-col gap-0 text-base w-52 font-medium ">
//         <div
//           className="flex flex-row justify-between items-center cursor-pointer hover:bg-gray-300 px-3 py-2 rounded-lg my-1"
//           onClick={() => handleCopyClick()}
//         >
//           {!!isCopied && (
//             <>
//               <p>Copied</p>
//               <CheckIcon className="w-5 h-5 text-green-600" />
//             </>
//           )}
//           {!isCopied && (
//             <>
//               <p>Copy Asset ID</p>
//               <DocumentDuplicateIcon className="w-5 h-5" />
//             </>
//           )}
//         </div>
//         <div className="flex flex-row justify-between items-center cursor-pointer hover:bg-gray-300 px-3 py-2 rounded-lg my-1">
//           <Link href={`${cardanoExplorerURL()}/token/${policy}`}>
//             <a
//               target={'_blank'}
//               className="flex flex-row justify-between w-full"
//             >
//               Open Cardanoscan <ArrowTopRightOnSquareIcon className="h-5 w-5" />
//             </a>
//           </Link>
//         </div>
//       </div>
//     );
//   };

//   if (!!hideTooltip) {
//     return (
//       <>
//         {' '}
//         <div className="flex flex-row flex-wrap gap-4">
//           <div className="h-10 bg-white dark:bg-gray-900/80 text-white w-[16rem] border dark:border-gray-600 rounded-full shadow-lg flex flex-row">
//             <div className="bg-gray-200 dark:bg-gray-800 dark:text-gray-300 flex flex-shrink rounded-full px-0.5 text-gray-800 text-sm font-normal pr-8 shadow-md items-center">
//               <img
//                 src={'/img/cardano-logo-black.webp'}
//                 alt="Event Hero"
//                 className="object-cover w-5 h-5 rounded-full flex-shrink m-2"
//               ></img>
//               Asset
//             </div>

//             <Link href={`${cardanoExplorerURL()}/token/${policy}`}>
//               <a
//                 className="flex flex-shrink text-gray-800 dark:text-white items-center ml-2 gap-2 pr-2 w-40 hover:text-black font-semibold text-sm"
//                 target={'_blank'}
//               >
//                 <MiddleEllipsis>
//                   <span
//                     className={cn('font-mono duration-200 transition', {
//                       'opacity-0': isHidden,
//                     })}
//                   >
//                     {policy ?? '* No Token *'}
//                   </span>
//                 </MiddleEllipsis>
//               </a>
//             </Link>
//           </div>
//         </div>
//       </>
//     );
//   }
//   return (
//     <>
//       <Tooltip
//         content={renderCreatorView()}
//         placement={'bottom'}
//         color={darkSide == true ? 'invert' : 'default'}
//       >
//         <div className="flex flex-row flex-wrap gap-4">
//           <div className="h-10 bg-white dark:bg-gray-900/80 text-white w-[16rem] border dark:border-gray-600 rounded-full shadow-lg flex flex-row">
//             <div className="bg-gray-200 dark:bg-gray-800 dark:text-gray-300 flex flex-shrink rounded-full px-0.5 text-gray-800 text-sm font-normal pr-8 shadow-md items-center">
//               <img
//                 src={'/img/cardano-logo-black.webp'}
//                 alt="Event Hero"
//                 className="object-cover w-5 h-5 rounded-full flex-shrink m-2"
//               ></img>
//               Asset
//             </div>

//             <Link href={`${cardanoExplorerURL()}/token/${policy}`}>
//               <a
//                 className="flex flex-shrink text-gray-800 dark:text-white items-center ml-2 gap-2 pr-2 w-40 hover:text-black font-semibold text-sm"
//                 target={'_blank'}
//               >
//                 <MiddleEllipsis>
//                   <span
//                     className={cn('font-mono duration-200 transition', {
//                       'opacity-0': isHidden,
//                     })}
//                   >
//                     {policy ?? '* No Asset *'}
//                   </span>
//                 </MiddleEllipsis>
//               </a>
//             </Link>
//           </div>
//         </div>
//       </Tooltip>
//     </>
//   );
// };
