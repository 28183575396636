import { useWalletPositionSummary } from '#lib/hooks/useCachedWallet';
import React, { PureComponent, useState } from 'react';
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from 'recharts';

const COLORS = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#D844FF',
  '#44FFAF',
  '#E9FF44',
  '#4468FF',
  '#FF4472',
];

type WalletPositionSummaryProps = {
  stakeAddress: string;
};

export const WalletNFTHoldPieChart = ({
  stakeAddress,
}: WalletPositionSummaryProps) => {
  const { walletPositionSummary } = useWalletPositionSummary(stakeAddress);

  const data2 = walletPositionSummary?.positionsNft?.map((p) => ({
    name: p.name,
    value: p.liquidValue,
  }));
  const [activeIndex, setActiveIndex] = useState<number | undefined>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onPieEnter = (_: any, index: any) => {
    setActiveIndex(index);
  };
  return (
    <div className="relative flex items-center justify-center pt-0 h-[20rem] mb-12 -mt-4">
      <div className="absolute w-full h-full flex flex-col items-center justify-center top-0 left-0">
        {!!activeIndex && walletPositionSummary?.positionsNft ? (
          <>
            {' '}
            <span className="pt-1 font-bold text-2xl">
              {' ₳ '}
              {walletPositionSummary?.positionsNft[activeIndex]?.liquidValue}
            </span>
          </>
        ) : (
          <div className="pt-1 font-bold text-2xl">
            ₳ {walletPositionSummary?.liquidValue?.toFixed(0) || 0}
          </div>
        )}

        <div className="text-xs font-light">liquid value</div>
      </div>
      {data2 && (
        <PieChart width={240} height={240}>
          <Pie
            data={data2}
            innerRadius={80}
            isAnimationActive={false}
            cx="50%"
            cy="50%"
            outerRadius={110}
            paddingAngle={5}
            dataKey="value"
            onMouseEnter={onPieEnter}
            onMouseLeave={() => setActiveIndex(undefined)}
          >
            {data2.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
                className="border-none rounded-full hover:brightness-150 duration-200 transition-colors cursor-pointer"
              />
            ))}
          </Pie>
        </PieChart>
      )}

      {walletPositionSummary?.positionsNft && (
        <div className="absolute -bottom-2 w-full flex justify-center mt-0 flex-col items-center">
          <span className="font-medium text-xl">
            {' '}
            {!!activeIndex ? (
              <> {walletPositionSummary?.positionsNft[activeIndex].name}</>
            ) : (
              <>Portfolio</>
            )}
          </span>
        </div>
      )}
    </div>
  );
};
