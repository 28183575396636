import {
  FirestoreQuery,
  FirestoreQueryItem,
  getHolderDistribution,
  getHolderWithKey,
  getHolders,
  getHoldersForNextBatch,
  getPolicyActivityListener,
  getPolicyStatsListener,
  getTTHolderDistribution,
  getTTPolicyStatsListener,
  getTTTraitFloors,
  getTraitFloors,
} from '#lib/firestore';
import 'firebase/functions';

import firebase from 'firebase';
import {
  Holder,
  HolderDistribution,
  NativePolicyStats,
  NativePolicyTransactions,
  PolicyActivity,
  TTHolderDistribution,
  TTPolicyStats,
} from '#types/index';
import { useEffect, useState } from 'react';
import { trpc } from '../../pages/_app';
import React from 'react';

type PolicyStats = {
  policy: string;
  ssrStats?: NativePolicyStats;
};

type TTPolicyStatsProps = {
  policy?: string;
  ssrTTPolicyStats?: TTPolicyStats;
};

export const usePolicyStats = ({ policy }: PolicyStats) => {
  console.debug('usePolicyStats', 'In Hook', policy);

  const { data, isLoading } = trpc.useQuery(['policy-stats', { policy }]);

  console.debug('usePolicyStats', 'In Hook', data);
  return { stats: data, loading: isLoading };
};

export const usePolicyStatsListenerListener = ({
  policy,
  ssrStats,
}: PolicyStats) => {
  const [policyStatistics, setPolicyStatistics] = useState<NativePolicyStats>();
  const [policyStatisticsLoading, setPolicyStatisticsLoading] = useState(true);
  useEffect(() => {
    if (!policy) {
      setPolicyStatisticsLoading(false);
      return;
    }
    if (!!ssrStats) setPolicyStatistics(ssrStats);

    setPolicyStatisticsLoading(true);
    return getPolicyStatsListener(policy, (data) => {
      setPolicyStatistics(data);
      setPolicyStatisticsLoading(false);
    });
  }, [policy, ssrStats]);
  return {
    policyStatistics,
    policyStatisticsLoading,
  };
};

export const useTTPolicyStatsListenerListener = ({
  policy,
  ssrTTPolicyStats,
}: TTPolicyStatsProps) => {
  const [policyStatistics, setPolicyStatistics] = useState<TTPolicyStats>();
  const [policyStatisticsLoading, setPolicyStatisticsLoading] = useState(true);
  useEffect(() => {
    if (!policy) {
      setPolicyStatisticsLoading(false);
      return;
    }
    if (!!ssrTTPolicyStats) setPolicyStatistics(ssrTTPolicyStats);

    setPolicyStatisticsLoading(true);
    return getTTPolicyStatsListener(policy, (data) => {
      if (data) {
        // call pub sub.
        const attr = JSON.stringify({ policy });
        firebase.functions().httpsCallable('callPubSubTopic')({
          topic: 'tap-tools-refresh',
          attributes: attr,
        });
      }
      setPolicyStatistics(data);
      setPolicyStatisticsLoading(false);
    });
  }, [policy, ssrTTPolicyStats]);
  return {
    policyStatistics,
    policyStatisticsLoading,
  };
};

export const usePolicyActivityListener = ({ policy }: PolicyStats) => {
  const [policyActivity, setPolicyActivity] =
    useState<NativePolicyTransactions[]>();
  const [policyActivityLoading, setPolicyActivityLoading] = useState(true);
  useEffect(() => {
    if (!policy) {
      setPolicyActivityLoading(false);
      return;
    }
    setPolicyActivityLoading(true);
    return getPolicyActivityListener(policy, 50, (data) => {
      setPolicyActivity(data);
      setPolicyActivityLoading(false);
    });
  }, [policy]);
  return {
    policyActivity,
    policyActivityLoading,
  };
};

export const useProjectHolderDistribution = (
  projectId: string,
  ssrHolderDisro?: TTHolderDistribution
) => {
  const [holderDistribution, setHolderDistribution] = useState<
    TTHolderDistribution | undefined
  >(ssrHolderDisro);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getTTHolderDistribution(projectId).then((data) => {
      if (data) {
        setHolderDistribution(data);
      }
      setLoading(false);
    });
  }, [projectId]);
  return {
    holderDistribution,
    loading,
  };
};

export const usePolicyHolders = (projectId: string) => {
  const [holders, setHolders] = useState<Holder[] | undefined>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getHolders(projectId).then((data) => {
      if (data) {
        setHolders(data);
      }
      setLoading(false);
    });
  }, [projectId]);
  return {
    holders,
    loading,
  };
};

type UsePolicyHoldersPagedProps = {
  policy: string;
  text?: string;
  ssrAssetItems?: HolderItem[];
};

const DEFAULT_QUERY_X = {
  desc: true,
  orderBy: 'totalAmount',
};

export type HolderItem = Holder & {
  groupKey?: string;
  key: string;
};

const LIMIT = 24;

export const usePolicyHolderPaged = ({
  policy,
  ssrAssetItems,
}: UsePolicyHoldersPagedProps) => {
  const [holders, setHolders] = React.useState<HolderItem[]>(
    ssrAssetItems || []
  );
  const [loading, setLoading] = useState(true);
  const [hasMoreData, setHasMoreData] = React.useState(true);

  const lastKey = React.useRef<string>();

  useEffect(() => {
    setHasMoreData(true);
    lastKey.current = undefined;
  }, []);

  const loadBatch = React.useCallback(
    async (key?: string | number) => {
      console.log('ILT: In loadBatch', key, lastKey.current);
      setLoading(true);
      if (!!lastKey.current && lastKey.current === key) {
        console.log('ILT: In loadBatch - Debounce');
        setLoading(false);
        return Promise.resolve();
      }

      if (!key && lastKey.current) {
        console.log('ILT: In loadBatch - Already Got Data');
        setLoading(false);
        return Promise.resolve();
      }

      lastKey.current = key as string;

      if (!key) {
        try {
          try {
            const data = await getHolderWithKey(policy, LIMIT);
            const x: HolderItem[] = data.holders.map((holder) => {
              const h = holder as HolderItem;
              h.key = holder.holderStake;
              h.groupKey = data.lastKey;
              return h;
            });
            if (x.length < LIMIT) {
              setHasMoreData(false);
            }
            setHolders(x);
          } catch (message_1) {
            setLoading(false);
            return console.error(message_1);
          }
        } finally {
          return setLoading(false);
        }
      }

      try {
        try {
          const data_1 = await getHoldersForNextBatch(
            policy,
            key as string,
            LIMIT
          );
          const x_1: HolderItem[] = data_1.holders.map((holder) => {
            const h_1 = holder as HolderItem;
            h_1.key = holder.holderStake;
            h_1.groupKey = data_1.lastKey;
            setLoading(false);
            return h_1;
          });
          if (x_1.length < LIMIT) {
            setHasMoreData(false);
          }
          setHolders((i) => [...i, ...x_1]);

          // console.log('ITEMS:', JSON.stringify(x_1));
        } catch (message_1) {
          setLoading(false);
          return console.error(message_1);
        }
      } finally {
        return setLoading(false);
      }
    },
    [policy]
  );

  React.useEffect(() => {
    loadBatch();
  }, [loadBatch]);

  return { loading, holders, loadBatch, hasMoreData };
};

export const useTraitFloors = (projectId: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [traitFloors, setTraitFloors] = useState<any | undefined>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getTraitFloors(projectId).then((data) => {
      if (data) {
        setTraitFloors(data);
      }
      setLoading(false);
    });
  }, [projectId]);
  return {
    traitFloors,
    loading,
  };
};

export const useTTTraitFloors = (projectId: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [traitFloors, setTraitFloors] = useState<any | undefined>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getTTTraitFloors(projectId).then((data) => {
      if (data) {
        setTraitFloors(data);
      }
      setLoading(false);
    });
  }, [projectId]);
  return {
    traitFloors,
    loading,
  };
};
