import { CoinGeckoPrices, useCoinGecko } from '#lib/hooks/useCoinGecko';
import { useState } from 'react';
import { TextInput } from '#components/Inputs/TextInput/TextInput';
import getSymbolFromCurrency from 'currency-symbol-map';
import { SaleEstimate } from '../../SaleEstimate/SaleEstimate';
import { BellAlertIcon } from '@heroicons/react/24/solid';
import { PolicyTooltip } from '#components/Tooltip/PolicyPopover';
import { NextImage } from '../../NextImage';
import {
  useMarketAsset,
  useMarketCloseActionModal,
  useMarketCurrentListing,
  useMarketDelistToDs,
  useMarketDoListing,
  useMarketProjectRoyalties,
} from './MarketStore';
import DaisyButton from '#components/Daisy/DaisyButton';
import { useSuperCube } from '../../../../lib/plutus/SuperCube';
import { DaisyTextInput } from '#components/Daisy/DaisyTextInput';
import { useCardanoWallet, useWalletStore } from '#lib/wallet/WalletContext';

export const CreateOfferV2 = () => {
  const { wallet } = useCardanoWallet();
  const {stakeAddress } = useWalletStore();
  const asset = useMarketAsset();
  const currentListing = useMarketCurrentListing();
  const royalties = useMarketProjectRoyalties();
  const doListing = useMarketDoListing();
  const doDelistToDs = useMarketDelistToDs();
  const doClose = useMarketCloseActionModal();

  const { data: superCube, isLoading: isSuperCubeLoading } = useSuperCube(
    asset.policy || ''
  );

  const { coinGeckoPrice } = useCoinGecko();

  const [price, setPrice] = useState<number | undefined>(() => {
    if (currentListing?.type === 'list') return undefined;
    if (currentListing?.utxo.type !== 'TXN' && currentListing?.utxo.type !== 'JPG_TXN')
      throw new Error('Invalid listing type');

    const p = Number.parseInt(currentListing?.utxo.price) ?? 0;
    console.log('Current Price', currentListing?.utxo.price, p / 1_000_000);

    return Number.isNaN(p) ? undefined : p / 1000000;
  });

  const [working, setWorking] = useState(false);

  if (!currentListing || isSuperCubeLoading) return null; //Shit got weird!

  const dsCommission = superCube?.cubeLevel ? 0 : 0.02; // DS Default Commission is 2%?

  return (
    <div>
      <div className="flex flex-row gap-4 items-center">
        <div className="flex-initial w-24 mt-2">
          <div className="relative w-24 h-24 object-cover rounded-lg overflow-hidden">
            {!!asset.assetUrl && (
              // <ImageSwap src={assetURL} alt={assetName} thumb={thumbB64} />
              <NextImage
                src={asset.assetUrl}
                width={100}
                height={100}
                quality={100}
                b64={asset.thumbnailB64}
                priority={true}
                className={'object-cover object-top shadow-2xl rounded-lg'}
                sizes={
                  '(max-width: 768px) 20vw, (max-width: 1200px) 20vw, 20vw'
                }
              />
            )}
          </div>
        </div>

        <div className="flex-1 my-6 gap-3 flex flex-col">
          <p className="font-bold text-lg text-gray-900 dark:text-white">
            {asset.title}
          </p>
          <div className="flex flex-row gap-1 items-center">
            {/* <PolicyTooltip policy={policy} hideTooltip={true} /> */}
            <PolicyTooltip
              policy={asset.id}
              hideTooltip={true}
              label={'asset'}
            />
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col gap-3 justify-center mt-4">
        <div className="flex-1">
          <DaisyTextInput
            id="amount"
            label="Sale Price (ADA)"
            placeholder=""
            size="input-lg"
            labelPosition="inside"
            value={price}
            type="number"
            onChange={(amt) => {
              try {
                setPrice(Number.parseFloat(amt));
              } catch (_) {}
            }}
          />
          <div className="h-8 mt-0">
            {coinGeckoPrice && !!price && (
              <div className="mb-1 gap-2 flex flex-row justify-center">
                {Object.keys(coinGeckoPrice).map((currency) => {
                  if (!price) return null;

                  const rate =
                    coinGeckoPrice[currency as keyof CoinGeckoPrices];
                  if (!rate) return null;
                  const amt = Math.round(
                    (rate * (price * 1_000_000)) / 1_000_000
                  );

                  if (isNaN(amt)) return null;

                  return (
                    <span
                      key={currency}
                      className="text-xs text-justify font-light text-gray-600 dark:text-gray-400"
                    >
                      {currency.toUpperCase()} {getSymbolFromCurrency(currency)}
                      {amt}
                    </span>
                  );
                })}
              </div>
            )}
          </div>
          <SaleEstimate
            price={price || 0}
            commissionPercent={dsCommission}
            royalties={royalties}
          />
          <div className=" border dark:border-gray-700 p-3 rounded-xl my-4">
            <div className="flex flex-row gap-2">
              <BellAlertIcon className="w-5 h-5 text-yellow-600" />
              <span className="text-yellow-600 font-bold text-sm">Note:</span>
            </div>
            <p className="text-yellow-800 font-light text-sm mt-1">
              An additional 1 ADA is required to list an asset. This is
              reimbursed to you when the asset is sold or the listing is
              cancelled.
            </p>
          </div>
        </div>
        <div className="flex-1 justify-center w-full flex flex-col gap-3">
          <DaisyButton
            isDisabled={!price || price < 10}
            label={
              (() => {
                switch (currentListing?.type) {
                  case 'relist':
                    return 'Update listing';
                  case 'delistToDs':
                    return 'Relist to Dropspot';
                  default:
                    return 'Create Listing';
                }
              })()
            }
            
            working={working}
            workingLabel={'Listing...'}
            colorName="btn-secondary"
            onClick={() => {
              if (!price) return;
              setWorking(true);
              if (currentListing?.type === 'delistToDs' && wallet) {
                doDelistToDs(currentListing.utxo, price * 1_000_000, wallet, stakeAddress).then(console.log);
              } else
              doListing(currentListing, price * 1_000_000).then(console.log);
            }}
          ></DaisyButton>
          <DaisyButton
            label="Cancel"
            colorName="btn-ghost"
            size="btn-sm"
            onClick={() => doClose()}
          />
        </div>
      </div>
    </div>
  );
};
