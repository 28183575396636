import { CoinGeckoPrices } from '../../../../lib/hooks/useCoinGecko';
import {
  DropspotMarketError,
  InternalErrorCode,
} from '../../../../lib/plutus/DropspotMarketError';
import { CardanoWalletExtended } from '../../../../lib/wallet/WalletContext';

export async function walletHasCollateral(wallet: CardanoWalletExtended) {
  const getCollateral = wallet.getCollateral
    ? wallet.getCollateral
    : wallet.experimental?.getCollateral;

  if (!getCollateral) {
    throw new DropspotMarketError({
      type: 'INTERNAL',
      code: InternalErrorCode.NO_COLLATERAL,
      info: 'Wallet is not CIP30 compliant',
    });
  }

  const c = await getCollateral();

  if (!c) {
    throw new DropspotMarketError({
      type: 'INTERNAL',
      code: InternalErrorCode.NO_COLLATERAL,
      info: 'Wallet has no Collateral',
    });
  }
}

export function countryToCurrency(country: string) {
  switch (country) {
    case 'AU':
      return 'aud';
    case 'US':
      return 'usd';
    case 'GB':
      return 'gbp';
    default:
      return 'usd';
  }
}
export const getExchangeRate = (
  country: string,
  simplePrice: CoinGeckoPrices
) => {
  const currency = countryToCurrency(country);

  if (currency in simplePrice) {
    return simplePrice[currency] as number;
  }
};
export function lovelaceToAda(ll: number) {
  return ll / 1_000_000;
}

export function renderCurrency(currency: string, number: number) {
  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency,
  }).format(number);
}

export function errorIsAPIError(error: unknown): error is DropspotMarketError {
  if (error instanceof DropspotMarketError) {
    return true;
  }
  return false;
}
