/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface CustomClearProps {
  items: any;
  refine: any;
  children: React.ReactNode;
}
export const ClearRefinementsHeadless = ({
  items,
  refine,
  children,
}: CustomClearProps) => <span onClick={() => refine(items)}>{children}</span>;
