import DaisyButton from '#components/Daisy/DaisyButton';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { Asset } from '#types/index';
import { forwardRef, useImperativeHandle } from 'react';
import React from 'react';
import { Tooltip } from '@nextui-org/react';
import cn from 'classnames';
import { toAdditionalProperties } from '#components/Assets/Onboard/AssetAttributesTab';
type AssetAttributesProps = { asset: Asset; mini?: boolean };

export const AssetAttributesRegion = ({
  asset,
  mini,
}: AssetAttributesProps) => {
  return (
    <>
      <div className="flex flex-col w-full lg:flex-col gap-8">
        <div className="flex-1 mt-0">
          {(!asset.additionalProperties && !asset.additionalPropertiesCalc) ||
            (!!asset.additionalPropertiesCalc &&
              asset.additionalPropertiesCalc.length == 0 && (
                <p
                  className={cn(
                    'dark:text-gray-400 text-gray-500 text-left py-2',
                    { 'text-center': mini }
                  )}
                >
                  No attributes or traits minted for this asset.
                </p>
              ))}

          {asset.additionalPropertiesCalc && (
            <>
              <div className="flex flex-wrap w-full gap-2 overflow-hidden ">
                {asset.additionalPropertiesCalc &&
                  asset.additionalPropertiesCalc.map((prop, idx) => (
                    <React.Fragment key={`${prop.key}${idx}`}>
                      <div
                        className={cn(
                          'flex flex-row items-center justify-between  bg-gray-50 dark:bg-gray-700/40 px-3 py-2 shadow-sm border border-gray-200 dark:border-gray-700/50  hover:bg-white duration-150 overflow-hidden overflow-ellipsis rounded-xl ',
                          { 'py-0.5 px-0.5': mini }
                        )}
                      >
                        <div className="flex flex-col">
                          <span className="flex flex-row justify-between">
                            <p className="text-xs uppercase font-light text-gray-600 dark:text-gray-400">
                              {typeof prop.key === 'string' && prop.key}
                            </p>
                            <div className="ml-4">
                              <Tooltip
                                content={`${prop.instances} others have this attribute`}
                              >
                                <p className="text-red-600 text-xs dark:text-secondary">
                                  {(prop.rarity * 100).toFixed(1)}%
                                </p>
                              </Tooltip>
                            </div>
                          </span>

                          <div className="flex justify-between text-base font-medium text-gray-800">
                            <p
                              className={cn(
                                'text-base font-bold dark:text-gray-100',
                                { 'text-xs': mini }
                              )}
                            >
                              {typeof prop.value === 'string' && prop.value}
                            </p>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
              </div>
            </>
          )}
          <div
            className={cn(
              'grid grid-cols-2 xl:grid-cols-3 w-full gap-2 overflow-hidden rounded-lg  ',
              { 'grid-cols-1': mini }
            )}
          >
            {asset.additionalProperties &&
              !asset.additionalPropertiesCalc &&
              toAdditionalProperties(asset.additionalProperties).map(
                ({ name, value }, idx) => {
                  return (
                    <React.Fragment key={`${name}${value}${idx}`}>
                      <div className="flex flex-row justify-between  rounded-lg  bg-gray-50 dark:bg-base-300 px-4 py-3 shadow-sm border border-gray-200 dark:border-gray-700  hover:bg-white duration-150 overflow- overflow-ellipsis  ">
                        <div className=" flex flex-col">
                          <p className="text-xs font-light text-gray-600 dark:text-gray-500">
                            {name}
                          </p>
                          <div className="flex justify-between text-sm font-medium text-gray-800">
                            <p className="font-semibold dark:text-gray-100">
                              {typeof value === 'string'
                                ? value
                                : JSON.stringify(value)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                }
              )}
          </div>
        </div>
      </div>
    </>
  );
};
