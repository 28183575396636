import { CardanoWalletExtended } from '#lib/wallet/WalletContext';
import { Asset, OfferRecord, Project } from '#types/index';
import {
  AssetProp,
  acceptOffer,
  cancelOffer,
  contracts,
  createOffer,
} from '@dropspot-io/contract-api';
import { create } from 'zustand';
import firebase from '#lib/firebase';
import {
  Address,
  Assets,
  MintingPolicyHash,
  Program,
  TxId,
  TxOutput,
  UTxO,
  Value,
  bytesToHex,
  hexToBytes,
  config,
  Tx,
  StakeAddress,
} from '@hyperionbt/helios';
import constants from '#lib/constants';
import {
  BUILD_ACTION,
  getAddress,
  getNetworkParams,
  signTransaction,
  submitTx,
} from '#lib/plutus/DropspotMarketContract';
import getAbsoluteURL from '#lib/getAbsoluteUrl';
import { devtools } from 'zustand/middleware';
import { OfferDetails } from '#server/routes/asset/offers';
import { getAssetOffersForBalance, getCollectionOffersForBalance, getMaestroTransaction, getMyOffers } from '#lib/firestore';
import {
  AcceptOfferTxHandlerSchemaType,
  CancelOfferTxHandlerSchemaType,
} from '../../../../pages/api/offers/offerTxHandler';
import { trpcClient } from '#lib/trpc';

type MyOffers = OfferRecord[];

// __offerType is used to know which type of offer it is.
type AcceptableOffers = OfferRecord;

config.IS_TESTNET = constants.TESTNET;

export interface OfferUIStore {
  setCollectionOffer: (collection: Project, price: number) => void;
  collectionOffers: Map<Project, number>;
  currentUser?: firebase.User;
  setCurrentUser: (currentUser: firebase.User) => void;
  setAssetOffer: (asset: Asset, price: number) => void;
  deleteAssetOffer: (asset: Asset) => void;
  assetOffers: Map<Asset, number>;
  createOffer: (
    wallet: CardanoWalletExtended,
    offerExpiry: number
  ) => Promise<string | undefined>;
  clearAll: () => void;
  txState?: BUILD_ACTION;
  //
  wallet?: CardanoWalletExtended;
  setWallet: (wallet: CardanoWalletExtended) => void;
  reloadMyOffers: (shouldSkipLoading?: boolean) => void;

  myOfferListener?: () => void;
  acceptableOffersListeners?: (() => void)[];
  reloadWalletBalance: () => Promise<void>;
  txInProgress?: boolean;
  myOffers?: MyOffers;
  acceptableOffers: Map<string, AcceptableOffers>;
  loadingMyOffers: boolean;
  walletAssets?: Assets;
  waitForTransaction: (txHash: string) => Promise<void>;
  cancelOffer: (offer: MyOffers[number]) => Promise<string>;
  getOfferableAssets: (offer: NonNullable<AcceptableOffers>) => Array<{
    policy: string;
    tokenName: string;
    asset?: Asset;
    quantity: bigint;
    offer: number;
  }>;
  acceptOffer: (
    offer: NonNullable<AcceptableOffers>,
    token: { policy: string; tokenName: string; quantity: number },
    acceptedLovelace: number
  ) => Promise<string>;
}

export const useOfferUIStore = create<OfferUIStore>()(
  devtools<OfferUIStore>(
    (set, get) => ({
      acceptableOffers: new Map(),
      loadingMyOffers: false,
      setWallet: (wallet) => {
        set({ wallet });

        get().reloadMyOffers();
        get().reloadWalletBalance();
      },
      cancelOffer: async (offer) => {
        const wallet = get().wallet;

        if (!wallet) throw new Error('No wallet');

        const utxo = await trpcClient.query('maestro-utxo', {
          txHash: offer.tx_hash,
          index: offer.index.toString(),
        });

        const offerUtxo = new UTxO(
          TxId.fromHex(utxo.tx_hash),
          BigInt(utxo.index),
          TxOutput.fromCbor(hexToBytes(utxo.txout_cbor))
        );
        console.log('OFFER UTXTO:', JSON.stringify(offerUtxo));

        if (
          offerUtxo.origOutput.address.validatorHash.hex ==
          constants.OFFER_VALIDATOR_HASH[0]
        ) {
          const currentUser = firebase.auth().currentUser;
          if (!currentUser) return '';

          const address = await getAddress(wallet);

          const utxos = await wallet.getUtxos();
          if (!utxos) throw new Error('Wallet has no UTxOs available');
          const data: CancelOfferTxHandlerSchemaType = {
            walletAddress: address,
            offerUTxO: offerUtxo.toCborHex(),
            action: 'cancel',
            network: constants.TESTNET ? 'preprod' : 'mainnet',
            walletUTxOs: utxos,
          };

          const url = getAbsoluteURL('/api/offers/offerTxHandler');

          const r = await fetch(url, {
            method: 'POST',
            headers: {
              'content-type': 'application/json',
              authorization: `bearer ${await currentUser.getIdToken()}`,
            },
            body: JSON.stringify(data),
          });

          if (!r.ok) {
            throw new Error('Tx Build Error');
          }

          const txCbor = await r.text();

          const tx = Tx.fromCbor(hexToBytes(txCbor));

          const signatures = await signTransaction(tx, wallet);
          tx.addSignatures(signatures);
          return submitTx(bytesToHex(tx.toCbor()), []);
        }

        const compiled = getCompiledProject();

        const tx = await cancelOffer({
          wallet,
          scriptCbor: bytesToHex(compiled.toCbor()),
          offerUtxo,
          networkParams: await getNetworkParams(),
          submitTx: (tx) => submitTx(tx, []),
          listener: (action) => {
            console.log('>>>>Action: ', action);
            set({ txState: action });
          },
        });

        const endpoint = getAbsoluteURL(`/api/offers/cancelOfferTracking`);

        //create the  offfers object

        const userToken = await get().currentUser?.getIdToken(true);
        console.log('ABOUT TO CALL POST OFFER API');
        const response = await fetch(endpoint, {
          method: 'POST',
          body: JSON.stringify({ txHash: tx, offer: offer }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
        });

        if (response.status != 200) {
          console.log('Error in the api POST OFFER API', await response.text());
        }

        return tx;
      },
      waitForTransaction: async (txHash) => {
        set({ txInProgress: true });

        getMaestroTransaction(txHash, (tx) => {
          console.log('Tx State from Maestro', txHash);
          if (!tx) return;
          if (tx.state === 'Onchain') {
            get().reloadWalletBalance();
            set({ txInProgress: false });
          }
        });
      },
      getOfferableAssets: (offer) => {
        const { walletAssets } = get();

        if (!walletAssets) return [];

        console.log('getOfferableAssets', walletAssets.toCborHex());

        // Get Collection
        if (!offer || !offer.details) return [];
        const result = offer.details
          .map((detail) => {
            switch (detail.type) {
              case 'Asset': {
                console.log('getOfferableAssets', detail.type, detail.policy, detail.tokenName);
                if (
                  walletAssets.has(
                    MintingPolicyHash.fromHex(detail.policy),
                    Array.from(Buffer.from(detail.tokenName))
                  )
                ) {
                  return {
                    policy: detail.policy,
                    tokenName: detail.tokenName,
                    asset: detail.asset,
                    quantity: walletAssets.get(
                      MintingPolicyHash.fromHex(detail.policy),
                      Array.from(Buffer.from(detail.tokenName))
                    ),
                    offer: detail.offer,
                  };
                }
                return;
              }
              case 'Collection': {

                console.log('getOfferableAssets', detail.type, detail.policy);
                const mph = MintingPolicyHash.fromHex(detail.policy);
                const x = walletAssets.mintingPolicies.find(
                  (m) => mph.hex === m.hex
                );

                if (x) {
                  // Get the Tokens
                  const d = walletAssets
                    .getTokenNames(mph)
                    .map(
                      (tn) =>
                        [
                          Buffer.from(bytesToHex(tn.bytes), 'hex').toString(
                            'utf8'
                          ),
                          tn,
                        ] as const
                    )
                    .map(([tokenName, tn]) => ({
                      policy: mph.hex,
                      tokenName,
                      quantity: walletAssets.get(mph, tn),
                      offer: detail.offer,
                    }));

                  return d;
                }
              }
            }
          })
          .filter((a): a is NonNullable<typeof a> => Boolean(a))
          .flatMap((a) => a);

        return result;

        // return offer.details.filter((detail) => {
        //   switch (detail.type) {
        //     case 'Asset': {
        //       return walletAssets.has(
        //         MintingPolicyHash.fromHex(detail.policy),
        //         Array.from(Buffer.from(detail.tokenName))
        //       );
        //     }
        //     case 'Collection': {
        //       const mph = MintingPolicyHash.fromHex(detail.policy);
        //       return walletAssets.mintingPolicies.find(
        //         (m) => mph.hex === m.hex
        //       );
        //     }
        //   }
        // });
      },
      acceptOffer: async (offer, token, acceptedLovelace) => {
        const wallet = get().wallet;
        if (!wallet) throw new Error('No wallet');

        const compiled = getCompiledProject();

        return processOfferAccept(
          wallet,
          offer,
          token,
          acceptedLovelace,
          bytesToHex(compiled.toCbor()),
          (action) => {
            set({ txState: action });
          }
        );
      },
      reloadMyOffers: (shouldSkipLoading = false) => {
        console.log('MyOffers', 'In reloadMyOffers');
        const wallet = get().wallet;
        if (!shouldSkipLoading) set({ loadingMyOffers: true });

        if (!wallet) return;
        console.log('MyOffers', 'In reloadMyOffers', 'Get Reward Address');
        wallet.getRewardAddresses().then((stakeAddresses) => {
          console.log('MyOffers', 'In reloadMyOffers', stakeAddresses);
          const stakeAddress = StakeAddress.fromHex(stakeAddresses[0]);

          get().myOfferListener?.();

          const listener = getMyOffers(stakeAddress.toBech32(), (offers) => {
            set({ myOffers: offers, loadingMyOffers: false })
          });

          set({ myOfferListener: listener });

        });
      },
      reloadWalletBalance: async () => {
        const wallet = get().wallet;
        if (!wallet) return;



        const b = await wallet.getBalance();

        const value = Value.fromCbor(hexToBytes(b));

        // Offers I can Accept
        const qry: {
          collection: string[];
          asset: string[];
        } = {
          collection: [],
          asset: [],
        }

        value.assets.mintingPolicies.forEach((mph) => {

          qry.collection.push(mph.hex);

          value.assets.getTokenNames(mph).forEach((tn) => {
            const tokenName = bytesToHex(tn.bytes);
            qry.asset.push(
              `${mph.hex}.${tokenName}`
            );
          });
        });

        get().acceptableOffersListeners?.forEach(l => l());

        console.log('Qry', qry);

        const collectionOffersListener = getCollectionOffersForBalance(qry.collection, collectionOffers => {
          const acceptableOffers = get().acceptableOffers;

          console.log('>>>Collection Offers', collectionOffers.length);

          collectionOffers.forEach((offer) => {
            acceptableOffers.set(`${offer.tx_hash}.${offer.index}`, offer);
          });
          set({ acceptableOffers: new Map(acceptableOffers) });
        });

        const assetOffersListener = getAssetOffersForBalance(qry.asset, assetOffers => {
          const acceptableOffers = get().acceptableOffers;

          console.log('>>>Asset Offers', assetOffers.length);
          assetOffers.forEach((offer) => {
            acceptableOffers.set(`${offer.tx_hash}.${offer.index}`, offer);
          });
          set({ acceptableOffers: new Map(acceptableOffers) });
        });

        set({ walletAssets: value.assets, acceptableOffersListeners: [collectionOffersListener, assetOffersListener] });
        return;
      },
      collectionOffers: new Map(),
      setCollectionOffer: (collection, price) => {
        console.log(
          'Offer UIStore -> Setting collection offer:',
          collection.name
        );
        const { collectionOffers } = get();
        const co = new Map(
          collectionOffers instanceof Map ? collectionOffers : undefined
        );
        console.log('Offer UIStore -> Setting collection offer', co);
        co.set(collection, price);
        set({ collectionOffers: co });
      },
      setCurrentUser: (user: firebase.User) => {
        set({
          currentUser: user,
        });
      },
      assetOffers: new Map(),
      setAssetOffer: (asset, price) => {
        const { assetOffers } = get();
        const ao = new Map(
          assetOffers instanceof Map ? assetOffers : undefined
        );
        ao.set(asset, price);
        set({ assetOffers: ao });
      },
      deleteAssetOffer: (asset) => {
        // const assetOffers = Array.from(get().assetOffers);
        const ao = get().assetOffers;
        ao.delete(asset);
        set({ assetOffers: new Map(ao) });
      },
      clearAll: () => {
        set({ assetOffers: new Map() });
        set({ collectionOffers: new Map() });
      },
      createOffer: async (wallet, offerExpiry) => {
        const { collectionOffers, assetOffers } = get();
        set({ txInProgress: true });
        const offerProp: AssetProp = new Map();
        let txHash = '';
        console.log(
          'Creating offer for collection count: ',
          collectionOffers.size
        );
        console.log('Creating offer for asset count: ', assetOffers.size);
        collectionOffers.forEach((price, collection) => {
          if (!collection.policy) return; // Collection is not minted?

          offerProp.set(
            {
              type: 'collection',
              policy: collection.policy,
              quantity: 1,
            },
            price
          );
        });

        assetOffers.forEach((price, asset) => {
          if (!asset.policy || !asset.tokenName) return;

          offerProp.set(
            {
              type: 'asset',
              policy: asset.policy,
              tokenName: asset.tokenName,
              quantity: 1,
            },
            price
          );
        });

        offerProp.forEach((amount, offer) => {
          console.log('>>>>>>>Offer', JSON.stringify(offer), amount);
        });

        if (offerProp.size === 0) {
          console.log('WE HAVE NO OFFERRS TO PROCESS:');
          return; // No Offers?!?
        }

        const compiled = getCompiledProject();

        console.log('Offers Contract', compiled.validatorHash.hex);

        txHash = await createOffer({
          scriptCbor: bytesToHex(compiled.toCbor()),
          wallet,
          asset: offerProp,
          expiry: offerExpiry,
          submitTx: (tx) => submitTx(tx, []),
          listener: (action) => {
            set({ txState: action });
          },
          networkParams: await getNetworkParams(),
        }).finally(() => {
          set({ txState: undefined });
          set({ txInProgress: false });
        });

        //create a tracked transaction here.
        const endpoint = getAbsoluteURL(`/api/offers/createOfferTracking`);

        const offerDetails: OfferDetails[] = [];

        collectionOffers.forEach((price, collection) => {
          if (!collection.policy) return; // Collection is not minted?
          const newOfferDetail = {
            project: collection,
            offer: price,
            quantity: 1,
            type: 'Collection',
            policy: collection.policy,
          } as OfferDetails;

          offerDetails.push(newOfferDetail);
        });
        assetOffers.forEach((price, assetOfferItem) => {
          console.log('Asset assetOfferItem: >>>>', assetOfferItem);
          if (!assetOfferItem.policy) return; // Collection is not minted?
          const newOfferDetail = {
            asset: {
              assetUrl: assetOfferItem.assetUrl,
              id: assetOfferItem.id,
              title: assetOfferItem.title,
              tokenName: assetOfferItem.tokenName,
              policy: assetOfferItem.policy,
              algoliaProjectFacet: assetOfferItem.algoliaProjectFacet,
            } as Asset,
            offer: price,
            quantity: 1,
            type: 'Asset',
            policy: assetOfferItem.policy,
            tokenName: assetOfferItem.tokenName,
          } as OfferDetails;

          offerDetails.push(newOfferDetail);
        });

        // Serialize the object to JSON

        const userToken = await get().currentUser?.getIdToken(true);
        console.log('ABOUT TO CALL POST OFFER API');
        console.log('POSTOFFER->', offerDetails);

        const response = await fetch(endpoint, {
          method: 'POST',
          body: JSON.stringify({
            txHash: txHash,
            offer: offerDetails,
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
        });

        if (response.status != 200) {
          console.log('Error in the api POST OFFER API', await response.text());
        }

        //clear the cached offers:
        set({ assetOffers: new Map() });
        set({ collectionOffers: new Map() });
        return txHash;
        // alert(txHash);
      },
    }),
    {
      name: 'OfferUIStore',
    }
  )
);

function getCompiledProject() {
  const program = Program.new(contracts.offerContract, [
    contracts.superCubeModule,
  ]);
  program.parameters = {
    'super_cube::DS_ADDRESS': Address.fromBech32(
      constants.NEXT_PUBLIC_DROPSPOT_ADDRESS
    ).pubKeyHash,
    'super_cube::DS_REDUCTION_POLICY': MintingPolicyHash.fromHex(
      constants.SUPER_CUBE_POLICY
    ),
  };
  return program.compile(true);
}

async function processOfferAccept(
  wallet: CardanoWalletExtended,
  offer: OfferRecord,
  token: { policy: string; tokenName: string; quantity: number },
  acceptedLovelace: number,
  scriptCbor: string,
  listener: (action: BUILD_ACTION) => void
): Promise<string> {
  const utxos = await wallet.getUtxos();

  if (!utxos) throw new Error('UTxO in wallet');

  const totalValue = utxos.reduce(
    (acc: Value, utxo: string) =>
      acc.add(UTxO.fromCbor(hexToBytes(utxo)).value),
    new Value()
  );

  const availableQuantity = totalValue.assets.get(
    MintingPolicyHash.fromHex(token.policy),
    Array.from(Buffer.from(token.tokenName, 'utf-8'))
  );

  if (availableQuantity < BigInt(1)) {
    console.log('Not enough quantity');
    throw new Error('Not enough quantity');
  }

  // Make sure I am the correct user to be able to accept this offer
  // i.e., I have the asset in my wallet

  const utxo = await trpcClient.query('maestro-utxo', {
    txHash: offer.tx_hash,
    index: offer.index.toString(),
  });
  const offerUtxo = new UTxO(
    TxId.fromHex(utxo.tx_hash),
    BigInt(utxo.index),
    TxOutput.fromCbor(hexToBytes(utxo.txout_cbor))
  );

  const asset = new Assets();
  asset.addComponent(
    MintingPolicyHash.fromHex(token.policy),
    Array.from(Buffer.from(token.tokenName, 'utf-8')),
    BigInt(1)
  );

  if (
    offerUtxo.origOutput.address.validatorHash.hex ==
    constants.OFFER_VALIDATOR_HASH[0]
  ) {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) return '';

    const address = await getAddress(wallet);

    const utxos = await wallet.getUtxos();
    if (!utxos) throw new Error('Wallet has no UTxOs available');
    const data: AcceptOfferTxHandlerSchemaType = {
      walletAddress: address,
      offerUTxO: offerUtxo.toCborHex(),
      action: 'accept',
      network: constants.TESTNET ? 'preprod' : 'mainnet',
      walletUTxOs: utxos,
      acceptedLovelace,
      token: {
        policy: token.policy,
        name: token.tokenName,
        quantity: token.quantity,
      },
    };

    const url = getAbsoluteURL('/api/offers/offerTxHandler');

    const r = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `bearer ${await currentUser.getIdToken()}`,
      },
      body: JSON.stringify(data),
    });

    if (!r.ok) {
      throw new Error('Tx Build Error');
    }

    const txCbor = await r.text();

    const tx = Tx.fromCbor(hexToBytes(txCbor));

    const signatures = await signTransaction(tx, wallet);
    tx.addSignatures(signatures);
    return submitTx(bytesToHex(tx.toCbor()), []);
  }

  return await acceptOffer({
    wallet,
    acceptedLovelace,
    asset,
    scriptCbor,
    offerUtxo,
    submitTx: (tx) => submitTx(tx, []),
    listener,
    networkParams: await getNetworkParams(),
    DropspotAddress: constants.NEXT_PUBLIC_DROPSPOT_ADDRESS,
  });
}

export const selectGetOfferableAssets = (state: OfferUIStore) =>
  state.getOfferableAssets;
export const selectMyOffers = (state: OfferUIStore) => state.myOffers;
export const selectLoadingMyOffers = (state: OfferUIStore) => state.loadingMyOffers;
export const selectReceievedOffers = (state: OfferUIStore) => Array.from(state.acceptableOffers.values());
export const selectSetAssetOffer = (state: OfferUIStore) => state.setAssetOffer;

export const selectAssetOffers = (state: OfferUIStore) => state.assetOffers;
export const selectSetWallet = (state: OfferUIStore) => state.setWallet;
