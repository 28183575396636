import React, { useCallback, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Asset, Disbursement } from '#types/index';
import { NextImage } from '#components/Assets/NextImage';
import { useCardanoWallet } from '#lib/wallet/WalletContext';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import {
  createBulkListing,
  generateAssetsToBeListed,
} from '#lib/plutus/DropspotMarketBulkManagement';
import {
  BUILD_ACTION,
  updateAssetListing,
} from '../../lib/plutus/DropspotMarketContract';
import {
  MarketActionModal,
  ProcessingTransactionModalComponent,
} from '#components/Assets/AssetPage/ChainInteraction/MarketActionModal';
import {
  DropspotMarketError,
  InternalErrorCode,
} from '#lib/plutus/DropspotMarketError';
import { errorIsAPIError } from '#components/Assets/AssetPage/ChainInteraction/utils';
import { BellAlertIcon } from '@heroicons/react/24/solid';
import { SaleEstimateTotals } from '#components/Assets/SaleEstimate/SaleEstimate';

import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { trpcClient } from '../../lib/trpc';
import DaisyButton from '#components/Daisy/DaisyButton';
import { DaisyTextInput } from '#components/Daisy/DaisyTextInput';
import cn from 'classnames';
import { SuperCube } from '../SuperCubes/SuperCube';
type BulkListingPropsView = {
  doHide: () => void;
  onTransactionComplete: () => void;
};

export type AssetWithRoyalty = {
  asset: Asset;
  royalties?: Disbursement[];
  askingPrice: string;
  totalCommission?: number;
  totalRoyalty?: number;
  hasSuperCube?: boolean;
  loading: boolean;
};

type BulkListingStoreProps = {
  currentAssets: Map<string, AssetWithRoyalty>;
  toggleAsset: (asset: Asset) => Promise<void>;
  removeAsset: (asset: Asset) => void;
  calculateDeductions: () => void;
  setAssetPrice: (asset: Asset, price: number | undefined) => void;
  clear: () => void;
};

// <
//   TrackedTransactionsState,
//   [['zustand/devtools', TrackedTransactionsState]]
// >

export const useBulkListingStore = create<
  BulkListingStoreProps,
  [['zustand/devtools', BulkListingStoreProps]]
>(
  devtools<BulkListingStoreProps>(
    (set, get) => ({
      currentAssets: new Map(),

      setAssetPrice: (asset: Asset, price: number | undefined) => {
        console.log('In setAssetPrice', asset, price);
        const currentAssetMap = new Map(get().currentAssets);
        const theAsset = currentAssetMap.get(asset.id);

        console.log('In setAssetPrice', !!theAsset);
        if (!theAsset) {
          console.log('Asset is not in list');
          return;
        }

        currentAssetMap.set(asset.id, {
          ...theAsset,
          askingPrice: price ? price.toString(10) : '',
        });

        set({ currentAssets: currentAssetMap });

        get().calculateDeductions();
      },
      calculateDeductions: () => {
        const currentAssetMap = new Map(get().currentAssets);

        currentAssetMap.forEach((asset, key) => {
          if (!asset.askingPrice) return;

          try {
            const askingPrice = Number.parseInt(asset.askingPrice);

            const totalCommission = askingPrice * 0.02; // 2% commission ??
            const totalRoyalty = asset.royalties
              ? asset.royalties?.reduce((acc, cur) => acc + cur.percent, 0) *
                askingPrice
              : undefined;

            currentAssetMap.set(key, {
              ...asset,
              totalRoyalty,
              totalCommission,
            });
          } catch (_) {}
        });

        set({ currentAssets: currentAssetMap });
      },

      removeAsset: (asset: Asset) => {
        const m = new Map(get().currentAssets);
        m.delete(asset.id);

        set({ currentAssets: m });
      },
      toggleAsset: async (asset: Asset) => {
        console.log('toggleAsset', asset.policy, asset.policy_id, asset);
        if (!asset.policy && !asset.policy_id) return;

        // If asset already in store then remove it.
        if (get().currentAssets.has(asset.id)) {
          get().removeAsset(asset);
          return;
        }

        if (asset.policy || asset.policy_id) {
          trpcClient
            .query('sc-for-policy', {
              policy: asset.policy || asset.policy_id || '',
            })
            .then((cube) => {
              console.log('cube', cube);
              if (cube?.cubeLevel) {
                // Check if the Asset is still in the Assets Map
                if (!get().currentAssets.has(asset.id)) {
                  return;
                }

                const current = get().currentAssets.get(asset.id);

                const myAsset: AssetWithRoyalty = {
                  asset,
                  ...current,
                  askingPrice: current?.askingPrice || '',
                  loading: false,
                  hasSuperCube: true,
                };

                const m = new Map(get().currentAssets);
                m.set(asset.id, myAsset);

                set({
                  currentAssets: m,
                });

                get().calculateDeductions();
              }
            });
        }

        trpcClient
          .query('policy-royalties', {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            policy: asset.policy || asset.policy_id!,
          })
          .then((royalties) => {
            if (!royalties) {
              return;
            }
            // Check if the Asset is still in the Assets Map
            if (!get().currentAssets.has(asset.id)) {
              return;
            }

            const current = get().currentAssets.get(asset.id);

            const myAsset: AssetWithRoyalty = {
              asset,
              ...current,
              askingPrice: current?.askingPrice || '',
              loading: false,
              royalties: royalties.map((r) => ({
                name: '',
                tag: '',
                address: r.address,
                percent: r.percent,
                id: '',
              })),
            };

            const m = new Map(get().currentAssets);
            m.set(asset.id, myAsset);

            set({
              currentAssets: m,
            });

            get().calculateDeductions();
          });

        const m = new Map(get().currentAssets);
        m.set(asset.id, {
          asset,
          askingPrice: '',
          loading: true,
        });
        set({
          currentAssets: m,
        });
      },
      clear: () => {
        set({ currentAssets: new Map() });
      },
    }),
    {
      enabled: true,
      name: 'Bulk Listing Store',
    }
  )
);

/** Store Selectors */
const BulkListingToggleAssetSelector = (s: BulkListingStoreProps) =>
  s.toggleAsset;
const BulkListingRemoveAssetSelector = (s: BulkListingStoreProps) =>
  s.removeAsset;
const BultListingClearSelector = (s: BulkListingStoreProps) => s.clear;
const BulkListingAssetsSelector = (s: BulkListingStoreProps) => s.currentAssets;
const BulkListingSetAssetPriceSelector = (s: BulkListingStoreProps) =>
  s.setAssetPrice;
/** Store Hooks **/
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useBulkListingToggleAsset = () =>
  useBulkListingStore(BulkListingToggleAssetSelector);
const useBulkListingRemoveAsset = () =>
  useBulkListingStore(BulkListingRemoveAssetSelector);
const useBultListingClear = () => useBulkListingStore(BultListingClearSelector);
export const useBulkListingAssets = () =>
  useBulkListingStore(BulkListingAssetsSelector);
const useBulkListingSetAssetPrice = () =>
  useBulkListingStore(BulkListingSetAssetPriceSelector);

/** Derived States */
const useTotalListingValue = () =>
  Array.from(useBulkListingAssets().values()).reduce((total, a) => {
    try {
      return total + (Number.parseInt(a.askingPrice) || 0);
    } catch (e) {
      return 0;
    }
  }, 0);
const useTotalCommission = () =>
  Array.from(useBulkListingAssets().values()).reduce(
    (total, a) => total + (a.totalCommission || 0),
    0
  );
const useTotalCommissionReductions = () =>
  Array.from(useBulkListingAssets().values()).reduce(
    (total, a) => total + (a.hasSuperCube ? a.totalCommission || 0 : 0),
    0
  );
const useTotalEarnings = () => {
  const totalListingValue = useTotalListingValue();
  const commissionSavings = useTotalCommissionReductions();
  const totalCommission = useTotalCommission();
  const totalRoyalty = useTotalRoyalty();

  return totalListingValue - totalCommission - totalRoyalty + commissionSavings;
};

/** End Store Hooks **/

const useTotalRoyalty = () =>
  Array.from(useBulkListingAssets().values()).reduce(
    (total, a) => total + (a.totalRoyalty || 0),
    0
  );

export const BulkListing = ({
  doHide,
  onTransactionComplete,
}: BulkListingPropsView) => {
  const listings = useBulkListingAssets();
  const handleAssetPriceChange = useBulkListingSetAssetPrice();
  const removeAsset = useBulkListingRemoveAsset();
  const listingTotal = useTotalListingValue();
  const roayltyTotal = useTotalRoyalty();
  const commissionTotal = useTotalCommission();
  const commissionSavings = useTotalCommissionReductions();
  const earningsTotal = useTotalEarnings();
  const clearBulkList = useBultListingClear();

  const { wallet } = useCardanoWallet();

  const [doingStuff, setDoingStuff] = useState(false);
  const [error, setError] = useState<DropspotMarketError>();

  const [txStage, setTxStage] = useState('Building');

  const listingListener = useCallback((msg: BUILD_ACTION) => {
    console.log('Build Action', msg);
    setTxStage(msg);
  }, []);

  async function doListings() {
    try {
      // Validate all Listings have a Price

      if (
        Array.from(listings.values()).filter(({ askingPrice: price }) => !price)
          .length > 0
      )
        return;

      if (!wallet) return;

      setDoingStuff(true);
      const assetsToBeListed = generateAssetsToBeListed(listings);

      const result = await createBulkListing(
        wallet,
        assetsToBeListed,
        listingListener
      );

      //map
      if (!result) {
        return;
      }

      await Promise.all(
        assetsToBeListed.map((asset) => {
          console.log('updated asset listings for the list', asset);

          return updateAssetListing(
            asset.assetId,
            asset.price,
            result.txId,
            'LISTED',
            'LIST'
          );
        })
      );
      firebase.analytics().logEvent('bulk_relist');
      onTransactionComplete();
      clearBulkList();
      setDoingStuff(false);
      // // OnTransactionCreated(result);
      // setTimeout(() => setDoingStuff(false), 2000); //this is the auto close
      console.log(JSON.stringify(result));
    } catch (err) {
      if (errorIsAPIError(err)) {
        setError(err);
      } else {
        console.error(err);
        setError(
          new DropspotMarketError({
            code: InternalErrorCode.UNEXPECTED,
            info: (err as Error).message,
            type: 'INTERNAL',
          })
        );
      }
    }
  }

  return (
    <>
      {doingStuff && (
        <MarketActionModal
          action="BUY"
          onClose={() => {
            setDoingStuff(false);
            setError(undefined);
          }}
          title="Bulk Listing"
          error={error}
          Component={(props) => (
            <ProcessingTransactionModalComponent
              {...{ ...props, txStage: txStage, action: 'CREATE LISTING' }}
            />
          )}
        />
      )}
      <div className="flex flex-row items-center justify-between  z-50 ">
        <span className="font-bold text-lg dark:text-gray-100 mt-0">
          List for Sale
        </span>
        <span
          className={cn(
            'cursor-pointer duration-200 rounded-full w-9 h-9 dark:bg-gray-800 items-center justify-center flex'
          )}
          onClick={() => {
            doHide();
          }}
        >
          <XMarkIcon className="w-5 h=5" />
        </span>
      </div>

      <div className="w-full flex flex-col gap-4 my-8 pr-1">
        {Array.from(listings.values()).map(({ asset, askingPrice: price }) => {
          console.log('Show Asset', asset, price);

          return (
            <BulkListingItem
              key={asset.id}
              asset={asset}
              handleAssetPriceChange={handleAssetPriceChange}
              price={price}
              removeAsset={removeAsset}
            />
          );
        })}
      </div>
      <span className="pr-1">
        <SaleEstimateTotals
          price={listingTotal || 0}
          commissionPercent={0.02}
          commissionAmount={commissionTotal}
          commissionSavings={commissionSavings}
          royaltyAmount={roayltyTotal}
          earnings={earningsTotal}
        />
      </span>

      <div className=" border dark:border-gray-700 p-3 rounded-xl my-4 mr-1">
        <div className="flex flex-row gap-2">
          <BellAlertIcon className="w-5 h-5 text-accent" />
          <span className="text-accent font-bold text-sm">Note:</span>
        </div>
        <p className="text-blue-700 dark:text-gray-400 font-light text-sm mt-1">
          Up to 1.5 ADA is required to ride with each NFT. This is reimbursed to
          you when your NFT is sold, or you cancel the listing.
        </p>
      </div>

      <div className="flex mt-8 w-full mr-1">
        {/* <ButtonPublic
          containerClassName="w-full"
          isDisabled={
            Array.from(listings).filter(([price]) => !price).length > 0
          }
          className="font-bold"
          onClick={() => doListings()}
        >
          List for Sale
        </ButtonPublic> */}
        <DaisyButton
          label="List for Sale"
          colorName="btn-secondary"
          classNames="w-full"
          isDisabled={
            Array.from(listings).filter(([price]) => !price).length > 0
          }
          onClick={() => doListings()}
        />
      </div>
    </>
  );
};

type BulkListingItemProps = {
  asset: Asset;
  price: string;
  handleAssetPriceChange: (asset: Asset, price: number | undefined) => void;
  removeAsset: (asset: Asset) => void;
};
function BulkListingItem({
  asset,
  price,
  handleAssetPriceChange,
  removeAsset,
}: BulkListingItemProps) {
  return (
    <React.Fragment key={asset.asset_id}>
      <div className="relative flex flex-row justify-between w-full items-center">
        <SuperCube
          policy={asset.policy || ''}
          listedOnSuperCubeContract
          className="absolute top-8 -left-6"
          width={15}
          height={15}
        />
        <div className="flex flex-1 items-center gap-4">
          <div className="relative p-3 w-16 h-16 object-cover rounded-lg overflow-hidden shadow-xl">
            <NextImage
              src={
                asset.thumbnailUrl
                  ? asset.thumbnailUrl && asset.thumbnailUrl != 'holding'
                    ? asset.thumbnailUrl
                    : 'https://media.dropspot.io/placeholders%2FNo-Image-Placeholder.svg.png'
                  : asset.assetUrl && asset.assetUrl != 'holding'
                  ? asset.assetUrl
                  : 'https://media.dropspot.io/placeholders%2FNo-Image-Placeholder.svg.png'
              }
              quality={100}
              b64={asset.thumbnailB64}
              priority={false}
              width={100}
              height={100}
              className={
                !!asset.imageDisplayPreference
                  ? asset.imageDisplayPreference + ' pixelated'
                  : 'object-cover pixelated' + ' shadow-2xl pixelated'
              }
            />
          </div>
          <div className="flex flex-col pr-2">
            <div className="font-bold text-base dark:text-white ">
              {asset.title}
            </div>

            <div className="font-bold text-sm ">
              {asset.algoliaProjectFacet &&
                JSON.parse(asset.algoliaProjectFacet).title}
            </div>
          </div>
        </div>
        <div className="flex w-36 items-center gap-1">
          <DaisyTextInput
            id={''}
            label={''}
            placeholder={''}
            className="w-60"
            size="input-lg"
            value={price}
            type="number"
            onChange={(text) => {
              let myPrice: number | undefined = Number.parseFloat(text);
              if (Number.isNaN(myPrice)) {
                myPrice = undefined;
              }
              handleAssetPriceChange(asset, myPrice);
            }}
          />

          <XMarkIcon
            className="w-4 h-4 text-gray-500 dark:text-gray-400 dark:hover:text-red-500 hover:text-red-600 cursor-pointer hover:scale-110 duration-200"
            onClick={() => removeAsset(asset)}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
