import { MiddleEllipsisV2 } from '#components/MiddleEllipsis/MiddleEllipsisVersion2';
import { Address, StakeAddress } from '@hyperionbt/helios';

export interface WalletDisplayProps {
  address: string;
  isStyled?: boolean;
  isHidden?: boolean;
  label?: string;
  width?: string;
}

export function WalletDisplay({
  address,
  isHidden = true,
  isStyled = true,
  label = 'address',
  width = 'w-[16rem]',
}: WalletDisplayProps) {
  if (!isStyled) {
    return (
      <MiddleEllipsisV2
        text={address || '******************* No Address *********************'}
        label="Address"
      />
    );
  }

  return (
    <div
      className={`h-10 gap-0 bg-white dark:bg-gray-800 dark:text-white rounded-full shadow-lg flex flex-row items-center border-gray-300 dark:border-gray-700/50 border `}
    >
      <div className="bg-gray-300 dark:bg-gray-900  flex h-full pl-3 rounded-full text-gray-800 dark:text-gray-400 text-xs  pr-3 shadow-md items-center">
        {label}
      </div>
      <MiddleEllipsisV2
        text={address || '******************* No Address *********************'}
        label="Address"
      />
    </div>
  );
}

export async function parseAddress(address: string) {
  const addressType = bech32AddressIdentification(address);

  let addr: Address | StakeAddress;
  if (addressType.type === 'payment') {
    addr = Address.fromBech32(address);
  } else {
    addr = StakeAddress.fromBech32(address);
  }

  return { ...addressType, address: addr };
}
function bech32AddressIdentification(address: string) {
  const shellyAddressPrefixes: AddressPrefixes = {
    addr: { network: 'Mainnet', type: 'payment', era: 'Shelly' },
    addr_test: { network: 'Testnet', type: 'payment', era: 'Shelly' },
    stake: { network: 'Mainnet', type: 'stake', era: 'Shelly' },
    stake_test: { network: 'Testnet', type: 'stake', era: 'Shelly' },
  };

  const prefix = address.split('1')[0];
  console.log(prefix);
  const prefixInfo = shellyAddressPrefixes[prefix];
  if (!prefixInfo) {
    return {
      network: 'Unknown' as const,
      type: 'Unknown' as const,
      era: 'Byron' as const,
    };
  }
  return prefixInfo;
}

type AddressPrefixes = {
  [key: string]:
    | {
        network: 'Mainnet' | 'Testnet';
        type: 'payment' | 'stake';
        era: 'Shelly' | 'Byron';
      }
    | undefined;
};
