import DaisyButton from '#components/Daisy/DaisyButton';
import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { Asset } from '#types/index';
import { forwardRef, useImperativeHandle } from 'react';
import React from 'react';
import { Tooltip } from '@nextui-org/react';
import { useMarketUtxos } from '../MarketActionModalV2/MarketStore';
import MiddleEllipsis from 'react-middle-ellipsis';
import { Square3Stack3DIcon } from '@heroicons/react/24/solid';
import cn from 'classnames';
type AssetHolderRegionProps = { asset: Asset };

export const AssetHoldersRegion = ({ asset }: AssetHolderRegionProps) => {
  const utxos = useMarketUtxos();
  return (
    <>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="border rounded-t-lg dark:border-gray-700/80  border-gray-300 p-4 flex flex-col my-1">
              <div className="dark:text-gray-400 text-sm flex gap-2 items-center justify-between w-full font-bold tracking-wider">
                <span className="flex flex-row items-center gap-2">
                  <Square3Stack3DIcon
                    className={cn('w-4 duration-200 text-info', {
                      'text-info': open,
                    })}
                  />{' '}
                  Holders{' '}
                </span>
                <span className="font-bold dark:text-white">
                  {utxos.filter((u) => u.type == 'WALLET').length}
                </span>
              </div>
            </Disclosure.Button>
            <Transition
              show={open}
              className="overflow-hidden"
              enter="transition transition-[max-height] duration-500 ease-in"
              enterFrom="transform max-h-0"
              enterTo="transform max-h-screen"
              leave="transition transition-[max-height] duration-300 ease-out"
              leaveFrom="transform max-h-screen"
              leaveTo="transform max-h-0"
            >
              <Disclosure.Panel className="text-gray-500 border dark:border-gray-700/80 p-3">
                <div className="w-full  p-2  shadow-inner flex flex-row gap-4  items-center  rounded-xl">
                  <div className="flex flex-col w-full lg:flex-col gap-8">
                    <div className="flex flex-col gap-2">
                      <div className="flex flex-row items-center justify-between">
                        <span>Holder</span>
                        <span>Amount</span>
                      </div>
                      {utxos &&
                        utxos
                          .filter((u) => u.type == 'WALLET')
                          .map((u, idx) => (
                            <React.Fragment
                              key={`${u.ownerStakeAddress}${idx}`}
                            >
                              <div className="flex flex-row justify-between items-center">
                                <div className="flex w-full items-center gap-3">
                                  <img
                                    alt=""
                                    className="w-8 h-8 rounded-full"
                                    src={`https://www.gravatar.com/avatar/?d=mp&s=500`}
                                  />

                                  <div className="w-60 text-base font-normal font-mono">
                                    <MiddleEllipsis>
                                      <span>{u.ownerStakeAddress}</span>
                                    </MiddleEllipsis>
                                  </div>
                                </div>

                                <div className="dark:text-white">1</div>
                              </div>
                            </React.Fragment>
                          ))}
                    </div>
                  </div>
                </div>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </>
  );
};
