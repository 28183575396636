import {DropspotMarketError} from "../../../../lib/plutus/DropspotMarketError";
import {useCardanoWallet} from "../../../../lib/wallet/WalletContext";
import DaisyButton from "../../../Daisy/DaisyButton";
import {LearnMoreHelp} from "../../../InlineHelp/LearnMoreHelp";
import { useMarketCloseActionModal, useMarketClearError } from './MarketStore';

type ErrorInlayProps = {
  error: DropspotMarketError;
};
export function ErrorInlay({ error: { message, title, errorId } }: ErrorInlayProps) {
  const closeActionModal = useMarketCloseActionModal();
  const clearError = useMarketClearError();
  const { wallet } = useCardanoWallet();

  return (
    <div className="flex flex-col items-center justify-between h-full mt-2 pb-10">
      <span className="flex flex-col items-center justify-between gap-6 ">
        <img
          src={wallet?.icon || '/img/cardano-logo-black.webp'}
          className="w-7 h-7 object-contain mt-4 "
          alt={`${wallet?.name}-icon`}
        />
        <p className="text-3xl font-bold leading-12 text-gray-900 dark:text-gray-100">
          {title}
        </p>
        <p className="text-gray-700 dark:text-gray-400 text-center">{`${message} ${
          errorId ? `(#${errorId})` : ''
        }`}</p>

      </span>
      <DaisyButton
        label="Got It"
        colorName="btn-secondary"
        onClick={() => {
          closeActionModal();
          setTimeout(() => clearError(), 2500);
        }}
      />
    </div>
  );
}