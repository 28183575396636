/* eslint-disable @typescript-eslint/no-explicit-any */

import { Divider } from '#components/Divider/Divider';
import { connectHierarchicalMenu } from 'react-instantsearch-dom';
import { Checkbox } from '#components/Inputs/Checkbox/Checkbox';
import React from 'react';

export const CustomHierarchicalMenu = ({
  items,
  refine,
}: {
  items: any[];
  refine: any;
}) => (
  <div className="flex-col min-w-xl">
    <div className="flex flex-row px-4 my-2 text-lg dark:text-gray-500 ">
      <p className="flex flex-1">Trait</p>
      <p className="flex flex-1">Value</p>
    </div>
    <Divider />
    <HierarchicalMenuList items={items} refine={refine} />
  </div>
);

const HierarchicalMenuList = ({
  items,
  refine,
}: {
  items: any[];
  refine: any;
}) => (
  <div className="flex flex-row w-full">
    <div className="w-72 flex flex-col h-72 overflow-scroll">
      {items.map((item) => (
        <React.Fragment key={item.label}>
          <div className="flex flex-row py-2 mx-4">
            <a
              href="#"
              className="flex-1 text-base flex flex-row justify-between"
              style={{ fontWeight: item.isRefined ? 'bold' : '' }}
              onClick={(event) => {
                event.preventDefault();
                refine(item.value);
              }}
            >
              <p>{item.label}</p>
              <p>({item.count})</p>
            </a>
          </div>
        </React.Fragment>
      ))}
    </div>

    {items && items.length > 0 && (
      <div className="max-w-72 flex flex-col">
        {items.map((item) => (
          <React.Fragment key={item.label}>
            {item.items && (
              <div className="flex flex-row" key={item.items.label}>
                <HierarchicalMenuList items={item.items} refine={refine} />
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    )}
  </div>

  //   <ul className="mx-3">
  //     {items.map((item) => (
  //       <li key={item.label} className="flex flex-row py-2">
  //         <div className="w-48 ">
  //           <a
  //             href="#"
  //             className="flex-1"
  //             style={{ fontWeight: item.isRefined ? 'bold' : '' }}
  //             onClick={(event) => {
  //               event.preventDefault();
  //               refine(item.value);
  //             }}
  //           >
  //             {item.label}
  //           </a>
  //         </div>

  //         <div className="w-48">
  //           {item.items && (
  //             <HierarchicalMenuList items={item.items} refine={refine} />
  //           )}
  //         </div>
  //       </li>
  //     ))}
  //   </ul>
);

export const DSHierarchicalMenu = ({
  items,
  refine,
  textClass,
}: {
  items: any[];
  refine: any;
  textClass?: string;
}) => (
  <div className="flex-col w-full">
    <DSMenuList items={items} refine={refine} textClass={textClass} />
  </div>
);

const DSMenuList = ({
  items,
  refine,
  textClass,
}: {
  items: any[];
  refine: any;
  textClass?: string;
}) => (
  <div className="flex flex-col w-full">
    {items.map((item) => (
      <div key={item.label} className="flex flex-col py-2 w-full ">
        <a
          href="#"
          className={`flex-1 text-base flex flex-row justify-between text-gray-600 ${textClass}`}
          style={{ fontWeight: item.isRefined ? 'bold' : '' }}
          onClick={(event) => {
            event.preventDefault();
            refine(item.value);
          }}
        >
          <span className="flex flex-row gap-2">
            <Checkbox
              checked={item.isRefined}
              size="xs"
              onChange={() => {
                console.log('do nothing');
              }}
            />
            <p>{item.label}</p>
          </span>

          <p>({item.count})</p>
        </a>
        {item.items && (
          <div className="flex flex-row" key={item.items.label}>
            <DSHierarchicalMenu
              items={item.items}
              refine={refine}
              textClass={'text-gray-500'}
            />
          </div>
        )}
      </div>
    ))}
  </div>
);
