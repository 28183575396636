import DaisyButton from '#components/Daisy/DaisyButton';
import { ArrowUpIcon } from '@heroicons/react/24/outline';
import { Tooltip } from '@nextui-org/react';
import React, { useEffect, useState } from 'react';

const ScrollToTopButton: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const layoutRef = document.querySelector('.layout');

    if (layoutRef) {
      if (layoutRef.scrollTop > 800) {

        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }
  };

  useEffect(() => {
    const layoutRef = document.querySelector('.layout');

    if (layoutRef) {
      layoutRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (layoutRef) {
        layoutRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const scrollToTop = () => {
    const layoutRef = document.querySelector('.layout');

    if (layoutRef) {
      layoutRef.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  if (isVisible)  return (
    <Tooltip 
      content="Scroll to top"
      placement="left"
      className="fixed bottom-4 right-4 z-50 w-max"
      >
    <DaisyButton 
      classNames=" rounded-full shadow-lg aspect-square px-2"
      onClick={scrollToTop}
      colorName='btn-accent'
      size='btn-md'
      label=''
      icon={<ArrowUpIcon className="w-6 h-6" />}
    />
    </Tooltip>
  );
  else return null;
};

export default ScrollToTopButton;
