import {
  Address,
  Assets,
  bytesToHex,
  Datum,
  hexToBytes,
  MintingPolicyHash,
  NetworkParams,
  textToBytes,
  Tx,
  TxOutput,
  UplcProgram,
  UTxO,
  Value,
} from '@hyperionbt/helios';
import { Cardano } from '../../types/cardano';

import constants from '../constants';
import { trpcClient } from '../trpc';
import { coinSelection } from './CoinSelection';
import {
  BUILD_ACTION,
  datum,
  getAddress,
  signTransaction,
} from './DropspotMarketContract';

import {
  CoinSelectionErrorCode,
  DropspotMarketError,
} from './DropspotMarketError';
import { captureException } from '@sentry/nextjs';
import { submitTx } from './DropspotMarketContract';
import { AssetWithRoyalty } from '../../components/Wallet/BulkListing';

let _networkParams: NetworkParams | undefined;
async function getNetworkParams() {
  if (!_networkParams) {
    const np = await fetch(constants.NETWORK_PARAMS_URL).then((r) => r.json());

    _networkParams = new NetworkParams(np); // Get Network Params
  }

  return _networkParams;
}

export function generateAssetsToBeListed(
  listings: Map<string, AssetWithRoyalty>
) {
  return Array.from(listings.values())
    .map<BulkListingProps | undefined>((listing) => {
      console.log('assetsToBeListed', listing.asset);
      try {
        if (!listing.asset.policy) {
          console.log('No Policy');
          return undefined;
        }
        if (!listing.askingPrice) {
          console.log('No Asking Price');
          return undefined;
        }

        if (listing.asset.assetStandard === 'CIP68') {
          if (!listing.asset.tokenNameEncoded) {
            return;
          }

          return {
            policy: listing.asset.policy,
            tokenName: listing.asset.tokenNameEncoded,
            quantity: 1,
            price: Number.parseInt(listing.askingPrice) * 1_000_000,
            assetId: listing.asset.asset_id || listing.asset.id,
            assetStandard: listing.asset.assetStandard,
          };
        }

        if (!listing.asset.tokenName) {
          return;
        }

        return {
          policy: listing.asset.policy,
          tokenName: listing.asset.tokenName,
          quantity: 1,
          price: Number.parseInt(listing.askingPrice) * 1_000_000,
          assetId: listing.asset.asset_id || listing.asset.id,
          assetStandard: listing.asset.assetStandard || 'CIP25',
        };
      } catch (e) {}
    })
    .filter((item): item is BulkListingProps => item !== undefined);
}

/*
The createBulkListing function is used to create a bulk listing on the Dropspot marketplace.
We pass in an Array of Assets and their Listing Price (An Asset is made up of Policy, Token Name and Quantity)
We then create a Transaction that will create a Bulk Listing on the Dropspot Marketplace.
*/
export async function createBulkListing(
  wallet: Cardano,
  assets: BulkListingProps[],
  listener: (action: BUILD_ACTION) => void
) {
  listener('Building');
  console.log('In createBulkListing', JSON.stringify(assets));
  const contract = await trpcClient.query('contract-text', { type: 'Sale' });

  if (!contract) return;

  const dsAddress = Address.fromBech32(constants.NEXT_PUBLIC_DROPSPOT_ADDRESS);
  const stakingHash = dsAddress.stakingHash;

  const program = UplcProgram.fromCbor(hexToBytes(contract.script));

  assert(
    program.validatorHash.hex === contract.hash,
    'Contract Build Issue: Not generating expected Validator Hash'
  );

  const ownerAddress = await getAddress(wallet);

  // 1. Check that we have the Assets in our Wallet
  const utxos = (await wallet.getUtxos())?.map((u) =>
    UTxO.fromCbor(hexToBytes(u))
  );

  if (!utxos) {
    throw new DropspotMarketError({
      type: 'COIN_SELECTION',
      code: CoinSelectionErrorCode.INPUTS_EXHAUSTED,
      info: "No UTxO's in Wallet",
    });
  }

  console.log('UTXOS', utxos);

  const requiredAda = assets.length * 1_800_000;

  const requiredAssets = new Assets();

  assets.forEach((a) => {
    requiredAssets.addComponent(
      MintingPolicyHash.fromHex(a.policy),
      a.assetStandard === 'CIP25'
        ? Array.from(new TextEncoder().encode(a.tokenName))
        : hexToBytes(a.tokenName), //CIP68
      BigInt(a.quantity)
    );
  });

  const networkParams = await getNetworkParams();

  const [walletUTxOs, otherUtxos] = coinSelection(
    utxos,
    new Value(BigInt(requiredAda), requiredAssets),
    networkParams
  );

  console.log('====================================');
  console.log('Got Wallet UTxOs', walletUTxOs);
  console.log('====================================');
  const contractAddress = Address.fromValidatorHash(
    program.validatorHash,
    stakingHash,
    constants.TESTNET
  );
  console.log('Got networkParams', networkParams);

  const txOutputs = await Promise.all(
    assets.map((a) =>
      assetToTxOutput(
        Address.fromHex(ownerAddress),
        contractAddress,
        a,
        networkParams
      )
    )
  );

  const tx = new Tx()
    .addInputs(walletUTxOs)
    .addOutputs(txOutputs)
    .addMetadata(406, ownerAddress);

  listener('Building');

  let builtTx: Tx;
  try {
    builtTx = await tx.finalize(
      networkParams,
      Address.fromHex(ownerAddress),
      otherUtxos
    );
  } catch (e) {
    if (e instanceof Error) {
      e.name = 'DropspotMarketError';
    }

    captureException(e, {
      tags: {
        type: 'DropspotMarketError',
        step: 'Finalize',
        process: 'BulkListing',
      },
      extra: {
        txCBOR: bytesToHex(tx.toCbor()),
        buyerAddress: ownerAddress,
        now: new Date(),
      },
    });

    throw e;
  }

  listener('Signing');
  console.log(builtTx.body.outputs.map((o, i) => `${i}: ${o.toCborHex()}`));
  const signatures = await signTransaction(builtTx, wallet);
  builtTx.addSignatures(signatures);

  listener('Submitting');

  try {
    const txId = await submitTx(
      bytesToHex(builtTx.toCbor()),
      assets.map((a) => a.assetId)
    );

    listener('Submitted');
    return {
      txId: txId,
      contractAddress: contractAddress.toBech32(),
      tx: tx.dump(),
    };
  } catch (err) {
    captureException(err, {
      extra: {
        tx: bytesToHex(builtTx.toCbor()),
        assets: assets.map((a) => a.assetId),
      },
    });
    throw new DropspotMarketError({
      code: 2,
      info: (err as Error).message,
      type: 'SEND',
    });
  }
}

async function assetToTxOutput(
  ownerAddress: Address,
  contractAddress: Address,
  asset: BulkListingProps,
  networkParams: NetworkParams
) {
  const royalties = await trpcClient.query('policy-royalties', {
    policy: asset.policy,
  });

  console.log('Got Royalties', royalties);

  const a = new Assets();
  a.addComponent(
    MintingPolicyHash.fromHex(asset.policy),
    asset.assetStandard === 'CIP25'
      ? Array.from(new TextEncoder().encode(asset.tokenName))
      : hexToBytes(asset.tokenName),
    BigInt(asset.quantity)
  );
  const value = new Value(BigInt(0), a); // Use Lovelace 0, we will need to Auto balance it later

  console.log('asset.tokenName', asset.tokenName);
  const { pure } = await datum({
    type: 'Sale',
    datum: {
      version: '13',
      ownerAddress: ownerAddress.toBech32(),
      amount: asset.price,
      disbursements: [],
      royalties: royalties,
      policy: asset.policy,
      tokenName:
        asset.assetStandard === 'CIP25'
          ? bytesToHex(textToBytes(asset.tokenName))
          : asset.tokenName,
      startDatePOSIX: `0`,
      assetStandard: asset.assetStandard,
    },
  });
  console.log('Got Pure', pure);
  const txo = new TxOutput(contractAddress, value, Datum.inline(pure));

  txo.correctLovelace(networkParams);

  return txo;
}

export type BulkListingProps = {
  policy: string;
  tokenName: string;
  assetStandard: 'CIP25' | 'CIP68';
  quantity: number;
  price: number;
  assetId: string;
};

function assert(condition: boolean, message: string) {
  if (!condition) {
    throw new Error(message);
  }
}

export const testing = {
  assetToTxOutput,
};
