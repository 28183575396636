export function flatten(s: string | string[] | undefined): string {
  if (!s) return '';
  if (typeof s === 'string') return s;
  return s.join('');
}

export function genRandomString(length: number) {
  const chars =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charLength = chars.length;
  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * charLength));
  }
  return result;
}

export function intToString(value: number) {
  const suffixes = ['', 'k', 'm', 'b', 't'];
  const suffixNum = Math.floor(('' + value).length / 3);
  let str = '';
  const shortValue = parseFloat(
    (suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(2)
  );
  if (shortValue % 1 != 0) {
    str = shortValue.toFixed(1);
  }
  return str + suffixes[suffixNum];
}

export function removeCharsPriorToHash(input: string): string {
  const hashIndex = input.indexOf('#');
  if (hashIndex === -1) {
    // Return the original string if no '#' is found
    return input;
  }
  // Return the string with all characters prior to '#' removed
  return input.substr(hashIndex);
}
