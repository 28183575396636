import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { trpc } from '../../pages/_app';
import { AssetAction } from '../Assets/AssetAction/AssetAction';
import { ButtonPublic } from '../Button/ButtonPublic';
import { TextInput } from '#components/Inputs/TextInput/TextInput';
import {
  MagnifyingGlassIcon,
  Squares2X2Icon,
  TagIcon,
  ViewColumnsIcon,
} from '@heroicons/react/24/outline';
import { LearnMoreHelp } from '#components/InlineHelp/LearnMoreHelp';
import { NativeAssetTable } from '#components/Wallet/NativeAssetTable';
import { AssetFlipCardPublic } from '#components/Assets/AssetFlipCard/AssetFlipCardPublic';
import { InlineLoading } from '#components/Loading/InlineLoading';
import {
  useCachedWalletExternalListings,
  useCachedWalletListings,
  useWalletSyncInserts,
} from '#lib/hooks/useCachedWallet';
import { useFirebaseCurrentUser } from '#lib/hooks/useFirebaseCurrentUser';
import cn from 'classnames';
import { SpinnerLoader } from '#components/Loading/SpinnerLoader';
import { useWalletStore } from '#lib/wallet/WalletContext';
export interface AddressProp {
  address: string;
  compact?: boolean;
  fixedHeight?: boolean;
  gridSize?: 'full' | 'contained' | 'compact';
  tab?: string;
}

const ADDRESS_ACTIVITY_PAGE_SIZE = 2;

export function AddressActivity({ address }: AddressProp) {
  // For this 'time' is used as a way of Paging in the content
  const [pagination, setPagination] = useState<string>();
  const [priorPage, setPriorPage] = useState<string[]>([]);

  const { prefetchQuery } = trpc.useContext();

  const { data, isLoading, isFetching } = trpc.useQuery(
    [
      'address-actions',
      { address, pagination, perPage: ADDRESS_ACTIVITY_PAGE_SIZE },
    ],
    {
      keepPreviousData: true, // Keep the prior data so that we can build up the data as we go
      staleTime: 30000,
      cacheTime: 30000,
    }
  );

  useEffect(() => {
    if (data?.length === ADDRESS_ACTIVITY_PAGE_SIZE) {
      prefetchQuery(
        [
          'address-actions',
          {
            address,
            pagination: data[data.length - 1].pagination,
            perPage: ADDRESS_ACTIVITY_PAGE_SIZE,
          },
        ],
        {
          staleTime: 30000,
          cacheTime: 30000,
        }
      );
    }
  }, [address, data, prefetchQuery]);

  if (isLoading) {
    return (
      <>
        {Array.from({ length: ADDRESS_ACTIVITY_PAGE_SIZE }).map((_, index) => {
          return <AssetAction key={index} loading={true} />;
        })}
      </>
    );
  }

  if (!data) {
    return <p>No activity</p>;
  }

  return (
    <ul>
      {data.map((activity) => {
        return (
          <AssetAction
            key={activity.tx_hash}
            loading={false}
            assetAction={activity}
          />
        );
      })}

      <li className="flex">
        <ButtonPublic
          working={isFetching}
          workingLabelName="Prior Page"
          isDisabled={priorPage.length === 0 && !pagination}
          onClick={() => {
            setPagination(priorPage[priorPage.length - 1]);
            setPriorPage((p) => p.slice(0, p.length - 1));
          }}
        >
          Prior Page
        </ButtonPublic>
        <ButtonPublic
          working={isFetching}
          workingLabelName="Next Page"
          isDisabled={data.length !== ADDRESS_ACTIVITY_PAGE_SIZE}
          onClick={() => {
            if (!data || data.length === 0) return;
            const p = pagination;
            setPriorPage((currPageStack) =>
              p ? [...currPageStack, p] : currPageStack
            );
            setPagination(data[data.length - 1].pagination);
          }}
        >
          Next Page
        </ButtonPublic>
      </li>
    </ul>
  );
}

export function ForSaleAssets({
  address,
  compact,
  fixedHeight = true,
  gridSize = 'full',
  tab,
}: AddressProp) {
  const [filter, setFilter] = useState<string>();
  // const { data, isLoading } = trpc.useQuery(['listed-assets', { address }]);
  const { listedAssets, loading } = useCachedWalletListings(address);
  const { externalListings, loadingExternalListings } =
    useCachedWalletExternalListings(address);
  const [filteredData, setFilteredData] = useState(listedAssets);
  const [viewMode, setViewMode] = useState<'LIST' | 'GRID'>(
    compact ? 'LIST' : 'GRID'
  );
  const [listingMode, setListingMode] = useState<'DS' | 'JPG'>('DS');

  const handleSetListingMode = (mode: 'DS' | 'JPG') => {
    setListingMode(mode);
  };

  const { push } = useRouter();

  const { stakeAddress } = useWalletStore();

  const { currentUser } = useFirebaseCurrentUser();
  useEffect(() => {
    if (listingMode == 'JPG') {
      setFilteredData(externalListings);
    } else {
      setFilteredData(listedAssets);
    }
  }, [listedAssets, externalListings, listingMode]);

  const [initialLoad, setInitialLoad] = useState(false);
  useEffect(() => {
    if (initialLoad) {
      return;
    }
    if (
      (!listedAssets || listedAssets.length < 1) &&
      externalListings &&
      externalListings.length > 0
    ) {
      setListingMode('JPG');
      setFilteredData(externalListings);
      setInitialLoad(true);
    } else if ((listedAssets && listedAssets.length > 0) || !externalListings) {
      setListingMode('DS');
      setFilteredData(listedAssets);
      setInitialLoad(true);
    }
  }, [listedAssets?.length, externalListings?.length]);

  const { inserting } = useWalletSyncInserts(address);
  if (loading || loadingExternalListings) {
    return (
      <div className="w-full">
        <div className="w-full flex justify-between items-center pt-2">
        {viewMode !== 'LIST' && 
          <div className="flex items-center">
            {/* {externalListings && externalListings.length > 0 && ( */}
            <div className="flex items-center gap-1">
              {' '}
              <button
                className={cn('btn btn-sm', {
                  'btn-secondary': listingMode == 'DS',
                })}
                onClick={() => setListingMode('DS')}
              >
                Listed
              </button>
              <button
                className={cn('btn btn-sm', {
                  'btn-secondary': listingMode == 'JPG',
                })}
                onClick={() => setListingMode('JPG')}
              >
                External Listings
              </button>
            </div>
            {/* )} */}
            <TextInput
              id={''}
              label={''}
              className="w-80"
              rounded={true}
              placeholder={'Name, collection or policy'}
              icon={() => (
                <MagnifyingGlassIcon className="w-5 h-5 text-gray-500" />
              )}
              value={filter}
              onChange={function (text: string): void {
                if (!!text && text.length > 0) {
                  setFilteredData(
                    filteredData?.filter((asset) =>
                      asset.title.toLowerCase().includes(text.toLowerCase())
                    )
                  );
                } else {
                  setFilteredData(listedAssets);
                }
                setFilter(text);
              }}
            />
          </div>
  }

          {!compact && (
            <span className="relative z-0 inline-flex shadow-sm rounded-md">
              <button
                type="button"
                onClick={() => setViewMode('GRID')}
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white dark:bg-gray-900 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500"
              >
                <span className="sr-only">Grid</span>
                <Squares2X2Icon className="h-5 w-5" aria-hidden="true" />
              </button>
              <button
                type="button"
                onClick={() => setViewMode('LIST')}
                className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white dark:bg-gray-900 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500"
              >
                <span className="sr-only">List</span>
                <ViewColumnsIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </span>
          )}
        </div>
        <div className="flex-1 w-full flex flex-col justify-center items-center min-h-[26rem] gap-4">
          <InlineLoading ballSize={8} />
          <span className="text-gray-500 dark:text-gray-400 font-light">
            loading
          </span>
        </div>
      </div>
    );
  }

  if (
    !loading &&
    ((((!!listedAssets && listedAssets?.length < 1) || !listedAssets) &&
      !!externalListings &&
      externalListings?.length < 1) ||
      !externalListings)
  ) {
    return (
      <div className="w-full min-h-[33rem] flex flex-col justify-center items-center bg-white/50 dark:bg-gray-900/50 rounded-lg gap-2">
        <TagIcon className="w-24 h-24 text-gray-400" />
        <h2 className="text-2xl font-bold dark:text-gray-400">No Listings</h2>
        <p className="text-base font-light text-gray-500 max-w-md w-full text-center mb-8">
          NFTs that you list for sale will appear here.
        </p>
        <LearnMoreHelp description={'listing your NFT for sale'} link={'#'} />
      </div>
    );
  }

  return (
    <>
      {listingMode === 'JPG' && inserting != 0 && (
        <div className="fixed flex w-max top-24 px-3 z-[25] py-1 right-14 bg-secondary rounded-2xl items-center">
          <SpinnerLoader />
          Syncing Wallet
        </div>
      )}
      {!compact && (
        <div className="w-full flex-col pr-1">
          <div className="w-full flex justify-between sm:items-center pt-2">
            <div className="flex items-center gap-2">
              {/* {externalListings && externalListings.length > 0 && ( */}
              <div className="flex items-center gap-1">
                <button
                  className={cn('btn btn-sm', {
                    'btn-secondary': listingMode == 'DS',
                  })}
                  onClick={() => setListingMode('DS')}
                >
                  Listed
                  {' ( '}
                  {listedAssets?.length}
                  {' )'}
                </button>
                <button
                  className={cn('btn btn-sm', {
                    'btn-secondary': listingMode == 'JPG',
                  })}
                  onClick={() => setListingMode('JPG')}
                >
                  External Listings
                  {' ( '}
                  {externalListings?.length}
                  {' )'}
                </button>
              </div>
              {/* // )} */}
              <TextInput
                id={'filterData'}
                label={''}
                className="w-80 hidden sm:flex"
                rounded={true}
                placeholder={'Name, collection or policy'}
                icon={() => (
                  <MagnifyingGlassIcon className="w-5 h-5 text-gray-500" />
                )}
                value={filter}
                onChange={function (text: string): void {
                  if (!!text && text.length > 0) {
                    setFilteredData(
                      listedAssets?.filter(
                        (asset) =>
                          asset.title
                            .toLowerCase()
                            .includes(text.toLowerCase()) ||
                          asset.policy?.includes(text)
                      )
                    );
                  } else {
                    setFilteredData(listedAssets);
                  }
                  setFilter(text);
                }}
              />
            </div>
            <span className="relative z-0 inline-flex shadow-sm rounded-md">
              <button
                type="button"
                onClick={() => setViewMode('GRID')}
                className="relative h-max inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white dark:bg-gray-900 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500"
              >
                <span className="sr-only">Grid</span>
                <Squares2X2Icon className="h-5 w-5" aria-hidden="true" />
              </button>
              <button
                type="button"
                onClick={() => setViewMode('LIST')}
                className="-ml-px relative h-max inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white dark:bg-gray-900 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500"
              >
                <span className="sr-only">List</span>
                <ViewColumnsIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </span>
          </div>
          <TextInput
            id={'filterData'}
            label={''}
            className="w-full mt-2 sm:hidden"
            rounded={true}
            placeholder={'Name, collection or policy'}
            icon={() => (
              <MagnifyingGlassIcon className="w-5 h-5 text-gray-500" />
            )}
            value={filter}
            onChange={function (text: string): void {
              if (!!text && text.length > 0) {
                setFilteredData(
                  listedAssets?.filter(
                    (asset) =>
                      asset.title.toLowerCase().includes(text.toLowerCase()) ||
                      asset.policy?.includes(text)
                  )
                );
              } else {
                setFilteredData(listedAssets);
              }
              setFilter(text);
            }}
          />
        </div>
      )}
      {fixedHeight == true ? (
        <div className="flex-1 w-full scrollbar-hide h-max min-h-[34rem]">
          {viewMode == 'GRID' && (
            <div
              className={cn(
                'grid w-full gap-8 mt-6 h-max',
                {
                  ' grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 4xl:grid-cols-5 5xl:grid-cols-5 6xl:grid-cols-5 gap-8 mt-6':
                    gridSize == 'contained',
                },
                {
                  'grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 4xl:grid-cols-5 5xl:grid-cols-6 6xl:grid-cols-7':
                    gridSize == 'full',
                }
              )}
            >
              {filteredData?.map((nativeAsset, idx) => {
                return (
                  <React.Fragment key={`${nativeAsset.id}${idx}`}>
                    <AssetFlipCardPublic
                      key={nativeAsset.id}
                      showProjectInfo={true}
                      heightClass={'h-[26rem] min-w-[16rem]'}
                      link={`/nft/${
                        nativeAsset.assetId || nativeAsset.asset_id
                      }`}
                      showLikes={false}
                      asset={nativeAsset}
                      viewedFromWallet={true}
                      viewedFromForSale={stakeAddress == address}
                      viewedFromListings={tab == 'listed'}
                      viewedFromExternalListings={
                        tab == 'listed' && listingMode == 'JPG'
                      }
                    />
                  </React.Fragment>
                );
              })}
            </div>
          )}
          {viewMode == 'LIST' && (
            <NativeAssetTable minify={compact} assets={filteredData} />
          )}
          {!filteredData ||
            (filteredData.length < 1 && (
              <div className="w-full min-h-[33rem] flex flex-col justify-center items-center bg-white/50 dark:bg-gray-900/50 rounded-lg gap-2">
                <TagIcon className="w-24 h-24 text-gray-400" />
                <h2 className="text-2xl font-bold dark:text-gray-400">
                  No Listings
                </h2>
                <p className="text-base font-light text-gray-500 max-w-md w-full text-center mb-8">
                  NFTs that you list for sale will appear here.
                </p>
                <LearnMoreHelp
                  description={'listing your NFT for sale'}
                  link={'#'}
                />
              </div>
            ))}
        </div>
      ) : (
        <div className="flex-1 w-full scrollbar-hide min-h-[36rem] overflow-y-scroll">
          {viewMode == 'GRID' && (
            <div
              className={cn(
                'grid w-full gap-8 mt-6',
                {
                  ' grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-5 4xl:grid-cols-5 5xl:grid-cols-5 6xl:grid-cols-5':
                    gridSize == 'contained',
                },
                {
                  'grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 4xl:grid-cols-5 5xl:grid-cols-6 6xl:grid-cols-7':
                    gridSize == 'full',
                }
              )}
            >
              {filteredData?.map((nativeAsset, idx) => {
                return (
                  <React.Fragment key={`${nativeAsset.id}${idx}`}>
                    <AssetFlipCardPublic
                      key={nativeAsset.id}
                      showProjectInfo={true}
                      heightClass={'h-[26rem] min-w-[16rem]'}
                      link={`/nft/${nativeAsset.assetId}`}
                      showLikes={false}
                      asset={nativeAsset}
                      viewedFromWallet={true}
                      viewedFromForSale={stakeAddress == address ? true : false}
                      viewedFromListings={tab == 'listed'}
                      viewedFromExternalListings={listingMode == 'JPG'}
                    />
                  </React.Fragment>
                );
              })}
            </div>
          )}
          {viewMode == 'LIST' && (
            <NativeAssetTable minify={compact} assets={filteredData} />
          )}
        </div>
      )}
    </>
  );
}
