import { useState } from 'react';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { CheckIcon, ShareIcon } from '@heroicons/react/24/solid';
import Link from 'next/link';
import { Tooltip } from '@nextui-org/react';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}
export async function copyTextToClipboard(text: string) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  }
}
export interface ShareButtonProps {
  url: string;
}

export function ShareButtonSmall({ url }: ShareButtonProps) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(url)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderCreatorView = () => {
    return (
      <div className="flex flex-col gap-0 text-base w-36 font-medium ">
        <div
          className="flex flex-row justify-between items-center cursor-pointer hover:bg-gray-300  px-3 py-2 rounded-lg my-1"
          onClick={() => handleCopyClick()}
        >
          {!!isCopied && (
            <>
              <p>Copied</p>
              <CheckIcon className="w-5 h-5 text-green-600" />
            </>
          )}
          {!isCopied && (
            <>
              <p>Copy Link</p>
              <DocumentDuplicateIcon className="w-5 h-5" />
            </>
          )}
        </div>
        <div className="flex flex-row justify-between items-center cursor-pointer hover:bg-gray-300 px-3 py-2 rounded-lg my-1">
          <Link href={`https://twitter.com/intent/tweet?url=${encodeURI(url)}`}>
            <a
              target={'_blank'}
              className="flex flex-row justify-between w-full"
            >
              Tweet
              <div className="h-5 w-5">
                <svg fill="currentColor" viewBox="0 0 24 24">
                  <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
              </div>
            </a>
          </Link>
        </div>
      </div>
    );
  };
  return (
    <Tooltip content={renderCreatorView()} placement={'bottom'}>
      <div className="p-3 gap-2 rounded-full  bg-gray-100 border dark:bg-gray-900/90 dark:border-gray-600/50 dark:text-gray-400 dark:hover:text-white shadow-sm flex items-center justify-center cursor-pointer hover:shadow-xl duration-200 transition hover:-translate-y-1">
        <ShareIcon className="w-5 h-5" />
      </div>
    </Tooltip>
  );
}
