import {
  OfferUIStore,
  useOfferUIStore,
} from '#components/Assets/AssetPage/MarketActionOffers/OfferUIStore';
import { NextImage } from '#components/Assets/NextImage';
import DaisyButton from '#components/Daisy/DaisyButton';
import { useFirebaseCurrentUser } from '#lib/hooks/useFirebaseCurrentUser';
import { useCardanoWallet } from '#lib/wallet/WalletContext';
import { Asset } from '#types/index';
import React, { useState } from 'react';
import { useCallback } from 'react';
import cn from 'classnames';
import { Tooltip } from '@nextui-org/react';
import { DropspotMarketError } from '#lib/plutus/DropspotMarketError';
import { useNotifier } from 'react-headless-notifier';
import { OfferErrorModal } from './OfferErrorModal';
import { OfferSuccessModal } from './OfferSuccessModal';
import { logger } from '../../lib/Logger';

export type OfferGroupPanelProps = {
  isActive: boolean;
  assets?: Asset[];
  floor?: number;
  doClose: () => void;
};
export const GroupOfferPanel = ({
  isActive,
  assets,
  doClose,
  floor,
}: OfferGroupPanelProps) => {
  const offerAssets = useOfferUIStore(
    useCallback((state) => state.assetOffers, [])
  );
  const collectionOffers = useOfferUIStore(
    useCallback((state) => state.collectionOffers, [])
  );

  const { notify } = useNotifier();
  const clearOffers = useOfferUIStore(
    useCallback((state) => state.clearAll, [])
  );
  const deleteAssetOffer = useOfferUIStore(
    useCallback((state) => state.deleteAssetOffer, [])
  );
  const setUser = useOfferUIStore(
    useCallback((state) => state.setCurrentUser, [])
  );
  const createOffer = useOfferUIStore(
    useCallback((state) => state.createOffer, [])
  );

  const txState = useOfferUIStore(selectTxState);
  const txInProgress = useOfferUIStore(selectTxInProgress);
  const { wallet } = useCardanoWallet();
  const { currentUser } = useFirebaseCurrentUser();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [successTx, setSuccessTx] = useState<string | undefined>();

  console.log('Offer Assets>>>>', offerAssets instanceof Map);

  const offerAdaAmount =
    offerAssets instanceof Map
      ? Array.from(offerAssets?.values()).reduce((existing, current) => {
          if (current > existing) return current;
          return existing;
        }, 0)
      : 0;

  const processOffer = useCallback(async () => {
    const showNotification = (type: string, message: string) => {
      notify(
        <div className="toast toast-end min-w-[18rem]">
          <div className={`alert ${type}`}>
            <div>
              <span>{message}</span>
            </div>
          </div>
        </div>,
        {
          max: 1,
          position: 'bottomRight',
          duration: 3000,
        }
      );
    };
    console.log('Process the offer.');
    if (!wallet) return;

    if (!currentUser) return;

    setUser(currentUser);

    // set all the assets:

    console.log('Process the offer post wallet.');
    const tx = await createOffer(wallet, 999999999999999).catch((error) => {
      console.log('we have an error to handle.');

      logger.error({
        message: error.message,
        level: 'error',
        timestamp: Date.now(),
        additional: {
          error: JSON.stringify(error),
        },
      });

      if (error instanceof DropspotMarketError) {
        const e = error;
        //    setDisplayError(true);
        setErrorMessage(e.message);
        setShowError(true);
        showNotification(
          e.code == 2 ? 'alert-info' : 'alert-error',
          `${e.message}`
        );
        return;
        //  setErrorMessage(e.message);
      }
    });
    if (tx) {
      setSuccessTx(tx);
      setShowSuccess(true);
      doClose();
    }
  }, [createOffer, currentUser, doClose, notify, setUser, wallet]);

  // const offerAdaAmount = Array.from(offerAssets.values()).reduce((a, b) => a + b, 0); // Current (Sum of all Offers)

  return (
    <>
      <div
        className={cn(
          'fixed bottom-0 bg-black w-full h-64 flex flex-col items-center p-6 duration-200 z-30',
          { 'translate-y-[400px] lg:translate-y-64': !isActive }
        )}
      >
        <OfferErrorModal
          title={'Transaction Error'}
          message={errorMessage}
          severity={'info'}
          open={showError}
          onClose={() => setShowError(false)}
        />
        {successTx && (
          <OfferSuccessModal
            tx_hash={successTx}
            open={showSuccess}
            onClose={() => setShowSuccess(false)}
          />
        )}

        <div className="flex w-full max-w-[105rem] flex-col h-full">
          <div className="flex flex-row justify-between w-full h-full items-center">
            <div className="w-72 font-normal text-gray-400 h-full mt-6">
              <span className="text-2xl text-white font-bold mt-2">
                Liquid Staked Offer
              </span>
              <p className='overflow-scroll h-full'> 
                Make multiple Asset and Collection offers in one transaction.
                First offer to be accepted will cancel the others. Offer amount
                remains staked / delegated.
              </p>
            </div>
            <div className="flex-1 flex-col h-full">
              <div className="w-full flex items-center justify-between font-normal dark:text-gray-400 text-gray-600 px-24">
                <div>
                  {offerAssets.size > 0
                    ? `${offerAssets.size}/20 Items`
                    : 'Liquid Offer Items'}
                </div>
                <div>
                  <DaisyButton
                    size="btn-xs"
                    colorName="btn-ghost"
                    label="Clear"
                    onClick={() => {
                      clearOffers();
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-wrap justify-center flex-row gap-3 mt-4 overflow-y-scroll h-full pb-4">
                <div className="grid grid-cols-10 gap-4 mb-6 w-full mx-2 mr-24 md:mx-24">
                  {Array.from(Array(20).keys()).map((index) => {
                    const asset = Array.from(offerAssets.keys())[index];
                    const assetTitle = asset?.title ?? '';
                    const o = offerAssets.get(asset);
                    return (
                      <Tooltip
                        content={`Asset Offer - ₳${(
                          (o || 0) / 1_000_000
                        ).toLocaleString()}`}
                        key={index}
                      >
                        <div
                          className="relative bg-gray-200 dark:bg-base-100 rounded-lg border border-dashed flex items-center justify-center w-[5rem] border-gray-400 "
                          onClick={() => deleteAssetOffer(asset)}
                        >
                          <div className="absolute w-full h-full top-0 flex items-center justify-center px-2 text-xs z-10 text-center font-bold">
                            {assetTitle !== '' ? assetTitle : `?`}
                          </div>
                          {!!asset && (
                            <div className="relative w-full h-full">
                              <NextImage
                                src={asset?.thumbnailUrl}
                                b64={asset?.thumbnailB64}
                                priority={false}
                                width={180}
                                className="object-cover rounded-lg"
                              />
                              <div className="absolute top-0 w-full h-full bg-black/30"></div>
                            </div>
                          )}
                        </div>
                      </Tooltip>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="w-50 items-end flex flex-col justify-end h-full gap-4">
              <div className="flex gap-4 items-end">
                <span className="flex flex-col">
                  <DaisyButton
                    size="btn-md"
                    colorName="btn-accent"
                    label="Close"
                    classNames="mt-2"
                    onClick={() => {
                      doClose();
                    }}
                  />
                </span>

                <div className="flex flex-col justify-end items-end gap-3">
                  <div className="text-4xl font-bold">
                    ₳{(offerAdaAmount || 0) / 1_000_000}
                  </div>
                  <DaisyButton
                    label="Make Offer"
                    colorName="btn-secondary"
                    isDisabled={txInProgress || offerAssets.size < 1}
                    working={txInProgress}
                    workingLabel={txState}
                    onClick={() => processOffer()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const selectTxState = (state: OfferUIStore) => state.txState;
const selectTxInProgress = (state: OfferUIStore) => state.txInProgress;
