import { Fragment, useEffect, useState } from 'react';
import { FC } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Divider } from 'components/Divider/Divider';
import { Asset } from '#types/index';
import { useNotifier } from 'react-headless-notifier';
import DaisyButton from '#components/Daisy/DaisyButton';
import { DaisyTextInput } from '#components/Daisy/DaisyTextInput';
import { NextImage } from '../NextImage';
import { useOfferUIStore, selectSetAssetOffer  } from '../AssetPage/MarketActionOffers/OfferUIStore';

type ButtonType = 'Button' | 'Icon';
export interface EnterPolicyProps {
  asset: Asset;
  open: boolean;
  floor?: number;
  assetAdded: (asset: Asset) => void;
}

export const AddOfferModal: FC<EnterPolicyProps> = ({
  asset,
  open,
  floor = 100,
  assetAdded,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const [price, setPrice] = useState<number | undefined>(floor);

  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => setIsOpen(open), [open]);

  const addAssetOffer = useOfferUIStore(selectSetAssetOffer);
  const isReadyToCreate = () => !!price;
  const { notify } = useNotifier();

  const setOfferPrice = (percent: number) => {
    setPrice(floor * 1000000 * (1 - percent));
  };

  useEffect(() => setOfferPrice(0.0), [floor]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment} static>
        <Dialog
          as="div"
          open={isOpen}
          className="fixed inset-0 z-40 overflow-y-auto transition-height duration-150 transform"
          onClose={() => setIsOpen(false)}
        >
          <div className="min-h-screen px-4 text-center transition-height duration-150 transform">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 backdrop-filter backdrop-blur-xl backdrop-brightness-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block dark:bg-base-300 w-full max-w-xl p-9 my-12 overflow-hidden text-left align-middle  bg-white shadow-xl rounded-2xl transition-height duration-150 transform">
                <div className="flex flex-row justify-between">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-bold leading-6 text-gray-800 dark:text-white flex items-center gap-3"
                  >
                    Set Offer Price
                  </Dialog.Title>
                </div>
                <div className="mt-4">
                  <Divider />
                </div>

                <div className="w-full flex flex-col gap-6 mb-6 justify-center mt-4">
                  <div className="flex items-end gap-4">
                    <div className="w-24 h-24 relative rounded-xl flex-none">
                      <NextImage
                        src={asset.thumbnailUrl}
                        layout={'fill'}
                        width={100}
                        priority={false}
                        className="object-cover rounded-xl"
                        b64={asset.thumbnailB64}
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <p className="font-normal text-lg">
                        {asset.algoliaProjectFacet &&
                          JSON.parse(asset.algoliaProjectFacet).title}
                      </p>
                      <p className="font-bold text-lg">{asset.title}</p>
                    </div>
                  </div>
                  <div className="flex-1">
                    <DaisyTextInput
                      id="policy"
                      label=""
                      colorName="input-secondary"
                      placeholder="Offer a price"
                      size="input-lg"
                      labelPosition="inside"
                      value={price && price.toFixed(0)}
                      onChange={(price) => setPrice(Number.parseInt(price))}
                    />
                  </div>
                  <div className="flex flex-row gap-2">
                    <DaisyButton
                      label="1%"
                      classNames="flex-1"
                      onClick={() => setOfferPrice(0.01)}
                    />
                    <DaisyButton
                      label="2%"
                      classNames="flex-1"
                      onClick={() => setOfferPrice(0.02)}
                    />
                    <DaisyButton
                      label="5%"
                      classNames="flex-1"
                      onClick={() => setOfferPrice(0.05)}
                    />
                    <DaisyButton
                      label="10%"
                      classNames="flex-1"
                      onClick={() => setOfferPrice(0.1)}
                    />
                    <DaisyButton
                      label="15%"
                      classNames="flex-1"
                      onClick={() => setOfferPrice(0.15)}
                    />
                    <DaisyButton
                      label="25%"
                      classNames="flex-1"
                      onClick={() => setOfferPrice(0.25)}
                    />
                    <DaisyButton
                      classNames="flex-1"
                      label="50%"
                      onClick={() => setOfferPrice(0.5)}
                    />
                  </div>
                  <div>
                    {' '}
                    <span className="font-normal dark:text-gray-400 text-gray-600">
                      Current Floor:{' '}
                    </span>
                    <span className="text-xl font-bold">₳{floor * 1000000}</span>
                  </div>

                  <div className="flex-1 flex flex-col gap-2">
                    {' '}
                    <DaisyButton
                      label={'Add to Offer'}
                      classNames="flex-1"
                      colorName="btn-secondary"
                      isDisabled={!isReadyToCreate()}
                      onClick={() => {
                        addAssetOffer(asset, (price || 0) * 1_000_000);
                        assetAdded(asset);
                      }}
                    />
                    <DaisyButton
                      label="Cancel"
                      colorName="btn-ghost"
                      size="btn-sm"
                      classNames="flex-1"
                      onClick={() => setIsOpen(false)}
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
