import { Asset } from '#types/index';
import Link from 'next/link';
import cn from 'classnames';
import { VerifiedCollectionPopoverSmall } from '#components/Tooltip/VerifiedCollectionPopoverSmall';
import { NextImage } from '#components/Assets/NextImage';
import React from 'react';

interface NativeAssetTableProps {
  assets?: Asset[];
  minify?: boolean;
}

export function NativeAssetTable({
  assets,
  minify = false,
}: NativeAssetTableProps) {
  return (
    <div className="mt-6 flex flex-col w-full justify-start">
      <div className="-my-2   w-full">
        <div className="flex w-full k min-w-full py-2 align-middle ">
          <div className="w-full ring-1 ring-black ring-opacity-5 md:rounded-lg dark:border-gray-700/25 border-gray-200/25 border">
            <div className="w-full flex md:hidden flex-col">
              <div className="w-full flex justify-between bg-gray-50 dark:bg-gray-900 dark:text-gray-300">
                <div className="py-3.5 pl-5 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-100 sm:pl-6">
                  Asset
                </div>
                <div className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-100 pr-5">
                  Price
                </div>{' '}
              </div>
              <div className="w-full flex flex-col bg-white dark:bg-gray-900/25">
                {assets?.map((asset, idx) => (
                  <div
                    key={asset.id || asset.title + idx}
                    className="flex justify-between items-center"
                  >
                    <div className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                      <Link href={`/nft/${asset.asset_id}`}>
                        <a className="flex flex-row gap-4 items-center">
                          <div
                            className={cn(
                              'relative rounded-xl overflow-hidden shadow-md hover:scale-150 duration-200 transition-transform',
                              { 'w-12 h-12': minify },
                              { 'h-16 w-16': !minify }
                            )}
                          >
                            <NextImage
                              src={
                                asset.thumbnailUrl ||
                                'https://media.dropspot.io/placeholders%2FNo-Image-Placeholder.svg.png'
                              }
                              width={300}
                              height={300}
                              quality={1.0}
                              b64={asset.thumbnailB64}
                              priority={true}
                              className={
                                'object-cover object-top shadow-2xl rounded-lg'
                              }
                            />
                          </div>
                          <div className="flex flex-col justify-center">
                            <h3 className="text-base font-bold text-gray-600 dark:text-gray-100">
                              {asset.title}
                            </h3>
                            <div className=" flex gap-1 items-center h-full">
                              <VerifiedCollectionPopoverSmall
                                isVerified={true}
                              />
                              <span className="dark:text-gray-200">
                                {asset.algoliaProjectFacet &&
                                  JSON.parse(asset.algoliaProjectFacet).title}
                              </span>
                            </div>
                          </div>
                        </a>
                      </Link>
                    </div>

                    {/* <div
                      className={cn(
                        'whitespace-nowrap px-3 py-4 text-sm text-gray-200',
                        { hidden: minify }
                      )}
                    >
                      <div className="flex flex-row gap-2 items-center">
                        <span className="px-2 py-1 bg-blue-500 rounded-full text-sm text-white">
                          Buy Now
                        </span>
                      </div>
                    </div> */}
                    <div
                      className={cn(
                        'whitespace-nowrap px-3 py-4 dark:text-gray-200 font-bold'
                      )}
                    >
                      <div className="flex flex-row gap-2 items-center pr-2">
                        {asset.listed_price ? (
                          <span>
                            ₳{(asset.listed_price / 1_000_000).toFixed(0)}
                          </span>
                        ) : asset.price ? (
                          <span>₳{(asset.price / 1_000_000).toFixed(0)}</span>
                        ) : (
                          <span> </span>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="hidden md:flex w-full ">
              <table className="min-w-full ">
                <thead
                  className={cn(
                    'bg-gray-50 dark:bg-gray-900 dark:text-gray-300',
                    { hidden: minify == true }
                  )}
                >
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-100 sm:pl-6"
                    >
                      Asset
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-100"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-100"
                    >
                      Price
                    </th>
                  </tr>
                </thead>
                <tbody className="dark:bg-gray-900/25 bg-white ">
                  {assets?.map((asset, idx) => (
                    <React.Fragment key={asset.id || asset.title + idx}>
                      <tr>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                          <Link href={`/nft/${asset.asset_id}`}>
                            <a className="flex flex-row items-end gap-4 items-center">
                              <div
                                className={cn(
                                  'relative rounded-xl overflow-hidden shadow-md hover:scale-150 duration-200 transition-transform',
                                  { 'w-12 h-12': minify },
                                  { 'h-16 w-16': !minify }
                                )}
                              >
                                <NextImage
                                  src={
                                    asset.thumbnailUrl ||
                                    'https://media.dropspot.io/placeholders%2FNo-Image-Placeholder.svg.png'
                                  }
                                  width={300}
                                  height={300}
                                  quality={1.0}
                                  b64={asset.thumbnailB64}
                                  priority={true}
                                  className={
                                    'object-cover object-top shadow-2xl rounded-lg'
                                  }
                                />
                              </div>
                              <div className="flex flex-col justify-center">
                                <h3 className="text-base font-bold text-gray-600 dark:text-gray-100">
                                  {asset.title}
                                </h3>
                                <div className=" flex gap-1 items-center h-full">
                                  <VerifiedCollectionPopoverSmall
                                    isVerified={true}
                                  />
                                  <span className="dark:text-gray-200">
                                    {asset.algoliaProjectFacet &&
                                      JSON.parse(asset.algoliaProjectFacet)
                                        .title}
                                  </span>
                                </div>
                              </div>
                            </a>
                          </Link>
                        </td>

                        <td
                          className={cn(
                            'whitespace-nowrap px-3 py-4 text-sm text-gray-200',
                            { hidden: minify }
                          )}
                        >
                          <div className="flex flex-row gap-2 items-center">
                            <span className="px-2 py-1 bg-blue-500 rounded-full text-sm text-white">
                              Buy Now
                            </span>
                          </div>
                        </td>
                        <td
                          className={cn(
                            'whitespace-nowrap px-3 py-4 dark:text-gray-200 font-bold'
                          )}
                        >
                          <div className="flex flex-row gap-2 items-center">
                            {asset.listed_price ? (
                              <span>
                                ₳{(asset.listed_price / 1_000_000).toFixed(0)}
                              </span>
                            ) : asset.price ? (
                              <span>
                                ₳{(asset.price / 1_000_000).toFixed(0)}
                              </span>
                            ) : (
                              <span> </span>
                            )}
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
