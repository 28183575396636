import {
  getTop100_24HR,
  getTop100_30D,
  getTop100_7D,
  getTop100_All,
} from '#lib/firestore';
import { NativeAssetRankings } from '#types/index';
import { useEffect, useState } from 'react';

type TrendingCollectionsProps = {
  ssrTop24HR?: NativeAssetRankings[];
  ssrTop7D?: NativeAssetRankings[];
  ssrTop30D?: NativeAssetRankings[];
  ssrTopALL?: NativeAssetRankings[];
};

export const useTrendingCollections = ({
  ssrTop24HR,
  ssrTop7D,
  ssrTop30D,
  ssrTopALL,
}: TrendingCollectionsProps) => {
  const [assetRankings24, setAssetRankings24] = useState<
    NativeAssetRankings[] | undefined
  >(ssrTop24HR);

  const [assetRankings7D, setAssetRankings7D] = useState<
    NativeAssetRankings[] | undefined
  >(ssrTop7D);

  const [assetRankings30D, setAssetRankings30D] = useState<
    NativeAssetRankings[] | undefined
  >(ssrTop30D);

  const [assetRankingsALL, setAssetRankingsALL] = useState<
    NativeAssetRankings[] | undefined
  >(ssrTopALL);

  const [loadingRankings, setLoadingRankings] = useState(true);

  useEffect(() => {
    console.log('get rankings');
    getTop100_24HR().then((c) => setAssetRankings24(c));
    setLoadingRankings(false);

    getTop100_7D().then((c) => setAssetRankings7D(c));
    getTop100_30D().then((c) => setAssetRankings30D(c));
    getTop100_All().then((c) => setAssetRankingsALL(c));
  }, []);

  return {
    assetRankings7D,
    assetRankings24,
    assetRankings30D,
    assetRankingsALL,
    loadingRankings,
  };
};
