import CoinGecko from 'coingecko-api';
import Dayjs from 'dayjs';
import { useEffect } from 'react';
import { create } from 'zustand';

const CoinGekoCurrencies = ['eur', 'usd', 'aud', 'gbp'] as const;
export type CoinGeckoPrices = Record<typeof CoinGekoCurrencies[number], number>;

type CoinGeckoStore = {
  loading: boolean;
  cgDate?: Dayjs.Dayjs;
  coinGeckoPrice?: CoinGeckoPrices;
  reload: () => Promise<void>;
};
const useGoinGeckoStore = create<CoinGeckoStore>()((set, get) => ({
  loading: false,
  reload: async () => {
    console.log('useCoinGecko reloading coingecko');

    if (get().loading)
      return;

    set((s) => ({ ...s, loading: true }));
    const CoinGeckoClient = new CoinGecko();
    const simplePrice = await CoinGeckoClient.simple.price({
      ids: ['cardano'],
      vs_currencies: [...CoinGekoCurrencies],
    });

    console.log('useCoinGecko reloading coingecko - complete');
    set((s) => ({
      ...s,
      loading: false,
      coinGeckoPrice: simplePrice.data.cardano,
      cgDate: Dayjs(),
    }))

  },
}))

const stateSelector = (state: CoinGeckoStore) => ({
  cgDate: state.cgDate,
  coinGeckoPrice: state.coinGeckoPrice,
  loading: state.loading,
});
const reloadSelector = (state: CoinGeckoStore) => state.reload;

export const useCoinGecko = () => {
  const { cgDate, coinGeckoPrice, loading } = useGoinGeckoStore(stateSelector);
  const reload = useGoinGeckoStore(reloadSelector);

  useEffect(() => {
    console.log('useCoinGecko - effect', cgDate, loading);
    if (!cgDate) {
      if (!loading) {
        reload();
      }
    }
    else if (Dayjs().diff(cgDate, 'minute') > 5) {
      reload();
    }
  }, [cgDate, loading, reload]);

  return {
    cgDate: cgDate ? cgDate.format('MMM D, YYYY h:mm A') : undefined,
    coinGeckoPrice,
    loading,
    reload
  };
};
