import { useState, useEffect } from 'react';
import {
  Asset,
  FloorHistory,
  HolderDistribution,
  HolderHistory,
  HolderHistoryItem,
  LiveTransaction,
  SaleHistory,
} from '#types/index';
import {
  getAllLiveTransactionsListener,
  getAssetsListener,
  getFloorHistory,
  getHolderDistribution,
  getHolderHistory,
  getListedAssetsListener,
  getListedProjectAssets,
  getLiveTransactions,
  getLiveTransactionsListener,
  getLiveTransactionsListenerByType,
  getSaleHistory,
} from '#lib/firestore';
import { triggerAsyncId } from 'async_hooks';

type useProjectAssetsProps = {
  projectId?: string;
  initialAssets?: Asset[];
  limit?: number;
  listedOnly?: boolean;
};

export const useProjectAssets = ({
  initialAssets,
  projectId,
  limit = 12,
  listedOnly = false,
}: useProjectAssetsProps) => {
  const [assets, setAssets] = useState<Asset[] | undefined>(initialAssets);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!projectId) {
      setLoading(false);
      setAssets(undefined);
      return;
    }

    setLoading(true);

    if (!!listedOnly) {
      return getListedAssetsListener(projectId, limit, (data) => {
        setAssets(data);
        setLoading(false);
      });
    }
    return getAssetsListener(projectId, limit, (data) => {
      setAssets(data);
      setLoading(false);
    }); // Return the result of this so that we can clean up if / when we need to.
  }, [limit, listedOnly, projectId]);

  return { assets, loading };
};

export const useProjectListedAssets = (
  projectId?: string,
  limit?: number,
  ssrListedAssets?: Asset[],
  priceOrder?: string
) => {
  const [listedAssets, setListedAssets] = useState<Asset[] | undefined>(
    ssrListedAssets
  );
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!projectId) return;
    getListedProjectAssets(projectId, limit).then((data) => {
      setListedAssets(data);
      setLoading(false);
    });
  }, [limit, projectId]);
  return {
    listedAssets,
    loading,
  };
};

export const useProjectFloorHistory = (projectId: string) => {
  const [floorHistory, setFloorHistory] = useState<FloorHistory | undefined>();
  const [loadingFloorHistory, setLoadingFloorHistory] = useState(true);
  useEffect(() => {
    getFloorHistory(projectId).then((data) => {
      if (data) {
        setFloorHistory(data);
      }
      setLoadingFloorHistory(false);
    });
  }, [projectId]);
  return {
    floorHistory,
    loadingFloorHistory,
  };
};

export const usePolicyLiveTransactions = (policy?: string) => {
  const [liveTransactions, setLiveTransactions] = useState<
    LiveTransaction[] | undefined
  >();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!policy) return;
    getLiveTransactions(policy).then((data) => {
      setLiveTransactions(data);
      setLoading(false);
    });
  }, [policy]);
  return {
    liveTransactions,
    loading,
  };
};

export const usePolicyLiveTransactionListener = (walletAddress?: string) => {
  const [liveTransactions, setLiveTransactions] = useState<
    LiveTransaction[] | undefined
  >();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!walletAddress) {
      setLoading(false);
      return;
    }
    console.log('in live transaction listener wallet: ', walletAddress);
    return getLiveTransactionsListener(walletAddress, 25, (data) => {
      setLiveTransactions(data);
      setLoading(false);
    });
  }, [walletAddress]);
  return {
    liveTransactions,
    loading,
  };
};

export const useLiveAllTransactionListener = (
  policy?: string,
  limit?: number
) => {
  const [liveTransactions, setLiveTransactions] = useState<
    LiveTransaction[] | undefined
  >();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (policy) {
      return getLiveTransactionsListener(policy, limit || 25, (data) => {
        setLiveTransactions(data);
        setLoading(false);
      });
    }
    return getAllLiveTransactionsListener((data) => {
      setLiveTransactions(data);
      setLoading(false);
    });
  }, [limit, policy]);
  return {
    liveTransactions,
    loading,
  };
};

export const useLiveTransactionsByTypeListener = (
  type: 'sale' | 'listed' | 'all',
  limit?: number
) => {
  const [liveTransactions, setLiveTransactions] = useState<
    LiveTransaction[] | undefined
  >();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    return getLiveTransactionsListenerByType(type, limit || 25, (data) => {
      setLiveTransactions(data);
      setLoading(false);
    });
  }, [limit, type]);
  return {
    liveTransactions,
    loading,
  };
};

export const useProjectHolderHistory = (projectId?: string) => {
  const [holderHistory, setHolderHistory] = useState<
    HolderHistory | undefined
  >();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!projectId) return;
    getHolderHistory(projectId).then((data) => {
      if (data) {
        setHolderHistory(data);
      }
      setLoading(false);
    });
  }, [projectId]);
  return {
    holderHistory,
    loading,
  };
};

export const useProjectSaleHistory = (
  projectId: string,
  ssrSalesHistory?: SaleHistory
) => {
  const [saleHistory, setSaleHistory] = useState<SaleHistory | undefined>(
    ssrSalesHistory
  );
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getSaleHistory(projectId).then((data) => {
      if (data) {
        setSaleHistory(data);
      }
      setLoading(false);
    });
  }, [projectId]);
  return {
    saleHistory,
    loading,
  };
};
