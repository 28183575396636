import { Asset, NativeAssetRankings, Project } from '#types/index';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useAuthUser } from 'next-firebase-auth';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import {
  ArrowPathIcon,
  BarsArrowDownIcon,
  ComputerDesktopIcon,
  CubeTransparentIcon,
  LinkIcon,
  MusicalNoteIcon,
  PaintBrushIcon,
  TrophyIcon,
} from '@heroicons/react/24/solid';
import cn from 'classnames';
import { useDegenStore } from '#lib/hooks/useGlobalMode';
import { NextRouter, useRouter } from 'next/router';
import {
  connectCurrentRefinements,
  InstantSearch,
} from 'react-instantsearch-dom';
import { ClearRefinementsHeadless } from '#components/Search/ClearRefinementsHeadless';
import { LiveTransactionsAll } from '#components/Projects/LiveTransactionsAll';
import { getInfiniteHitsComponent } from '#components/Search/infiniteHits';
import {
  BoltIcon,
  BoltSlashIcon,
  ChevronRightIcon,
  SwatchIcon,
  TagIcon,
} from '@heroicons/react/24/outline';
import { NextImage } from '#components/Assets/NextImage';
import { Disclosure } from '@headlessui/react';
import { Checkbox } from '#components/Inputs/Checkbox/Checkbox';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useProjectTraits } from '#lib/hooks/useProjectTraits';
import DaisyButton from '#components/Daisy/DaisyButton';
import { useTrendingCollections } from '#lib/hooks/useTrendingCollections';
import { FirestoreQuery, FirestoreQueryItem } from '#lib/firestore';
import {
  DSInfiniteGrid,
  DSInfiniteGridHandle,
} from '#components/DataHandler/DSInfiniteGrid';
import { ProjectItem, useProjectItems } from '#lib/hooks/useProject';
import { ProjectCardPublic } from '#components/Projects/ProjectCardPublic';
import { AssetItem } from '#lib/hooks/useAsset';
import { useAssetItems } from '#lib/hooks/useAsset';
import { AssetCardMemo } from '#components/Assets/AssetFlipCard/AssetFlipCardPublic';
import { MarketTop12 } from 'pages/trending';
import LiveTransactionsSlideOut from './Live/LiveTransactionsSlideOut';
import HitProject from '#components/Search/HitProject';
export interface ProjectCardPublicProps {
  project: Project;
}
const DEFAULT_QUERY_X = {
  desc: true,
  orderBy: 'creationDate',
  whereClauses: [
    { fieldPath: 'status', opStr: '==', value: 'Minted' },
  ] as FirestoreQueryItem[],
};

function assetColumnCalc(width: number): number {
  if (width < 400) {
    return 1;
  }

  if (width < 600) {
    return 2;
  }

  return 5;
}

function projectColumnCalc(width: number): number {
  if (width < 400) {
    return 1;
  }

  if (width < 600) {
    return 2;
  }

  return 5;
}

type HomeProps = {
  heroCollections?: Project[];
  featuredCollections?: Project[];
  marketCollections?: Project[];
  trendingCollections?: Project[];
  featuredAssets?: Asset[];
  marketTop10?: NativeAssetRankings[];
};

const InfiniteHitsProject = getInfiniteHitsComponent<Project>();
dayjs.extend(relativeTime);

const searchClientM = instantMeiliSearch(
  'https://ms-29d8f4a738e8-3235.sfo.meilisearch.io',
  'c4a76a214cb7409dabed53758a96da0e214761429145d8735aa52746058434a9'
);

const TraderHomePage = ({ marketTop10 }: HomeProps) => {
  const { id, photoURL, displayName } = useAuthUser();

  const {
    assetRankings24,
    assetRankings7D,
    assetRankings30D,
    assetRankingsALL,
  } = useTrendingCollections({
    ssrTop24HR: marketTop10,
  });

  const displayedRankings = useState<NativeAssetRankings[] | undefined>(
    assetRankings24
  );

  const selectedProject = useDegenStore((state) => state.selectedCollection);
  const { push } = useRouter();
  const CustomClearRefinements = connectCurrentRefinements(
    ClearRefinementsHeadless
  );
  const selectTheProject = useDegenStore(
    (state) => state.setSelectedCollection
  );
  const [sideBarShown, setSideBarShown] = useState(false);
  const [movementBarShown, setMovementBarShown] = useState(false);
  const [viewMode, setViewMode] = useState('GRID');
  const containerRef = React.useRef<HTMLDivElement>(null);
  const assetInfiniteGridRef = React.useRef<DSInfiniteGridHandle>(null);
  const projectInfiniteGridRef = React.useRef<DSInfiniteGridHandle>(null);
  const [rankingsMode, setRankingsMode] = useState<
    '24H' | '7D' | '30D' | 'ALL'
  >('24H');

  const [isInTrendingMode, setIsInTrendingMode] = useState<boolean>(false);
  // useEffect(() => {
  //   if (rankingsMode === '24D') {
  //     setRankingsMode('24D');
  //   } else if (rankingsMode === '7D') {
  //     setRankingsMode('7D');
  //   } else if (rankingsMode === '30D') {
  //     setRankingsMode('30D');
  //   } else if (rankingsMode === 'ALL') {
  //     setRankingsMode('ALL');
  //   }
  // }, [rankingsMode]);

  type collectionCategory = {
    name: string;
    category: string;
    filter?: string;
    icon: React.Component;
  };

  const [gridSmoothLoading, setGridSmoothLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedSearchMode, setSelectedSearchMode] = useState<
    'COLLECTION' | 'ASSET'
  >('COLLECTION');

  const [currentFirestoreQuery, setCurrentFirestoreQuery] =
    useState<FirestoreQuery>(DEFAULT_QUERY_X);

  const { hasMoreData, loadBatch, loading, items } = useProjectItems({
    query: currentFirestoreQuery,
  });
  const assetItems = useAssetItems({
    query: currentFirestoreQuery,
    ssrAssetItems: undefined,
  });

  useEffect(() => {
    setGridSmoothLoading(true);
    setTimeout(() => setGridSmoothLoading(false), 300);
    console.log('SEARCH QUERY CHANGED ');
    if (selectedSearchMode === 'COLLECTION') {
      console.log('SEARCH QUERY CHANGED - Collection ');
      if (projectInfiniteGridRef.current) {
        projectInfiniteGridRef.current.refreshCache();
        console.log('SEARCH QUERY CHANGED - Cache refresh called. ');
      }
    } else {
      if (assetInfiniteGridRef.current) {
        assetInfiniteGridRef.current.refreshCache();
        console.log('SEARCH QUERY CHANGED - Cache refresh called. ');
      }
    }
  }, [currentFirestoreQuery, selectedSearchMode]);

  return (
    <div
      className="flex overflow-hidden w-full pt-[5rem] "
      style={{ height: 'calc(100vh)' }}
    >
      {/* Main area */}
      <InstantSearch indexName="Project" searchClient={searchClientM}>
        <main className="min-w-0 flex-1 border-gray-200 dark:border-gray-700 lg:flex">
          <div className="relative w-full ">
            <div className="z-20 absolute top-0 flex items-center justify-between">
              <div className="flex w-full items-center px-10 mt-2 gap-3">
                <div className="flex gap-2 items-center my-2">
                  {/* <DaisyButton
                    label={'Trending'}
                    colorName={isInTrendingMode ? 'btn-secondary' : 'btn-ghost'}
                    classNames=""
                    onClick={() => {
                      setIsInTrendingMode(!sideBarShown);
                      setCurrentFirestoreQuery(DEFAULT_QUERY_X);
                    }}
                  /> */}
                  {/* <CategoryButtons
                    searchMode={selectedSearchMode}
                    firestoreQuery={currentFirestoreQuery}
                    setSearchQuery={(q) => {
                      setCurrentFirestoreQuery(q);
                      setIsInTrendingMode(false);
                      console.log('set search query', q);
                    }}
                  /> */}
                </div>
              </div>
            </div>

            {!!isInTrendingMode && (
              <div
                className={cn(
                  'px-8 pb-12 mt-[5rem]  h-full w-full duration-150  ',
                  {
                    'brightness-90 blur-sm filter ': gridSmoothLoading,
                  }
                )}
                ref={containerRef}
              >
                <MarketTop12
                  rankings24H={assetRankings24}
                  rankings7D={assetRankings24}
                  rankings30D={assetRankings24}
                  rankingsALL={assetRankings24}
                />
              </div>
            )}
            {!isInTrendingMode && (
              <div
                className={cn(
                  'px-6 mt-[5rem] pb-8 h-full w-full duration-150  ',
                  {
                    'brightness-90 blur-sm filter ': gridSmoothLoading,
                  }
                )}
                ref={containerRef}
              >
                <InfiniteHitsProject
                  containerClassNames="grid xl:grid-cols-3 2xl:grid-cols-5 gap-8 px-4 sm:px-0"
                  render={(hit) => (
                    <div
                      key={hit.tokenPrefix}
                      className="ais-InfiniteHits-item bg-white shadow-xl hover:shadow-2xl rounded-xl overflow-hidden h-[30rem] sm:h-[32rem] transition transform duration-200 cursor-pointer"
                    >
                      <HitProject project={hit} />
                    </div>
                  )}
                />
                {/* {selectedSearchMode === 'COLLECTION' ? (
                  <DSInfiniteGrid<ProjectItem>
                    hasMoreData={hasMoreData}
                    items={items}
                    igRef={projectInfiniteGridRef}
                    windowSize={16}
                    loadBatch={loadBatch}
                    containerRef={containerRef}
                    calculateColumns={projectColumnCalc}
                    loading={loading}
                    Component={(item) => {
                      // console.log('ILT: ITEM:', item);
                      return (
                        <ProjectCardPublic
                          project={item.item}
                          heightClass="h-full"
                          path="marketplace"
                          avoidB64={true}
                        />
                      );
                    }}
                    rowHeight={600}
                  />
                ) : (
                  <DSInfiniteGrid<AssetItem>
                    hasMoreData={assetItems.hasMoreData}
                    igRef={assetInfiniteGridRef}
                    items={assetItems.items}
                    windowSize={24}
                    loadBatch={assetItems.loadBatch}
                    containerRef={containerRef}
                    loading={assetItems.loading}
                    calculateColumns={assetColumnCalc}
                    Component={(item) => {
                      //  console.log('ILT: ITEM:', item);
                      return (
                        <AssetCardMemo
                          key={item.item.id}
                          asset={item.item}
                          link={''}
                          heightClass={'h-[24rem]'}
                        />
                      );
                    }}
                    rowHeight={420}
                  />
                )} */}
              </div>
            )}
          </div>
          <LiveTransactionsSlideOut
            policy={selectedProject?.policy}
            doClose={() => {
              console.log('do close');
              setMovementBarShown(movementBarShown ? false : true);
            }}
          />
        </main>
      </InstantSearch>
    </div>
  );
};

export const MemoisedTraderHomePage = React.memo(TraderHomePage);

const CategoryButtons = ({
  searchMode,
  firestoreQuery,
  setSearchQuery,
}: MultiObjectSearchBarProps) => {
  const categoryButtons = [
    {
      name: 'Art',
      category: 'art',
      filter: '1:1',
      icon: <PaintBrushIcon className="w-5 h-5 text-gray-400" />,
    },
    {
      name: 'On-chain',
      category: 'on-chain',
      filter: 'on-chain',
      icon: <LinkIcon className="w-5 h-5 text-gray-400" />,
    },
    {
      name: 'Gaming',
      category: 'gaming',
      filter: 'gaming',
      icon: <ComputerDesktopIcon className="w-5 h-5 text-gray-400" />,
    },
    {
      name: 'Metaverse',
      category: 'metaverse',
      filter: 'Metaverse',
      icon: <CubeTransparentIcon className="w-5 h-5 text-gray-400" />,
    },
    {
      name: 'Photography',
      category: 'photography',
      filter: 'photography',
      icon: <CubeTransparentIcon className="w-5 h-5 text-gray-400" />,
    },
    {
      name: 'Music',
      category: 'music',
      filter: 'Music',
      icon: <MusicalNoteIcon className="w-5 h-5 text-gray-400" />,
    },
  ];
  const defaultQuery = firestoreQuery
    ? firestoreQuery
    : searchMode == 'COLLECTION'
    ? {
        orderBy: 'creationDate',
        desc: true,
        whereClauses: [{ fieldPath: 'status', opStr: '==', value: 'Minted' }],
      }
    : {
        orderBy: 'creationDate',
        desc: true,
        whereClauses: [{ fieldPath: 'status', opStr: '==', value: 'Minted' }],
      };

  const [query, setQuery] = useState<FirestoreQuery>(
    defaultQuery as FirestoreQuery
  );
  const getCategory = () => {
    const category = query?.whereClauses?.find(
      (clause) => clause.fieldPath === 'categories'
    ) as FirestoreQueryItem;
    if (!category) return 'Featured';
    const val = Array(category.value);
    const value = val[0] as string;
    return value;
  };

  useEffect(() => {
    firestoreQuery && setQuery(firestoreQuery);
  }, [firestoreQuery]);

  const setCategory = (category: string) => {
    //TODO: set the category
    console.log('set category: ', category);
    const newWhereClause = [
      {
        fieldPath: 'categories',
        opStr: 'array-contains-any',
        value: [category],
      },
      { fieldPath: 'status', opStr: '==', value: 'Minted' },
    ] as FirestoreQueryItem[];

    const newQuery = {
      orderBy: query.orderBy,
      desc: query?.desc,
      whereClauses: newWhereClause,
    } as FirestoreQuery;

    console.log(JSON.stringify(newQuery));

    setSearchQuery(newQuery);
    setQuery(newQuery);

    console.log('new query: ', newQuery);
  };
  return (
    <div className="flex flex-row items-center gap-2">
      {categoryButtons.map((c) => (
        <React.Fragment key={c.category}>
          <DaisyButton
            label={c.name}
            colorName={
              c.category == getCategory() ? 'btn-secondary' : 'btn-ghost'
            }
            classNames=""
            onClick={() => setCategory(c.category)}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

type MultiObjectSearchBarProps = {
  searchMode: 'COLLECTION' | 'ASSET';
  firestoreQuery?: FirestoreQuery;
  setSearchQuery: (firestoreQuery: FirestoreQuery) => void;
};

type FirestoreSort = {
  name: string;
  fieldName: string;
  desc: boolean;
};

const MultiObjectSearchBar = ({
  searchMode,
  firestoreQuery,
  setSearchQuery,
}: MultiObjectSearchBarProps) => {
  const defaultQuery = firestoreQuery
    ? firestoreQuery
    : searchMode == 'COLLECTION'
    ? {
        orderBy: 'creationDate',
        desc: true,
        whereClauses: [{ fieldPath: 'status', opStr: '==', value: 'Minted' }],
      }
    : {
        orderBy: 'creationDate',
        desc: true,
        whereClauses: [{ fieldPath: 'status', opStr: '==', value: 'Minted' }],
      };

  const [query, setQuery] = useState<FirestoreQuery>(
    defaultQuery as FirestoreQuery
  );

  useEffect(() => {
    setQuery(
      firestoreQuery
        ? firestoreQuery
        : searchMode == 'COLLECTION'
        ? {
            orderBy: 'creationDate',
            desc: true,
            whereClauses: [
              { fieldPath: 'status', opStr: '==', value: 'Minted' },
            ],
          }
        : {
            orderBy: 'creationDate',
            desc: true,
            whereClauses: [
              { fieldPath: 'status', opStr: '==', value: 'Minted' },
            ],
          }
    );
  }, [firestoreQuery, searchMode]);

  const resetQuery = () => {
    setQuery(defaultQuery as FirestoreQuery);
    setSearchQuery(defaultQuery as FirestoreQuery);
  };

  const setSort = (sort: FirestoreSort) => {
    setQuery({
      ...query,
      orderBy: sort.fieldName,
      desc: sort.desc,
    });
    setSearchQuery({
      ...query,
      orderBy: sort.fieldName,
      desc: sort.desc,
    });
  };

  const getCategory = () => {
    const category = query?.whereClauses?.find(
      (clause) => clause.fieldPath === 'categories'
    ) as FirestoreQueryItem;
    if (!category) return 'All';
    const val = Array(category.value);
    const value = val[0] as string;
    return value;
  };

  const setCategory = (category: string) => {
    //TODO: set the category
    console.log('set category: ', category);
    const newWhereClause = [
      {
        fieldPath: 'categories',
        opStr: 'array-contains-any',
        value: [category],
      },
      { fieldPath: 'status', opStr: '==', value: 'Minted' },
    ] as FirestoreQueryItem[];

    const newQuery = {
      orderBy: query.orderBy,
      desc: query?.desc,
      whereClauses: newWhereClause,
    } as FirestoreQuery;

    console.log(JSON.stringify(newQuery));

    setSearchQuery(newQuery);
    setQuery(newQuery);

    console.log('new query: ', newQuery);
  };

  const assetSortFields: FirestoreSort[] = [
    {
      name: 'Price: low to high',
      fieldName: 'price',
      desc: false,
    },
    {
      name: 'Price: high to low',
      fieldName: 'price',
      desc: true,
    },
  ];
  const collectionSortFields: FirestoreSort[] = [
    {
      name: 'Listed: newest first',
      fieldName: 'creationDate',
      desc: true,
    },
    {
      name: 'Listed: oldest first',
      fieldName: 'creationDate',
      desc: false,
    },
  ];

  const marketStatuses = [
    {
      name: 'On sale',
      value: 'LISTED',
      fieldName: 'marketStatus',
      icon: <BoltIcon className="w-5 h-5 text-gray-400" />,
    },
    {
      name: 'Not for sale',
      value: 'UNLISTED',
      fieldName: 'marketStatus',
      icon: <BoltSlashIcon className="w-5 h-5 text-gray-400" />,
    },
  ];
  const categoryButtons = [
    {
      name: 'Featured',
      category: 'trending',
      icon: <TrophyIcon className="w-5 h-5 text-gray-400" />,
    },
    {
      name: 'Art',
      category: 'art',
      filter: '1:1',
      icon: <PaintBrushIcon className="w-5 h-5 text-gray-400" />,
    },
    {
      name: 'On-chain',
      category: 'on-chain',
      filter: 'on-chain',
      icon: <LinkIcon className="w-5 h-5 text-gray-400" />,
    },
    {
      name: 'Gaming',
      category: 'gaming',
      filter: 'gaming',
      icon: <ComputerDesktopIcon className="w-5 h-5 text-gray-400" />,
    },
    {
      name: 'Metaverse',
      category: 'metaverse',
      filter: 'Metaverse',
      icon: <CubeTransparentIcon className="w-5 h-5 text-gray-400" />,
    },
    {
      name: 'Photography',
      category: 'photography',
      filter: 'photography',
      icon: <CubeTransparentIcon className="w-5 h-5 text-gray-400" />,
    },
    {
      name: 'Music',
      category: 'music',
      filter: 'Music',
      icon: <MusicalNoteIcon className="w-5 h-5 text-gray-400" />,
    },
  ];
  return (
    <>
      <div className="h-full py-2 mr-2 ml-4 flex flex-row items-center gap-3">
        <DaisyButton
          label={''}
          size="btn-sm"
          classNames="btn-circle p-1.5"
          icon={<ArrowPathIcon />}
          onClick={() => resetQuery()}
        />
        <input
          className="h-9 dark:bg-gray-700/20 border dark:border-gray-700/80 rounded-full px-4 font-light"
          placeholder="Search "
        />
        {searchMode === 'ASSET' && (
          <div className="dropdown dropdown-end dropdown-hover ">
            <label
              tabIndex={0}
              className="cursor-pointer flex flex-row gap-1 items-center justify-between border border-gray-300/50 dark:bg-gray-700/20 dark:border-gray-700/50 bg-gray-100 shadow-md px-4 py-2 rounded-full w-48"
            >
              <div className="flex-1 flex gap-2">
                <TagIcon className="w-5" />{' '}
                <span className="text-sm dark:text-gray-300">Status:</span>
              </div>
              <div className="text-accent text-sm">Buy Now</div>
            </label>
            <ul
              tabIndex={0}
              className="dropdown-content menu p-2 mt-0.5 shadow bg-base-100 rounded-box w-52"
            >
              {marketStatuses.map((status, idx) => (
                <React.Fragment key={`${status.value}${idx}`}>
                  <li>
                    <a onClick={() => setCategory(status.value)}>
                      {status.icon} {status.name}
                    </a>
                  </li>
                </React.Fragment>
              ))}
            </ul>
          </div>
        )}
        <div className="dropdown dropdown-end dropdown-hover ">
          <label
            tabIndex={0}
            className="cursor-pointer flex flex-row gap-1 items-center justify-between border border-gray-300/50 dark:bg-gray-700/20 dark:border-gray-700/50 bg-gray-100 shadow-md px-4 py-2 rounded-full w-52"
          >
            <div className="flex-1 flex gap-2">
              <SwatchIcon className="w-5" />{' '}
              <span className="text-sm dark:text-gray-300">Category:</span>
            </div>
            <div className="text-accent text-sm">
              {getCategory() ? getCategory() : 'All'}
            </div>
          </label>
          <ul
            tabIndex={0}
            className="dropdown-content menu p-2 mt-0.5 shadow bg-base-100 rounded-box w-52"
          >
            {categoryButtons.map((cat, idx) => (
              <React.Fragment key={`${cat}${idx}`}>
                <li>
                  <a onClick={() => setCategory(cat.category)}>
                    {cat.icon} {cat.name}
                  </a>
                </li>
              </React.Fragment>
            ))}
          </ul>
        </div>
        {searchMode === 'ASSET' && (
          <>
            <div className="dropdown dropdown-end dropdown-hover">
              <label
                tabIndex={0}
                className="cursor-pointer flex flex-row gap-1 items-center border border-gray-300/50 dark:bg-gray-700/20 dark:border-gray-700/50 bg-gray-100 shadow-md px-4 py-2 rounded-full"
              >
                {' '}
                <BarsArrowDownIcon className="w-5" />{' '}
                <span className="text-sm dark:text-gray-300">
                  Listed:<span className="text-accent"> Low to High</span>
                </span>
              </label>
              <ul
                tabIndex={0}
                className="dropdown-content menu p-2 mt-0.5 shadow bg-base-100 rounded-box w-52"
              >
                {assetSortFields.map((field, idx) => (
                  <React.Fragment key={`${field.name}${idx}`}>
                    <li>
                      <a onClick={() => setSort(field)}>{field.name}</a>
                    </li>
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </>
        )}
        {searchMode === 'COLLECTION' && (
          <>
            {' '}
            <div className="dropdown dropdown-end dropdown-hover">
              <label
                tabIndex={0}
                className="cursor-pointer flex flex-row gap-1 items-center border border-gray-300/50 dark:bg-gray-700/20 dark:border-gray-700/50 bg-gray-100 shadow-md px-4 py-2 rounded-full"
              >
                {' '}
                <BarsArrowDownIcon className="w-5" />{' '}
                <span className="text-sm dark:text-gray-300">
                  Listed:<span className="text-accent"> Low to High</span>
                </span>
              </label>
              <ul
                tabIndex={0}
                className="dropdown-content menu p-2 mt-0.5 shadow bg-base-100 rounded-box w-52"
              >
                {collectionSortFields.map((field, idx) => (
                  <React.Fragment key={`${field.name}${idx}`}>
                    <li>
                      <a onClick={() => setSort(field)}>{field.name}</a>
                    </li>
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </>
        )}
      </div>
    </>
  );
};

type projectTraitFilterProps = { projectId: Project };

type Trait = {
  name: string;
  value: string;
};

const CollectionTraits = ({ projectId }: projectTraitFilterProps) => {
  const { loading, traits } = useProjectTraits(projectId.id);
  const [selectedTraits, setSelectedTraits] = useState<Trait[]>();

  const didToggleTrait = (category: string, trait: string) => {
    console.log('didToggleTrait on:', trait);

    // if found, remove it
    //if not found, add it:

    if (!selectedTraits || (!!selectedTraits && selectedTraits.length === 0)) {
      setSelectedTraits([{ name: category, value: trait }]);
      console.log('didToggleTrait new array init');
    } else {
      console.log('didToggleTrait  existing array');
      //determine if trait exists in trait array:
      const found = selectedTraits.find(
        (t) => t.value === trait && t.name == category
      );
      if (found) {
        //remove
        console.log('didToggleTrait found item exaclty, removing');
        const newArrar = selectedTraits.filter((t) => t.value !== trait);
        setSelectedTraits(newArrar);
      }
      if (!found) {
        //check if an item exists in the array for the category:
        const foundCategory = selectedTraits.find((t) => t.name === category);
        if (foundCategory) {
          //remove the category and add the new trait
          const newArrar = selectedTraits.filter((t) => t.name !== category);
          newArrar.push({ name: category, value: trait });
          setSelectedTraits(newArrar);
        } else {
          //add the trait
          const newArrar = selectedTraits;
          newArrar.push({ name: category, value: trait });
          setSelectedTraits(newArrar);
        }
      }
    }
    console.log('didToggleTrait new traits:', JSON.stringify(selectedTraits));
  };

  // const toggleTrait = (trait: string) => {
  //   if (!selectedTraits || (!!selectedTraits && selectedTraits.length === 0)) {
  //     setSelectedTraits([trait]);
  //     console.log('new array init');
  //     return;
  //   }

  //   const newArrar = selectedTraits;

  //   const found = selectedTraits.find((t) => t === trait);
  //   if (found) {
  //     const index = newArrar.indexOf(trait);
  //     if (index > -1) {
  //       newArrar.splice(index, 1);
  //       setSelectedTraits(newArrar);
  //       console.log('found - removing');
  //       console.log(selectedTraits);
  //     }
  //     return;
  //   }

  //   newArrar.push(trait);
  //   console.log('Not found - adding)');
  //   console.log(selectedTraits);
  //   setSelectedTraits(newArrar);

  //   return;
  // };

  return (
    <div className="flex flex-col">
      <div className=" bg-gray-800 relative">
        <NextImage
          width={160}
          height={100}
          className={''}
          src={projectId.bannerImage || projectId.heroImage || ''}
          priority={true}
        />
      </div>
      <div className="pb-6 px-8 flex flex-col gap-1">
        <div className="w-full py-4">Policy:</div>
        <div className="w-full  font-normal text-gray-700 dark:text-gray-300 text-sm">
          {projectId.projectDescription}
        </div>
      </div>

      <span className="pl-6 pr-2 flex flex-col">
        {' '}
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex flex-row w-full border-t border-b  dark:border-gray-700 h-16 px-4 items-center justify-between">
                Market Status{' '}
                <ChevronDownIcon
                  className={cn('w-6', {
                    'rotate-180 transition-transform duration-150': !open,
                    'rotate-0 transition-transform duration-150': open,
                  })}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="text-gray-400 font-normal p-4 flex-col flex gap-4 text-base">
                <div className="flex flex-row justify-between">
                  <span>Buy Now </span>
                  <Checkbox
                    checked={true}
                    size="xs"
                    onChange={() => console.log('hehre')}
                  />
                </div>
                <div className="flex flex-row justify-between">
                  <span>Buy Now </span>
                  <Checkbox
                    checked={true}
                    size="xs"
                    onChange={() => console.log('hehre')}
                  />
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex flex-row w-full  border-b  dark:border-gray-700 h-16 px-4 items-center justify-between">
                Market Status{' '}
                <ChevronDownIcon
                  className={cn('w-6', {
                    'rotate-180 transition-transform duration-150': !open,
                    'rotate-0 transition-transform duration-150': open,
                  })}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="text-gray-400 font-normal p-4 flex-col flex gap-4 text-base">
                <div className="flex flex-row justify-between">
                  <span>Buy Now </span>
                  <Checkbox
                    checked={true}
                    size="xs"
                    onChange={() => console.log('hehre')}
                  />
                </div>
                <div className="flex flex-row justify-between">
                  <span>Buy Now </span>
                  <Checkbox
                    checked={true}
                    size="xs"
                    onChange={() => console.log('hehre')}
                  />
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex flex-row w-full border-b  dark:border-gray-700 h-16 px-4 items-center justify-between">
                Traits{' '}
                <ChevronDownIcon
                  className={cn('w-6', {
                    'rotate-180 transition-transform duration-150': !open,
                    'rotate-0 transition-transform duration-150': open,
                  })}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="text-gray-400 font-normal p-4 flex-col flex gap-4 text-base">
                <>
                  {!!traits && (
                    <>
                      {Object.entries(traits)
                        .sort()
                        .map(([key, value]) => (
                          <React.Fragment key={key}>
                            <h3 className="dark:text-gray-300 text-gray-600 font-medium">
                              {key}
                            </h3>
                            <TraitsChild
                              category={key}
                              traits={value}
                              didToggleTrait={didToggleTrait}
                            />
                          </React.Fragment>
                        ))}
                    </>
                  )}
                  {/* <div className="flex flex-row justify-between">
                        <span>Buy Now </span>
                        <Checkbox
                          checked={true}
                          size="xs"
                          onChange={() => console.log('hehre')}
                        />
                      </div>
                      <div className="flex flex-row justify-between">
                        <span>{JSON.stringify(traits)}</span>
                        <Checkbox
                          checked={true}
                          size="xs"
                          onChange={() => console.log('hehre')}
                        />
                      </div> */}
                </>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </span>
    </div>
  );
};

type TraitsChildProps = {
  category: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  traits: any;
  didToggleTrait: (category: string, trait: string) => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TraitsChild = React.memo(
  ({ category, traits, didToggleTrait }: TraitsChildProps) => {
    const [selectedTrait, setSelectedTrait] = useState<string>();

    const router: NextRouter = useRouter();
    const [filters, setFilters] = useState<Filter[]>([]);

    useEffect(() => {
      setFilters(getFiltersFromUrl(router));
    }, [router]);

    const handleUpdateFilter = (
      type: 'trait' | 'status',
      value: string
    ): void => {
      const newFilters = updateFilter(type, value, filters);
      setFilters(newFilters);
      updateFiltersInUrl(newFilters, router);
    };

    const toggleTrait = (trait: string) => {
      console.log('>>toggling: ', trait);
      if (!selectedTrait) {
        setSelectedTrait(trait);
        console.log('new array init');
        handleUpdateFilter('trait', trait);
        didToggleTrait(category, trait);
        return;
      }
      if (selectedTrait === trait) {
        setSelectedTrait(undefined);
        console.log('removing');

        handleUpdateFilter('trait', trait);
        didToggleTrait(category, trait);
        return;
      }
      console.log('Not found - adding)');
      handleUpdateFilter('trait', trait);
      setSelectedTrait(trait);
      didToggleTrait(category, trait);
      return;
    };

    return (
      <>
        <div className="flex flex-wrap gap-1.5 pt-4  ">
          {Array.isArray(traits) ? (
            traits.map((childValue, index) => (
              <React.Fragment key={`${index}-${childValue}`}>
                <div
                  onClick={() => {
                    console.log('clicked: ', `${category} > ${childValue}`);
                    toggleTrait(`${category} > ${childValue}`);
                  }}
                  className={cn(
                    'dark:bg-gray-900 bg-gray-200 dark:text-gray-400 text-gray-800 px-3 py-1 rounded-2xl border dark:border-gray-700 border-gray-300 text-sm cursor-pointer hover:-translate-y-0.5 transition-transform hover:scale-105',
                    {
                      'dark:bg-violet-700 bg-violet-700 dark:text-white text-gray-100  border-violet-400/50 shadow-lg':
                        selectedTrait &&
                        selectedTrait === `${category} > ${childValue}`,
                    }
                  )}
                >
                  <a>{childValue}</a>
                </div>
              </React.Fragment>
            ))
          ) : (
            <span className="cursor-pointer">
              <label>{category}</label>
            </span>
          )}
        </div>
      </>
    );
  }
);

export interface Filter {
  type: 'trait' | 'status';
  value: string;
}

export const decodeFilters = (filtersEncoded: string): Filter[] => {
  const filtersBuffer = Buffer.from(filtersEncoded, 'base64');
  const filtersDecoded = JSON.parse(filtersBuffer.toString());
  return filtersDecoded;
};

const encodeFilters = (filters: Filter[]): string => {
  const filtersString = JSON.stringify(filters);
  const filtersEncoded =
    typeof window !== 'undefined'
      ? window.btoa(filtersString)
      : Buffer.from(filtersString).toString('base64');
  return filtersEncoded;
};

export const getFiltersFromUrl = (
  router: ReturnType<typeof useRouter>
): Filter[] => {
  const filtersEncoded = router.query.filters as string;
  if (filtersEncoded) {
    return decodeFilters(filtersEncoded);
  } else {
    return [];
  }
};

export const updateFiltersInUrl = (
  filters: Filter[],
  router: ReturnType<typeof useRouter>
): void => {
  console.log('updating filters in url: ', filters, router.query.filters);
  const filtersEncoded = encodeFilters(filters);
  router.push({ query: { ...router.query, filters: filtersEncoded } });
};

export const updateFilter = (
  type: 'trait' | 'status',
  value: string,
  filters: Filter[]
): Filter[] => {
  const index = filters.findIndex((filter) => filter.type === type);
  if (index >= 0) {
    filters[index] = { type, value };
  } else {
    filters.push({ type, value });
  }
  return filters;
};

type CollectionSearchProps = { category: string };

// const CollectionSearch = React.memo(({ category }: CollectionSearchProps) => {
//   const collectionIndex = constants.ALGOLIA_PROJECT_INDEX;

//   const InfiniteHitsProject = getInfiniteHitsComponent<Project>();
//   const searchClient = algoliasearch(
//     '91RRC8EAH8',
//     'f1f7d0552a938afdece75849a9b79abc'
//   );
//   return (
//     <>
//       <InstantSearch indexName={collectionIndex} searchClient={searchClient}>
//         <div className="flex flex-row w-full mt-0 sm:mt-4  h-[40rem]">
//           <Configure
//             hitsPerPage={12}
//             filters={`status:Minted AND categories:'${category}'`}
//           />

//           <InfiniteHitsProject
//             containerClassNames="grid xl:grid-cols-3 2xl:grid-cols-4 gap-8 px-4 sm:px-0"
//             render={(hit) => (
//               <div
//                 key={hit.tokenPrefix}
//                 className="ais-InfiniteHits-item bg-white shadow-xl hover:shadow-2xl rounded-xl overflow-hidden h-[30rem] sm:h-[32rem] transition transform duration-200 cursor-pointer"
//               >
//                 <HitProject project={hit} />
//               </div>
//             )}
//           />
//         </div>
//       </InstantSearch>
//     </>
//   );
// });

type SearchBarProps = { project: Project };

// const SearchBarHeader = React.memo(({ project }: SearchBarProps) => {
//   return (
//     <>
//       <div className="flex h-14 w-full items-center">Some Search Shit</div>
//     </>
//   );
// });
