import { useDarkSide } from '#lib/hooks/useDarkSide';
import { Project } from '#types/index';
import { CheckBadgeIcon, ChartPieIcon } from '@heroicons/react/24/solid';
import { Tooltip } from '@nextui-org/react';
import React, { useState } from 'react';
import cn from 'classnames';

type VerifiedCollectionProps = {
  project: Project;
  overideToWhite?: boolean;
};

export const RoyaltiesTooltip = ({
  project,
  overideToWhite = false,
}: VerifiedCollectionProps) => {
  const { colorTheme, setTheme } = useDarkSide();
  const [darkSide, setDarkSide] = useState<boolean>(
    colorTheme === 'light' ? true : false
  );

  const colorClass = !!project.royaltyItems ? 'bg-yellow-500' : 'bg-green-600';

  const renderCreatorView = () => {
    return (
      <div className="flex flex-col gap-0 text-base max-w-[22rem] font-medium px-3 py-2 items-center">
        {project.royaltyItems && (
          <p>
            The creator(s) of this project will receive{' '}
            <span className="text-yellow-600">
              {(project.royaltyItems[0].percent * 100).toFixed(1)}%{' '}
            </span>{' '}
            of the total from each sale.
          </p>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-row gap-2 items-start ">
        <Tooltip
          content={renderCreatorView()}
          placement={'bottom'}
          color={darkSide == true ? 'invert' : 'default'}
        >
          <div className="flex flex-row items-center">
            <div
              className={`${colorClass} w-7 h-7 rounded-full flex items-center justify-center`}
            >
              <ChartPieIcon className="w-5 h-5 text-white" />
            </div>
            <span
              className={cn(
                'flex items-center',
                {
                  'text-white font-bold mt-0 ml-1 mr-2': !!overideToWhite,
                },
                {
                  'text-yellow-600 dark:text-yellow-600 font-bold mr-2 ml-1':
                    !overideToWhite && !!project.royaltyItems,
                },
                {
                  'text-green-600 dark:text-green-600 font-bold mr-2 ml-1 hidden':
                    !overideToWhite && !project.royaltyItems,
                }
              )}
            >
              {project.royaltyItems && (
                <p>{(project.royaltyItems[0].percent * 100).toFixed(1)}% </p>
              )}
              {/* {!project.royaltyItems && <p>Royalty Free</p>} */}
            </span>
          </div>
        </Tooltip>
      </div>
    </>
  );
};
