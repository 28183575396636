import { copyTextToClipboard } from '#components/ShareButton/ShareButton';
import { useDarkSide } from '#lib/hooks/useDarkSide';
import { cardanoExplorerURL } from '#lib/plutus/utils';
import cn from 'classnames';
import {
  CheckIcon,
  ArrowTopRightOnSquareIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/24/outline';
import { Tooltip } from '@nextui-org/react';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { MiddleEllipsisV2 } from '#components/MiddleEllipsis/MiddleEllipsisVersion2';

type PolicyTooltipProps = {
  policy?: string;
  hideTooltip?: boolean;
  label?: string;
};

export const PolicyTooltipV2 = ({
  policy,
  hideTooltip,
  label = 'Policy',
}: PolicyTooltipProps) => {
  const [isCopied, setIsCopied] = useState(false);
  const { colorTheme, setTheme } = useDarkSide();
  const [isHidden, setIsHidden] = useState(true);
  const [darkSide, setDarkSide] = useState<boolean>(
    colorTheme === 'light' ? true : false
  );
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(policy || `No ${label}`)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setIsHidden(false);
    }, 100);
  }, [policy]);

  const renderTT = () => {
    return (
      <div className="flex flex-col gap-0 text-base w-52 font-medium ">
        <div
          className="flex flex-row justify-between items-center cursor-pointer hover:bg-gray-300 px-3 py-2 rounded-lg my-1"
          onClick={() => handleCopyClick()}
        >
          {!!isCopied && (
            <>
              <p>Copied</p>
              <CheckIcon className="w-5 h-5 text-green-600" />
            </>
          )}
          {!isCopied && (
            <>
              <p>Copy {label}</p>
              <DocumentDuplicateIcon className="w-5 h-5" />
            </>
          )}
        </div>
        <div className="flex flex-row justify-between items-center cursor-pointer hover:bg-gray-300 px-3 py-2 rounded-lg my-1">
          <Link
            href={`${cardanoExplorerURL()}/${
              label == 'asset'
                ? 'token'
                : label == 'Hash'
                ? 'Hash'
                : 'tokenPolicy'
            }/${policy}`}
          >
            <a
              target={'_blank'}
              className="flex flex-row justify-between w-full"
            >
              Open Cardanoscan <ArrowTopRightOnSquareIcon className="h-5 w-5" />
            </a>
          </Link>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-row items-center gap-2">
        <Tooltip
          content={renderTT()}
          placement={'bottom'}
          enterDelay={500}
          color={darkSide == true ? 'invert' : 'default'}
        >
          <div className="text-gray-700 w-[12rem] items-center dark:text-gray-100 font-mono text-sm rounded-full shadow-sm flex flex-row">
            <MiddleEllipsisV2
              text={policy || `* No Policy */>`}
              label="Policy"
              classNames={`font-mono duration-200 transition ${
                isHidden && 'opacity-0'
              }`}
              hideTooltip={true}
            />
          </div>
        </Tooltip>
        <span>
          {!!isCopied && <CheckIcon className="w-4 h-4 text-green-500 -ml-4" />}
          {!isCopied && (
            <DocumentDuplicateIcon
              className="w-4 h-4 cursor-pointer text-accent -ml-4"
              onClick={() => handleCopyClick()}
            />
          )}
        </span>
      </div>
    </>
  );
};
