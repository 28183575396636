import { EventAsset } from '#types/index';
import Link from 'next/link';
import React from 'react';
import { ImageSwap } from '../DSImage';
import cn from 'classnames';

export interface EventAssetCardProps {
  asset: EventAsset;
  link: string;
  heightClass?: string;
}

export const EventAssetCard = ({
  asset,
  link,
  heightClass,
}: EventAssetCardProps) => {
  return (
    <Link href={link} key={asset.id}>
      <a className="w-full">
        <div
          className={`${heightClass} shadow-md group flex flex-col duration-150 transition transform hover:-translate-y-1 hover:scale-105  hover:shadow-2xl`}
          key={asset.id}
        >
          <div className="flex-1 m-3 bg-gray-100 dark:bg-gray-800  overflow-hidden">
            <img
              className={cn(
                'h-full w-full object-cover',
                {
                  'object-cover': asset.imageResizeType == 'COVER',
                },
                {
                  'object-contain': asset.imageResizeType == 'CONTAIN',
                },
                {
                  'object-contain pixelated':
                    asset.imageResizeType == 'PIXELATED',
                }
              )}
              src={asset.thumbnailUrl || asset.assetUrl}
              alt={asset.title}
            />
          </div>
          <div className="flex flex-row justify-between px-3">
            <div className="text-gray-700 text-lg font-semibold dark:text-gray-100">
              {asset.creator?.displayName && asset.creator?.displayName}
            </div>
            <div className="flex flex-col text-sm duration-150 transform text-gray-900 dark:text-gray-100 text-opacity-0 group-hover:text-opacity-100">
              {asset.price && (
                <div className="font-bold">
                  ₳ {asset.price && asset.price / 1_000_000}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-row justify-between px-3 pb-3">
            <div className="text-gray-800 text-2xl font-bold dark:text-gray-100">
              {asset.title}
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
};
