import { Fragment, useEffect, useState } from 'react';
import { FC } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Divider } from 'components/Divider/Divider';
import DaisyButton from '#components/Daisy/DaisyButton';
import cn from 'classnames';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { TwitterIcon } from '#components/svg/twitterIcon';
import { TxDisplay } from './TxDisplay';

export interface OfferSuccessProps {
  tx_hash: string;
  open: boolean;
  onClose?: () => void;
}

export const OfferSuccessModal: FC<OfferSuccessProps> = ({
  tx_hash,
  open,
  onClose,
}) => {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => setIsOpen(open), [open]);
  const constructTweet = () => {
    //  const baseUrl = `https://dropspot.io/marketplace/${project.tokenPrefix}`;
    const tweetText = `I've just made a Liquid Offer on @dropspot_io. LFG 🔥"`;

    // Now you can use the `encodedTweetText` in your URL
    const twitterIntentUrl = `https://twitter.com/intent/tweet?text=${tweetText}`;

    return twitterIntentUrl;
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          open={isOpen}
          className="fixed inset-0 z-40 overflow-y-auto transition-height duration-150 transform"
          onClose={() => {
            onClose && onClose();
            setIsOpen(false);
          }}
        >
          <div className="min-h-screen px-4 text-center transition-height duration-150 transform">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 backdrop-filter backdrop-blur-xl backdrop-brightness-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            ></span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block dark:bg-base-300 w-full max-w-lg p-9 my-12 overflow-hidden text-left align-middle  bg-white shadow-xl rounded-2xl transition-height duration-150 transform">
                <div className="flex flex-row justify-between">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-bold leading-6 text-gray-800 dark:text-white flex items-center gap-3"
                  >
                    Liquid Offer
                  </Dialog.Title>
                </div>
                <div className="mt-4">
                  <Divider />
                </div>

                <div
                  className={cn(
                    'duration-200 transition-all flex flex-col justify-between font-normal text-center items-center dark:text-gray-300 text-gray-700'
                  )}
                >
                  <>
                    <div className="w-full flex justify-center flex-col items-center">
                      <span className="text-3xl font-bold mt-6 mb-4">
                        {`Let's Go! 🚀`}
                      </span>
                      <div className="mb-8 text-center font-normal">
                        Congratulations. Your offer has made.
                      </div>
                      <div className="p-4 rounded-xl h-16 flex flex-row items-center justify-between bg-base-100 w-full mb-8">
                        <span className="text-gray-600 dark:text-gray-400">
                          Transaction:
                        </span>
                        <TxDisplay txHash={tx_hash || ''} />
                      </div>
                      <div className="mb-6 text-center font-normal text-sm dark:text-gray-400">
                        Please allow up to 30s for this offer to be confirmed.
                      </div>
                      <div className="flex flex-col gap-4 w-full mt-6">
                        <a
                          className="btn btn-secondary"
                          target="_blank"
                          href={constructTweet()}
                          rel="noreferrer"
                        >
                          {' '}
                          <span className="flex gap-2 items-center">
                            <TwitterIcon />
                            <span>Promote</span>
                          </span>
                        </a>
                        <DaisyButton
                          label="OK"
                          classNames="w-full"
                          onClick={() => {
                            onClose && onClose();
                            setIsOpen(false);
                          }}
                        />
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
