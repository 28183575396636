import React from 'react';
import { Asset, Project } from '#types/index';
import { AssetFlipCardPublic } from '#components/Assets/AssetFlipCard/AssetFlipCardPublic';

const HitAsset = ({
  asset,
  project,
  isLoggedIn,
  showProjectInfo = false,
  heightClass = 'h-[26rem] sm:h-[30rem]',
  useB64ForImage,
  inGroupOfferMode,
  imageDisplayPreference,
  showRarity,
  floor,
  overrideBaseMediaType,
  preventOnchainRender,
  viewingUserId,
  doSelectAsset,
}: {
  asset: Asset;
  project?: Project;
  isLoggedIn: boolean;
  showProjectInfo: boolean;
  inGroupOfferMode?: boolean;
  showRarity?: boolean;
  overrideBaseMediaType?: boolean;
  floor?: number;
  heightClass?: string;
  useB64ForImage?: boolean;
  imageDisplayPreference?: string;
  preventOnchainRender?: boolean;
  viewingUserId?: string;
  doSelectAsset?: (asset: Asset) => void;
}) => {
  return (
    <AssetFlipCardPublic
      asset={asset}
      showProjectInfo={showProjectInfo}
      isInSelectionMode={inGroupOfferMode}
      inGroupOfferMode={inGroupOfferMode}
      showRarity={showRarity}
      floor={floor}
      overrideBaseMediaType={overrideBaseMediaType}
      heightClass={heightClass}
      link={`/nft/${asset.id}`}
      showLikes={isLoggedIn}
      useB64ForImage={useB64ForImage}
      project={project}
      imageDisplayPreference={imageDisplayPreference}
      preventOnchainRender={preventOnchainRender}
      viewingUserId={viewingUserId}
      doSelectAsset={(a) => doSelectAsset && doSelectAsset(a)}
    />
  );
};
export default HitAsset;
