import { Fragment, useState } from 'react';
import { LiveTransactionsAll } from '#components/Projects/LiveTransactionsAll';
import cn from 'classnames';
import { Transition } from '@headlessui/react';
import DaisyButton from '#components/Daisy/DaisyButton';
import { BoltIcon } from '@heroicons/react/24/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';

type LiveTransactionsAllProps = { policy?: string; doClose?: () => void };
export default function LiveTransactionsSlideOut({
  policy,
}: LiveTransactionsAllProps) {
  const [open, setOpen] = useState(false);

  return (
    // <Transition.Root show={open} as={Fragment}>
    <>
      <div className="z-[21] absolute bottom-8 right-8 flex items-end justify-end">
        <DaisyButton
          colorName="btn-secondary"
          size="btn-lg"
          classNames="btn-circle shadow-2xl"
          label=""
          icon={open ? <XMarkIcon className="w-6 h-6" /> : <BoltIcon className="w-6 h-6" />}
          onClick={() => setOpen(!open)}
        />
      </div>
      <aside
        className={cn(
          'dark:bg-black/70 backdrop-blur-xl z-20 rounded-l-xl overflow-hidden fixed right-0 top-[5rem] flex-shrink-0 w-[24rem] flex flex-col border-r dark:border-gray-700 transition-all duration-150 ease-out',
          { 'translate-x-96': !open }
        )}
      >
        <nav className="relative flex-1 flex flex-col overflow-y-scroll">
          <div
            className="flex flex-col w-full overflow-y-scroll scrollbar-hide"
            style={{ height: 'calc(100vh  - 5rem)' }}
          >
            <LiveTransactionsAll
              policy={policy}
              doClose={() => {
                console.log('do close');
                setOpen(false);
              }}
            />
          </div>
        </nav>
      </aside>
    </>

    // </Transition.Root>
  );
}
