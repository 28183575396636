import {
  getWalletNativeAssetsListener,
  getWalletListingsListener,
  getWalletExternalListingsListener,
  getWalletParsedTransactions,
  getWalletSyncInsertsListener,
  getWalletExternalListingSyncInsertsListener,
  getProjectByPolicy,
  getWalletPositionSummaryListener,
} from '#lib/firestore';
import { Asset, WalletPositionSummary } from '#types/index';
import { useEffect, useState } from 'react';
import { ParsedTxInfo, TxInfo } from './useTxInfo';

export type GroupedAssets = Array<ProjectGroup>;

export interface ProjectGroup {
  id: string;
  title: string;
  coverURL: string;
  assets: Asset[];
  base64?: string;
}

export const useCachedWallet = (userId?: string) => {
  const [walletAssets, setWalletAssets] = useState<Asset[]>([]);
  const [groupedWalletAssets, setGroupedWalletAssets] = useState<
    GroupedAssets | undefined
  >();
  const [loading, setLoading] = useState(true);
  const [totalAssets, setTotalAssets] = useState(0);

  useEffect(() => {
    if (walletAssets.length > 0) {
      // Create an async function to fetch and set the grouped assets
      const fetchGroupedAssets = async () => {
        const groupedAssets = await groupAssetsByProject(walletAssets);
        setGroupedWalletAssets(groupedAssets);
      };

      fetchGroupedAssets();
    }
  }, [walletAssets]);

  useEffect(() => {
    if (!userId) {
      setLoading(false);
      return;
    }
    return getWalletNativeAssetsListener(userId, (data) => {
      const filteredAssets = data.filter((asset) => asset.assetUrl);
      setWalletAssets(filteredAssets);
      setTotalAssets(filteredAssets.length);
      setLoading(false);
    });
  }, [userId]);

  return {
    walletAssets,
    loading,
    groupedWalletAssets,
    totalAssets,
  };
};

export const useWalletSyncInserts = (userId?: string) => {
  const [inserting, setInserting] = useState<number | undefined>();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!userId) {
      setLoading(false);
      return;
    }
    return getWalletSyncInsertsListener(userId, (data) => {
      setInserting(data);
      setLoading(false);
    });
  }, [userId]);

  return {
    inserting,
    loading,
  };
};
export const useWalletExternalListingSyncInserts = (userId?: string) => {
  const [inserting, setInserting] = useState<number | undefined>();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!userId) {
      setLoading(false);
      return;
    }
    return getWalletExternalListingSyncInsertsListener(userId, (data) => {
      setInserting(data);
      setLoading(false);
    });
  }, [userId]);

  return {
    inserting,
    loading,
  };
};
async function groupAssetsByProject(assets: Asset[]): Promise<GroupedAssets> {
  const initialGroupedAssets: GroupedAssets = [];

  for (const asset of assets) {
    const projectIndex = initialGroupedAssets.findIndex(
      (project) => project.id === asset.policy
    );

    if (projectIndex === -1 && asset.policy) {
      initialGroupedAssets.push({
        id: asset.policy,
        base64: asset.thumbnailB64,
        title:
          (asset.algoliaProjectFacet &&
            JSON.parse(asset.algoliaProjectFacet).title) ||
          asset.title?.split('#')[0],
        coverURL:
          (asset.algoliaProjectFacet &&
            JSON.parse(asset.algoliaProjectFacet).pt) ||
          asset.thumbnailUrl ||
          asset.assetUrl,
        assets: [asset],
      });
    } else {
      if (initialGroupedAssets[projectIndex]?.assets) {
        initialGroupedAssets[projectIndex].assets?.push(asset);
      }
    }
  }

  return initialGroupedAssets;
}

export const useCachedWalletListings = (walletAddress?: string) => {
  const [listedAssets, setListedAssets] = useState<Asset[] | undefined>();

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!walletAddress) {
      setLoading(false);
      return;
    }
    return getWalletListingsListener(walletAddress, (data) => {
      setListedAssets(data);
      setLoading(false);
    });
  }, [walletAddress]);
  return {
    listedAssets,
    loading,
  };
};

export const useCachedWalletExternalListings = (walletAddress?: string) => {
  const [externalListings, setExternalListings] = useState<
    Asset[] | undefined
  >();
  const [loadingExternalListings, setLoadingExternalListings] = useState(true);
  useEffect(() => {
    if (!walletAddress) {
      setLoadingExternalListings(false);
      return;
    }
    return getWalletExternalListingsListener(walletAddress, (data) => {
      setExternalListings(data);
      setLoadingExternalListings(false);
    });
  }, [walletAddress]);
  return {
    externalListings,
    loadingExternalListings,
  };
};

export const useWalletParsedTransactions = (walletAddress?: string) => {
  const [parsedTransactions, setParsedTransactions] = useState<
    ParsedTxInfo[] | undefined
  >();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!walletAddress) {
      setLoading(false);
      return;
    }
    getWalletParsedTransactions(walletAddress).then((data) => {
      console.log('we have some data');
      const txArray = data as ParsedTxInfo[];
      setParsedTransactions(
        txArray.sort(
          (a, b) => (b.block_timestamp || 0) - (a.block_timestamp || 0)
        )
      );
    });
  }, [walletAddress]);
  return {
    parsedTransactions,
    loading,
  };
};

export const useWalletPositionSummary = (stakeAddress?: string) => {
  const [walletPositionSummary, setWalletPositionSummary] = useState<
    WalletPositionSummary | undefined
  >();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!stakeAddress) {
      setLoading(false);
      return;
    }
    return getWalletPositionSummaryListener(stakeAddress, (data) => {
      setWalletPositionSummary(data);
      setLoading(false);
    });
  }, [stakeAddress]);
  return {
    walletPositionSummary,
    loading,
  };
};
