import cn from 'classnames';
import { useCardanoWallet } from '../../../../lib/wallet/WalletContext';
import { useMarketTxState } from './MarketStore';
import { InlineLoading } from '../../../Loading/InlineLoading';
import { CheckIcon } from '@heroicons/react/24/solid';

export const ProcessingTransactionModalComponentV2 = () => {
  const { wallet } = useCardanoWallet();
  const txStage = useMarketTxState();

  return (
    <div className="flex flex-col items-center justify-start h-full gap-8 mt-8">
      <img
        src={wallet?.icon || '/img/cardano-logo-black.webp'}
        className="w-7 h-7 object-contain "
        alt={`${wallet?.name}-icon`}
      />
      <p className="text-3xl font-semibold leading-12 text-gray-900 dark:text-gray-100">
        {txStage == 'Submitted' && <span> Transaction success</span>}
        {txStage == 'Submitting' && <span> Submitting transaction</span>}
        {txStage == 'Signing' && <span> Sign the transaction</span>}
        {txStage == 'Building' && <span> Building transaction</span>}
        {txStage == 'Finalizing' && <span> Finalising transaction</span>}
      </p>

      <p className="text-gray-700 dark:text-gray-400 font-normal text-center">
        {txStage == 'Submitted' && (
          <span>
            The transaction has been submitted to the blockchain, and being
            tracked for confirmation
          </span>
        )}
        {txStage == 'Signing' && (
          <span> A transaction has been sent to your wallet for signing.</span>
        )}
        {txStage == 'Building' && (
          <span> The transaction is being built for your signing.</span>
        )}
        {txStage == 'Submitting' && (
          <span> The transaction is being submitted to the blockchain.</span>
        )}
        {txStage == 'Finalizing' && (
          <span> The transaction is being verified.</span>
        )}
      </p>
      <div className="w-full h-12 flex justify-center items-center">
        {' '}
        {txStage && txStage != 'Submitted' && <InlineLoading ballSize={8} />}
        {txStage && txStage == 'Submitted' && (
          <CheckIcon className="w-12 h-12 text-green-500" />
        )}
      </div>

      <p
        className={cn(
          'text-sm font-normal',
          {
            'dark:text-sky-400 text-sky-600 ': txStage != 'Submitted',
          },
          {
            'dark:text-green-500 text-green-500': txStage == 'Submitted',
          }
        )}
      >
        Transaction - {txStage}
      </p>
    </div>
  );
};
