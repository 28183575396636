/* eslint-disable @typescript-eslint/no-explicit-any */
import { connectRange } from 'react-instantsearch-dom';

const RangeInput = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
}: {
  currentRefinement: any;
  min: number;
  max: number;
  precision: number;
  refine: any;
}) => (
  <form className="flex flex-row gap-2 justify-between items-center">
    <input
      type="number"
      min={min}
      max={max}
      step={1 / Math.pow(10, precision)}
      value={currentRefinement.min || ''}
      onChange={(event) =>
        refine({
          ...currentRefinement,
          min: event.currentTarget.value,
        })
      }
    />
    {' - '}
    <input
      type="number"
      min={min}
      max={max}
      step={1 / Math.pow(10, precision)}
      value={currentRefinement.max || ''}
      onChange={(event) =>
        refine({
          ...currentRefinement,
          max: event.currentTarget.value,
        })
      }
    />
  </form>
);

export const CustomRangeInput = connectRange(RangeInput);
