/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useWalletStore, WalletContextProps } from '#lib/wallet/WalletContext';
import { useCoinGecko } from '#lib/hooks/useCoinGecko';
import { OwnedAssetsTab } from '#components/Wallet/OwnedAssets';
import { DegenMode, useDegenStore } from '#lib/hooks/useGlobalMode';
import cn from 'classnames';
import { WalletDisplay } from '#components/Wallet/WalletDisplay';
import { useFirebaseCurrentUser } from '#lib/hooks/useFirebaseCurrentUser';
import { useCardanoWallet } from '#lib/wallet/WalletContext';
import { LearnMoreHelp } from '#components/InlineHelp/LearnMoreHelp';

import { useCreator } from '#lib/hooks/useCreator';
import {
  ArrowRightOnRectangleIcon,
  ArrowsRightLeftIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/solid';
import { Tooltip } from '@nextui-org/react';
import {
  countryToCurrency,
  getExchangeRate,
  renderCurrency,
} from '#components/Assets/AssetPage/ChainInteraction/utils';
import { useIpCountry } from '#lib/hooks/useIpCountry';
import { InlineLoading } from '#components/Loading/InlineLoading';
import { Divider } from '#components/Divider/Divider';
import { TransactionActivity } from '#components/Wallet/TransactionActivity';
import { Checkbox } from '#components/Inputs/Checkbox/Checkbox';
import { ForSaleAssets } from '#components/Wallet/NativeAssetActivity';

import 'firebase/auth';
import 'firebase/functions';
import { useWalletRefresh } from '../../lib/wallet/useWalletRefresh';
import { createCreator } from '#lib/firestore';
import { BrowserView, isBrowser, isMobile } from 'react-device-detect';
import Link from 'next/link';
import { useAlert } from '#lib/utils/Alert';
import { ButtonPublic } from '#components/Button/ButtonPublic';
import { SUPPORTED_WALLETS_META } from '#types/cardano';
import { WalletNFTHoldPieChart } from '#components/Wallet/OwnedAssetComponents/WalletSummaryPie';
import { WalletPieChart2 } from '#components/Wallet/OwnedAssetComponents/WalletPieChart2';

type WalletSlideOutProps = {
  isOpen: boolean;
  close: () => void;
};

function degenModeSelector(state: DegenMode) {
  return state.degenMode;
}

function walletStoreStateSelector(state: WalletContextProps) {
  return {
    registerBalanceListener: state.registerBalanceListener,
    wallet: state.wallet,
    adaBalance: state.adaBalance,
    adaBalanceNumber: state.adaBalanceNumber,
    stakeAddress: state.stakeAddress,
    walletAddress: state.walletAddress,
    connecting: state.connecting,
  };
}

export default function WalletSlideOut({ isOpen, close }: WalletSlideOutProps) {
  const { coinGeckoPrice } = useCoinGecko();
  const degenMode = useDegenStore(degenModeSelector);

  const {
    registerBalanceListener,
    wallet,
    adaBalance,
    adaBalanceNumber,
    stakeAddress,
    walletAddress,
    connecting,
  } = useWalletStore(walletStoreStateSelector);

  const { defaultCountry } = useIpCountry();
  const { currentUser, signOut, reloadUser } = useFirebaseCurrentUser();
  const { creator, setCreator } = useCreator({ creatorId: currentUser?.uid });
  const [hideAddress, setHideAddress] = useState(true);
  const [selectedWalletTab, setSelectedWalletTab] = useState<
    'owned' | 'listed'
  >('owned');

  useEffect(() => registerBalanceListener(), [registerBalanceListener]);
  const [showCompletedTransactions, setShowCompletedTransactions] =
    useState(true);

  const { adaHandle } = useCardanoWallet();

  // useEffect(() => {
  //   if (!wallet) return;

  //   wallet
  //     .getBalance()
  //     .then((balance) => {
  //       return cbor.decodeAll(balance);
  //     })
  //     .then((decoded) => {
  //       if (!Array.isArray(decoded)) return;
  //       const [ada] = decoded;

  //       setAdaBalance(
  //         (Math.round(Number.parseInt(ada) / 1_000_0) / 100)
  //           .toFixed(0)
  //           .toString()
  //       );
  //     });
  // }, [wallet]);

  const { ping } = useWalletRefresh();

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => setHideAddress(false), 100);
      ping();
    } else {
      setTimeout(() => setHideAddress(true), 100);
    }
  }, [isOpen, ping]);

  const handleTurboTransactions = async () => {
    if (!currentUser) return;
    const myCreator = {
      ...creator,
      id: creator.id || currentUser.uid,
      turboTransactionsOn: !!creator.turboTransactionsOn
        ? !creator.turboTransactionsOn
        : true,
    };
    await createCreator(myCreator, true);
    setCreator(myCreator);
  };

  const { showError } = useAlert();

  const error = useWalletStore((s) => s.error);
  const clearError = useWalletStore((s) => s.clearError);
  const {
    availableWallets,
    switchWallet,
    waitingForWalletLogin,
    tryLoadingAgain,
  } = useCardanoWallet();

  const [isOpenModal, setIsOpenModal] = useState(false);

  function closeModal() {
    setIsOpenModal(false);
  }

  function openModal() {
    if (availableWallets?.length === 0) {
      tryLoadingAgain().then(() => setIsOpenModal(true));
    } else {
      setIsOpenModal(true);
    }
  }

  useEffect(() => {
    !currentUser?.uid && close();
  }, [currentUser?.uid, close]);

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden z-50 shadow-2xl"
          onClose={() => close()}
        >
          <div className="absolute inset-0 overflow-hidden z-50">
            <Dialog.Overlay className="absolute inset-0" />

            <div
              className={cn(
                'pointer-events-none w-full fixed inset-y-0 right-0 flex pl-2 sm:pl-10 h-full',
                { 'pt-24': degenMode },
                { '': !degenMode }
              )}
            >
              <Transition.Child
                as="div"
                enter="transform transition ease-in-out duration-200 sm:duration-200"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-200 sm:duration-200"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
                className={'h-full w-full flex justify-end'}
              >
                <div className="h-full pointer-events-auto w-full  max-w-md sm:w-[448px]">
                  <div
                    className={cn(
                      'w-full flex h-full flex-col divide-y divide-gray-200 bg-gray-100 dark:bg-base-300 border-l border-t dark:border-gray-700 shadow-xl',
                      { 'rounded-xl': !degenMode }
                    )}
                  >
                    <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll scrollbar-hide h-full gap-6 w-full">
                      <div className="sticky top-0 bg-base-300 flex-none px-4 sm:px-6 pt-8 pb-3 z-[2] rounded-tl-xl ">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-xl font-medium text-gray-900 dark:text-gray-200 flex flex-row gap-3 items-center">
                            <img
                              src={
                                wallet?.icon || '/img/cardano-logo-black.webp'
                              }
                              className="w-6 h-6 object-contain "
                              alt={`${wallet?.name}-icon`}
                            />
                            {adaHandle ? adaHandle : wallet?.name}
                            <Tooltip
                              hideArrow
                              content={
                                <div className="flex flex-col">
                                  <button
                                    role="button"
                                    onClick={() => {
                                      openModal();
                                    }}
                                    className={cn(
                                      'group  text-gray-600 flex flex-col items-center gap-4 cursor-pointer '
                                    )}
                                  >
                                    <div className="w-36 flex items-center justify-between h-8 text-base group-hover:bg-gray-300 px-2 py-1 rounded-lg -mx-1">
                                      <ArrowsRightLeftIcon className="w-5 h-5" />{' '}
                                      Switch Wallet
                                    </div>
                                  </button>

                                  <button
                                    role="button"
                                    onClick={() => {
                                      signOut()
                                        .then(() => close())
                                        .catch((err) =>
                                          console.error(err.message, err.stack)
                                        );
                                    }}
                                    className={cn(
                                      'group  text-gray-600 flex flex-col items-center gap-4 cursor-pointer '
                                    )}
                                  >
                                    <div className="w-36 flex items-center justify-between h-8 text-base group-hover:bg-gray-300 px-2 py-1 rounded-lg -mx-1">
                                      <ArrowRightOnRectangleIcon className="w-5 h-5" />{' '}
                                      Sign out
                                    </div>
                                  </button>
                                </div>
                              }
                              placement={'bottom'}
                            >
                              <ChevronDownIcon className="w-5 h-5" />
                            </Tooltip>
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center p-1 gap-3">
                            <button
                              type="button"
                              className="rounded-full bg-white dark:bg-gray-900 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 p-1"
                              onClick={() => close()}
                            >
                              <XMarkIcon
                                className="h-6 w-6 dark:hover:text-white"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="flex-none flex flex-col gap-2 px-2 sm:px-6 h-max w-full">
                        <div className="border bg-white dark:bg-base-100 dark:border-gray-700 rounded-2xl px-4 py-3 items-center flex justify-between">
                          <span className="flex flex-row items-center gap-3">
                            <img
                              src="/img/cardano-logo-black.webp"
                              alt=""
                              className="w-6 h-6 object-fit dark:hidden"
                            />
                            <img
                              src="/img/cardano-logo-white.webp"
                              alt=""
                              className="w-6 h-6 object-fit hidden dark:flex"
                            />
                            <span className="text-gray-800 dark:text-gray-300 font-medium">
                              Funds
                            </span>
                          </span>
                          <span className="flex flex-col justify-end items-end">
                            <span className="dark:text-gray-200 font-bold text-xl">
                              ₳ {adaBalance ?? 0.0}
                            </span>
                            <span className="dark:text-gray-300 font-light text-sm">
                              {!!coinGeckoPrice && (
                                <>
                                  {!!defaultCountry && !!coinGeckoPrice ? (
                                    <>
                                      {renderCurrency(
                                        countryToCurrency(defaultCountry),
                                        (getExchangeRate(
                                          defaultCountry,
                                          coinGeckoPrice
                                        ) || 0) * (adaBalanceNumber || 0)
                                      )}
                                    </>
                                  ) : (
                                    <InlineLoading ballSize={5} />
                                  )}
                                </>
                              )}
                              {!coinGeckoPrice && <>$</>}
                            </span>
                          </span>
                        </div>
                        <div className=" flex flex-col  mt-6 gap-1 w-full">
                          <div className="bg-base-100 rounded-xl py-4 px-5 flex flex-col gap-2">
                            <div className="text-sm w-full font-normal dark:text-gray-400 text-gray-700 flex-col sm:flex-row flex sm:items-center justify-between">
                              <span>Payment</span>
                              <span className="font-medium ml-auto text-base dark:text-white text-gray-900">
                                <WalletDisplay
                                  isHidden={hideAddress}
                                  isStyled={false}
                                  address={walletAddress || ''}
                                  label="Payment"
                                />
                              </span>
                            </div>
                            <div className="text-sm w-full font-normal dark:text-gray-400 text-gray-700 flex-col sm:flex-row flex sm:items-center justify-between">
                              <span>Stake</span>
                              <span className="font-medium ml-auto text-base dark:text-white text-gray-900">
                                <WalletDisplay
                                  isHidden={hideAddress}
                                  address={stakeAddress || ''}
                                  isStyled={false}
                                  label="Payment"
                                />
                              </span>
                            </div>
                            {adaHandle && (
                              <div className="text-sm font-normal dark:text-gray-400 text-gray-700 flex items-center justify-between">
                                <span>Handle</span>
                                <span className="font-medium text-base dark:text-white text-gray-900">
                                  {adaHandle}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>

                        {!!currentUser && (
                          // <TrackedTransactionsRegion userId={currentUser.uid} />
                          <div className="w-full">
                            <div>
                              {!!currentUser && (
                                <WalletNFTHoldPieChart
                                  stakeAddress={currentUser.uid}
                                />
                              )}
                              {/* {!!currentUser && (
                            <WalletPieChart2 stakeAddress={currentUser.uid} />
                          )} */}
                            </div>
                            {!!currentUser && (
                              <span className=" flex gap-2 items-center justify-between mt-2">
                                <span className="text-sm sm:text-lg font-medium tracking-wider">
                                  Turbo Transactions
                                </span>
                                <span className="flex flex-row-reverse gap-2 items-center">
                                  <label className="relative inline-flex items-center cursor-pointer">
                                    <input
                                      type="checkbox"
                                      value=""
                                      className="sr-only peer"
                                      checked={
                                        creator.turboTransactionsOn || false
                                      }
                                      onChange={handleTurboTransactions}
                                    />
                                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-500"></div>
                                  </label>

                                  <span className="text-xs dark:text-gray-400 font-light text-gray-600">
                                    On/Off
                                  </span>
                                </span>
                              </span>
                            )}
                            <div className="flex dark:text-gray-300 text-lg font-medium mt-8">
                              <span className="flex-1 flex gap-2 items-center justify-between">
                                <span className="text-sm sm:text-lg font-medium tracking-wider">
                                  {!!showCompletedTransactions
                                    ? 'Recent'
                                    : 'In Progress'}
                                </span>
                                <span className="flex flex-row-reverse gap-2 items-center">
                                  <Checkbox
                                    size="xs"
                                    checked={showCompletedTransactions}
                                    onChange={() =>
                                      setShowCompletedTransactions(
                                        !showCompletedTransactions
                                      )
                                    }
                                  />
                                  <span className="text-xs dark:text-gray-400 font-light text-gray-600">
                                    Show recent
                                  </span>
                                </span>
                              </span>
                              {/* <span className="flex-1 flex gap-2 items-center">
                            <span className="text-lg font-bold">On Sale</span>
                          </span> */}
                            </div>
                            <TransactionActivity
                              showAll={showCompletedTransactions}
                              userId={currentUser.uid}
                              compact={true}
                            />
                          </div>
                        )}

                        <div className="flex dark:text-gray-300 text-lg font-medium mt-8">
                          <span className="flex-1 flex gap-6 items-center">
                            <span
                              className={cn(
                                'text-lg font-medium tracking-wider cursor-pointer',
                                {
                                  'dark:text-white text-gray-900':
                                    selectedWalletTab === 'owned',
                                },
                                {
                                  'dark:text-gray-400 text-gray-600':
                                    selectedWalletTab != 'owned',
                                }
                              )}
                              onClick={() => setSelectedWalletTab('owned')}
                            >
                              Owned{' '}
                              {creator.assetCount || 0 > 0
                                ? ' (' + creator.assetCount + ')'
                                : ''}
                            </span>{' '}
                            <span
                              className={cn(
                                'text-lg font-medium tracking-wider cursor-pointer',
                                {
                                  'dark:text-white text-gray-900':
                                    selectedWalletTab === 'listed',
                                },
                                {
                                  'dark:text-gray-400 text-gray-600':
                                    selectedWalletTab != 'listed',
                                }
                              )}
                              onClick={() => setSelectedWalletTab('listed')}
                            >
                              Listed{' '}
                              {creator.listingCount || 0 > 0
                                ? ' (' + creator.listingCount + ')'
                                : ''}
                            </span>
                          </span>
                          {/* <span className="flex-1 flex gap-2 items-center">
                            <span className="text-lg font-bold">On Sale</span>
                          </span> */}
                        </div>
                        <Divider />
                        <div className="min-h-96 max-h-[42rem]">
                          {currentUser && selectedWalletTab === 'owned' && (
                            <OwnedAssetsTab
                              creatorId={currentUser?.uid}
                              forSlideOut={true}
                            />
                          )}
                          {currentUser && selectedWalletTab === 'listed' && (
                            <ForSaleAssets
                              address={currentUser?.uid}
                              compact={true}
                            />
                          )}
                        </div>
                      </div>

                      <div className="hidden relative mt-6 flex-1 px-4 sm:px-6">
                        <div
                          className="h-full flex flex-col gap-2"
                          aria-hidden="true"
                        >
                          {/* {!!currentUser && <CartMain userId={currentUser.uid} />} */}

                          <div className=" border bg-white dark:bg-gray-900 dark:border-gray-700 rounded-2xl  px-4 py-3 items-center flex justify-between">
                            <span className="flex flex-row items-center gap-3">
                              <img
                                src="/img/dmt-pill.png"
                                alt=""
                                className="w-6 h-6 object-fit"
                              />
                              <span className="text-gray-800 dark:text-gray-300 font-bold">
                                Rewards
                              </span>
                            </span>
                            <span className="flex flex-col justify-end items-end">
                              <span className="dark:text-gray-200 font-bold text-lg">
                                10 DMT
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition appear show={isOpenModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-[70] overflow-y-auto"
          onClose={closeModal}
        >
          <Dialog.Overlay className="fixed inset-0 backdrop-filter backdrop-blur-xl backdrop-brightness-75" />
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {error ? (
                <div className="inline-block w-full max-w-xl h-full p-12 my-12 overflow-hidden text-center align-middle transition-all transform bg-white dark:bg-gray-800 border-gray-300 dark:border-gray-700/50 border shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-4xl font-bold sm:leading-6 text-gray-800 dark:text-gray-100"
                  >
                    Error Connecting Wallet
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-lg mt-8 text-gray-700 dark:text-gray-200 font-light flex items-center justify-center h-4">
                      {error}
                    </p>
                    <div className="flex flex-col dark:text-white font-medium text-lg gap-3 mt-6 mb-10">
                      <ButtonPublic onClick={() => clearError()}>
                        Okay
                      </ButtonPublic>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="inline-block w-full max-w-xl h-full p-6 sm:p-12 my-12 overflow-hidden text-center align-middle transition-all transform bg-white dark:bg-gray-900 border-gray-300 dark:border-gray-700/50 border shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-4xl font-bold leading-6 text-gray-800 dark:text-gray-100"
                  >
                    Connect
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-lg mt-8 text-gray-700 dark:text-gray-200 font-light flex items-center justify-center h-4">
                      {!availableWallets ||
                        (!!availableWallets && availableWallets.length == 0 && (
                          <span>Install and activate a supported wallet:</span>
                        ))}
                      {!wallet &&
                        !!availableWallets &&
                        availableWallets.length > 0 && (
                          <span>Connect one of your installed wallets:</span>
                        )}

                      {!!wallet && (
                        <span>
                          {!waitingForWalletLogin && !connecting && (
                            <>Connect with a supported wallet:</>
                          )}
                          {waitingForWalletLogin ||
                            (connecting && (
                              <span className="loading loading-dots loading-lg " />
                            ))}
                        </span>
                      )}
                    </p>
                  </div>

                  <>
                    <div className="flex flex-col dark:text-white font-medium text-lg gap-3 mt-6 mb-10">
                      {SUPPORTED_WALLETS_META.filter((ww) => {
                        return isBrowser
                          ? (ww.internalName !== 'vespr' && ww.internalName !== 'tokeo') && true
                          : ww.mobileSupported === true;
                      }).map((wal) => {
                        const w = availableWallets?.find(
                          (aw) => aw.internalName == wal.internalName
                        );
                        return (
                          <div key={wal.name}>
                            <div
                              className={`${
                                wallet?.name === w?.name
                                  ? 'opacity-60 '
                                  : ' hover:-translate-y-1 hover:bg-white hover:shadow-md hover:scale-102'
                              } group border dark:border-gray-700/50 flex flex-row items-center bg-gray-50  dark:bg-gray-800 rounded-full duration-200 cursor-pointer`}
                            >
                              {!w && (
                                <Link
                                  href={
                                    isMobile
                                      ? wal.deeplinkURL ?? wal.install
                                      : wal.install
                                  }
                                  target={'_blank'}
                                >
                                  <a
                                    className="flex flex-row justify-between w-full rounded-full px-6 py-3 "
                                    target={'_blank'}
                                  >
                                    <div className="flex flex-row gap-4 items-center">
                                      <img
                                        src={wal.icon}
                                        className="w-8 h-8 object-fit"
                                        alt={'icon'}
                                      />

                                      {wal.name}
                                    </div>
                                    <p>Install</p>
                                  </a>
                                </Link>
                              )}
                              {!!w && (
                                <a
                                  className={`${
                                    wallet?.name === w?.name
                                      ? 'cursor-default'
                                      : 'cursor-pointer'
                                  } flex flex-row justify-between w-full rounded-full px-6 py-3 `}
                                  onClick={() => {
                                    wallet?.name !== w.name &&
                                      switchWallet(w)
                                        .then((r) => {
                                          reloadUser();
                                          r === 'success' && closeModal();
                                        })
                                        .catch((err) =>
                                          showError({
                                            message: err.message,
                                            title: 'Error',
                                          })
                                        );
                                  }}
                                >
                                  <div className="flex flex-row gap-4 items-center">
                                    <img
                                      src={wal.icon}
                                      className="w-8 h-8 object-fit"
                                      alt={'icon'}
                                    />
                                    {wal.name}
                                  </div>
                                  <p>
                                    {wallet?.name !== w.name
                                      ? 'Connect'
                                      : 'Connected'}
                                  </p>
                                </a>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>

                  <span className=" text-gray-500">
                    Remember to{' '}
                    <a
                      onClick={() => window.location.reload()}
                      className="text-red-500 cursor-pointer"
                    >
                      refresh
                    </a>{' '}
                    your browser after you have installed a new wallet
                  </span>
                  <BrowserView>
                    <div className="mt-8">
                      <LearnMoreHelp
                        description={'Setup your web wallet'}
                        link={
                          'https://intercom.help/dropspot/en/articles/6188523-how-do-i-set-up-cardano-web-wallet'
                        }
                      />
                    </div>
                  </BrowserView>
                </div>
              )}
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

WalletSlideOut.displayName = 'WalletSlideOut';
