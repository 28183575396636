import React from 'react';
type BadgeProps = {
  label: string;
  size?: string;
  colorName?: string;
  specialIcon?: JSX.Element;
  didSelect?: () => void;
};

export default function DaisyBadge({
  label,
  size = 'badge-lg',
  colorName,
  didSelect,
  specialIcon,
}: BadgeProps) {
  return (
    <div
      className={`py-3 pl-1 pr-2 flex items-center justify-center gap-1 badge ${colorName} ${size} ${
        !!didSelect ? 'cursor-pointer' : 'cursor-auto'
      }`}
      onClick={() => !!didSelect && didSelect()}
    >
      {!!specialIcon && <span className=""> {specialIcon}</span>}
      <span className="pl-1">{label}</span>
      {/* <ArrowTrendingUpIcon className="w-4 h-4" /> */}
    </div>
  );
}
