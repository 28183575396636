import { useAssetStats } from '#lib/hooks/useTTAssetStats';
import { Asset } from '#types/index';
import dayjs from 'dayjs';
import React from 'react';

type AssetStatsProps = {
  asset: Asset;
};
export const AssetStatsView = ({ asset }: AssetStatsProps) => {
  const { statsLoading, assetHistory, assetStats } = useAssetStats(
    asset.policy,
    asset.tokenName,
    asset.id
  );

  if (statsLoading)
    return (
      <>
        <div className="w-full h-full flex items-center justify-center">
          <div className="loading-balls loading"></div>
        </div>
      </>
    );
  return (
    <>
      <div className="flex flex-col text-white">
        <div>Owners: {assetStats?.owners}</div>
      </div>

      <div>
        {!!assetHistory &&
          Object.values(assetHistory).map((stat) => (
            <React.Fragment key={stat.time}>
              <div className="flex flex-row justify-between text-white">
                <div className="font-bold">
                  ₳{stat.price && stat.price.toFixed(0)}
                </div>
                <div className="font-light text-gray-400">
                  {' '}
                  {dayjs(stat.time * 1000).fromNow()}
                </div>
              </div>
            </React.Fragment>
          ))}
      </div>
    </>
  );
};
