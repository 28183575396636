import Image from 'next/image';
import Link from 'next/link';
import cn from 'classnames';
import { useDegenStore } from '#lib/hooks/useGlobalMode';
import { ChevronDownIcon, RocketLaunchIcon } from '@heroicons/react/24/solid';
import { Fragment, useEffect, useState } from 'react';
import { NotificationsMenu } from '#components/NotificationsMenu/NotificationsMenu';
import firebase from '#lib/firebase';

import { useFirebaseCurrentUser } from '#lib/hooks/useFirebaseCurrentUser';
import ConnectWallet from 'components/ConnectWallet/ConnectWallet';
import { WalletMenu } from 'components/UserProfileMenu/WalletMenu';
import { UserProfileMenu } from '#components/UserProfileMenu/UserProfileMenu';
import { Tooltip } from '@nextui-org/react';
import { Search } from '#components/Search/Search';
import WalletSlideOut from '#components/NavigationBar/WalletSlideOut';
import ThemeSwitcher from '#components/NavigationBar/ThemeSwitcher';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import MobileSlideMenu from './MobileMenu';
import { useCardanoWallet, useWalletStore } from '#lib/wallet/WalletContext';
import { useRouter } from 'next/router';
const user = {
  name: 'Tom Cook',
  email: 'tom@example.com',
  imageUrl:
    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
};
const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' },
];
const navigation = [
  { name: 'Collections', href: '/marketplace', current: true },
  { name: 'Explore', href: '/marketplace/explore', current: false },
  { name: 'Trending', href: '/trending', current: false },
  { name: 'Portfolio', href: '/profile', current: false },
];
const navigationCompressed = [
  { name: 'Collections', href: '/marketplace', current: true },
  { name: 'Explore', href: '/marketplace/explore', current: false },
  { name: 'Trending', href: '/trending', current: false },
];

export type Props = {
  preview?: boolean;
  hideSubNav?: boolean;
  selectedSubMenuIndex?: number;
  translucent?: boolean;
  fullWidth?: boolean;
  showFooter?: boolean;
  miniFooter?: boolean;
};

export const NavigationBar = ({
  hideSubNav,
  selectedSubMenuIndex = -1,
  showFooter = true,
  miniFooter = false,
  translucent = false,
}: Props) => {
  const mode = useDegenStore((state) => state.degenMode);
  const selectTheProject = useDegenStore(
    (state) => state.setSelectedCollection
  );
  const { claims, currentUser } = useFirebaseCurrentUser();

  // const [isLiveActionOpen, setIsLiveActionOpen] = useState(false);
  // const { id, photoURL, displayName } = useAuthUser();
  return (
    <Disclosure as="header" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="mx-auto absolute z-40 w-full bg-gray-100/50 dark:bg-white/0 backdrop-blur-xl px-2 sm:px-4 lg:px-12">
            <div className="relative w-full flex h-[5rem] justify-between items-center">
              <div className="xl:w-2/12 min-w-max">
                <SubNav />
              </div>
              <div className="hidden w-full md:flex flex-1 justify-center">
                <Search />
              </div>
              <div className="min-w-max lg:w-4/12 flex justify-end">
                <UserNav
                  currentUser={currentUser}
                  claims={claims}
                  open={open}
                />
              </div>
            </div>
          </div>

          {/* <Disclosure.Panel as="nav" className="lg:hidden" aria-label="Global">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <Link href={item.href} key={item.name}>
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={cn(
                      item.current
                        ? 'bg-gray-900 text-white'
                        : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'block rounded-md py-2 px-3 text-base font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                </Link>
              ))}
            </div>
            <div className="border-t border-gray-700 pb-3 pt-4">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={user.imageUrl}
                    alt=""
                  />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-white">
                    {user.name}
                  </div>
                  <div className="text-sm font-medium text-gray-400">
                    {user.email}
                  </div>
                </div>
                <button
                  type="button"
                  className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-3 space-y-1 px-2">
                {userNavigation.map((item) => (
                  <Link href={item.href} key={item.name}>
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                    >
                      {item.name}
                    </Disclosure.Button>
                  </Link>
                ))}
              </div>
            </div>
          </Disclosure.Panel> */}
        </>
      )}
    </Disclosure>
  );
};

export type UserNavProps = {
  open: boolean;
  currentUser?: firebase.User | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  claims?: { [key: string]: any };
};

const UserNav = ({ open, currentUser, claims }: UserNavProps) => {
  const [isWalletOpen, setIsWalletOpen] = useState(false);
  const { signOut } = useFirebaseCurrentUser();

  useEffect(() => {
    // Define the event listener function
    const selectedWallet = localStorage.getItem('selected-wallet');

    const handleLocalStorageChange = (e: StorageEvent) => {
      if (e.key === 'selected-wallet') {
        if (e.newValue) {
          //check if it is same as selected wallet, if not, window reload
          if (e.newValue !== selectedWallet) {
            window.location.reload();
          }
        } else {
          signOut();
        }
      }
    };

    const handleLocalStorageRemove = (e: StorageEvent) => {
      if (e.key === 'selected-wallet' && !e.newValue) {
        console.log('Storage event (remove) signout', e);
        signOut();
      }
    };

    // Add the event listener for changes
    window.addEventListener('storage', handleLocalStorageChange);

    // Add the event listener for item removal
    window.addEventListener('storage', handleLocalStorageRemove);

    // Clean up the event listeners when the component is unmounted
    return () => {
      window.removeEventListener('storage', handleLocalStorageChange);
      window.removeEventListener('storage', handleLocalStorageRemove);
    };
  }, []);

  const { wallet, stakeAddress, loadingWalletAddress } = useCardanoWallet();

  const { signOutTrigger } = useWalletStore.getState();

  useEffect(() => {
    if (signOutTrigger) {
      signOut();
    }
  }, [signOutTrigger, signOut]);

  return (
    <>
      <div className="relative lg:z-10 lg:ml-4 flex gap-2 lg:items-center">
        <div className="hidden lg:flex">
          <Tooltip
            content={'Creator Launchpad'}
            placement="bottom"
            className="hidden lg:flex"
          >
            <Link href={claims?.creator ? '/create' : '/create/onboard'}>
              <a className=" w-11 h-11 rounded-full hidden hover:bg-white dark:hover:bg-black bg-gray-100/50 dark:bg-gray-900/50 dark:border-gray-700 text-gray-900  dark:text-white  tracking-wider sm:flex justify-center items-center text-sm font-semibold border border-gray-300 hover:scale-105 hover:-translate-y-1 duration-200 transition shadow-sm ">
                <RocketLaunchIcon className="w-6 h-6 text-gray-700 dark:text-white" />
              </a>
            </Link>
          </Tooltip>
        </div>
        <div className="hidden lg:flex items-center">
          {/* <DarkModeSwitcher /> */}
          <ThemeSwitcher />
        </div>
        {!!currentUser?.uid && wallet && (
          <div className="flex flex-row gap-2 lg:gap-3 items-center">
            <NotificationsMenu userId={currentUser?.uid} />
            <UserProfileMenu
              userName={currentUser.displayName}
              userPhoto={currentUser.photoURL}
              userId={currentUser?.uid}
            />

            <WalletMenu
              toggleWalletSlideOut={() => setIsWalletOpen(!isWalletOpen)}
              uid={currentUser.uid}
            />

            {/* <BoltIcon
                        className="w-10 h-10 cursor-pointer"
                        onClick={() => setIsLiveActionOpen(!isLiveActionOpen)}
                      /> */}
          </div>
        )}
        {(!currentUser?.uid || !wallet) && <ConnectWallet navMode={true} />}
        <WalletSlideOut
          isOpen={isWalletOpen}
          close={() => setIsWalletOpen(false)}
        />
      </div>
      <div className="relative z-10 pl-2 flex items-center xl:hidden">
        {/* Mobile menu button */}

        <MobileSlideMenu claims={claims} />

        {/* <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
          <span className="sr-only">Open menu</span>
          {open ? (
            <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
          ) : (
            <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
          )}
        </Disclosure.Button> */}
      </div>
    </>
  );
};

const SubNav = () => {
  const router = useRouter();
  const route = router.pathname;

  return (
    <>
      <div className="relative z-10 flex px-2 lg:px-0 gap-2">
        <div className="flex-shrink-0 items-center hidden sm:flex justify-center dark:hidden pt-1 sm:pt-2">
          <Link href="/">
            <a className="block dark:hidden">
              <Image
                width="120"
                height="24"
                className="block w-auto"
                src="/img/drop-logo-black.webp"
                alt="Dropspot Logo"
              />
            </a>
          </Link>
        </div>
        <div className="flex-shrink-0 items-center hidden justify-center  pt-1 sm:pt-2 sm:flex ">
          <Link href="/">
            <a className="dark:block hidden">
              <Image
                width="120"
                height="24"
                className=" w-auto dark:block hidden"
                src="/img/drop-logo-white.webp"
                alt="Dropspot Logo"
              />
            </a>
          </Link>
        </div>
        <div className="flex-shrink-0 items-center  justify-center flex dark:hidden pt-1 sm:pt-2">
          <Link href="/">
            <a className="sm:hidden">
              <Image
                width="60"
                height="24"
                className="block w-auto sm:hidden"
                src="/img/ds-black.png"
                alt="Dropspot Logo"
              />
            </a>
          </Link>
        </div>
        <div className="flex-shrink-0 items-center justify-center dark:flex hidden pt-1 sm:pt-2 sm:hidden ">
          <Link href="/" className="sm:hidden flex">
            <a className="sm:hidden">
              <Image
                width="60"
                height="24"
                className="block w-auto sm:hidden"
                src="/img/ds-white.png"
                alt="Dropspot Logo"
              />
            </a>
          </Link>
        </div>

        <nav
          className="hidden xl:flex 3xl:hidden space-x-1 2xl:space-x-1 lg:pt-1.5 items-center"
          aria-label="Global"
        >
          <Menu as="div" className="relative ml-4 flex-shrink-0">
            <div>
              <Menu.Button className="btn btn-ghost dark:text-gray-300 text-gray-600 hover:bg-gray-700 hover:text-white gap-1">
                <span className="sr-only">Open user menu</span>
                Marketplace
                <ChevronDownIcon className="w-3" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {navigationCompressed.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <Link href={item.href}>
                        <a
                          className={cn(
                            active ? 'bg-gray-100' : '',
                            'block px-4 py-2 text-sm text-gray-700'
                          )}
                        >
                          {item.name}
                        </a>
                      </Link>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
          <a
            key={'Portfolio1'}
            href={'/profile'}
            className={cn(
              'dark:text-gray-300 text-gray-600 hover:bg-gray-700 hover:text-white btn btn-ghost',
              'inline-flex items-center rounded-md py-2 px-3 text-sm font-medium '
            )}
          >
            Portfolio
          </a>
        </nav>
        <nav
          className="hidden 3xl:flex space-x-1 2xl:space-x-1 lg:pt-1.5 items-center"
          aria-label="Global"
        >
          {navigation.map((item) => (
            <Link key={item.name} href={item.href}>
              <a
                className={cn(
                  item.href === route
                    ? 'dark:text-white btn btn-ghost'
                    : 'dark:text-gray-300 text-gray-600 hover:bg-gray-700 hover:text-white btn btn-ghost',
                  'inline-flex items-center rounded-md py-2 px-3 text-sm font-medium '
                )}
                aria-current={item.href ==='' ? 'page' : undefined}
              >
                {item.name}
              </a>
            </Link>
          ))}
        </nav>
      </div>
    </>
  );
};
