import { Divider } from '#components/Divider/Divider';
import { TextInput } from '#components/Inputs/TextInput/TextInput';
import { AdditionalProperty } from '#types/index';
import { Dialog, Transition } from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { ButtonPublic } from 'components/Button/ButtonPublic';
import { Fragment, useState } from 'react';
import { FC } from 'react';

export interface AdditionalPropertiesProps {
  properties: AdditionalProperty[];
  onChange: (properties: AdditionalProperty[]) => void;
  disabled?: boolean;
}

export const AdditionalProperties: FC<AdditionalPropertiesProps> = ({
  properties,
  onChange,
  disabled,
}) => {
  const [property, setProperty] = useState<AdditionalProperty>({
    name: '',
    value: '',
  });

  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  return (
    <div>
      <div className="w-full flex flex-row flex-wrap gap-4 text-ellipsis overflow-hidden shadow-lg ">
        {properties.map(({ name, value }, index) => {
          return (
            <div
              className="w-full flex flex-col gap-1 bg-white dark:bg-gray-800 rounded-md shadow-lg px-4 py-3"
              key={`${name}.${index}`}
            >
              <div className="flex flex-row justify-between">
                <p className="text-xs font-light text-gray-600 dark:text-gray-400">
                  {name}
                </p>
                {!disabled && (
                  <a
                    className="cursor-pointer"
                    onClick={() => {
                      const data = [...properties];
                      data.splice(index, 1);
                      onChange(data);
                    }}
                  >
                    <XMarkIcon className="h-4 w-4 text-red-600" />
                  </a>
                )}
              </div>
              <p className="text-base text-gray-900 dark:text-white w-96 ">
                {value}
              </p>
            </div>
          );
        })}
      </div>
      <>
        {!disabled && (
          <div className="inset-0 flex items-center justify-center mb-10">
            <ButtonPublic
              onClick={openModal}
              type="White"
              className="mt-10 px-4 py-0 text-sm font-medium  rounded-full focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            >
              <PlusIcon className="w-4 h-4 mr-2" /> Add Tag
            </ButtonPublic>
          </div>
        )}

        <Transition appear show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-40 overflow-y-auto"
            onClose={closeModal}
          >
            <div className="min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0  backdrop-filter backdrop-blur-xl backdrop-brightness-75" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block w-full max-w-xl p-12 my-12 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-gray-900 shadow-xl rounded-2xl">
                  <div className="flex flex-row justify-between">
                    <Dialog.Title
                      as="h3"
                      className="text-2xl font-medium leading-6 text-gray-900 dark:text-white"
                    >
                      Add Tag
                    </Dialog.Title>
                  </div>
                  <div className="mt-4">
                    <Divider />
                  </div>
                  <p className="font-light text-gray-700 dark:text-gray-400">
                    Add additional information to your NFT mint, that describe
                    the NFT in more detail with attribute tags.
                  </p>
                  <div className="w-full flex flex-col gap-6 mb-6 justify-center mt-4">
                    <div className="flex-1">
                      {' '}
                      <TextInput
                        id="name"
                        label="Tag name"
                        placeholder="rarity, color, etc."
                        labelPosition="inside"
                        maxLength={32}
                        onChange={(name) => setProperty({ ...property, name })}
                        value={property.name}
                      />
                    </div>
                    <div className="flex-1">
                      {' '}
                      <TextInput
                        id="name"
                        label="Tag value"
                        placeholder="Red, Blue, etc."
                        labelPosition="inside"
                        maxLength={32}
                        onChange={(value) =>
                          setProperty({ ...property, value })
                        }
                        value={property.value}
                      />
                    </div>
                  </div>
                  <div className="mt-8 pt-12">
                    <ButtonPublic
                      className="rounded-full"
                      isDisabled={!property.name || !property.value}
                      onClick={() => {
                        const data = [...properties];
                        data.push(property);
                        setProperty({ name: '', value: '' });
                        onChange(data);
                        closeModal();
                      }}
                    >
                      SAVE
                    </ButtonPublic>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </>
      {/* {properties.map(({ name, value }, index) => {
        return (
          <div className="w-full flex gap-3" key={`${name}.${index}`}>
            <TextInput
              id={`name.${name}.${index}`}
              label="Name"
              labelPosition="top"
              onChange={() => null}
              value={name}
            />
            <TextInput
              id={`value.${name}.${index}`}
              label="Value"
              labelPosition="top"
              onChange={() => null}
              value={value}
            />
            <Button
              onClick={() => {
                const data = [...properties];
                data.splice(index, 1);
                onChange(data);
              }}
              containerClassName="self-center"
              type="Outline"
            >
              <XCircleIcon className="w-5 h-5 text-accent-1-700" />
            </Button>
          </div>
        );
      })} */}
      {/* <div className="w-full flex flex-row gap-3 mb-12 justify-center items-center">
        <TextInput
          id="name"
          label="Name"
          labelPosition="top"
          onChange={(name) => setProperty({ ...property, name })}
          value={property.name}
        />
        <TextInput
          id="value"
          label="Value"
          labelPosition="top"
          onChange={(value) => setProperty({ ...property, value })}
          value={property.value}
        />
        <ButtonPublic
          onClick={() => {
            const data = [...properties];
            data.push(property);
            setProperty({ name: '', value: '' });
            onChange(data);
          }}
          className="py-0 mt-6"
          containerClassName="self-center"
        >
          <PlusCircleIcon className="w-5 h-5" />
        </ButtonPublic>
      </div> */}
    </div>
  );
};
