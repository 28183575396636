import { NextImage } from '#components/Assets/NextImage';
import { DiscordIcon } from '#components/svg/discord';
import { FirestoreQuery, FirestoreQueryItem } from '#lib/firestore';
import { useProjectTraits } from '#lib/hooks/useProjectTraits';
import { Project, TraitDictionaryV2 } from '#types/index';
import { GlobeAsiaAustraliaIcon } from '@heroicons/react/24/solid';
import { Tooltip } from '@nextui-org/react';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { PolicyStatsHeadline } from './PolicyStatsHeadline';
import Truncate from 'react-truncate';
import React from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import { Checkbox } from '#components/Inputs/Checkbox/Checkbox';
import cn from 'classnames';
import { DaisyTextInput } from '#components/Daisy/DaisyTextInput';

type projectTraitFilterProps = {
  project: Project;
  firestoreQuery: FirestoreQuery;
  setSearchQuery: (firestoreQuery: FirestoreQuery) => void;
  setAlgoliaFilters: (attributeFilters: string | undefined) => void;
};

type Trait = {
  name: string;
  value: string;
};

export const CollectionTraitsSearch = ({
  project,
  firestoreQuery,
  setSearchQuery,
  setAlgoliaFilters,
}: projectTraitFilterProps) => {
  const { loading, traits, traitsV2 } = useProjectTraits(project.id);
  const [selectedTraits, setSelectedTraits] = useState<Trait[]>();

  const [marketStatusExpanded, setMarketStatusExpanded] = useState(false);

  const didToggleTrait = (category: string, trait: string) => {
    // if found, remove it
    //if not found, add it:

    let localTraits: Trait[] = [];
    if (!selectedTraits || (!!selectedTraits && selectedTraits.length === 0)) {
      setSelectedTraits([{ name: category, value: trait }]);
      console.log('didToggleTrait new array init');
      localTraits = [{ name: category, value: trait }];
    } else {
      console.log('didToggleTrait  existing array');
      //determine if trait exists in trait array:
      const found = selectedTraits.find(
        (t) => t.value === trait && t.name == category
      );
      if (found) {
        //remove
        console.log('didToggleTrait found item exaclty, removing');
        const newArrar = selectedTraits.filter((t) => t.value !== trait);
        setSelectedTraits(newArrar);
        localTraits = newArrar;
      }
      if (!found) {
        //check if an item exists in the array for the category:
        const foundCategory = selectedTraits.find((t) => t.name === category);
        if (foundCategory) {
          //remove the category and add the new trait
          const newArrar = selectedTraits.filter((t) => t.name !== category);
          newArrar.push({ name: category, value: trait });
          setSelectedTraits(newArrar);
          localTraits = newArrar;
        } else {
          //add the trait
          const newArrar = selectedTraits;
          newArrar.push({ name: category, value: trait });
          setSelectedTraits(newArrar);
          localTraits = newArrar;
        }
      }
    }
    console.log('didToggleTrait new traits:', JSON.stringify(localTraits));

    //now do the query:
    if (!!localTraits && localTraits.length > 0) {
      console.log(
        'filterstring we have a filter list of local traits',
        localTraits.length
      );
      let filterString = "AND algoliaTraitsL2:'";
      localTraits.map((t, idx) => {
        if (idx < localTraits.length - 1) {
          filterString = filterString.concat(t.value, "' OR algoliaTraitsL2:'");
        } else {
          filterString = filterString.concat(t.value, "'");
        }
      });
      console.log('>>filterstring ', filterString);
      setAlgoliaFilters(filterString);

      const tc = {
        fieldPath: 'algoliaTraitsL2',
        opStr: 'array-contains-any',
        value: localTraits.map((t) => t.value),
      } as FirestoreQueryItem;
      console.log('didToggleTrait trait conditions:', tc);

      const qq: FirestoreQueryItem[] = [];

      qq?.push(tc);
      //add the project:
      qq.push({
        fieldPath: 'projectId',
        opStr: '==',
        value: project.id,
      } as FirestoreQueryItem);

      //now we can add this to the existing query passed in:

      const newQuery = {
        orderBy: firestoreQuery.orderBy,
        desc: firestoreQuery.desc,
        whereClauses: qq,
      } as FirestoreQuery;

      console.log('didToggleTrait new query: ', newQuery);
      setSearchQuery(newQuery);
    } else {
      setAlgoliaFilters(undefined);
      console.log('didToggleTrait needs resetting: ');
      const newQuery = {
        orderBy: firestoreQuery.orderBy,
        desc: firestoreQuery.desc,
        whereClauses: [
          {
            fieldPath: 'projectId',
            opStr: '==',
            value: project.id,
          },
        ],
      } as FirestoreQuery;
      setSearchQuery(newQuery);
    }
  };

  return (
    <div className="flex flex-col">
      <span className="flex flex-col  dark:text-gray-300">
        <>
          {!!traitsV2 && (
            <MyComponent
              data={traitsV2 as MyDataStructure}
              didToggleTrait={didToggleTrait}
            />
          )}

          {!!traits && !traitsV2 && (
            <>
              {Object.entries(traits)
                .sort()
                .map(([key, value]) => (
                  <React.Fragment key={key}>
                    <Disclosure defaultOpen={false}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="border rounded-t-lg dark:border-gray-700/80 py-4 border-gray-300  flex flex-col my-1">
                            <div className="w-full flex items-center justify-between">
                              <h3 className="dark:text-gray-300 text-gray-600 font-bold text-sm">
                                {key}
                              </h3>
                              <span className="flex items-center gap-2 text-sm font-normal">
                                {!!value && (
                                  <>
                                    <div className="badge">90</div>
                                  </>
                                )}
                                <ChevronDownIcon
                                  className={cn('w-4 h-4 duration-200', {
                                    'rotate-180': open,
                                  })}
                                />
                              </span>
                            </div>
                          </Disclosure.Button>
                          <Transition
                            show={open}
                            className="overflow-hidden"
                            enter="transition transition-[max-height] duration-500 ease-in"
                            enterFrom="transform max-h-0"
                            enterTo="transform max-h-screen"
                            leave="transition transition-[max-height] duration-300 ease-out"
                            leaveFrom="transform max-h-screen"
                            leaveTo="transform max-h-0"
                          >
                            <Disclosure.Panel className="text-gray-500  rounded-lg py-2">
                              <p>{JSON.stringify(key)}</p>
                              <TraitsChild
                                category={key}
                                traits={value}
                                didToggleTrait={didToggleTrait}
                              />
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  </React.Fragment>
                ))}
            </>
          )}
          {/* {!!traitsV2 && (
            <>
              {Object.entries(traitsV2)
                .sort()
                .map(([key, value]) => (
                  <React.Fragment key={key}>
                    <Disclosure defaultOpen={false}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="border rounded-t-lg dark:border-gray-700/80 py-4 border-gray-300  flex flex-col my-1">
                            <div className="w-full flex items-center justify-between">
                              <h3 className="dark:text-gray-300 text-gray-600 font-bold text-sm">
                                {key}
                              </h3>
                              <span className="flex items-center gap-2 text-sm font-normal">
                                {!!value && (
                                  <>
                                    <div className="badge">90</div>
                                  </>
                                )}
                                <ChevronDownIcon
                                  className={cn('w-4 h-4 duration-200', {
                                    'rotate-180': !open,
                                  })}
                                />
                              </span>
                            </div>
                          </Disclosure.Button>
                          <Transition
                            show={open}
                            className="overflow-hidden"
                            enter="transition transition-[max-height] duration-500 ease-in"
                            enterFrom="transform max-h-0"
                            enterTo="transform max-h-screen"
                            leave="transition transition-[max-height] duration-300 ease-out"
                            leaveFrom="transform max-h-screen"
                            leaveTo="transform max-h-0"
                          >
                            <Disclosure.Panel className="text-gray-500  rounded-lg py-2">
                              <p>{JSON.stringify(key)}</p>
                              <TraitsChild
                                category={key}
                                traits={value}
                                didToggleTrait={didToggleTrait}
                              />
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  </React.Fragment>
                ))}
            </>
          )} */}
        </>
      </span>
    </div>
  );
};

type TraitsChildProps = {
  category: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  traits: any;
  didToggleTrait: (category: string, trait: string) => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TraitsChild = React.memo(
  ({ category, traits, didToggleTrait }: TraitsChildProps) => {
    const [selectedTrait, setSelectedTrait] = useState<string>();

    // const router: NextRouter = useRouter();
    const [filters, setFilters] = useState<Filter[]>([]);

    // useEffect(() => {
    //   setFilters(getFiltersFromUrl(router));
    // }, [router]);

    const handleUpdateFilter = (
      type: 'trait' | 'status',
      value: string
    ): void => {
      //    const newFilters = updateFilter(type, value, filters);
      //    setFilters(newFilters);
      //  updateFiltersInUrl(newFilters, router);
    };

    const toggleTrait = (trait: string) => {
      console.log('>>toggling: ', trait);
      if (!selectedTrait) {
        setSelectedTrait(trait);
        console.log('new array init');
        handleUpdateFilter('trait', trait);
        didToggleTrait(category, trait);
        return;
      }
      if (selectedTrait === trait) {
        setSelectedTrait(undefined);
        console.log('removing');

        handleUpdateFilter('trait', trait);
        didToggleTrait(category, trait);
        return;
      }
      console.log('Not found - adding)');
      handleUpdateFilter('trait', trait);
      setSelectedTrait(trait);
      didToggleTrait(category, trait);
      return;
    };

    return (
      <>
        <div className="flex flex-wrap gap-1.5">
          {Array.isArray(traits) ? (
            traits.map((childValue, index) => (
              <React.Fragment key={`${index}-${childValue}`}>
                <div
                  onClick={() => {
                    console.log('clicked: ', `${category} > ${childValue}`);
                    toggleTrait(`${category} > ${childValue}`);
                  }}
                  className={cn(
                    'dark:bg-gray-900 bg-gray-200 dark:text-gray-400 font-medium text-gray-800 px-3 py-1 rounded-2xl border dark:border-gray-700/60 border-gray-300 text-sm cursor-pointer hover:-translate-y-0.5 transition-transform hover:scale-105',
                    {
                      'dark:bg-secondary bg-secondary dark:text-white text-gray-100  border-secondary/50 shadow-lg':
                        selectedTrait &&
                        selectedTrait === `${category} > ${childValue}`,
                    }
                  )}
                >
                  <a> {typeof childValue === 'string' && childValue}</a>
                </div>
              </React.Fragment>
            ))
          ) : (
            <div className="">
              <span className="cursor-pointer">
                <label>{category}</label>
              </span>
            </div>
          )}
        </div>
      </>
    );
  }
);

export interface Filter {
  type: 'trait' | 'status';
  value: string;
}

// export const decodeFilters = (filtersEncoded: string): Filter[] => {
//   const filtersBuffer = Buffer.from(filtersEncoded, 'base64');
//   const filtersDecoded = JSON.parse(filtersBuffer.toString());
//   return filtersDecoded;
// };

// const encodeFilters = (filters: Filter[]): string => {
//   const filtersString = JSON.stringify(filters);
//   const filtersEncoded =
//     typeof window !== 'undefined'
//       ? window.btoa(filtersString)
//       : Buffer.from(filtersString).toString('base64');
//   return filtersEncoded;
// };

// export const getFiltersFromUrl = (
//   router: ReturnType<typeof useRouter>
// ): Filter[] => {
//   const filtersEncoded = router.query.filters as string;
//   if (filtersEncoded) {
//     return decodeFilters(filtersEncoded);
//   } else {
//     return [];
//   }
// };

// export const updateFiltersInUrl = (
//   filters: Filter[],
//   router: ReturnType<typeof useRouter>
// ): void => {
//   console.log('updating filters in url: ', filters, router.query.filters);
//   const filtersEncoded = encodeFilters(filters);
//   router.push({ query: { ...router.query, filters: filtersEncoded } });
// };

// export const updateFilter = (
//   type: 'trait' | 'status',
//   value: string,
//   filters: Filter[]
// ): Filter[] => {
//   const index = filters.findIndex((filter) => filter.type === type);
//   if (index >= 0) {
//     filters[index] = { type, value };
//   } else {
//     filters.push({ type, value });
//   }
//   return filters;
// };
type MyDataStructure = {
  [category: string]: {
    [name: string]: number;
  };
};

interface CategoryProps {
  category: string;
  data: { [name: string]: number };
  didToggleTrait: (category: string, trait: string) => void;
}

interface ItemProps {
  name: string;
  count: number;
  didToggleTrait: (trait: string) => void;
  isSelected: boolean;
}

const isObject = (value: unknown): value is { [name: string]: number } => {
  return typeof value === 'object' && value !== null;
};

const MyComponent: React.FC<{
  data: MyDataStructure;
  didToggleTrait: (category: string, trait: string) => void;
}> = ({ data, didToggleTrait }) => {
  return (
    <div>
      {data &&
        Object.entries(data)
          .sort((a, b) => a[0].localeCompare(b[0]))
          .map(([category, items]) => (
            <Category
              key={category}
              category={category}
              data={items}
              didToggleTrait={didToggleTrait}
            />
          ))}
    </div>
  );
};

const Category: React.FC<CategoryProps> = ({
  category,
  data,
  didToggleTrait,
}) => {
  const [selectedTrait, setSelectedTrait] = useState<string>();

  const toggleTrait = (trait: string) => {
    console.log('>>toggling: ', trait);
    if (!selectedTrait) {
      setSelectedTrait(trait);
      console.log('new array init');
      didToggleTrait(category, trait);
      return;
    }
    if (selectedTrait === trait) {
      setSelectedTrait(undefined);
      console.log('removing');

      didToggleTrait(category, trait);
      return;
    }
    console.log('Not found - adding)');
    setSelectedTrait(trait);
    didToggleTrait(category, trait);
    return;
  };
  // Sort the items in ascending order by count
  const [sortedItems, setSortedItems] = useState(
    Object.entries(data).sort((a, b) => a[1] - b[1])
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [categoryFilter, setCategoryFilter] = useState<string | undefined>();

  useEffect(() => {
    console.log('cat changed');
    if (categoryFilter) {
      const filteredItems = Object.entries(data)
        .sort((a, b) => a[1] - b[1])
        .filter(([name]) =>
          name.toUpperCase().includes(categoryFilter.toUpperCase())
        );
      setSortedItems(filteredItems);
    } else {
      setSortedItems(Object.entries(data).sort((a, b) => a[1] - b[1]));
    }
  }, [categoryFilter, data]);

  if (!isObject(data)) {
    return null;
  }
  return (
    <>
      <React.Fragment key={category}>
        <Disclosure defaultOpen={false}>
          {({ open }) => (
            <>
              <Disclosure.Button className="border rounded-t-lg dark:border-gray-700/80 py-2 border-gray-300  flex flex-col my-1 w-full">
                <div className="w-full flex items-center justify-between">
                  <h2 className="w-48 truncate text-left font-medium text-base">
                    {category}
                  </h2>

                  <span className="flex items-center gap-2 text-sm font-normal">
                    <div className="badge">{Object.entries(data).length}</div>
                    <ChevronDownIcon
                      className={cn('w-4 h-4 duration-200', {
                        'rotate-180': open,
                      })}
                    />
                  </span>
                </div>
              </Disclosure.Button>
              <Transition
                show={open}
                className="overflow-hidden"
                enter="transition transition-[max-height] duration-500 ease-in"
                enterFrom="transform max-h-0"
                enterTo="transform max-h-[2000rem]"
                leave="transition transition-[max-height] duration-300 ease-out"
                leaveFrom="transform max-h-[2000rem]"
                leaveTo="transform max-h-0"
              >
                <Disclosure.Panel className="text-gray-500  rounded-lg py-1">
                  <DaisyTextInput
                    specialIcon={<MagnifyingGlassIcon />}
                    placeholder={`Filter ${category}`}
                    id={''}
                    label={''}
                    value={categoryFilter}
                    size="input-sm"
                    onChange={(t) => setCategoryFilter(t)}
                  />
                  <div className="flex flex-wrap gap-1">
                    {sortedItems &&
                      sortedItems.map(([name, count]) => (
                        <Item
                          key={name}
                          name={name}
                          count={count}
                          didToggleTrait={(trait) => {
                            toggleTrait(`${category} > ${trait}`);
                          }}
                          isSelected={`${category} > ${name}` == selectedTrait}
                        />
                      ))}
                  </div>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      </React.Fragment>
    </>
  );
};

const Item: React.FC<ItemProps> = ({
  name,
  count,
  didToggleTrait,
  isSelected,
}) => {
  return (
    <div
      className={cn('btn btn-sm w-full flex justify-between', {
        'btn-secondary': isSelected,
        'btn-ghost': !isSelected,
      })}
      onClick={() => didToggleTrait(name)}
    >
      <p className="w-48 truncate text-left">{name}</p>
      <p className="dark:text-white pl-2 font-normal">{count}</p>
    </div>
  );
};
