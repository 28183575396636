import { Asset, LiveTransaction, Project } from '#types/index';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import cn from 'classnames';
import Link from 'next/link';
import { ImageSwap } from '../DSImage';
import { EyeIcon, PlusIcon } from '@heroicons/react/24/solid';
import { useIsAssetLiked } from '#lib/utils/LikesContext';
import { addAssetToCart, createLikeForAsset } from '#lib/firestore';
import { useNotifier } from 'react-headless-notifier';
import { SuccessNotification } from '#components/Notifications/Notification';
import { useRouter } from 'next/router';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
// import ReactPlayer from 'react-player';

import dynamic from 'next/dynamic';
import { IframeComponent } from '#components/iFrame';
import { NextImage } from '../NextImage';
import { ButtonPublic } from '#components/Button/ButtonPublic';
import {
  MarketActionModal,
  MarketActionModalComponent,
} from '../AssetPage/ChainInteraction/MarketActionModal';
import { ManageListing } from '../AssetPage/ChainInteraction/ManageListing';
import { trpc } from 'pages/_app';
import { ChainUTxO } from '#server/routes/asset/utxo';
import {
  CheckIcon,
  ClockIcon,
  UserIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import React from 'react';
import DaisyButton from '#components/Daisy/DaisyButton';

import { gsap } from 'gsap';
import { Flip } from 'gsap/dist/Flip';
import { AssetCardModalComponent } from './AssetCardModal';
import { removeCharsPriorToHash } from '#lib/utils/stringUtils';
import { AssetAttributesRegion } from '../AssetPage/AssetPageComps/AssetAttributes';
import {
  useMarketActionModalStatus,
  useMarketPerformListing,
  useMarketPerformTransaction,
  useMarketUtxos,
} from '../AssetPage/MarketActionModalV2/MarketStore';
import { TransactionComponent } from '../AssetPage/AssetPageMarketV2';
import { MarketActionModalV2 } from '../AssetPage/MarketActionModalV2/MarketActionModalV2';
import { CreateOfferV2 } from '../AssetPage/MarketActionModalV2/NewListingModalV2';
import { ProcessingTransactionModalComponentV2 } from '../AssetPage/MarketActionModalV2/ProcessingTransactionModalComponentV2';
import { SuperCube } from '../../SuperCubes/SuperCube';
import {
  selectAssetOffers,
  selectReceievedOffers,
  useOfferUIStore,
} from '../AssetPage/MarketActionOffers/OfferUIStore';
import { AddOfferModal } from './AddOfferModal';
import { AssetStatsView } from './AssetStats';
import { Tooltip } from '@nextui-org/react';

const ReactPlayerWrapper = dynamic(() => import('../DSVideoPlayer'), {
  ssr: false,
});

export interface AssetFlipCardProps {
  revealMode?: boolean;
  asset: Asset;
  link: string;
  heightClass?: string;
  inGroupOfferMode?: boolean;
  showLikes?: boolean;
  project?: Project;
  floor?: number;
  showProjectInfo?: boolean;
  avoidHover?: boolean;
  autoPlay?: boolean;
  useB64ForImage?: boolean;
  imageDisplayPreference?: string;
  showRarity?: boolean;
  overrideBaseMediaType?: boolean;
  preventOnchainRender?: boolean;
  viewingUserId?: string;
  viewedFromWallet?: boolean;
  viewedFromForSale?: boolean;
  viewedFromListings?: boolean;
  viewedFromExternalListings?: boolean;
  viewedFromCollected?: boolean;
  isInSelectionMode?: boolean;
  isAssetSelected?: boolean;
  doSelectAsset?: (asset: Asset) => void;
  doEnterSelectionMode?: (asset: Asset) => void;
  showLoader?: boolean;
  transaction?: LiveTransaction;
  showSoldPrice?: boolean;
  showMulti?: boolean;
  setPFP?: ((pfp: string) => void) | undefined;
  hidePrice?: boolean;
}
export const AssetFlipCardPublic = ({
  transaction,
  revealMode = false,
  asset,
  link,
  heightClass = 'h-[28rem]',
  showLikes,
  showProjectInfo = false,
  inGroupOfferMode,
  project,
  floor,
  showRarity = false,
  viewingUserId,
  viewedFromWallet = false,
  viewedFromForSale = false,
  viewedFromListings = false,
  viewedFromExternalListings = false,
  viewedFromCollected = false,
  isInSelectionMode = false,
  isAssetSelected = false,
  imageDisplayPreference,
  doSelectAsset,
  doEnterSelectionMode,
  showLoader,
  showSoldPrice = false,
  showMulti = false,
  setPFP,
  hidePrice = false,
}: AssetFlipCardProps) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const isAssetLiked = useIsAssetLiked(asset.id);
  const [showBuyNow, setShowBuyNow] = useState(false);
  const { notify } = useNotifier();
  const { push } = useRouter();
  // const { ref, inView, entry } = useInView({
  //   /* Optional options */
  //   threshold: 0,
  // });

  gsap.registerPlugin(Flip);

  const offerAssets = useOfferUIStore(
    useCallback((state) => state.assetOffers, [])
  );
  const addAssetOffer = useOfferUIStore(
    useCallback((state) => state.setAssetOffer, [])
  );

  const orderGroupAssets = useLayoutEffect(() => {
    // if (ref.current) {
    // }
    //  console.log('Asset:', asset);
  }, [asset]);

  const [showControls, setShowControls] = useState(false);
  const [assetOfferOpen, setAssetOfferOpen] = useState(false);
  const [videoURL, setVideoURL] = useState<string | undefined>();
  const [gifReplacementURL, setGifReplacementURL] = useState<
    string | undefined
  >();
  const [actionComponent, setActionComponent] = useState<{
    title: string;
    action: 'REPRICE' | 'CREATE LISTING';
    component: MarketActionModalComponent;
  }>();

  const showNotificationAddSuccess = () => {
    notify(<SuccessNotification message="NFT added to your likes 👌" />, {
      max: 1,
      position: 'bottom',
      duration: 2000,
    });
  };
  const router = useRouter();

  const showNotificationRemmoveSuccess = () => {
    notify(<SuccessNotification message="NFT removed from your likes 👌" />, {
      max: 1,
      position: 'bottom',
      duration: 2000,
    });
  };

  const handleAddToCart = async (asset: Asset, userId: string) => {
    console.log('adding: ', asset.tokenName, ' to cart');
    await addAssetToCart(asset, userId);
    console.log('asset added to cart');
  };

  const handleLikeAsset = async () => {
    const action = await createLikeForAsset(asset);
    if (action === 'R') {
      showNotificationRemmoveSuccess();
    } else {
      showNotificationAddSuccess();
    }
  };
  const [hasWindow, setHasWindow] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true);
    }
  }, []);

  const assetOffers = useOfferUIStore(selectAssetOffers);
  useEffect(() => {
    if (
      asset.mediaType == 'image/gif' ||
      asset.mediaType?.startsWith('image')
    ) {
      //look for other assets in files:

      if (!!asset.filesImported) {
        asset.filesImported.forEach((file) => {
          if (file.mediaType == 'video/mp4') {
            setVideoURL(file.dsFile);
            console.log('setting video url>>>>>>> ', file.dsFile);
          }
          if (file.mediaType.startsWith('image')) {
            setGifReplacementURL(file.dsFile);
            console.log('setting gif replace url>>>>>>> ', file.dsFile);
          }
        });
      }
    }
  }, [asset]);
  // useEffect(() => {
  //   if (videoRef.current) {
  //     if (inView) {
  //       videoRef.current.play();
  //     } else {
  //       if (videoRef.current) {
  //         videoRef.current.pause();
  //       }
  //     }
  //   }
  // }, [inView]);

  function useTimeAgo(timestamp: unknown) {
    const [timeAgo, setTimeAgo] = useState('');

    useEffect(() => {
      if (!timestamp) return;
      if (typeof timestamp !== 'number') return; // Check if timestamp is a number

      const interval = setInterval(() => {
        const now = Date.now();
        const diff = now - timestamp;

        if (diff < 60 * 1000) {
          // Less than a minute ago
          setTimeAgo(`${Math.floor(diff / 1000)} sec ago`);
        } else if (diff < 60 * 60 * 1000) {
          // Less than an hour ago
          setTimeAgo(`${Math.floor(diff / (60 * 1000))} min ago`);
        } else {
          // More than an hour ago
          setTimeAgo(`${Math.floor(diff / (60 * 60 * 1000))} hr ago`);
        }
      }, 1000); // Update every second

      return () => clearInterval(interval);
    }, [timestamp]);

    return timeAgo;
  }

  const timeAgo = useTimeAgo(transaction?.createdDate);
  return (
    <div className="w-full h-full relative">
      <div
        className={`absolute w-full h-full z-10 flex-col justify-center ${
          revealMode ? 'flex' : 'hidden'
        }`}
      >
        <span
          className={` mx-auto  loading loading-rings loading-lg z-[100] opacity-75`}
        />
      </div>

      <AddOfferModal
        asset={asset}
        open={assetOfferOpen}
        floor={floor}
        assetAdded={() => setAssetOfferOpen(false)}
      />
      {actionComponent && (
        <MarketActionModal
          action={actionComponent.action}
          title={actionComponent.title}
          onClose={(withDelay?: boolean) => {
            setTimeout(
              function () {
                setActionComponent(undefined);
              },
              withDelay ? 2500 : 0
            );
            console.log('Set action undefined');
          }}
          Component={actionComponent.component}
        />
      )}

      <div className={cn('flex-col flex group')}>
        <div
          className={cn(
            `${heightClass} group perspective duration-150 rounded-xl  hover:-translate-y-1`,
            // { 'cursor-not-allowed': !asset.id },
            { 'cursor-pointer': !!asset.id && !revealMode },
            { 'blur-md cursor-not-allowed': revealMode == true }
          )}
          onMouseLeave={() => {
            setShowControls(false);
            setIsFlipped(false);
            setShowBuyNow(false);

            // console.log('should stop:', showControls);
          }}
          onMouseOver={() => {
            setShowControls(true);
            if (
              asset.marketStatus == 'LISTED' ||
              asset.marketStatus == 'Listed'
            ) {
              setShowBuyNow(true);
            }
            //console.log('should play:', showControls);
          }}
        >
          <div
            className={cn(
              'relative preserve-3d w-full h-full duration-200 ease-in-out rounded-2xl shadow-xl group-hover:shadow-2xl ',
              { 'my-rotate-y-180': isFlipped == true }
            )}
          >
            {asset.txInProgress && (
              <span className="flex h-5 w-5 m-1 absolute -top-3 -right-3 z-20">
                <span className="animate-ping inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                <span className="text-sm text-white font-bold absolute inline-flex rounded-full m-0.5 h-4 w-4 bg-sky-500 items-center justify-center p-1"></span>
              </span>
            )}
            <div
              className={cn(
                'absolute backface-hidden w-full h-full rounded-lg overflow-hidden flex flex-col transition duration-200',
                {
                  'border-2 border-sky-500 animate-pulse ':
                    !!asset.txInProgress,
                }
              )}
            >
              <div
                className={cn(
                  'dark:bg-gray-900 h-[7rem] w-full absolute z-40 bottom-0 left-0 transition duration flex items-center justify-center hover:scale-105',
                  { 'translate-y-[7rem]': !showBuyNow },
                  { 'translate-y-[7rem]': showBuyNow }
                )}
              ></div>
              {isInSelectionMode && (
                <div
                  onClick={() => {
                    console.log('Did Select');
                    if (!!doSelectAsset) {
                      doSelectAsset(asset);
                    }
                    if (inGroupOfferMode) {
                      //      addAssetOffer(asset, 99);
                      if (assetOffers.size < 20) {
                        setAssetOfferOpen(true);
                      }
                    }
                  }}
                  className={cn(
                    'w-full h-full absolute z-30 bg-black/70 border-4 rounded-lg flex flex-col justify-center items-center gap-1',
                    { 'border-yellow-500': !isAssetSelected },
                    { 'border-green-500': isAssetSelected }
                  )}
                >
                  <span>
                    {isAssetSelected && (
                      <CheckIcon className="w-7 h-7 font-bold text-green-500" />
                    )}
                    {!isAssetSelected && (
                      <PlusIcon className="w-7 h-7 font-bold text-white" />
                    )}
                  </span>
                  <span className="font-bold tracking-wider text-lg text-white">
                    {isAssetSelected && (
                      <span className="text-green-500"> Added to Listing</span>
                    )}
                    {!isAssetSelected && inGroupOfferMode && (
                      <span> Add to Offer</span>
                    )}
                    {!isAssetSelected && !inGroupOfferMode && (
                      <span> Add to Listing</span>
                    )}
                  </span>
                </div>
              )}

              <div className="relative w-full h-full bg-white dark:bg-black">
                {setPFP && (
                  <div className="absolute bottom-1 right-1 z-10">
                    <Tooltip content="Set as PFP" placement="left">
                      <DaisyButton
                        label=""
                        icon={<UserPlusIcon className="w-5 h-5" />}
                        size="btn-sm"
                        colorName="btn-ghost"
                        classNames="hover:opacity-100 opacity-[75%]"
                        onClick={() => {
                          setPFP(asset.assetUrl);
                        }}
                      />
                    </Tooltip>
                  </div>
                )}
                <Link
                  href={
                    !!revealMode
                      ? '#'
                      : !!link
                      ? link
                      : asset.id
                      ? `/nft/${asset.id}`
                      : '#'
                  }
                  scroll={true}
                >
                  <a
                    className={cn('h-full w-full', {
                      'cursor-not-allowed': !asset.id || revealMode,
                    })}
                  >
                    {transaction?.asset && (
                      <div
                        className={`absolute leading-4 flex flex-col items-end top-[0.75rem] right-[0.75rem]  z-50  bg-opacity-90 text-white rounded-2xl px-1 border ${
                          transaction.type === 'listed'
                            ? 'border-accent bg-accent'
                            : 'border-[#D926A9] bg-[#D926A9]'
                        }`}
                      >
                        <span className="text-sm font-bold">
                          {transaction.type === 'sale' ? 'Sold' : 'Listed'}
                        </span>
                      </div>
                    )}
                    {transaction?.asset && (
                      <div
                        className={`absolute  flex items-center  w-max bottom-[0.5rem] right-[0.5rem] py-[2px] z-50 gap-1 bg-opacity-90 text-gray-900 dark:text-gray-300 rounded-2xl px-1 dark:bg-gray-800 bg-gray-50 text-sm `}
                      >
                        <ClockIcon className="w-5 h-5" />

                        <span className="flex w-max font-light text-xs pr-1 ">
                          {timeAgo
                            ? timeAgo
                            : transaction?.createdDate &&
                              (() => {
                                const now = Date.now();
                                const diff = now - transaction.createdDate;
                                if (diff < 60 * 1000) {
                                  return `${Math.floor(diff / 1000)} sec ago`;
                                } else if (diff < 60 * 60 * 1000) {
                                  return `${Math.floor(
                                    diff / (60 * 1000)
                                  )} min ago`;
                                } else {
                                  return `${Math.floor(
                                    diff / (60 * 60 * 1000)
                                  )} hr ago`;
                                }
                              })()}{' '}
                        </span>
                      </div>
                    )}
                    {/* <NextImage
                      className={
                        asset.imageDisplayPreference == 'object-contain'
                          ? 'object-contain'
                          : 'object-cover'
                      }
                      src={
                        asset.mediaType == 'image/svg+xml'
                          ? asset.assetUrl
                          : asset.thumbnailUrl
                      }
                      width={400}
                      layout="fill"
                      b64={asset.thumbnailB64}
                      priority={false}
                    /> */}

                    {asset.fileType?.startsWith('video/') ||
                    !!videoURL ||
                    (!!asset.videoOveride && asset.videoOveride.length > 0) ? (
                      <div
                        className="absolute top-0 w-full h-full"
                        onMouseLeave={() => {
                          setShowControls(false);
                          //  console.log('should stop:', showControls);
                        }}
                        onMouseOver={() => {
                          setShowControls(true);
                          console.log('should play:', showControls);
                        }}
                      >
                        <span className="z-0 absolute w-full h-full">
                          <NextImage
                            className={
                              asset.imageDisplayPreference == 'object-contain'
                                ? 'object-contain'
                                : 'object-cover'
                            }
                            src={
                              asset.mediaType == 'image/svg+xml'
                                ? asset.assetUrl
                                : asset.thumbnailUrl
                                ? asset.thumbnailUrl &&
                                  asset.thumbnailUrl != 'holding'
                                  ? asset.thumbnailUrl
                                  : 'https://media.dropspot.io/placeholders%2FNo-Image-Placeholder.svg.png'
                                : asset.assetUrl && asset.assetUrl != 'holding'
                                ? asset.assetUrl
                                : 'https://media.dropspot.io/placeholders%2FNo-Image-Placeholder.svg.png'
                            }
                            width={400}
                            layout="fill"
                            b64={asset.thumbnailB64}
                            priority={false}
                            avoidCloudflare={
                              asset.mediaType == 'image/svg+xml' ||
                              asset.mediaType == 'image/gif' ||
                              parseInt(asset.width || '0') > 6000
                            }
                          />
                        </span>
                        {showControls && (
                          <div className=" absolute w-full z-0 h-full overflow-hidden object-contain bg-gray-800">
                            <ReactPlayerWrapper
                              src={
                                !!asset.videoOveride &&
                                asset.videoOveride.length > 0
                                  ? asset.videoOveride
                                  : !!videoURL
                                  ? videoURL
                                  : asset.assetUrl
                              }
                              shouldPlay={showControls}
                              showControls={showControls}
                              shouldContain={
                                asset.imageDisplayPreference == 'object-contain'
                              }
                              muted={true}
                            />
                          </div>
                        )}
                      </div>
                    ) : asset.assetType == 'ONCHAIN-HTML' &&
                      showControls &&
                      !asset.preventOnchainRender ? (
                      <div className="h-full w-full relative">
                        <div className="absolute z-10 h-full w-full"></div>
                        <IframeComponent
                          classNames="w-full h-full bg-black"
                          src={
                            (asset.files &&
                              asset.files
                                ?.map((f) => {
                                  const srcArray = f.src as string[];
                                  // Check if srcArray is an array before using reduce
                                  if (Array.isArray(srcArray)) {
                                    return srcArray.reduce(
                                      (str, conc) => `${str}${conc}`,
                                      ''
                                    );
                                  } else {
                                    // Handle the case when srcArray is not an array
                                    console.error(
                                      'srcArray is not an array:',
                                      srcArray
                                    );
                                    return ''; // or handle it in a way that makes sense for your application
                                  }
                                })
                                .toString()) ||
                            ''
                          }
                        />
                      </div>
                    ) : (
                      <div className="relative w-full h-full">
                        <NextImage
                          className={
                            asset.imageDisplayPreference == 'object-contain'
                              ? 'object-contain'
                              : 'object-cover'
                          }
                          src={
                            asset.mediaType == 'image/svg+xml'
                              ? asset.assetUrl
                              : asset.thumbnailUrl
                              ? asset.thumbnailUrl &&
                                asset.thumbnailUrl != 'holding'
                                ? asset.thumbnailUrl
                                : 'https://media.dropspot.io/placeholders%2FNo-Image-Placeholder.svg.png'
                              : asset.assetUrl && asset.assetUrl != 'holding'
                              ? asset.assetUrl
                              : 'https://media.dropspot.io/placeholders%2FNo-Image-Placeholder.svg.png'
                          }
                          width={400}
                          layout="fill"
                          b64={asset.thumbnailB64}
                          priority={false}
                          avoidCloudflare={
                            asset.mediaType == 'image/svg+xml' ||
                            asset.mediaType == 'image/gif' ||
                            parseInt(asset.width || '0') > 6000
                          }
                        />
                      </div>
                    )}
                  </a>
                </Link>
              </div>

              <div className="bg-white dark:bg-gray-900 flex-grow ">
                <CardContent
                  transaction={transaction}
                  revealMode={revealMode}
                  asset={asset}
                  showRarity={true}
                  project={project}
                  showProjectInfo={showProjectInfo}
                  viewedFromWallet={viewedFromWallet}
                  viewedFromForSale={viewedFromForSale}
                  viewedFromListings={viewedFromListings}
                  viewedFromExternalListings={viewedFromExternalListings}
                  viewedFromCollected={viewedFromCollected}
                  link={link}
                  doEnterSelectionMode={(a) =>
                    doEnterSelectionMode && doEnterSelectionMode(a)
                  }
                  doSetFlipped={(flipped) => {
                    setIsFlipped(flipped);
                    if (flipped) {
                      firebase.analytics().logEvent('asset_flip', {
                        policy: asset.policy,
                        assetId: asset.id,
                        assetName: asset.tokenName,
                      });
                    }
                  }}
                  showSoldPrice={showSoldPrice}
                  showMulti
                  hidePrice={hidePrice}
                  isInSelectionMode={isInSelectionMode}
                />
              </div>
            </div>
            <AssetBackFace asset={asset} isFlipped={isFlipped} />
          </div>
        </div>
      </div>
    </div>
  );
};

type AssetBackFaceProps = {
  asset: Asset;
  isFlipped: boolean;
};

const AssetBackFace = ({ asset, isFlipped }: AssetBackFaceProps) => {
  const [mode, setMode] = useState('ATTRIBUTES');
  return (
    <>
      {' '}
      <div className="absolute my-rotate-y-180 backface-hidden w-full h-full bg-white overflow-hidden shadow-2xl rounded-2xl">
        <NextImage
          src={`${asset.thumbnailB64}`}
          priority={false}
          layout="fill"
          className="object-cover w-full rounded-2xl flip z-0"
        />
        <div className="flex flex-col gap-2 items-center py-6 px-3 pb-1  h-full  w-full text-gray-800 bg-white/70 dark:bg-black/20 backdrop-blur-xl backdrop-brightness-50 absolute z-10 top-0">
          <div className="text-xl text-white w-full break-words text-center">
            {asset.title == 'UNSET' ? asset.tokenName : asset.title}
          </div>
          <div className="text-white/50 font-normal text-sm flex gap-2">
            <span>
              <DaisyButton
                label="Traits"
                size="btn-xs"
                colorName="btn-ghost"
                onClick={() => setMode('ATTRIBUTES')}
              />
            </span>
            <span>
              <DaisyButton
                label="Stats"
                size="btn-xs"
                colorName="btn-ghost"
                onClick={() => setMode('STATS')}
              />
            </span>
            <span>
              <DaisyButton
                label="Rarity"
                size="btn-xs"
                colorName="btn-ghost"
                onClick={() => setMode('SCORECARD')}
              />
            </span>
          </div>
          <div className="w-full h-[22rem] overflow-hidden overflow-y-scroll scrollbar-hide">
            <div className="flex w-full mt-2 flex-col gap-2">
              {isFlipped && mode == 'STATS' && <AssetStatsView asset={asset} />}
              {isFlipped && mode == 'ATTRIBUTES' && (
                <>
                  <AssetAttributesRegion asset={asset} mini={true} />
                </>
              )}
              {isFlipped && mode == 'SCORECARD' && (
                <>
                  <div className="flex flex-row w-full justify-between">
                    <p className="text-gray-50 text-sm">Status</p>
                    <div className="font-bold text-white flex flex-row items-center gap-2">
                      {!!asset.rarityStatus &&
                        asset.rarityStatus != 'Unkown' &&
                        asset.showRarity && (
                          <img
                            src={`/img/rarity/${asset.rarityStatus}-V1.png`}
                            className="w-4 h-3.5 fill-red-500 text-red-500 object-fit"
                            alt=""
                          />
                        )}
                      {asset.rarityStatus}
                    </div>
                  </div>
                  <div className="flex flex-row w-full justify-between">
                    <p className="text-gray-50 text-sm">Rarity</p>
                    <div className="font-bold text-white">
                      {asset.rarityRankPercentage &&
                        asset.rarityRankPercentage?.toFixed(0)}
                      %
                    </div>
                  </div>

                  <div className="flex flex-row w-full justify-between">
                    <p className="text-gray-50 text-sm">Estimate</p>
                    <div className="font-bold text-white">
                      ₳{' '}
                      {asset.priceEstimate &&
                        ((asset.priceEstimate || 0) / 1_000_000).toFixed(0)}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const AssetCardMemo = React.memo(AssetFlipCardPublic);

type CardContentProps = {
  revealMode?: boolean;
  asset: Asset;
  showRarity?: boolean;
  showProjectInfo?: boolean;
  viewedFromWallet?: boolean;
  viewedFromForSale?: boolean;
  viewedFromListings?: boolean;
  viewedFromExternalListings?: boolean;
  viewedFromCollected?: boolean;
  project?: Project;
  link?: string;
  doEnterSelectionMode: (asset: Asset) => void;
  doSetFlipped: (isFlipped: boolean) => void;
  transaction?: LiveTransaction;
  showSoldPrice?: boolean;
  showMulti?: boolean;
  hidePrice?: boolean;
  isInSelectionMode?: boolean;
};

export const CardContent = ({
  transaction,
  revealMode,
  asset,
  showRarity,
  showProjectInfo,
  viewedFromWallet,
  viewedFromForSale,
  viewedFromListings,
  viewedFromExternalListings,
  viewedFromCollected,
  link,
  doEnterSelectionMode,
  doSetFlipped,
  project,
  showSoldPrice,
  showMulti = false,
  hidePrice = false,
  isInSelectionMode = false,
}: CardContentProps) => {
  const push = useRouter().push;
  const receivedOffers = useOfferUIStore(selectReceievedOffers);
  const actionableOffers = receivedOffers
    ?.map((offer) => offer.details.filter((d) => d.policy == asset.policy))
    .flat();
  return (
    <>
      {' '}
      <div className="flex  flex-col justify-between p-2 sm:py-4 sm:px-4 z-20 gap-1 bg-gray-50 dark:bg-gray-900 rounded-b-xl">
        <div className="flex flex-col justify-between items-start mr-0.5">
          <span className="w-full flex items-center justify-between">
            <p className="text-gray-900  dark:text-gray-100 font-bold break-words w-10/12 ">
              {asset.title == 'UNSET' ? asset.tokenName : asset.title}
            </p>{' '}
            <span className="flex items-center gap-2 ">
              {asset.quantity && asset.quantity > 1 && (
                <span className="text-xs">{asset.quantity}x</span>
              )}
              <SuperCube
                policy={asset.policy || ''}
                listedOnSuperCubeContract
                className=""
                width={15}
                height={15}
              />
              <EyeIcon
                className={`${
                  revealMode
                    ? 'cursor-not-allowed'
                    : 'cursor-pointer hover:text-secondary'
                } w-4 h-4  dark:text-gray-400 text-gray-500  `}
                onMouseEnter={() => {
                  if (!revealMode) {
                    console.log('setting flipped');
                    doSetFlipped(true);
                  }
                }}
              />
            </span>
          </span>

          {showProjectInfo && (
            <div className="group w-full flex flex-row items-center gap-1 mb-1">
              <span
                onClick={() => push(!!link ? link : `/policy/${asset.policy}`)}
                className=" hover:text-black dark:hover:text-white text-sm text-gray-800 dark:text-gray-300 "
              >
                {(asset.algoliaProjectFacet &&
                  asset.id &&
                  JSON.parse(asset.algoliaProjectFacet).title) ||
                  asset.asset_text ||
                  asset.tokenName ||
                  'Collection not activated'}
              </span>
            </div>
          )}
        </div>

        <div className="flex flex-row justify-between min-h-6">
          {!viewedFromExternalListings && 
          !isInSelectionMode &&
          (viewedFromForSale || viewedFromCollected) &&
          !viewedFromListings &&
          viewedFromWallet &&
          !!asset.policy &&
          !!asset.id ? (
            <div className="flex flex-row gap-2">
              {/* {actionableOffers && actionableOffers.length > 0 && (
                <>
                  <DaisyButton
                    label="Accept Offer"
                    size="btn-xs"
                    colorName="btn-accent"
                    onClick={() => {
                      console.log('Setting selection mode to true.0');
                      //     setIsInSelectionModeLocal(true);
                      if (doEnterSelectionMode) {
                        console.log('Setting selection mode to true.1');
                        doEnterSelectionMode(asset);
                      }
                      console.log('Setting selection mode to true.2');
                    }}
                  />
                </>
              )} */}
              {/* {!showMulti && ( */}
              <DaisyButton
                label="List for Sale"
                size="btn-xs"
                colorName="btn-secondary"
                onClick={() => {
                  console.log('Setting selection mode to true.0');
                  //     setIsInSelectionModeLocal(true);
                  if (doEnterSelectionMode) {
                    console.log('Setting selection mode to true.1');
                    doEnterSelectionMode(asset);
                  }
                  console.log('Setting selection mode to true.2');
                }}
              />
              {/* )} */}
            </div>
          ) : (viewedFromListings || viewedFromExternalListings) &&
            !!asset.policy &&
            !!asset.id ? (
            <>
              <div className="flex flex-col flex-initial w-max">
                ₳{' '}
                {asset.price &&
                  (asset.price / 1_000_000)
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </>
          ) : (
            <>
              <div className="flex-1 gap-3 flex flex-row justify-between items-center ">
                {((!hidePrice &&
                  asset.marketStatus?.toUpperCase() == 'LISTED') ||
                  showSoldPrice) && (
                  <>
                    <div className="flex flex-col flex-initial w-max">
                      {`₳ ${Intl.NumberFormat('en-US', {
                        notation: 'compact',
                        maximumFractionDigits: 1,
                      }).format(
                        (transaction?.price ||
                          transaction?.pricePaid ||
                          asset.price ||
                          0) / 1_000_000
                      )}`}
                    </div>
                  </>
                )}

                {(asset.marketStatus == 'UNLISTED' ||
                  !asset.marketStatus ||
                  asset.marketStatus == 'Unlisted') &&
                  project?.source == 'Dropspot' && (
                    <>
                      <div className="flex flex-row items-center gap-2">
                        <div className="bg-pink-500 w-2 h-2 rounded-full" />{' '}
                        <div className="text-sm font-semibold text-gray-600 dark:text-gray-500">
                          Sold
                        </div>
                      </div>
                    </>
                  )}

                {asset.marketStatus == 'Redeemable' && (
                  <>
                    <div className="text-base font-semibold text-gray-900 dark:text-gray-100">
                      Scan QR
                    </div>
                  </>
                )}
                {asset.marketStatus == 'Claimed' && (
                  <>
                    <div className="flex flex-row items-center gap-2">
                      <div className="w-2 h-2 bg-red-500 rounded-full"></div>
                      <p className="text-base font-semibold text-gray-900 dark:text-gray-100">
                        Redeemed
                      </p>
                    </div>
                  </>
                )}
                <div className=" flex-1">
                  <span
                    className={cn(
                      'flex flex-1 gap-2 w-full items-center ',
                      {
                        'justify-between': viewedFromWallet,
                      },
                      {
                        'justify-end': !viewedFromWallet,
                      }
                    )}
                  >
                    {/* {!!viewingUserId && (
                          <div className="flex flex-col items-baseline justify-end">
                            <Tooltip
                              content={
                                !!isAssetLiked ? 'Add to cart' : 'Add to cart'
                              }
                            >
                              <ShoppingCartIcon
                                className={classNames(
                                  !!isAssetLiked
                                    ? 'text-blue--500'
                                    : 'text-gray-600 opacity-80 dark:text-gray-500',
                                  'w-5 h-5 hover:scale-105 hover:text-blue-500 transform duration-200 cursor-pointer'
                                )}
                                onClick={(e) => {
                                  handleAddToCart(asset, viewingUserId);
                                  e.preventDefault();
                                }}
                              />
                            </Tooltip>
                          </div>]
                        )} */}

                    {/* {showLikes && (
                            <div className="flex flex-col items-baseline justify-end">
                              <Tooltip
                                content={
                                  !!isAssetLiked
                                    ? 'Remove from liked NFTs'
                                    : 'Add to liked NFTs'
                                }
                              >
                                <HeartIcon
                                  className={classNames(
                                    !!isAssetLiked
                                      ? 'text-red-500'
                                      : 'text-gray-600 opacity-80 dark:text-gray-500',
                                    'w-5 h-5 hover:scale-105 hover:text-red-500 transform duration-200 cursor-pointer'
                                  )}
                                  onClick={(e) => {
                                    handleLikeAsset();
                                    e.preventDefault();
                                  }}
                                />
                              </Tooltip>
                            </div>
                          )} */}
                  </span>
                </div>
              </div>
            </>
          )}

          <span className={`flex flex-row gap-1 items-center `}>
            {(!viewedFromForSale || !viewedFromExternalListings) &&
              (!viewedFromForSale || !viewedFromListings) &&
              !!asset.rarityStatus &&
              asset.rarityStatus != 'Unkown' &&
              showRarity && (
                <img
                  src={`/img/${asset.rarityStatus}.png`}
                  className="w-4 h-3.5 fill-red-500 text-red-500 object-fit"
                  alt=""
                />
              )}
            {!asset.txInProgress && (
              <AssetCardModalComponent
                asset={asset}
                project={project}
                label={viewedFromForSale ? 'Manage Listing' : 'Details'}
              />
            )}
          </span>
        </div>
      </div>
    </>
  );
};
