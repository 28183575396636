/* eslint-disable @typescript-eslint/no-explicit-any */
import { connectRange } from 'react-instantsearch-dom';
import React from 'react';
import Slider from 'rc-slider';
import Range from 'rc-slider';
import TooltipSlider from '#components/Inputs/Slider/TooltipSlider';
// Prerequisite: install rheostat@4

interface DSRangeProps {
  min?: number;
  max?: number;
  currentRefinement: any;
  refine: any;
  canRefine: boolean;
}

const RS = ({
  min = 0,
  max = 100000,
  currentRefinement,
  refine,
  canRefine,
}: DSRangeProps) => {
  return (
    <>
      <div className="flex justify-between">
        <span>A{(currentRefinement.min / 1_000_000).toFixed(0)}</span>
        <span>A{(currentRefinement.max / 1_000_000).toFixed(0)}</span>
      </div>
      <div className="my-4 mx-4">
        <TooltipSlider
          range
          min={min}
          max={max}
          trackStyle={{ backgroundColor: '#FF00AC', height: 9 }}
          handleStyle={{
            borderColor: '#FF00AC',
            height: 24,
            width: 24,
            marginLeft: -4,
            marginTop: -7,
            backgroundColor: 'black',
          }}
          defaultValue={[min, max]}
          tipFormatter={(value) => `${(value / 1_000_000).toFixed(0)}`}
          tipProps={undefined}
          onChange={(values) => {
            const [min, max] = values as number[];
            if (
              currentRefinement.min !== min ||
              currentRefinement.max !== max
            ) {
              console.log('refine!!');
              refine({ min, max });
            }
          }}
        />
      </div>
    </>
  );
};

const RS2 = ({
  min = 0,
  max = 100000,
  currentRefinement,
  refine,
  canRefine,
}: DSRangeProps) => {
  const [stateMin, setStateMin] = React.useState<number>(min);
  const [stateMax, setStateMax] = React.useState<number>(max);

  React.useEffect(() => {
    if (canRefine) {
      setStateMin(currentRefinement.min);
      setStateMax(currentRefinement.max);
    }
  }, [currentRefinement.min, currentRefinement.max, canRefine]);

  if (min === max) {
    return null;
  }
  const onChange = (value: number | number[]) => {
    if (Array.isArray(value)) {
      const [min, max] = value;
      if (stateMin !== min || stateMax !== max) {
        setStateMin(min);
        setStateMax(max);
        refine({ min, max });
      }
    }
  };

  const onValuesUpdated = ({
    values: [min, max],
  }: {
    values: [number, number];
  }) => {
    setStateMin(min);
    setStateMax(max);
  };
  return (
    <>
      <div className="flex justify-between">
        <span>A{(currentRefinement.min / 1_000_000).toFixed(0)}</span>
        <span>A{(currentRefinement.max / 1_000_000).toFixed(0)}</span>
      </div>
      <div className="my-4 mx-4">
        <TooltipSlider
          range
          min={min}
          max={max}
          trackStyle={{ backgroundColor: '#FF00AC', height: 9 }}
          handleStyle={{
            borderColor: '#FF00AC',
            height: 24,
            width: 24,
            marginLeft: -4,
            marginTop: -7,
            backgroundColor: 'black',
          }}
          defaultValue={[min, max]}
          tipFormatter={(value) => `${(value / 1_000_000).toFixed(0)}`}
          tipProps={undefined}
          value={[currentRefinement.min, currentRefinement.max]}
          onChange={onChange}
        />
      </div>
    </>
  );
};

export const DropspotRangeSlider = connectRange(RS);
export const DropspotRangeSlider2 = connectRange(RS2);
