import { LearnMoreHelp } from '#components/InlineHelp/LearnMoreHelp';
import { Checkbox } from '#components/Inputs/Checkbox/Checkbox';
import { triggerAsyncId } from 'async_hooks';
import { ButtonPublic } from 'components/Button/ButtonPublic';
import { Divider } from 'components/Divider/Divider';
import { AdditionalProperties } from '../AdditionalProperties/AdditionalProperties';
import { AssetMaintenance } from '../AssetCreatOrEdit/AssetCreateOrEdit';
import { EventAssetCard } from '../EventAssetCard/EventAssetCard';
import { AdditionalProperty } from '../../../types';

export const AssetAttributesTab = ({
  projectId,
  asset,
  setAsset,
  changeAsset,
  errors,
  disabled,
  working,
}: AssetMaintenance) => (
  <>
    <div className="flex flex-row w-full mt-8 mb-10 gap-10">
      <div className="flex-initial w-5/12 justify-center  mb-12">
        <EventAssetCard
          asset={asset}
          heightClass="h-large"
          link={`/create/collection/${projectId}/${asset.id}`}
        />
      </div>

      <div className="flex-1 justify-center flex flex-col">
        <h2 className="text-4xl font-bold text-gray-800 dark:text-gray-100">
          Add attribute tags.
        </h2>
        <p className="text-base font-light text-gray-700 my-4 dark:text-gray-300">
          Add additional tags to your NFT to further describe your artwork.
          These will be minted with your NFT.
        </p>
        <Divider />
        <div className="min-h-[18rem] flex flex-col justify-between">
          <AdditionalProperties
            onChange={(props) => {
              setAsset({
                ...asset,
                additionalProperties: fromAdditionalProperties(props),
              });
            }}
            disabled={disabled}
            properties={toAdditionalProperties(asset.additionalProperties)}
          />

          {!disabled && (
            <div className="flex flex-row gap-2 items-center my-2">
              <Checkbox
                checked={asset.attributesSkipped == true}
                size="xs"
                onChange={() =>
                  setAsset({
                    ...asset,
                    attributesSkipped: !asset.attributesSkipped,
                  })
                }
              />
              <span className="text-gray-800 dark:text-gray-300 my-4">
                Skip this step
              </span>
            </div>
          )}
        </div>

        <ButtonPublic
          role="submit"
          className=" rounded-full text-lg font-medium"
          containerClassName="w-full text-right flex-1 "
          isDisabled={
            asset.attributesSkipped == false && !asset.additionalProperties
          }
          onClick={() => {
            setTimeout(async () => await changeAsset(true), 500);
          }}
          working={working}
        >
          NEXT
        </ButtonPublic>
        <div className="flex items-center justify-center">
          <LearnMoreHelp
            description={'Tags and attributes'}
            link={'https://intercom.help/dropspot/en/articles/6309166-nft-tags'}
          />
        </div>
      </div>
    </div>
    <div className="flex flex-row w-full items-center justify-center">
      <div></div>
    </div>
  </>
);

function fromAdditionalProperties(arr: AdditionalProperty[]) {
  const obj: { [key: string]: string } = {};

  arr.forEach((a) => (obj[a.name] = a.value));

  return obj;
}

export function toAdditionalProperties(
  obj:
    | {
        [key: string]: string;
      }
    | undefined
): AdditionalProperty[] {
  const result: AdditionalProperty[] = [];
  for (const key in obj) {
    result.push({
      name: key,
      value: obj[key],
    });
  }

  return result;
}
