import React, { useEffect, useRef } from 'react';
import { Asset, Project } from '#types/index';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import DaisyButton from '#components/Daisy/DaisyButton';
import { getWalletPKH } from '#lib/plutus/DropspotMarketContract';
import { NextImage } from '../NextImage';
import { AssetAttributesRegion } from '../AssetPage/AssetPageComps/AssetAttributes';
import { Link } from '@nextui-org/react';
import { AboutCreatorToolTip } from '#components/Creator/AboutTheCreator/AboutCreatorTooltip';
import { AssetFilesRegion } from '../AssetPage/AssetPageComps/AssetFilesRegion';
import { AssetInsights } from '../AssetPage/AssetPageComps/AssetInsights';
import { useRouter } from 'next/router';
import { getProjectByPolicy } from '#lib/firestore';
import { PolicyTooltipV2 } from '#components/Tooltip/PolicyTooltipV2';
import { AssetImage } from '../AssetPage/AssetImage';
import { useCardanoWallet } from '#lib/wallet/WalletContext';
import {
  AssetPageMarketContextWrapper,
  WrapperRef,
  useMarketActionModalStatus,
  useMarketSetWallet,
  useMarketTxInProgress,
} from '../AssetPage/MarketActionModalV2/MarketStore';
import { MarketRegionV2 } from '../AssetPage/AssetPageMarketV2';
import { MarketActionModalV2 } from '../AssetPage/MarketActionModalV2/MarketActionModalV2';
import { ProcessingTransactionModalComponentV2 } from '../AssetPage/MarketActionModalV2/ProcessingTransactionModalComponentV2';
import { CreateOfferV2 } from '../AssetPage/MarketActionModalV2/NewListingModalV2';
import { XMarkIcon } from '@heroicons/react/24/outline';

type AssetModalProps = {
  asset: Asset;
  project?: Project;
  label?: string;
  colorName?: "btn-primary" | "btn-secondary" | "btn-accent" | "btn-neutral" | "btn-success" | "btn-danger" | "btn-warning" | "btn-info" | "btn-light" | "btn-dark" | "btn-ghost" | "btn" | undefined};

export const AssetCardModalComponent = ({
  asset,
  project,
  label,
  colorName = 'btn-ghost',
}: AssetModalProps) => {
  const [theProject, setTheProject] = useState(project);

  useEffect(() => {
    if (!theProject) {
      console.log('project get');
      if (asset.policy) {
        getProjectByPolicy(asset.policy).then((p) => setTheProject(p));
      }
    }
  }, [asset.policy, theProject]);

  if (!asset) return <></>;
  return (
    <AssetCardModal asset={asset} project={project} label={label} colorName={colorName} />
  );
};

const AssetCardModal = ({
  asset,
  project,
  label = 'DETAILS',
  colorName = 'btn-ghost',
}: AssetModalProps) => {
  const assetPageMarketRef = useRef<WrapperRef>(null);

  useEffect(() => {
    // Effect Hook to wait for the transaction to be confirmed
    // Useful if the Asset changes state externally (e.g. another user buys it)
    if (!asset.txHash) return;
    assetPageMarketRef.current?.waitForTransaction(asset.txHash);
  }, [asset.txHash]);

  const [isOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  return (
    <>
      <div className="flex items-center justify-center">
        {/* <button
          type="button"
          className="rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        >
          Open dialog
        </button> */}
        <DaisyButton
          label={label}
            size="btn-xs"
            colorName={colorName ? colorName : 'btn-ghost'}
            onClick={openModal}
        />
      </div>
      {isOpen && (
        <AssetPageMarketContextWrapper
          wrapperRef={assetPageMarketRef}
          asset={asset}
          project={project}
        >
          <ModalPanel
            closeModal={() => closeModal()}
            asset={asset}
            project={project}
            isOpen={isOpen}
          />
        </AssetPageMarketContextWrapper>
      )}
    </>
  );
};

type ModalPanelProps = {
  closeModal: () => void;
  isOpen: boolean;
  asset: Asset;
  project?: Project;
};

const ModalPanel = ({
  isOpen,
  closeModal,
  asset,
  project,
}: ModalPanelProps) => {
  const router = useRouter();
  const setWallet = useMarketSetWallet();

  const { wallet, loadingWalletAddress, walletAddress, stakeAddress } =
    useCardanoWallet();
  const [walletPKH, setWalletPKH] = useState<string>();
  const actionModalStatus = useMarketActionModalStatus();
  const txInProgress = useMarketTxInProgress();

  useEffect(() => {
    if (!wallet) return;
    setWallet(wallet);

    getWalletPKH(wallet)
      .then((pkh) => setWalletPKH(pkh.hex))
      .catch(console.error);
  }, [wallet, setWallet, isOpen]);

  return (
    <>
      {actionModalStatus === 'OFF' ? (
        <Transition appear show={actionModalStatus === 'OFF'} as={Fragment}>
          <Dialog as="div" className="relative z-[50]" onClose={closeModal}>
            <div className="fixed inset-0 backdrop-blur" aria-hidden="true" />
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel
                    className=" flex flex-col sm:flex-row w-[70rem] gap-2 sm:gap-8  transform overflow-hidden rounded-2xl bg-white dark:bg-base-300 p-9 text-left align-middle shadow-xl transition-all"
                    //style={{ height: 'calc(75vh)' }}
                  >
                    <div
                      className="absolute right-3 top-3 w-max h-max rounded-full hover:opacity-75 cursor-pointer dark:text-white text-black"
                      onClick={() => closeModal()}
                    >
                      <XMarkIcon className="w-6 h-6" />
                    </div>
                    <div className="flex flex-col-reverse sm:flex-col flex-1 justify-between">
                      {/* <div className="relative w-full sm:h-[32rem] ">
                        <div className="rounded-2xl overflow-hidden ">
                          <AssetImage asset={asset} mini={true} />
                        </div>
                      </div> */}
                      <div className="w-full  h-[16rem] sm:h-[32rem] sm:max-h-[calc(100vh-12rem)]  rounded-xl flex justify-center items-start  overflow-hidden group mx-0 sm:mx-0">
                        <AssetImage asset={asset} />
                      </div>
                      <div className="pt-8 hidden sm:flex ">
                        <DaisyButton
                          label="Open Page"
                          classNames="w-full"
                          onClick={() => router.push(`/nft/${asset.id}`)}
                        />
                      </div>
                    </div>

                    <div className="flex-none w-full sm:w-6/12 flex flex-col justify-between pt-2">
                      <div className="flex flex-col gap-2">
                        {' '}
                        <div className="text-3xl sm:text-5xl font-bold">
                          {asset.title}
                        </div>
                        <div className="text-lg font-normal">
                          {asset.description}
                        </div>
                        <div className="flex flex-row gap-4 flex-wrap mb-4 mt-2 items-center">
                          <Link href={`/marketplace/${project?.tokenPrefix}`}>
                            <a className="flex flex-shrink items-center gap-2 font-semibold">
                              <div className=" py-1.5 hover:-translate-y-1 cursor-pointer transition duration-150 bg-white dark:bg-gray-900/50 dark:text-white shadow-md rounded-full flex flex-row items-center pl-2 pr-5 border border-gray-300 dark:border-gray-700 gap-1">
                                <div className="relative h-9 w-9">
                                  <NextImage
                                    src={project?.heroImage}
                                    b64={project?.heroImage1x1B64}
                                    width={100}
                                    className="object-cover w-9 h-9 rounded-full -ml-1"
                                    priority={false}
                                  />
                                </div>

                                <div className="font-bold pl-1">
                                  {project?.name}
                                </div>
                              </div>
                            </a>
                          </Link>
                          {project?.creator &&
                            project?.creator.profilePhoto &&
                            !project.creator.profilePhoto.startsWith(
                              'undefined'
                            ) && (
                              <AboutCreatorToolTip creator={project?.creator} />
                            )}

                          <div className="flex flex-row flex-wrap gap-4 ">
                            <PolicyTooltipV2
                              policy={asset.id || 'No Asset'}
                              hideTooltip={false}
                              label="asset"
                            />
                          </div>
                        </div>
                        <MarketRegionV2
                          loading={loadingWalletAddress}
                          walletPKH={walletPKH}
                          walletAddress={walletAddress}
                          stakeAddress={stakeAddress}
                          txInProgress={txInProgress}
                          asset={asset}
                          project={project}
                        />
                        <div className="py-4">
                          <AssetAttributesRegion asset={asset} />
                        </div>
                      </div>
                      <AssetFilesRegion asset={asset} />

                      {project?.source != 'Dropspot' && (
                        <div className="my-4 w-full">
                          <AssetInsights asset={asset} />
                        </div>
                      )}

                      {/* <DaisyButton
                      label="Buy Now"
                      colorName="btn-secondary"
                      onClick={() => console.log('Do Purchase')}
                    /> */}
                    </div>
                    <div className="pt-8 sm:hidden flex ">
                      <DaisyButton
                        label="Open Page"
                        classNames="w-full"
                        onClick={() => router.push(`/nft/${asset.id}`)}
                      />
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      ) : (
        <MarketActionModalV2 onTxSuccess={() => closeModal()}>
          {(() => {
            console.log('>>>> actionModalStatus', actionModalStatus);
            switch (actionModalStatus) {
              case 'WAITING':
                return <ProcessingTransactionModalComponentV2 />;
              case 'BUILDING':
                return <CreateOfferV2 />;
            }
          })()}
        </MarketActionModalV2>
      )}
    </>
  );
};

export default AssetCardModal;
