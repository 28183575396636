import { InlineLoading } from '../../../Loading/InlineLoading';
import { Dialog, Transition } from '@headlessui/react';
import { captureException } from '@sentry/nextjs';
import React, {
  CSSProperties,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { errorIsAPIError } from './utils';
import { DropspotMarketError } from '../../../../lib/plutus/DropspotMarketError';
import { ButtonPublic } from '../../../Button/ButtonPublic';
import cn from 'classnames';
import { LearnMoreHelp } from '#components/InlineHelp/LearnMoreHelp';
import { useCardanoWallet } from '#lib/wallet/WalletContext';
import { Divider } from '#components/Divider/Divider';
import type { CreateTypes } from 'canvas-confetti';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { CheckIcon } from '@heroicons/react/20/solid';
export type MarketActionModalComponentProps = {
  onClose: (withDelay?: boolean) => void;
  errorHandler: (error: unknown, extra?: Record<string, unknown>) => void;
  onAwaitingSignature: () => void;
  onSubmitSuccess: (txId: string) => void;
  txStage?: string;
  action?: 'REPRICE' | 'CREATE LISTING' | 'BUY' | 'CLAIM' | 'DELIST' | 'DELISTODS';
};
export type MarketActionModalComponent = (
  props: MarketActionModalComponentProps
) => React.ReactElement;

export type MarketActionModalProps = {
  Component: MarketActionModalComponent;
  onClose: () => void;
  title: string;
  error?: DropspotMarketError;
  action: 'REPRICE' | 'CREATE LISTING' | 'BUY' | 'CLAIM' | 'DELIST' | 'DELISTODS';
};

const MarketActionModal_ = ({
  Component,
  onClose,
  title,
  error: inputError,
  action,
}: MarketActionModalProps) => {
  const [error, setError] = useState<DropspotMarketError>();
  const [isAwaitingSignature, setIsAwaitingSignature] = useState(false);
  const [hasSuccessfullySubmitted, setHasSuccessfullySubmitted] =
    useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const closeModal = useCallback(() => onClose(), [onClose]);
  const { wallet } = useCardanoWallet();

  const refAnimationInstance = useRef<CreateTypes | null>(null);
  const getInstance = useCallback((instance: CreateTypes | null) => {
    refAnimationInstance.current = instance;
  }, []);
  const makeShot = useCallback(
    (particleRatio: number, opts?: confetti.Options) => {
      refAnimationInstance.current &&
        refAnimationInstance?.current({
          ...opts,
          origin: { y: 1.0, x: 0.1 },
          particleCount: Math.floor(100 * particleRatio),
        });
    },
    []
  );
  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 25,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);

  const canvasStyles: CSSProperties = {
    position: 'absolute',
    width: '70%',
    height: '50%',
    top: 50,
    left: 300,
  };

  const handleError = useCallback(
    (e: unknown, extra?: Record<string, unknown>) => {
      console.log('In handleError', e);
      let error = e;
      if (!errorIsAPIError(error)) {
        error = new DropspotMarketError({
          code: 1111,
          type: 'BUILD',
          info: `Error building Transaction ${(e as Error).message}`,
        });
      }

      if (errorIsAPIError(error)) {
        console.log(error.message);
        if (error.report) {
          error.errorId = captureException(e, {
            extra,
          });
        }

        setError(error);

        if (error.autoClose) {
          setTimeout(closeModal, 5000);
        }
        return;
      }
    },
    [closeModal]
  );

  useEffect(() => {
    // Effect to handle errors that are Passed into the component, rather that those
    // coming from the rendered child component.
    if (inputError) {
      handleError(inputError);
    }
  }, [handleError, inputError]);

  type ErrorInlayProps = {
    error: DropspotMarketError;
  };
  function ErrorInlay({ error: { message, title, errorId } }: ErrorInlayProps) {
    return (
      <div className="flex flex-col items-center justify-between h-full mt-2 pb-10">
        <span className="flex flex-col items-center justify-between gap-6 ">
          <img
            src={wallet?.icon || '/img/cardano-logo-black.webp'}
            className="w-7 h-7 object-contain mt-4 "
            alt={`${wallet?.name}-icon`}
          />
          <p className="text-3xl font-bold leading-12 text-gray-900 dark:text-gray-100">
            {title}
          </p>
          <p className="text-gray-700 dark:text-gray-400 text-center">{`${message} ${
            errorId ? `(#${errorId})` : ''
          }`}</p>

          <div className=" flex justify-center">
            <LearnMoreHelp
              description={'How to setup wallet collateral'}
              link={
                'https://help.dropspot.io/en/articles/6336322-what-is-collateral-and-how-do-i-set-it-up'
              }
            />
          </div>
        </span>
        <ButtonPublic
          className="w-36"
          onClick={() => {
            closeModal();
            setTimeout(() => setError(undefined), 2500);
          }}
        >
          Got It
        </ButtonPublic>
      </div>
    );
  }

  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[60] overflow-y-auto"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0  backdrop-filter backdrop-blur-xl backdrop-brightness-75">
              <ReactCanvasConfetti
                refConfetti={getInstance}
                style={canvasStyles}
                className={cn('z-0')}
              />
            </Dialog.Overlay>
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              ref={divRef}
              className={cn(
                'inline-block w-full max-w-xl p-12 my-12 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-gray-900 dark:text-gray-100 shadow-xl rounded-2xl duration-300 ease-out',

                { 'h-[30rem]': !!error },
                { 'h-[30rem]': isAwaitingSignature },
                {
                  'h-[30rem]':
                    !error &&
                    action != 'CREATE LISTING' &&
                    action != 'REPRICE' &&
                    !isAwaitingSignature,
                },
                {
                  'h-[46rem]':
                    !error &&
                    !isAwaitingSignature &&
                    (action === 'REPRICE' || action === 'CREATE LISTING'),
                }
              )}
            >
              <div className="flex flex-row justify-between">
                <Dialog.Title
                  as="h3"
                  className="text-2xl font-medium leading-6 text-gray-900 dark:text-gray-100"
                >
                  {title}
                </Dialog.Title>
              </div>
              <div className="mt-4">
                <Divider />
              </div>
              {error ? (
                <ErrorInlay error={error} />
              ) : (
                <Component
                  onClose={() => closeModal()}
                  onAwaitingSignature={() => {
                    setIsAwaitingSignature(true);
                  }}
                  onSubmitSuccess={() => {
                    fire();
                    setHasSuccessfullySubmitted(true);
                  }}
                  errorHandler={(
                    error: unknown,
                    extra?: Record<string, unknown>
                  ) => handleError(error, extra)}
                />
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

type ProcessingTransactionModalComponentProps = MarketActionModalComponentProps;
export const ProcessingTransactionModalComponent = ({
  txStage,
  action,
  onSubmitSuccess,
}: ProcessingTransactionModalComponentProps) => {
  const { wallet } = useCardanoWallet();

  useEffect(() => {
    if (txStage == 'Submitted' && action == 'BUY') {
      onSubmitSuccess('txn');
      console.log('>>>>>>>>>>>>>>>>>>Calling TX SUCCESS');
    }
  }, [action, onSubmitSuccess, txStage]);
  return (
    <div className="flex flex-col items-center justify-start h-full gap-8 mt-8">
      <img
        src={wallet?.icon || '/img/cardano-logo-black.webp'}
        className="w-7 h-7 object-contain "
        alt={`${wallet?.name}-icon`}
      />
      <p className="text-3xl font-semibold leading-12 text-gray-900 dark:text-gray-100">
        {txStage == 'Submitted' && <span> Transaction success</span>}
        {txStage == 'Submitting' && <span> Submitting transaction</span>}
        {txStage == 'Signing' && <span> Sign the transaction</span>}
        {txStage == 'Building' && <span> Building transaction</span>}
        {txStage == 'Finalizing' && <span> Finalising transaction</span>}
      </p>

      <p className="text-gray-700 dark:text-gray-400 font-normal text-center">
        {txStage == 'Submitted' && (
          <span>
            The transaction has been submitted to the blockchain, and being
            tracked for confirmation
          </span>
        )}
        {txStage == 'Signing' && (
          <span> A transaction has been sent to your wallet for signing.</span>
        )}
        {txStage == 'Building' && (
          <span> The transaction is being built for your signing.</span>
        )}
        {txStage == 'Submitting' && (
          <span> The transaction is being submitted to the blockchain.</span>
        )}
        {txStage == 'Finalizing' && (
          <span> The transaction is being verified.</span>
        )}
      </p>
      <div className="w-full h-12 flex justify-center items-center">
        {' '}
        {txStage && txStage != 'Submitted' && <InlineLoading ballSize={8} />}
        {txStage && txStage == 'Submitted' && (
          <CheckIcon className="w-12 h-12 text-green-500" />
        )}
      </div>

      <p
        className={cn(
          'text-sm font-normal',
          {
            'dark:text-sky-400 text-sky-600 ': txStage != 'Submitted',
          },
          {
            'dark:text-green-500 text-green-500': txStage == 'Submitted',
          }
        )}
      >
        Transaction - {txStage}
      </p>
    </div>
  );
};

export const MarketActionModal = React.memo(MarketActionModal_);
MarketActionModal.displayName = 'MarketActionModal';
