import type { CreateTypes } from 'canvas-confetti';
import ReactCanvasConfetti from 'react-canvas-confetti';
import {
  useMarketAction,
  useMarketAwaitingSignature,
  useMarketCloseActionModal,
  useMarketError,
  useMarketActionModalStatus,
  useMarketTxState,
} from './MarketStore';

import { Fragment, ReactNode, useCallback, useEffect, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ErrorInlay } from './ErrorInlay';
import { Divider } from '#components/Divider/Divider';
import { CSSProperties } from '@nextui-org/react/types/theme';
import cn from 'classnames';

function getTitle(
  action: 'REPRICE' | 'CREATE LISTING' | 'BUY' | 'CLAIM' | 'DELIST' | 'DELISTODS'| undefined
) {
  switch (action) {
    case 'REPRICE':
      return 'Reprice';
    case 'BUY':
      return 'Purchase';
    case 'CLAIM':
      return 'Asset Claim';
    case 'DELIST':
      return 'Delist Asset';
    case 'CREATE LISTING':
      return 'List for sale';
  }
}

export const MarketActionModalV2 = ({
  children,
  onTxSuccess = () => null,
}: {
  children: ReactNode;
  onTxSuccess?: () => void;
}) => {
  //                               ^?
  const error = useMarketError();
  const marketActionModalStatus = useMarketActionModalStatus();
  const closeActionModal = useMarketCloseActionModal();
  const isAwaitingSignature = useMarketAwaitingSignature();
  const txStage = useMarketTxState();
  const action = useMarketAction();

  const title = getTitle(action);

  const divRef = useRef<HTMLDivElement>(null);

  const refAnimationInstance = useRef<CreateTypes | null>(null);
  const getInstance = useCallback((instance: CreateTypes | null) => {
    refAnimationInstance.current = instance;
  }, []);
  const makeShot = useCallback(
    (particleRatio: number, opts?: confetti.Options) => {
      refAnimationInstance.current &&
        refAnimationInstance?.current({
          ...opts,
          origin: { y: 1.0, x: 0.1 },
          particleCount: Math.floor(100 * particleRatio),
        });
    },
    []
  );
  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 25,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);

  useEffect(() => {
    if (txStage == 'Submitted' && action == 'BUY') {
      fire();
    }
    if (txStage === 'Submitted') {
      setTimeout(() => {
        onTxSuccess();
        closeActionModal();
      }, 2000);
    }
  }, [txStage, action, fire, closeActionModal, onTxSuccess]);

  const canvasStyles: CSSProperties = {
    position: 'absolute',
    width: '70%',
    height: '50%',
    top: 50,
    left: 300,
  };

  console.log('MarketActionModalV2: showActionModal', marketActionModalStatus);

  return (
    <Transition appear show={marketActionModalStatus !== 'OFF'} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[60] overflow-y-auto"
        onClose={closeActionModal}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0  backdrop-filter backdrop-blur-xl backdrop-brightness-75">
              <ReactCanvasConfetti
                refConfetti={getInstance}
                style={canvasStyles}
                className={cn('z-0')}
              />
            </Dialog.Overlay>
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              ref={divRef}
              className={cn(
                'inline-block w-full max-w-xl p-12 my-12 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-gray-900 dark:text-gray-100 shadow-xl rounded-2xl duration-300 ease-out',

                { 'h-[30rem]': !!error },
                { 'h-[30rem]': isAwaitingSignature },
                {
                  'h-[30rem]':
                    !error &&
                    action != 'CREATE LISTING' &&
                    action != 'REPRICE' &&
                    !isAwaitingSignature,
                },
                {
                  'h-[46rem]':
                    !error &&
                    !isAwaitingSignature &&
                    (action === 'REPRICE' || action === 'CREATE LISTING'),
                }
              )}
            >
              <div className="flex flex-row justify-between">
                <Dialog.Title
                  as="h3"
                  className="text-2xl font-medium leading-6 text-gray-900 dark:text-gray-100"
                >
                  {title}
                </Dialog.Title>
              </div>
              <div className="mt-4">
                <Divider />
              </div>
              {error ? <ErrorInlay error={error} /> : children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
