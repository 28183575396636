import firebase from 'firebase/app';
import 'firebase/functions';
import { useCallback, useEffect, useState } from 'react';
import { ProvenanceItem } from '#types/index';
import { getAssetProvenanceListener } from '#lib/firestore';

export const useProvenanceItemsFromCache = ({
  assetId,
  policy,
  tokenName,
}: {
  assetId?: string;
  policy?: string;
  tokenName?: string;
}) => {
  const [provenanceItems, setProvenanceItems] = useState<
    ProvenanceItem[] | undefined
  >();
  const [loading, setLoading] = useState(true);

  const refreshProvenanceItems = useCallback(() => {
    // If we don't have assetId, policy, or tokenName, we can't refresh so just return
    if (!assetId || !policy || !tokenName) {
      return;
    }

    firebase.functions().httpsCallable('callPubSubTopic')({
      topic: 'asset-provenance-refresh',
      attributes: JSON.stringify({
        assetId,
        policy,
        tokenName,
      }),
    });
  }, [assetId, policy, tokenName]);

  useEffect(() => {
    if (!assetId) {
      setLoading(false);
      return;
    }

    refreshProvenanceItems();

    console.log('in live transaction listener wallet: ', assetId);
    return getAssetProvenanceListener(assetId, (data) => {
      setProvenanceItems(data);
      setLoading(false);
    });
  }, [assetId, refreshProvenanceItems]);

  return {
    provenanceItems,
    loading,
    refreshProvenanceItems,
  };
};
