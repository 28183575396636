import { Fragment, useCallback, useEffect, useState } from 'react';
import { FC } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Divider } from 'components/Divider/Divider';
import { useFirebaseCurrentUser } from '#lib/hooks/useFirebaseCurrentUser';
import { Asset, Project } from '#types/index';
import { useNotifier } from 'react-headless-notifier';
import DaisyButton from '#components/Daisy/DaisyButton';
import {
  OfferUIStore,
  useOfferUIStore,
} from '#components/Assets/AssetPage/MarketActionOffers/OfferUIStore';
import { NextImage } from '#components/Assets/NextImage';
import { MiddleEllipsis } from '#components/MiddleEllipsis/MiddleEllipsis';
import { useCardanoWallet } from '#lib/wallet/WalletContext';
import { DropspotMarketError } from '#lib/plutus/DropspotMarketError';
import cn from 'classnames';
import { TwitterIcon } from '#components/svg/twitterIcon';
import { InferQueryOutput } from '#lib/trpc';
import { ReceivedOffer } from '#components/Wallet/ReceivedOffers';
import getAbsoluteURL from '#lib/getAbsoluteUrl';
import { useAssetFromPolicyAndTokenName } from '#lib/hooks/useAsset';
import React from 'react';
import { SaleEstimate } from '#components/Assets/SaleEstimate/SaleEstimate';
import { BellAlertIcon } from '@heroicons/react/24/outline';
import { getProject } from '#lib/firestore';
import { useProject } from '#lib/hooks/useProject';
import {
  usePolicyStats,
  usePolicyStatsListenerListener,
} from '#lib/hooks/usePolicyStats';
import { logger } from '../../lib/Logger';

type ButtonType = 'Button' | 'Icon';
export interface AcceptOfferModalProps {
  project?: Project;
  asset?: Asset;
  offer: ReceivedOffer;
  open: boolean;
  offerAccepted: () => void;
  floor?: number;
  compactMode?: boolean;
}

export const AcceptOfferMoodal: FC<AcceptOfferModalProps> = ({
  project,
  asset,
  offer,
  open,
  offerAccepted,
  floor,
  compactMode = false,
}) => {
  const [isOpen, setIsOpen] = useState(open);

  const getOfferableAssets = useOfferUIStore(selectGetOfferableAssets);
  const reloadMyOffers = useOfferUIStore((state) => state.reloadMyOffers);
  const setCollectionOffer = useOfferUIStore(selectAddCollectionToOffer);
  const setUser = useOfferUIStore(selectSetUser);
  const createOffer = useOfferUIStore(selectCreateOffer);
  const txState = useOfferUIStore(selectTxState);
  const txInProgress = useOfferUIStore(selectTxInProgress);
  const acceptOffer = useOfferUIStore(selectAcceptOffer);
  function openModal() {
    setIsOpen(true);
  }
  const { wallet } = useCardanoWallet();

  const { currentUser } = useFirebaseCurrentUser();
  useEffect(() => setIsOpen(open), [open]);

  const isReadyToCreate = () => true;
  const { notify } = useNotifier();
  const [displayError, setDisplayError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [txSuccess, setTxSuccess] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState('');
  const [acceptTxInProgress, setAcceptTxInProgress] = useState(false);
  const waitForTransaction = useOfferUIStore(selectWaitForTransaction);
  const oa = getOfferableAssets(offer);
  const [selectedAssetIndex, setSelectedAssetIndex] = useState(0);
  const [assets, setAssets] = useState<Asset[]>();

  const { project: ssProject } = useProject(offer?.collection_policy);

  const { policyStatistics } = usePolicyStatsListenerListener({
    policy: offer?.collection_policy,
  });

  const floorToUse = floor || policyStatistics?.floor_price;

  const showNotification = (type: string, message: string) => {
    notify(
      <div className="toast toast-end min-w-[18rem]">
        <div className={`alert ${type}`}>
          <div>
            <span>{message}</span>
          </div>
        </div>
      </div>,
      {
        max: 1,
        position: 'bottomRight',
        duration: 3000,
      }
    );
  };

  const doOnCloseCleanup = () => {
    setDisplayError(false);
    setIsSuccess(false);
    setTxSuccess(undefined);
  };
  const offerableAssets = getOfferableAssets(offer);

  // const offerableAssets = getOfferableAssets(offer).filter(
  //   (o) => o.policy == project?.policy
  // );

  function getOfferAmount(): number {
    if (!offer || !offer.details) return 0;
    const collectionOffers = offer.details
      .filter((offer) => offer.type === 'Collection')
      .map((offer) => offer.offer);

    const assetOffers = offer.details
      .filter((offer) => offer.type === 'Asset')
      .map((offer) => offer.offer);

    if (collectionOffers.length === 0 && assetOffers.length == 0) {
      return 0; // No collection offers found
    }

    return Math.max(...collectionOffers, ...assetOffers);
  }

  return (
    <>
      {compactMode ? (
        <div className="flex items-center ">
          <DaisyButton
            onClick={() => {
              setErrorMessage(''), setDisplayError(false);
              openModal();
            }}
            label={'Accept'}
            size="btn-sm"
            isDisabled={!offer}
            colorName="btn-secondary"
            classNames="h-full px-2 my-auto"
          />
        </div>
      ) : (
        <div className="">
          <DaisyButton
            onClick={() => {
              setErrorMessage(''), setDisplayError(false);
              openModal();
            }}
            label={'Accept Offer'}
            size="btn-sm"
            isDisabled={!offer}
            colorName="btn-secondary"
          />
        </div>
      )}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          open={isOpen}
          className="fixed inset-0 z-40 overflow-y-auto transition-height duration-150 transform"
          onClose={() => {
            doOnCloseCleanup();
            setIsOpen(false);
          }}
        >
          <div className="min-h-screen px-4 text-center transition-height duration-150 transform">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 backdrop-filter backdrop-blur-xl backdrop-brightness-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block dark:bg-base-300 w-full max-w-lg p-9 my-12 overflow-hidden text-left align-middle  bg-white shadow-xl rounded-2xl transition-height duration-150 transform">
                <div className="flex flex-row justify-between">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-bold leading-6 text-gray-800 dark:text-white flex items-center gap-3"
                  >
                    Accept Offer
                  </Dialog.Title>
                </div>
                <div className="mt-4">
                  <Divider />
                </div>

                <div
                  className={cn(
                    'duration-200 transition-all',
                    {
                      'h-[16rem] ': !!displayError,
                    },
                    {
                      'h-[28rem]': !!isSuccess,
                    },
                    {
                      'h-[38rem]': !displayError && !isSuccess,
                    }
                  )}
                >
                  {!!displayError && (
                    <>
                      <div className="w-full flex justify-between h-full flex-col items-center">
                        <span className="text-3xl font-bold mt-8 mb-8">
                          Transaction Cancelled
                        </span>
                        <div className="mb-8">{errorMessage}</div>
                        <DaisyButton
                          label="OK"
                          classNames="w-full"
                          onClick={() => {
                            doOnCloseCleanup();
                            setIsOpen(false);
                          }}
                        />
                      </div>
                    </>
                  )}
                  {!!isSuccess && (
                    <>
                      <div className="w-full flex justify-center flex-col items-center">
                        <span className="text-3xl font-bold mt-8 mb-8">
                          Offer Accepted! 🎉
                        </span>
                        <div className="mb-8 text-center font-normal">
                          Congratulations. A transaction accepting this offer
                          has been submitted to the blockchain.
                        </div>
                        <div className="p-4 rounded-xl flex flex-row justify-between bg-base-100 w-full mb-8">
                          <span className="text-gray-600 dark:text-gray-400">
                            Transaction:
                          </span>
                          <MiddleEllipsis text={txSuccess || ''} />
                        </div>
                        <div className="mb-8 text-center font-normal text-sm dark:text-gray-400">
                          A transaction is typically confirmed within 20s,
                          depending upon blockchain load.
                        </div>
                        <div className="flex flex-col gap-2 w-full">
                          <DaisyButton
                            label="OK"
                            classNames="w-full"
                            onClick={() => {
                              doOnCloseCleanup();
                              setIsOpen(false);
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {!displayError && !isSuccess && (
                    <div className="w-full flex flex-col gap-4  justify-center mt-4 pb-8">
                      <div className="text-5xl font-extrabold bg-base-100 p-4 rounded-xl flex items-center justify-center flex-col">
                        ₳ {getOfferAmount() / 1_000_000}
                        <span className="text-xs font-normal text-accent">
                          Highest Offer
                        </span>
                      </div>
                      <div className="text-sm dark:text-gray-400 font-normal text-gray-600">
                        Pick 1 of {offerableAssets.length} Assets to trade:
                      </div>
                      <div className="flex overflow-x-scroll  hide-scroll-bar w-full scrollbar-hide ">
                        <div className="flex flex-nowrap gap-3 h-full items-start">
                          {offerableAssets &&
                            offerableAssets.map((a, idx) => (
                              <React.Fragment key={`${a.asset?.id}${idx}`}>
                                <AssetOfferThumb
                                  policy={a.policy}
                                  tokenName={a.tokenName}
                                  selectAsset={(i) => setSelectedAssetIndex(i)}
                                  index={idx}
                                  selected={idx == selectedAssetIndex}
                                />
                              </React.Fragment>
                            ))}
                        </div>
                      </div>
                      <div className="mt-2">
                        {' '}
                        <SaleEstimate
                          price={getOfferAmount() / 1_000_000}
                          royalties={ssProject?.royaltyItems}
                          commissionPercent={0}
                        />
                      </div>

                      <div className="flex-1 w-full justify-center flex">
                        {1 - getOfferAmount() / (floorToUse || 1) > 0.25 ? (
                          <>
                            <div className="flex items-center gap-2 text-orange-400 dark:text-yellow-500 my-2 font-normal">
                              <BellAlertIcon className="w-5 flex-none" />
                              <p>
                                This offer is{' '}
                                {(
                                  (1 - getOfferAmount() / (floorToUse || 1)) *
                                  100
                                ).toFixed(0)}
                                % below the floor price.
                              </p>
                            </div>
                          </>
                        ) : (
                          <p className="flex items-center gap-2 text-orange-400 dark:text-yellow-500 my-2 font-normal">
                            Please ensure you are comfortable with the offer
                            price.{' '}
                            {/* {offerableAssets[selectedAssetIndex].tokenName}! */}
                          </p>
                        )}
                      </div>
                      <div className="flex-1 flex flex-col gap-2">
                        <DaisyButton
                          label="Accept"
                          colorName="btn-secondary"
                          working={acceptTxInProgress}
                          isDisabled={acceptTxInProgress}
                          workingLabel={txState}
                          onClick={() => {
                            console.log('do accept');
                            setAcceptTxInProgress(true);
                            if (offerableAssets[selectedAssetIndex]) {
                              console.log(
                                'accept:',
                                offerableAssets[selectedAssetIndex]
                              );

                              acceptOffer(
                                offer,
                                {
                                  ...offerableAssets[selectedAssetIndex],
                                  quantity: 1,
                                },
                                offerableAssets[selectedAssetIndex].offer
                              )
                                .then(
                                  async (
                                    tx: React.SetStateAction<string | undefined>
                                  ) => {
                                    console.log('track it', tx);
                                    const endpoint = getAbsoluteURL(
                                      `/api/offers/acceptOfferTracking`
                                    );
                                    const userToken =
                                      await currentUser?.getIdToken(true);
                                    console.log('ABOUT TO CALL POST OFFER API');
                                    const response = await fetch(endpoint, {
                                      method: 'POST',
                                      body: JSON.stringify({
                                        txHash: tx,
                                        offer: offer,
                                        acceptedPolicy:
                                          offerableAssets[selectedAssetIndex]
                                            .policy,
                                        acceptedTokenName:
                                          offerableAssets[selectedAssetIndex]
                                            .tokenName,
                                      }),
                                      headers: {
                                        'Content-Type': 'application/json',
                                        Authorization: `Bearer ${userToken}`,
                                      },
                                    });

                                    if (response.status != 200) {
                                      console.log(
                                        'Error in the api POST OFFER API',
                                        await response.text()
                                      );
                                    }
                                    console.log('Post Offer APi Complete.');

                                    // waitForTransaction(tx).then((t) => {
                                    //   console.log('did a refresh for t', t, tx);
                                    // });
                                    reloadMyOffers(true);
                                    setIsSuccess(true);
                                    setTxSuccess(tx);
                                    setAcceptTxInProgress(false);
                                  }
                                )
                                .catch((error) => {
                                  setAcceptTxInProgress(false);

                                  logger.error({
                                    message: error.message,
                                    level: 'error',
                                    timestamp: Date.now(),
                                    additional: {
                                      offer: JSON.stringify(offer),
                                      error: JSON.stringify(error),
                                    },
                                  });

                                  if (error instanceof DropspotMarketError) {
                                    setDisplayError(true);
                                    showNotification(
                                      error.code == 2
                                        ? 'alert-info'
                                        : 'alert-error',
                                      `${error.code} ${error.info}.`
                                    );
                                    setErrorMessage(error.info);
                                  }
                                });
                            }
                          }}
                        />
                        <DaisyButton
                          label="Cancel"
                          colorName="btn-ghost"
                          size="btn-sm"
                          classNames="flex-1"
                          onClick={() => {
                            doOnCloseCleanup();
                            setIsOpen(false);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

type AssetOfferThumProps = {
  policy: string;
  tokenName: string;
  selected: boolean;
  index: number;
  selectAsset: (index: number) => void;
};

const AssetOfferThumb = ({
  policy,
  tokenName,
  selected,
  index,
  selectAsset,
}: AssetOfferThumProps) => {
  const { asset, loading } = useAssetFromPolicyAndTokenName(policy, tokenName);
  if (!asset) return <></>;
  return (
    <>
      <div className="flex flex-col w-full gap-2">
        <div
          className={cn(
            'relative w-[5rem] h-[5rem] rounded-xl m-2 cursor-pointer ',
            {
              'ring ring-secondary': selected,
            }
          )}
          onClick={() => selectAsset(index)}
        >
          <NextImage
            src={asset?.thumbnailUrl}
            b64={asset.thumbnailB64}
            width={180}
            layout="fill"
            priority={false}
            className="w-full h-full object-cover rounded-xl"
          />
        </div>
        <div className="text-xs">{asset?.title}</div>
      </div>
    </>
  );
};

const selectAddCollectionToOffer = (state: OfferUIStore) =>
  state.setCollectionOffer;
const selectSetUser = (state: OfferUIStore) => state.setCurrentUser;
const selectCreateOffer = (state: OfferUIStore) => state.createOffer;
const selectTxState = (state: OfferUIStore) => state.txState;
const selectTxInProgress = (state: OfferUIStore) => state.txInProgress;
const selectGetOfferableAssets = (state: OfferUIStore) =>
  state.getOfferableAssets;

const selectAcceptOffer = (state: OfferUIStore) => state.acceptOffer;
const selectWaitForTransaction = (state: OfferUIStore) =>
  state.waitForTransaction;
