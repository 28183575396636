import { NextImage } from '#components/Assets/NextImage';
import { useLiveAllTransactionListener } from '#lib/hooks/useProjectAssets';
import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import cn from 'classnames';
import { Divider } from '#components/Divider/Divider';
import Link from 'next/link';

type LiveTransactionsAllProps = { policy?: string; doClose?: () => void };

export const LiveTransactionsAll = ({
  policy,
  doClose,
}: LiveTransactionsAllProps) => {
  dayjs.extend(relativeTime);
  const { liveTransactions, loading: liveLoading } =
    useLiveAllTransactionListener(policy);

  return (
    <>
      <div className="flex flex-col w-full  h-full  border-l dark:border-gray-700/80 bg-white dark:bg-gray-900/20">
        <span className="flex flex-row justify-between items-center h-14  border-b dark:border-gray-700/10 bg-gray-100/10 dark:bg-base-200/10 ">
          <span
            className="text-base tracking-wider font-bold  cursor-pointer pl-5"
            onClick={() => doClose && doClose()}
          >
            MOVEMENT
          </span>
          <span className="text-sm font-light flex items-center gap-2 mb-1 pr-4">
            <span className="font-normal text-xs text-yellow-500 dark:text-accent ">
              Listening
            </span>
            <span className="flex h-3 w-3 relative items-center justify-center">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full dark:bg-accent bg-accent opacity-75"></span>
              <span className="relative inline-flex rounded-full h-2 w-2 bg-accent dark:yellow:400"></span>
            </span>
          </span>
        </span>
        <div className=" w-full flex-1 overflow-auto scrollbar-hide mt-2">
          {liveTransactions &&
            liveTransactions.map((tx, idx) => (
              <React.Fragment key={`${tx.txHash}${idx}`}>
                <Link href={`/nft/${tx.assetId}`}>
                  <a className="">
                    <div className="flex flex-row justify-between items-center gap-4 hover:bg-gray-100 dark:hover:bg-base-100 rounded-xl pl-4 pr-4 py-3 ">
                      <span className="flex flex-row items-center w-full gap-4">
                        <div className="relative h-12 w-12 bg-gray-700/10 shadow-lg flex-initial flex items-center justify-center rounded-lg object-cover">
                          <NextImage
                            src={tx.asset?.thumbnailUrl || ''}
                            priority={false}
                            width={120}
                            height={120}
                            b64={tx.asset?.thumbnailB64}
                            quality={100}
                            className="object-cover w-full h-full rounded-lg"
                          />
                        </div>
                        <div className="flex flex-1 flex-col">
                          <span className="text-sm">{tx.asset?.title}</span>

                          <span
                            className={cn('font-light text-xs', {
                              'dark:text-green-400/90 text-green-600 text-shadow shadow-green-500/10 dark:shadow-green-500/50 font-light font-mono':
                                tx.type == 'sale',
                              'dark:text-red-400/90 text-red-600 shadow-red-500/10 dark:shadow-red-500/50 text-shadow font-light font-mono':
                                tx.type == 'delist',
                              'text-sky-600/90 dark:text-sky-400/90 shadow-sky-500/10 dark:shadow-sky-500/50 font-light text-shadow font-mono':
                                tx.type == 'relisting',
                              'text-blue-400/90 shadow-blue-500/10 dark:shadow-blue-500/50 text-shadow font-light font-mono':
                                tx.type == 'listing' || 'listed',
                            })}
                          >
                            {tx.type == 'sale'
                              ? 'sold'
                              : tx.type == 'delist'
                              ? 'delisted'
                              : tx.type == 'relisting'
                              ? 'repriced'
                              : 'listed'}
                          </span>
                        </div>
                      </span>
                      <div className="flex-initial w-32 flex flex-col items-end text-lg font-medium">
                        {(tx.price || tx.pricePaid || 0) > 0 && (
                          <span className="">
                            {`₳ ${Intl.NumberFormat('en-US', {
                              notation: 'compact',
                              maximumFractionDigits: 0,
                            }).format(
                              (tx.price || tx.pricePaid || 0) / 1_000_000
                            )}`}
                          </span>
                        )}
                        <span className="text-xs dark:text-gray-400 text-gray-600 font-normal text-right">
                          {dayjs(tx.createdDate).fromNow()}
                        </span>
                      </div>
                    </div>
                  </a>
                </Link>
              </React.Fragment>
            ))}
        </div>
        <div className="bg-yellow-200 w-full flex-initial h-16 hidden">
          <div className=" h-16 bottom-0 flex flex-row p-8 items-center justify-center justify-items-center bg-white border-l dark:bg-gray-900/95 font-light shadow-lg border-t dark:border-gray-700/50">
            <div className="flex-1 text-center text-sky-600 text-shadow shadow-sky-600/80">
              Listed
            </div>
            <div className="flex-1 text-center text-sky-600 text-shadow shadow-sky-600/80">
              Sold
            </div>
            <div className="flex-1 text-center text-sky-600 text-shadow shadow-sky-600/80">
              Repriced
            </div>
            <div className="flex-1 text-center text-sky-600 text-shadow shadow-sky-600/80">
              Cancelled
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
