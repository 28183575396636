import { FC, useRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import { OptimisedImage } from '#types/index';
import constants from '#lib/constants';
import { gateway } from '@blockfrost/blockfrost-js/lib/endpoints/ipfs';

export interface DSImageProps {
  thumb?: string;
  src: string;
  className: string;
}

export const DSImage: FC<DSImageProps> = ({ thumb, src, className }) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const onImageLoadHandler = () => {
    if (!imgRef.current) {
      return; // We don't have the reference?
    }
    imgRef.current.classList.add('opacity-100');
    imgRef.current.classList.remove('opacity-0');
  };

  return (
    <>
      {thumb && (
        <img
          src={thumb}
          className={classNames(className, 'filter blur-xl')}
          alt=""
        />
      )}
      {!thumb && <span className={classNames(className, '')} />}
      <img
        src={src}
        ref={imgRef}
        className={classNames(
          className,
          'pixelated opacity-0 transition duration-200 filter brightness-75 sm:group-hover:brightness-50'
        )}
        alt=""
        onLoad={() => onImageLoadHandler()}
      />
    </>
  );
};

export const ImageSwap: FC<{
  thumb?: string;
  src: string;
  alt: string;
  width?: number;
  imageAspect?: string;
  avoidHover?: boolean;
}> = ({
  thumb,
  src,
  alt,
  width = 600,
  imageAspect = 'object-cover',
  avoidHover = false,
}) => {
  const [loaded, setLoaded] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const thumbRef = useRef<HTMLImageElement>(null);
  const w = width;
  const params = [`width=${w}`];
  const paramsString = params.join(',');

  let filteredSrc = src;

  if (
    !filteredSrc ||
    filteredSrc === 'undefined' ||
    filteredSrc === 'null' ||
    filteredSrc === 'holding'
  ) {
    filteredSrc =
      'https://media.dropspot.io/placeholders%2FNo-Image-Placeholder.svg.png';
  }

  if (!filteredSrc.startsWith('data')) {
    filteredSrc = `https://dropspot.io/cdn-cgi/image/${paramsString}/${normalizeSrc(
      src ||
        'https://media.dropspot.io/placeholders%2FNo-Image-Placeholder.svg.png'
    )}`;
  }
  useEffect(() => {
    if (loaded) return;
    const img = new Image();
    img.src = src;
    img.alt = alt;
    img.className = `rounded-lg pixelated opacity-0 shaddow-lg rounded-sm transition duration-200 w-full h-full scale-102 ${imageAspect} transform sm:group-hover:scale-100`;
    img.onload = () => {
      divRef.current?.appendChild(img);
      setTimeout(() => {
        img.style.opacity = '1';
        thumbRef.current && (thumbRef.current.style.opacity = '0');
      }, 1);
      setLoaded(true);
    };
  }, [src, loaded, alt, imageAspect, avoidHover]);

  return (
    <div
      className={`relative w-full h-full flex justify-center items-center duration-200 transition transform sm:group-hover:scale-100`}
    >
      <div className="w-full h-full relative z-0 overflow-hidden" ref={divRef}>
        {thumb && (
          <img
            ref={thumbRef}
            src={filteredSrc}
            className="rounded-lg pixelated absolute transition duration-200 inset-0 w-full h-full filter blur-xl opacity-100"
            alt=""
          />
        )}
      </div>
    </div>
  );
};

export const ImageSwapHero: FC<{
  thumb?: string;
  src: string;
  alt: string;
  imageAspect?: string;
  avoidHover?: boolean;
}> = ({
  thumb,
  src,
  alt,
  imageAspect = 'object-cover',
  avoidHover = false,
}) => {
  const [loaded, setLoaded] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const thumbRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (loaded) return;
    const img = new Image();
    img.src = src;
    img.alt = alt;
    img.className = ` pixelated opacity-0 shadow-lg rounded-2xl transition duration-200 w-full h-full scale-102 ${imageAspect} transform sm:group-hover:scale-100`;
    img.onload = () => {
      divRef.current?.appendChild(img);
      setTimeout(() => {
        img.style.opacity = '1';
        thumbRef.current && (thumbRef.current.style.opacity = '0');
      }, 1);
      setLoaded(true);
    };
  }, [src, loaded, alt, imageAspect, avoidHover]);

  return (
    <div
      className={`relative w-full h-full flex justify-center items-center duration-200 transition transform sm:group-hover:scale-100`}
    >
      <div className="w-full h-full relative z-0 overflow-hidden" ref={divRef}>
        {thumb && (
          <img
            ref={thumbRef}
            src={thumb}
            className="rounded-2xl pixelated  absolute transition duration-200 inset-0 w-full h-full filter blur-3xl opacity-100"
            alt=""
          />
        )}
      </div>
    </div>
  );
};
const normalizeSrc = (src: string) => {
  return src && src.startsWith('/') ? src.slice(1) : src;
};
