import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { ButtonPublic } from '../Button/ButtonPublic';
import { TextInput } from '#components/Inputs/TextInput/TextInput';
import cn from 'classnames';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { AssetFlipCardPublic } from '#components/Assets/AssetFlipCard/AssetFlipCardPublic';
import { InlineLoading } from '#components/Loading/InlineLoading';
import { useCachedWallet } from '#lib/hooks/useCachedWallet';
import { useFirebaseCurrentUser } from '#lib/hooks/useFirebaseCurrentUser';
import { Asset, Disbursement } from '#types/index';
import { NextImage } from '#components/Assets/NextImage';
import { useCardanoWallet } from '#lib/wallet/WalletContext';
import {
  createBulkListing,
  generateAssetsToBeListed,
} from '#lib/plutus/DropspotMarketBulkManagement';
import {
  BUILD_ACTION,
  updateAssetListing,
} from '../../lib/plutus/DropspotMarketContract';
import {
  MarketActionModal,
  ProcessingTransactionModalComponent,
} from '#components/Assets/AssetPage/ChainInteraction/MarketActionModal';
import {
  DropspotMarketError,
  InternalErrorCode,
} from '#lib/plutus/DropspotMarketError';
import { errorIsAPIError } from '#components/Assets/AssetPage/ChainInteraction/utils';
import { BellAlertIcon } from '@heroicons/react/24/solid';
import { SaleEstimateTotals } from '#components/Assets/SaleEstimate/SaleEstimate';

import { useBulkListingStore } from './BulkListing';
import { OfferUIStore } from '#components/Assets/AssetPage/MarketActionOffers/OfferUIStore';

// <
//   TrackedTransactionsState,
//   [['zustand/devtools', TrackedTransactionsState]]
// >

interface OwnedAssetsTabProps {
  creatorId: string;
  forSlideOut?: boolean;
  inListingMode?: boolean;
  collectionFilterId?: string;
  didEnterListingMode?: (on: boolean) => void;
  gridSize?: 'full' | 'contained' | 'compact';
}

/** Store Hooks **/
const useBulkListingToggleAsset = () =>
  useBulkListingStore((s) => s.toggleAsset);
const useBulkListingRemoveAsset = () =>
  useBulkListingStore((s) => s.removeAsset);
const useBultListingClear = () => useBulkListingStore((s) => s.clear);
const useBulkListingAssets = () => useBulkListingStore((s) => s.currentAssets);
const useBulkListingSetAssetPrice = () =>
  useBulkListingStore((s) => s.setAssetPrice);
const useTotalListingValue = () =>
  Array.from(useBulkListingAssets().values()).reduce((total, a) => {
    try {
      return total + (Number.parseInt(a.askingPrice) || 0);
    } catch (e) {
      return 0;
    }
  }, 0);
const useTotalCommission = () =>
  Array.from(useBulkListingAssets().values()).reduce(
    (total, a) => total + (a.totalCommission || 0),
    0
  );
const useTotalEarnings = () => {
  const totalListingValue = useTotalListingValue();
  const totalCommission = useTotalCommission();
  const totalRoyalty = useTotalRoyalty();

  return totalListingValue - totalCommission - totalRoyalty;
};

/** End Store Hooks **/

const useTotalRoyalty = () =>
  Array.from(useBulkListingAssets().values()).reduce(
    (total, a) => total + (a.totalRoyalty || 0),
    0
  );

export function OwnedAssetsTab({
  creatorId,
  forSlideOut = false,
  inListingMode = false,
  collectionFilterId,
  gridSize = 'full',
  didEnterListingMode,
}: OwnedAssetsTabProps) {
  const bulkListingToggleAsset = useBulkListingToggleAsset();
  const bulkListingClearAssets = useBultListingClear();

  const selectedAssets = useBulkListingAssets();
  const [filter, setFilter] = useState<string>();
  const [localCollectionFilerId, setLocalCollectionFilterId] =
    useState(collectionFilterId);
  const [viewMode, setViewMode] = useState<'GRID' | 'GROUPED'>('GRID');
  const { push } = useRouter();
  console.log('Getting native assets for ', creatorId);
  const { currentUser } = useFirebaseCurrentUser();
  const {
    walletAssets,
    groupedWalletAssets,
    loading: loadingCachedWallet,
  } = useCachedWallet(creatorId);
  const [filteredData, setFilteredData] = useState(walletAssets);
  const [showMulti, setShowMulti] = useState(inListingMode);
  useEffect(() => {
    if (localCollectionFilerId) {
      setFilteredData(
        walletAssets?.filter((a) => a.projectId == localCollectionFilerId)
      );
    } else {
      setFilteredData(walletAssets);
    }
  }, [localCollectionFilerId, walletAssets]);

  useEffect(() => setShowMulti(inListingMode), [inListingMode]);

  useEffect(() => {
    if (selectedAssets.size == 0) {
      setShowMulti(false);
      didEnterListingMode && didEnterListingMode(false);
    }
  }, [selectedAssets]);

  // - select an asset callback add to array if not already there.
  // - if already there, remove it
  const doSelectAsset = (asset: Asset) => {
    console.log('Did select asset');

    bulkListingToggleAsset(asset);
  };

  const doEnterSelectionMode = (asset: Asset) => {
    console.log('Will enter selection mode');
    setShowMulti(true);

    didEnterListingMode && didEnterListingMode(true);
    bulkListingToggleAsset(asset);
  };

  const tooggleBulkList = () => {
    console.log('will toggle');

    didEnterListingMode && didEnterListingMode(false);
    setShowMulti(showMulti ? false : true);

    bulkListingClearAssets();
  };

  if (
    ((walletAssets && walletAssets.length) ?? 0) < 1 &&
    !loadingCachedWallet
  ) {
    return (
      <div className="w-full min-h-[33rem] flex flex-col justify-center items-center bg-white/25 dark:bg-gray-900/40 rounded-lg gap-2">
        <img
          className="w-24 opacity-30 object-fit shadow-xl"
          src="/img/like-empty-state.png"
          alt="No Likes"
        ></img>
        <h2 className="text-2xl font-bold mt-4 dark:text-white">No NFTs </h2>
        <p className="text-base font-light text-gray-500 dark:text-gray-300 max-w-md text-center mb-8">
          Explore and collect NFTs from the Dropspot Marketplace.
        </p>
        <ButtonPublic onClick={() => push('/marketplace/explore')}>Explore</ButtonPublic>
      </div>
    );
  }

  if (!walletAssets && loadingCachedWallet) {
    return (
      <div className="w-full min-h-[33rem] flex flex-col justify-center items-center  rounded-lg gap-2">
        {/* <img
          className="w-24 opacity-30 object-fit shadow-xl"
          src="/img/like-empty-state.png"
          alt="No Likes"
        ></img>{' '} */}
        <div className="flex-1 w-full flex flex-col justify-center items-center min-h-[26rem] gap-4">
          <InlineLoading ballSize={8} />
          <span className="text-gray-500 dark:text-gray-400 font-light">
            loading
          </span>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="relative w-full overflow-hidden overflow-y-scroll h-full max-h-full mt-4 scrollbar-hide">
        <div className="relative  ">
          {!forSlideOut && (
            <div className="sticky top-0 z-10 bg-base-200 dark:bg-base-200 w-full flex flex-col md:flex-row justify-between md:items-center pb-3 pt-2">
              <div>
                <TextInput
                  id={'search'}
                  label={''}
                  className={forSlideOut ? 'w-full' : 'w-full sm:w-96'}
                  rounded={true}
                  placeholder={'Name, collection or policy'}
                  icon={() => (
                    <MagnifyingGlassIcon className="w-5 h-5 text-gray-500" />
                  )}
                  value={filter}
                  onChange={function (text: string): void {
                    if (!!text && text.length > 0) {
                      setFilteredData(
                        walletAssets &&
                          walletAssets.filter(
                            (asset) =>
                              asset.title &&
                              asset.title
                                .toLowerCase()
                                .startsWith(text.toLowerCase())
                          )
                      );
                    } else {
                      setFilteredData(walletAssets);
                    }
                    setFilter(text);
                  }}
                />
              </div>
              <div className="flex flex-row gap-2 items-center mx-1">
                {groupedWalletAssets &&
                  groupedWalletAssets.length > 0 &&
                  groupedWalletAssets.map((group) => {
                    return (
                      <React.Fragment key={group.id}>
                        <div className="my-2">
                          <div
                            className={cn(
                              'relative w-10 h-10 rounded-full bg-red-200 cursor-pointer ',
                              {
                                'ring-secondary ring-2':
                                  group.id == localCollectionFilerId,
                              }
                            )}
                            onClick={() =>
                              setLocalCollectionFilterId(
                                localCollectionFilerId == group.id
                                  ? undefined
                                  : group.id
                              )
                            }
                          >
                            <NextImage
                              className="object-cover rounded-full"
                              layout="fill"
                              width={100}
                              priority={false}
                              src={group.coverURL}
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>
          )}
          <div className="flex-1 w-full">
            {viewMode === 'GROUPED' && (
              <div className="flex-1 w-full flex flex-col justify-start items-center min-h-[36rem]">
                <div className="flex flex-col items-start justify-start w-full my-8 gap-3">
                  {groupedWalletAssets &&
                    groupedWalletAssets.length > 0 &&
                    groupedWalletAssets.map((group) => {
                      return (
                        <React.Fragment key={group.id}>
                          <div className="flex flex-row items-center justify-between w-full border rounded-lg p-2 dark:border-gray-700/50 dark:bg-gray-900/50">
                            <div className="flex flex-row gap-4 items-center">
                              <span className="rounded-lg">
                                {group.coverURL &&
                                  group.coverURL.length > 0 && (
                                    <img
                                      src={group.coverURL}
                                      className="w-16 h-16 rounded-lg object-cover"
                                      alt=""
                                    ></img>
                                  )}
                              </span>
                              <span className="text-xl tracking-wide">
                                {group.title}
                              </span>
                            </div>

                            <span className="font-bold text-lg mr-6 tracking-wider">
                              {group.assets &&
                                group.assets.length > 0 &&
                                group.assets.length}{' '}
                              items
                            </span>
                          </div>
                          <div className="flex flex-row flex-wrap gap-8 p-4 my-4">
                            {' '}
                            {group.assets &&
                              group.assets.length > 0 &&
                              group.assets.map((a, idx) => {
                                return (
                                  <React.Fragment key={`${a.assetId}${idx}`}>
                                    {' '}
                                    <AssetFlipCardPublic
                                      showProjectInfo={true}
                                      heightClass={
                                        forSlideOut
                                          ? 'h-[18rem]'
                                          : 'h-[24rem] w-[17rem]'
                                      }
                                      link={`/nft/${a.assetId || a.id}`}
                                      showLikes={false}
                                      asset={a}
                                      viewedFromWallet={false}
                                      isInSelectionMode={showMulti}
                                      doSelectAsset={doSelectAsset}
                                      isAssetSelected={selectedAssets.has(a.id)}
                                    />
                                  </React.Fragment>
                                );
                              })}
                          </div>
                        </React.Fragment>
                      );
                    })}
                </div>
              </div>
            )}

            {viewMode === 'GRID' && (
              <div
                className={cn(
                  'w-full grid  gap-8 min-h-[36rem] h-full mt-6  scrollbar-hide',
                  { ' sm:grid-cols-2': forSlideOut },
                  {
                    'grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  2xl:grid-cols-4 4xl:grid-cols-5 5xl:grid-cols-6 6xl:grid-cols-7':
                      !forSlideOut && gridSize == 'full',
                  },
                  {
                    'grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-5 4xl:grid-cols-5 5xl:grid-cols-5 6xl:grid-cols-5':
                      !forSlideOut && gridSize == 'contained',
                  }
                )}
              >
                {!!filteredData &&
                  filteredData.length > 0 &&
                  filteredData?.map((nativeAsset, idx) => (
                    <React.Fragment key={`${nativeAsset.id}${idx}`}>
                      {!!nativeAsset.projectId ? (
                        <div className="w-full">
                          <AssetFlipCardPublic
                            showProjectInfo={true}
                            heightClass={
                              forSlideOut ? 'h-[18rem]' : 'h-[26rem]  w-full'
                            }
                            link={`/nft/${nativeAsset.id}`}
                            showLikes={false}
                            asset={nativeAsset}
                            viewedFromWallet={!forSlideOut}
                            isInSelectionMode={showMulti}
                            doSelectAsset={doSelectAsset}
                            isAssetSelected={selectedAssets.has(nativeAsset.id)}
                            doEnterSelectionMode={(a) =>
                              doEnterSelectionMode(a)
                            }
                          />
                        </div>
                      ) : (
                        <AssetFlipCardPublic
                          showProjectInfo={true}
                          heightClass={
                            forSlideOut ? 'h-[18rem]' : 'h-[26rem] w-full'
                          }
                          link={`/nft/${nativeAsset.asset || nativeAsset.id}`}
                          showLikes={false}
                          asset={nativeAsset}
                          viewedFromWallet={!forSlideOut}
                          isInSelectionMode={showMulti}
                          doSelectAsset={doSelectAsset}
                          isAssetSelected={selectedAssets.has(nativeAsset.id)}
                          doEnterSelectionMode={(a) => doEnterSelectionMode(a)}
                        />
                      )}
                    </React.Fragment>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

type BulkListingPropsView = {
  doHide: () => void;
  onTransactionComplete: () => void;
};

const BulkListing = ({
  doHide,
  onTransactionComplete,
}: BulkListingPropsView) => {
  const listings = useBulkListingAssets();
  const handleAssetPriceChange = useBulkListingSetAssetPrice();
  const removeAsset = useBulkListingRemoveAsset();
  const listingTotal = useTotalListingValue();
  const roayltyTotal = useTotalRoyalty();
  const commissionTotal = useTotalCommission();
  const earningsTotal = useTotalEarnings();

  const { wallet } = useCardanoWallet();

  const [doingStuff, setDoingStuff] = useState(false);
  const [error, setError] = useState<DropspotMarketError>();

  const [txStage, setTxStage] = useState('Building');

  const listingListener = useCallback((msg: BUILD_ACTION) => {
    console.log('Build Action', msg);
    setTxStage(msg);
  }, []);

  async function doListings() {
    try {
      // Validate all Listings have a Price

      if (
        Array.from(listings.values()).filter(({ askingPrice: price }) => !price)
          .length > 0
      )
        return;

      if (!wallet) return;

      setDoingStuff(true);
      const assetsToBeListed = generateAssetsToBeListed(listings);

      console.log('assetsToBeListed', JSON.stringify(assetsToBeListed));
      const result = await createBulkListing(
        wallet,
        assetsToBeListed,
        listingListener
      );

      //map
      if (!result) {
        return;
      }

      await Promise.all(
        assetsToBeListed.map((asset) => {
          console.log('updated asset listings for the list', asset);
          return updateAssetListing(
            asset.assetId,
            asset.price,
            result.txId,
            'LISTED',
            'LIST'
          );
        })
      );
      onTransactionComplete();
      setDoingStuff(false);
      // // OnTransactionCreated(result);
      // setTimeout(() => setDoingStuff(false), 2000); //this is the auto close
      console.log(JSON.stringify(result));
    } catch (err) {
      if (errorIsAPIError(err)) {
        setError(err);
      } else {
        console.error(err);
        setError(
          new DropspotMarketError({
            code: InternalErrorCode.UNEXPECTED,
            info: (err as Error).message,
            type: 'INTERNAL',
          })
        );
      }
    }
  }

  return (
    <>
      {doingStuff && (
        <MarketActionModal
          action="BUY"
          onClose={() => {
            setDoingStuff(false);
            setError(undefined);
          }}
          title="Bulk Listing"
          error={error}
          Component={(props) => (
            <ProcessingTransactionModalComponent
              {...{ ...props, txStage: txStage, action: 'CREATE LISTING' }}
            />
          )}
        />
      )}
      <div className="flex flex-row items-center justify-between">
        <span className="font-bold text-2xl mt-0">List for Sale</span>
        <span
          className={cn(
            'cursor-pointer duration-200 rounded-full w-9 h-9 dark:bg-gray-800 items-center justify-center flex'
          )}
          onClick={() => {
            doHide();
          }}
        >
          <XMarkIcon className="w-5 h=5" />
        </span>
      </div>

      <div className="w-full flex flex-col gap-4 my-8 pr-1">
        {Array.from(listings.values()).map(({ asset, askingPrice: price }) => {
          console.log('Show Asset', asset, price);

          return (
            <React.Fragment key={asset.asset_id}>
              <div className="flex flex-row justify-between w-full items-center">
                <div className="flex items-center gap-2">
                  <div className="w-12 h-12 object-cover rounded-lg overflow-hidden shadow-xl">
                    <NextImage
                      src={
                        asset.thumbnailUrl
                          ? asset.thumbnailUrl &&
                            asset.thumbnailUrl != 'holding'
                            ? asset.thumbnailUrl
                            : 'https://media.dropspot.io/placeholders%2FNo-Image-Placeholder.svg.png'
                          : asset.assetUrl && asset.assetUrl != 'holding'
                          ? asset.assetUrl
                          : 'https://media.dropspot.io/placeholders%2FNo-Image-Placeholder.svg.png'
                      }
                      quality={100}
                      b64={asset.thumbnailB64}
                      priority={true}
                      width={64}
                      height={64}
                      sizes={
                        '(max-width: 768px) 90vw, (max-width: 1200px) 20vw, 15vw'
                      }
                      className={
                        !!asset.imageDisplayPreference
                          ? asset.imageDisplayPreference + ' pixelated'
                          : 'object-cover pixelated' + ' shadow-2xl pixelated'
                      }
                    />
                  </div>

                  <div>{asset.title}</div>
                </div>
                <div className="flex items-center gap-1">
                  <TextInput
                    id={''}
                    label={''}
                    placeholder={'Enter ADA price'}
                    value={price}
                    type="number"
                    inputClassName={cn('outline-none', {
                      'border border-green-400 outline-none dark:outline-none':
                        !!price,
                      'border border-orange-400': !price,
                    })}
                    onChange={(text) => {
                      let myPrice: number | undefined = Number.parseFloat(text);
                      if (Number.isNaN(myPrice)) {
                        myPrice = undefined;
                      }
                      handleAssetPriceChange(asset, myPrice);
                    }}
                  />
                  <XMarkIcon
                    className="w-4 h-4 text-gray-500 dark:text-gray-400 dark:hover:text-red-500 hover:text-red-600 cursor-pointer hover:scale-110 duration-200"
                    onClick={() => removeAsset(asset)}
                  />
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
      <span className="pr-1">
        <SaleEstimateTotals
          price={listingTotal || 0}
          commissionPercent={0.02}
          commissionAmount={commissionTotal}
          royaltyAmount={roayltyTotal}
          earnings={earningsTotal}
        />
      </span>

      <div className=" border dark:border-gray-700 p-3 rounded-xl my-4 mr-1">
        <div className="flex flex-row gap-2">
          <BellAlertIcon className="w-5 h-5 text-yellow-600" />
          <span className="text-yellow-600 font-bold text-sm">Note:</span>
        </div>
        <p className="text-yellow-800 dark:text-yellow-700 font-light text-sm mt-1">
          Up to 1.5 ADA is required to ride with each NFT. This is reimbursed to
          you when your NFT is sold, or you cancel the listing.
        </p>
      </div>

      <div className="flex mt-8 w-full mr-1">
        <ButtonPublic
          containerClassName="w-full"
          isDisabled={
            Array.from(listings).filter(([price]) => !price).length > 0
          }
          className="text-lg font-bold"
          onClick={() => doListings()}
        >
          List for Sale
        </ButtonPublic>
      </div>
    </>
  );
};

const selectMyOffers = (state: OfferUIStore) => state.myOffers;
